/*
 * @Author: your name
 * @Date: 2022-03-31 17:17:15
 * @LastEditTime: 2022-04-20 15:44:49
 * @LastEditors: Please set LastEditors
 * @Description: 
 * @FilePath: /recovery_home_management/src/api/assessmentMethods.js
 */

/**
 * 页面: 评估方法
 * pageName: assessmentMethods
 **/
import request from '@/utils/request'

/**
 * @description: 获取评估方法列表数据
 */
export function getList(data) {
    return request({
        url: '/admin/kfAssessment/list',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 新增评估方法数据
 */
export function insertData(data) {
    return request({
        url: '/admin/kfAssessment/add',
        method: 'POST',
        data,
        showLoading: true
    })
}

/**
 * @description: 修改评估方法数据
 */
export function editData(data, timeStamp) {
    return request({
        url: '/admin/kfAssessment/update',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}
            

/**
 * @description: 获取评估方法详情数据
 */
export function getDetail(data) {
    return request({
        url: '/admin/kfAssessment/info',
        method: 'GET',
        params: data,
        showLoading: true
    })
}
            
/**
 * @description: 删除评估方法数据
 */
    export function deleteData(data, timeStamp) {
    return request({
        url: '/admin/kfAssessment/delete',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}

/**
 * @description: 启用/禁用
 */
export function changeState(data, timeStamp) {
    return request({
        url: '/admin/kfAssessment/changeState',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}
        

/**********************方案视频 ***************************/
/**
 * @description: 康复视频列表
 * @param {*} data
 * @return {*}
 */
 export function getVideoList(data) {
    return request({
        url: '/admin/kfAssessmentVideoDetails/list',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 新增治疗方案数据
 */
export function insertVideoData(data) {
    return request({
        url: '/admin/kfAssessmentVideoDetails/add',
        method: 'POST',
        data,
        showLoading: true
    })
}

/**
 * @description: 修改治疗方案数据
 */
export function editVideoData(data, timeStamp) {
    return request({
        url: '/admin/kfAssessmentVideoDetails/update',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}


/**
 * @description: 删除治疗方案数据
 */
export function deleteVideoData(data, timeStamp) {
    return request({
        url: '/admin/kfAssessmentVideoDetails/delete',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}