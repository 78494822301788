<!--
* @Description: 课程订单页面
* @Reference:
-->

<template>
  <el-container>
    <el-header height="auto">
      <searchForm
        ref="mainSearch"
        exportStatus
        @exportFun="toExport"
        :searchoptions="searchOptions"
        @search="search"
        :backStatus="backStatus"
      />
    </el-header>
    <el-main class="basic-main">
      <generalTable
        ref="mainTable"
        :table-data="tableData"
        :config="tableConfig"
        :total="total"
        @updatePage="getList"
      >
        <template slot="photoUrl" slot-scope="scope">
          <el-image
            style="width: 50px; height: 50px; margin-top: 10px"
            src="http://public.rehabilitation-qiniu-file.dph.cn/FuTHzxDJPlJfwnJCrUEtW63rNDKC"
            :preview-src-list="[
              commonConfig.$filePublicUrl + scope.data.row.photoUrl
            ]"
          >
          </el-image>
        </template>
        <template slot="expandPanel" slot-scope="scope">
          <el-form
            label-position="left"
            inline
            class="service-table-expand"
          >
            <el-descriptions title="商品明细"> </el-descriptions>
            <el-form-item label="">
              <span class="service-no">{{ 1 }}.</span>
            </el-form-item>
            <!-- <el-form-item label="">
              <span>
                <el-image
                  style="width: 35px; height: 35px;"
                  :src="item.goods_image"
                  :preview-src-list="[goods_image]"
                >
                </el-image
              ></span>
            </el-form-item> -->
            <el-form-item label="商品名称">
              <span>{{ scope.data.row.goodsName }}</span>
            </el-form-item>
            <el-form-item label="数量">
              <span>{{ 1 }}</span>
            </el-form-item>
            <el-form-item label="单价">
              <span>{{ scope.data.row.unitPrice }}元</span>
            </el-form-item>
          </el-form>
        </template>
      </generalTable>
      <detailDialog :id="checkId" ref="detail" />
    </el-main>
  </el-container>
</template>

<script>
import { getXETOrderList } from "@/api/order";
import searchForm from "@/components/SearchForm";
import generalTable from "@/components/Table";
import { exportExcel } from "@/utils/common";
import detailDialog from "@/views/financial/invoice/detailDialog";
import moment from "moment";

export default {
  name: "Invoice",
  components: {
    searchForm,
    generalTable,
    detailDialog
  },
  data() {
    return {
      tableData: [],
      searchOptions: [
        {
          type: "input",
          placeholder: "请输入...",
          key: "orderNo",
          label: "订单号",
          maxlength: 50,
          defaultVal: ""
        },
        {
          type: "input",
          placeholder: "请输入...",
          key: "patientNumber",
          label: "患者手机号",
          defaultVal: ""
        },
        {
          type: "datetimerange",
          placeholder: "请输入...",
          key: "order",
          label: "下单时间",
          defaultVal: [
            moment(new Date())
              .subtract(30, "days")
              .format("YYYY-MM-DD") + " 00:00:00",
            moment().format("YYYY-MM-DD") + " 23:59:59"
          ]
        },
        {
          type: "input",
          placeholder: "请输入...",
          key: "doctorNumber",
          label: "医生手机号",
          defaultVal: ""
        },
        {
          type: "select",
          placeholder: "请选择",
          key: "orderState",
          filterable: true,
          multiple: false,
          label: "订单状态",
          options: [
            {
              value: "0",
              label: "未支付"
            },
            {
              value: "1",
              label: "支付成功"
            },
            {
              value: "2",
              label: "支付失败"
            },
            {
              value: "3",
              label: "已退款(如拼团未成功等情况)"
            },
            {
              value: "6",
              label: "订单超时未支付，自动取消"
            },
            {
              value: "7",
              label: "手动取消"
            },
            {
              value: "8",
              label: "退款中"
            },
            {
              value: "9",
              label: "退款失败"
            },
            {
              value: "10",
              label: "退款成功"
            },
            {
              value: "11",
              label: "部分退款"
            }
          ]
        },
        {
          type: "select",
          placeholder: "请选择",
          key: "payType",
          filterable: true,
          multiple: false,
          label: "支付方式",
          options: [
            {
              value: "-1",
              label: "未指定支付方式"
            },
            {
              value: "0",
              label: "线上微信"
            },
            {
              value: "1",
              label: "线上支付宝"
            },
            {
              value: "2",
              label: "IOS支付"
            },
            {
              value: "3",
              label: "安卓支付"
            },
            {
              value: "4",
              label: "线下支付"
            }
          ]
        }
      ],
      tableConfig: {
        id: "invoice",
        align: "center",
        selection: "single",
        index: true,
        pagination: true,
        column: [
          // {
          //   label: " ",
          //   expand: "expand",
          //   slot: "expandPanel",
          //   prop: ""
          // },
          {
            label: "订单号",
            prop: "orderNo",
            width: 300
          },
          {
            label: "课程名称",
            prop: "goodsName",
            width: 300
          },
          {
            label: "购买患者手机号",
            prop: "patientNumber",
            width: 120
          },
          {
            label: "购买患者姓名",
            prop: "patientName",
            width: 120
          },
          {
            label: "医生手机号",
            prop: "doctorNumber",
            width: 120
          },
          {
            label: "医生姓名",
            prop: "doctorName",
            width: 120
          },
          {
            label: "分润比例%",
            prop: "shareInProportion",
            width: 120
          },
          {
            label: "分润金额(元)",
            prop: "amount",
            width: 120,
            formatter: records => {
              return (
                (records.amount || 0)
              );
            }
          },
          {
            label: "原价（元）",
            width: 120,
            formatter: records => {
              console.log('discountAmountTotal=',records.discountAmountTotal)
              console.log('actualFee=',records.actualFee)
              return (
                parseFloat((records.discountAmountTotal || 0)) +
                parseFloat(records.actualFee || 0)
              );
            }
          },
          {
            label: "活动折扣总金额(元)",
            prop: "discountAmountTotal",
            width: 130,
            formatter: records => {
              return (
                (records.discountAmountTotal || 0)
              );
            }
          },
          {
            label: "实收金额(元)",
            prop: "actualFee",
            width: 120,
            formatter: records => {
              return (
                records.actualFee || 0
              );
            }
          },
          {
            label: "订单状态",
            prop: "orderState",
            formatter: (row, column) => {
              // 0-未支付，1-支付成功，2-支付失败，3-已退款(如拼团未成功等情况)，6-订单超时未支付，自动取消，7-手动取消，8-退款中，9-退款失败，10-退款成功，11-部分退款
              const orderState = {
                0:"未支付",
                1:"支付成功",
                2:"支付失败",
                3:"已退款(如拼团未成功等情况)",
                6:"订单超时未支付，自动取消",
                7:"手动取消",
                8:"退款中",
                9:"退款失败",
                10:"退款成功",
                11:"部分退款",
              };
              return orderState[row.orderState];
            },
            width: 160
          },
          {
            label: "支付方式",
            prop: "payType",
            formatter: (row, column) => {
              if (row.payType == -1) {
                return '未指定支付方式 '
              }
              const payWay = {
                0: "线上微信",
                1: "线上支付宝",
                2: "IOS支付",
                3: "安卓支付",
                4: "线下支付",
              };
              return payWay[row.payType];
            },
            width: 160
          },
          {
            label: "订单创建时间",
            prop: "createdTime",
            width: 160,
            formatter: (row, column) => {
              return this.moment(row.createdTime).format(
                "YYYY-MM-DD HH:mm:ss"
              );
            }
          },
          {
            label: "支付时间",
            prop: "payStateTime",
            width: 160,
            formatter: (row, column) => {
              if (
                !row.payStateTime ||
                "0000-00-00 00:00:00" == row.payStateTime
              ) {
                return "-";
              }
              return this.moment(row.payStateTime).format(
                "YYYY-MM-DD HH:mm:ss"
              );
            }
          }
        ]
      },
      paramObj: null,
      total: 0,
      checkId: "",
      backStatus: true,
      timeStamp: ""
    };
  },
  created() {
    this.getList();
  },
  mounted: function() {},
  methods: {
    search(data) {
      Object.assign(this.paramObj, data);
      this.$refs.mainTable.handleCurrentChange(1);
    },
    disabledDate(time) {
      return time.getTime() > Date.now();
    },
    getList() {
      this.$nextTick(() => {
        const param = this.getParam();
        // 小鹅通空窜是不传递给后台
        if (!param["orderNo"]) {
          delete param["orderNo"];
        }
        if (!param["orderState"]) {
          delete param["orderState"];
        }
        if (!param["patientNumber"]) {
          delete param["patientNumber"];
        }
        if (!param["doctorNumber"]) {
          delete param["doctorNumber"];
        }
        let _this = this;
        getXETOrderList(param)
          .then(({ res, timeStamp }) => {
            if (res.records) {
              _this.tableData = res.records;
              _this.timeStamp = timeStamp;
              _this.total = res.total;
            } else {
              _this.$message({
                message: res.msg || "查询失败",
                duration: 5000,
                type: "warning"
              });
            }
          })
          .catch(handleError => {
            console.log("handleError====", handleError);
          });
      });
    },
    toExport() {
      const paramObj = this.$refs.mainSearch.packageData();
      console.log(paramObj);
      let url = process.env.VUE_APP_BASE_API + "/xiaoePayment/order/export";
      let _this = this;
      exportExcel(url, {}, paramObj, "课程订单", "xls")
        .then(res => {})
        .catch(function(error) {
          _this.$message({
            message: error.message || "导出失败",
            duration: 5000,
            type: "warning"
          });
        });
    },
    getParam() {
      const paramObj = this.$refs.mainSearch.packageData();
      const page = this.$refs.mainTable.getPage();
      const param = Object.assign({}, this.paramObj, page, paramObj);
      this.paramObj = param;
      return param;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
</style>
