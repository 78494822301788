
<!--
* @Description: 评估分类页面
* @Reference:
-->

<template>
    <el-container>
        <el-header height="auto">
            <searchForm
                ref="mainSearch"
                :searchoptions="searchOptions"
                @search="search"
                :backStatus="backStatus"
            />
        </el-header>
        <el-main class="basic-main">
            <el-row class="table-btn-group"
                ><el-button type="primary" @click="toInsert()"
                    >新增</el-button
                ></el-row
            >
            <generalTable
                ref="mainTable"
                :table-data="tableData"
                :config="tableConfig"
                :total="total"
                @updatePage="getList"
                @onClickRow="onClickRow"
            >
                <template slot="handle" slot-scope="scope">
                    <el-button type="text" @click="toDetail(scope)"
                        >查看</el-button
                    >
                    <el-button type="text" @click="toEdit(scope)"
                        >编辑</el-button
                    >
                    <el-button type="text" class="delBtn" @click="toDel(scope)"
                        >删除</el-button
                    >
                </template>
            </generalTable>
            <detailDialog
                :id="checkId"
                :platform-array="platformArray"
                ref="detail"
            />
            <addDialog
                :id="checkId"
                :table-data="tableData"
                :platform-array="platformArray"
                ref="insert"
                :timeStamp="timeStamp"
                @submit="search"
            />
        </el-main>
    </el-container>
</template>

<script>
import { getAssessmentClassList, deleteData } from "@/api/assessmentClass";
import searchForm from "@/components/SearchForm";
import generalTable from "@/components/Table";
import addDialog from "@/views/assessment/assessmentClass/addDialog";
import detailDialog from "@/views/assessment/assessmentClass/detailDialog";
import { getDict } from "@/api/common";

export default {
    name: "assessmentClass",
    components: {
        searchForm,
        generalTable,
        detailDialog,
        addDialog,
    },
    data() {
        return {
            tableData: [],
            searchOptions: [
                {
                    type: "input",
                    placeholder: "请输入...",
                    key: "officesName",
                    label: "分类名称",
                    defaultVal: "",
                },
                {
                    type: "select",
                    placeholder: "请选择",
                    key: "platform",
                    filterable: true,
                    
                    label: "平台标识",
                    options: [],
                    defaultVal: [],
                },
            ],
            tableConfig: {
                id: "classify",
                align: "center", // 不必填 默认为center
                selection: "normal", // 不必填 多选: multiple  单选: single  默认为 normal
                expandOpen: true, // 默认展开全部
                index: true, // 不必填 序号
                column: [
                    {
                        label: "分类名称", // 必填
                        prop: "officesName", // 必填
                    },
                    {
                        label: "平台标识", // 必填
                        prop: "platform", // 必填
                        formatter: (row) => {
                            // 根据字典表展示对应项目的label值
                            const currentType = this.platformArray.filter(
                                (type) => type.value === row.platform
                            );
                            if (currentType.length) {
                                return currentType[0].label;
                            }
                        },
                        width: "150",
                    },
                    {
                        label: "级别", // 必填
                        prop: "level", // 必填
                        width: "100",
                    },
                    {
                        label: "简介", // 必填
                        prop: "introduction", // 必填
                    },
                    {
                        label: "排序", // 必填
                        prop: "sort", // 必填
                        width: "100",
                    },
                    {
                        slot: "handle", // 不必填 单列插槽
                        label: "操作", // 必填
                        fixed: "right", // 不必填 固定列  left right
                        width: "220", // 不必填
                    },
                ],
            },
            platformArray: [], //平台
            paramObj: null, // 筛选项
            total: 0, // 总条数
            checkId: "", // 单条数据id 用于详情或编辑请求数据用
            backStatus: true, //返回按钮的隐藏显示
            timeStamp: "", //时间戳 用于修改数据或删除数据
        };
    },
    created() {
        this.getList();
        this.getPlatformList();
    },
    mounted: function () {},
    methods: {
        search(data) {
            Object.assign(this.paramObj, data);
            this.$refs.mainTable.handleCurrentChange(1);
        },
        getList() {
            this.$nextTick(() => {
                const param = this.getParam();
                getAssessmentClassList(param).then(({ res, timeStamp }) => {
                    this.tableData = res;
                    this.timeStamp = timeStamp;
                });
            });
        },
        toInsert() {
            this.checkId = "";
            this.$refs.insert.updateVisible(true);
        },
        toDetail(scope) {
            this.checkId = scope.data.row.id;
            this.$refs.detail.updateVisible(true);
        },
        toEdit(scope) {
            if (this.ifTopOrg(scope.data.row.id)) {
                return false;
            }
            this.checkId = scope.data.row.id;
            this.$refs.insert.updateVisible(true);
        },
        toDel(scope) {
            const { $delMsg, $delTitle, $messageBoxConfig, $handleSuccess } =
                this.commonConfig;
            this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
                const param = { id: scope.data.row.id };
                deleteData(param, this.timeStamp).then(({ res }) => {
                    this.$message({ ...$handleSuccess });
                    this.getList();
                });
            });
        },
        onClickRow(row) {
            console.log("点击行:", row);
        },
        getParam() {
            const paramObj = this.$refs.mainSearch.packageData();
            const page = this.$refs.mainTable.getPage();
            const param = Object.assign({}, this.paramObj, page, paramObj);
            this.paramObj = param;
            return param;
        },
        /**
         * @description: 获取所属平台
         * @param {*}
         * @return {*}
         */
        getPlatformList() {
            getDict({ type: "platform" }).then(({ res }) => {
                this.platformArray = res.platform;
                this.searchOptions[1].options = res.platform;
            });
        },
        /** 判断该机构是否为顶层机构
         * @description: 如果为顶层机构，则进行消息提示，并返回true；否则返回false
         * @param {String} id 机构id
         * @return {Boolean} 顶层机构返回true，其余机构返回false
         */
        ifTopOrg(id) {
            if (id === "0") {
                this.$message({
                    message: cannotChangeMsg,
                    type: "warning",
                });
                return true;
            }
            return false;
        },
    },
};
</script>

<style lang='scss' scoped>
@import "~@/styles/variables.scss";
</style>

        
        