
<template>
  <el-dialog
    width="70%"
    :title="id === '' ? '新增字典管理' : '修改字典管理'"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    append-to-body
  >
    <el-form
      ref="insertForm"
      :model="form"
      label-position="right"
      label-width="120px"
      class="formBox"
      :rules="rules"
    >
      <el-row :gutter="10" type="flex" justify="start">
        <el-col :sm="12" :md="12" :lg="8" :xl="12">
          <el-form-item label="数据值" prop="value">
            <el-input
              v-model="form.value"
              placeholder="请输入..."
              clearable
              maxlength="20"
            />
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="8" :xl="12">
          <el-form-item label="标签名" prop="label">
            <el-input
              v-model="form.label"
              placeholder="请输入..."
              clearable
              maxlength="20"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10" type="flex" justify="start">
        <el-col :sm="12" :md="12" :lg="8" :xl="12">
          <el-form-item label="类型" prop="type">
            <el-input
              v-model="form.type"
              placeholder="请输入..."
              clearable
              maxlength="20"
            />
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="8" :xl="12">
          <el-form-item label="描述" prop="description">
            <el-input
              v-model="form.description"
              placeholder="请输入..."
              clearable
              maxlength="20"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10" type="flex" justify="start">
        <el-col :sm="24">
          <el-form-item label="备注" prop="remark">
            <el-input
              v-model="form.remark"
              rows="3"
              type="textarea"
              placeholder="请输入"
              clearable
              maxlength="200"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit()">提 交</el-button>
      <el-button @click="updateVisible(false)">取 消</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { insertData } from "@/api/dictionaryManagement";
export default {
  props: {
    id: {
      type: String,
    },
    timeStamp: {
      type: String,
    },
  },
  data() {
    return {
      checkId: "",
      tableConfig: {
        align: "center", // 不必填 默认为center
        selection: "normal", // 不必填 多选: multiple  单选: single  默认为 normal
        index: true, // 不必填 序号
        pagination: false, // 不必填 分页 默认为false
      },
      rules: {
        value: [
          { required: true, message: "请输入数据值", trigger: "blur" },
        ],
        label: [
          { required: true, message: "请输入标签名", trigger: "blur" },
        ],
        type: [
          { required: true, message: "请输入类型", trigger: "blur" },
        ],
        description: [
          { required: true, message: "请输入描述", trigger: "blur" },
        ],
      },
      options: [],
      valueArr:[],
      labelArr:[],
      form: {
      },
      total: 0,
      visible: false,
    };
  },
  watch: {
    visible(newVal, oldVal) {
      if (newVal === true) {
        if (this.id) {
          this.getDetail();
        }
        //...todo
      }
    },
  },
  created() {

  },
  mounted() {},
  methods: {
    submit() {
      this.$refs.insertForm.validate((valid) => {
        this.valueArr.push(this.form.value);
        this.labelArr.push(this.form.label);
        if (valid) {
          const argument = {
            value:this.valueArr,
            label:this.labelArr,
            description:this.form.description,
            type:this.form.type,
            remark:this.form.remark
          }
          const parm = argument;
          if (this.id === "") {
            insertData(parm, this.timeStamp).then(({ res }) => {
              this.submitSuccess(res);
            });
          } else {
            editData(parm, this.timeStamp).then(({ res }) => {
              this.submitSuccess(res);
            });
          }
        }
      });
    },
    submitSuccess(res) {
      this.valueArr = []
      this.labelArr = []
      this.$message({ ...this.commonConfig.$handleSuccess });
      this.updateVisible(false);
      this.$emit("submit");
    },
    getDetail() {
      getDetail({ id: this.id }).then(({ res, timeStamp }) => {
        this.form = res;
        // this.timeStamp = timeStamp
      });
    },
    toDel(scope) {
      const { $delMsg, $delTitle, $messageBoxConfig } = this.commonConfig;
      this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
        const param = { id: scope.data.row.id };
        deleteData(param, this.timeStamp).then(({ res }) => {
          this.$message({ ...this.commonConfig.$handleSuccess });
        });
      });
    },
    updateVisible(val) {
      this.visible = val;
      if (val === false) {
        this.$refs.insertForm.resetFields();
      }
    },
  },
};
</script>
<style scoped lang='scss'>
</style>
      
        