<template>
    <el-container>
        <el-tabs style="width: 100%; background-color: #fff; padding: 1em;" v-model="state"
            @tab-click="handleClick">
            <el-tab-pane label="服务中患者" name="1">
                <template v-if="state == '1'">
                    <el-header height="auto">
                        <searchForm ref="mainSearch" :searchoptions="searchOptions" @search="search" :backStatus="true" />
                    </el-header>
                    <el-main class="basic-main">
                        <generalTable ref="mainTable" :table-data="tableData" :config="tableConfig" :total="total" @updatePage="getList">


              <template slot="taskFinishCount" slot-scope="scope">

                <el-button size="text" type="primary"
                  @click="$refs.patientDialog.open(scope.data.row)">
                  {{scope.data.row.taskFinishCount}} / {{scope.data.row.taskTotalCount}}
                </el-button>
              </template>
                    </generalTable>
                </el-main>
            </template>
            </el-tab-pane>
            <el-tab-pane label="待结案患者" name="2">
                <template v-if="state == '2'">
                    <el-header height="auto">
                        <searchForm ref="mainSearch" :searchoptions="searchOptions" @search="search" :backStatus="true" />
                    </el-header>
                    <el-main class="basic-main">
                        <generalTable ref="mainTable" :table-data="tableData" :config="tableConfig" :total="total" @updatePage="getList">
              <template slot="taskFinishCount" slot-scope="scope">

                <el-button size="text" type="primary"
                  @click="$refs.patientDialog.open(scope.data.row)">
                  {{scope.data.row.taskFinishCount}} / {{scope.data.row.taskTotalCount}}
                </el-button>
              </template>
                        </generalTable>
                    </el-main>
                </template>
            </el-tab-pane>
            <el-tab-pane label="已结案患者" name="3">
                <template v-if="state == '3'">
                    <el-header height="auto">
                        <searchForm ref="mainSearch" :searchoptions="searchOptions" @search="search" :backStatus="true" />
                    </el-header>
                    <el-main class="basic-main">
                        <generalTable ref="mainTable" :table-data="tableData" :config="tableConfig2" :total="total" @updatePage="getList">
              <template slot="taskFinishCount" slot-scope="scope">

                <el-button size="text" type="primary"
                  @click="$refs.patientDialog.open(scope.data.row)">
                  {{scope.data.row.taskFinishCount}} / {{scope.data.row.taskTotalCount}}
                </el-button>
              </template>
                        </generalTable>
                    </el-main>
                </template>
            </el-tab-pane>
        </el-tabs>
        <patientDialog ref="patientDialog" />
    </el-container>
</template>
<script>
    import request from '@/utils/request'
    import { getDict } from "@/api/common";
    import searchForm from '@/components/SearchForm'
    import generalTable from '@/components/Table'
    import patientDialog from './patientDialog'


    export default {
        components: {
            searchForm, generalTable, patientDialog
        },
        data() {
            return {
                visible: false,
                state: '1',
                total: 0,
                paramObj: {},
                searchOptions: [
                    {
                        type: 'input',
                        placeholder: '请输入患者姓名',
                        key: 'patientName',
                        label: '患者姓名',
                        defaultVal: ''
                    },
                    {
                        type: 'input',
                        placeholder: '请输入手机号',
                        key: 'phone',
                        label: '手机号',
                        defaultVal: ''
                    },
                ],
                tableData: [],
                tableConfig: {
                    id: 'doctorListDialog',
                    index: true,
                    pagination: true,
                    // saveSelected: true,
                    column: [
                        {
                            label: '患者姓名',
                            prop: 'patientName',
                        },
                        {
                            label: '手机号',
                            prop: 'phone',
                        },
                        {
                            label: '性别',
                            prop: 'sex',
                            with: '100px'
                        },
                        {
                            label: '年龄',
                            prop: 'age',
                            with: '100px'
                        },
                        {
                            label: '订单金额（元）',
                            prop: 'price',
                        },
                        {
                            label: '订单号',
                            prop: 'orderNo',
                        },
                        {
                            label: '下单时间',
                            prop: 'orderCreateTime',
                            formatter: (row, column) => {
                                return row.orderCreateTime ? this.moment(row.orderCreateTime).format("YYYY-MM-DD HH:mm:ss") : '--'
                            }
                        },
                        {
                            label: '执行进度',
                            prop: 'taskFinishCount',
                            slot: 'taskFinishCount',
                        },
                    ]
                },
                tableConfig2: {}
            };
        },
        watch: {
        },
        created() {
            this.tableConfig2 = JSON.parse(JSON.stringify(this.tableConfig))
            this.tableConfig2.column.splice(6, 1, {
              label: '下单时间',
              prop: 'orderCreateTime',
              formatter: (row, column) => {
                  return row.orderCreateTime ? this.moment(row.orderCreateTime).format("YYYY-MM-DD HH:mm:ss") : '--'
              }
            });
            this.tableConfig2.column.splice(7, 0, {
              label: '结案时间',
              prop: 'finishDate',
              formatter: (row, column) => {
                return row.finishDate ? this.moment(row.finishDate).format("YYYY-MM-DD HH:mm:ss") : '--'
              }
            });
            // console.log('this.tableConfig2 -----------> ', this.tableConfig2);


          const { tabIndex, doctorId, schemeId } = this.$route.query;
          this.pathParams = {doctorId, schemeId};
          this.state = tabIndex;
          this.getList();
        },
        mounted() { },
        methods: {
            handleClick() {
                if (this.$refs.mainSearch) {
                    this.$refs.mainSearch.resetForm();
                } else {
                    this.$refs.mainTable.handleCurrentChange(1);
                    // this.getList();
                }
            },
            search(data) {
                Object.assign(this.paramObj, data);
                this.$refs.mainTable.handleCurrentChange(1);
            },
            /**
             * @description:
             * @param {*}
             * @return {*}
             */
            getList() {
                this.tableData = [];
                this.$nextTick(() => {
                    const param = this.getParam();
                    request({
                        url: 'admin/kfSchemeInfo/getSchemeDetailsList',
                        method: 'GET',
                        params: {
                            ...this.paramObj,
                            ...this.page,
                            ...this.pathParams,
                            state: this.state,
                        },
                        showLoading: true
                    }).then(({ res, timeStamp }) => {
                        this.tableData = res.records;
                        this.total = parseInt(res.total)
                    });
                });
            },
            getParam() {
                const paramObj = this.$refs.mainSearch.packageData();
                const page = this.$refs.mainTable.getPage();
                const param = Object.assign({}, this.paramObj, page, paramObj);
                this.paramObj = param;
                return param;
            },

        },
    };
</script>
<style scoped lang='scss'>
</style>
