<template>
  <el-dialog width="100%" :title="id ? '修改内容' : '新增内容'" :visible.sync="visible" :close-on-click-modal="false"
      :close-on-press-escape="false" :show-close="false" append-to-body>
      <el-form ref="insertForm" :model="form" label-position="right" label-width="120px" class="formBox"
          :rules="rules">

          <el-row :gutter="10" type="flex" justify="start">
              
              <el-col :sm="12" :md="12" :lg="8" :xl="6">
                  <el-form-item label="内容标题" prop="name">
                      <el-input v-model="form.name" placeholder="请输入" clearable maxlength="64" />
                  </el-form-item>
              </el-col>
            
              <el-col :sm="12" :md="12" :lg="8" :xl="6">
                <el-form-item label="分类" prop="kfTypeId">
                    <el-cascader
                        v-model="form.kfTypeId"
                        :options="classifyArray"
                        :props="{
                            checkStrictly: true,
                            emitPath: false,
                            label: 'name',
                            value: 'id',
                        }"
                        clearable
                        filterable
                    />
                </el-form-item>
            </el-col>
              <el-col :sm="12" :md="12" :lg="8" :xl="6">
                  <el-form-item label="描述" prop="synopsis">
                      <el-input v-model="form.synopsis" placeholder="请输入" clearable maxlength="8"
                         />
                  </el-form-item>
              </el-col>
              <el-col :sm="120" :md="120" :lg="8" :xl="6">
                <el-form-item label="发送时间" prop="pushTime">
                  <el-date-picker
                    v-model="form.pushTime"
                    value-format="timestamp" 
                    type="datetime"
                    placeholder="选择日期时间">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :sm="120" :md="120" :lg="8" :xl="6">
                <el-form-item label="结束时间" prop="endPushTime">
                  <el-date-picker
                    v-model="form.endPushTime"
                    value-format="timestamp" 
                    type="datetime"
                    placeholder="选择日期时间">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :sm="12" :md="12" :lg="8" :xl="6">
                <el-form-item label="状态" prop="contentState">
                  <el-switch
                    v-model="form.contentState"
                    name="contentState"
                    active-text="上架"
                    inactive-text="下架"
                    inactive-value="2"
                    active-value="1"
                  />
                </el-form-item>
              </el-col>
              <el-col :sm="24" :md="24" :lg="24" :xl="24">
                  <el-form-item label="内容详情" prop="introduction" class="content-wrapper">
                      <tinymce :height="500" v-if="visible" name="introduction" class="introduction" v-model="form.introduction" id="tinymce">
                      </tinymce>
                  </el-form-item>
              </el-col>

              <el-col :sm="12" :md="12" :lg="8" :xl="12">
                  <el-form-item label="内容图片" prop="photoUrl">
                      <div class="imgList">
                          <div v-for="(url, index) in form.photoList" class="defalutImg " :key="url+index">
                              <div class="remove" @click="removePhoto(index)"><i class="el-icon-error"></i></div>
                              <el-image style="width: 150px; height: 150px" :src="commonConfig.$filePublicUrl + url"
                                  fit="contain"></el-image>
                          </div>
                      </div>
                      <div class="img">
                          <div :src="form.photoUrl" @click="setLogoClick" class="defalutImg">
                              <i class="el-icon-plus"></i>
                          </div>
                      </div>
                      <input type="file" name="form.photoUrl" accept="image/gif,image/jpeg,image/jpg,image/png"
                          style="display: none" multiple="multiple" @change="changeLogoImage($event)"
                          ref="logoInput" />
                  </el-form-item>
              </el-col>
          </el-row>
          <el-divider />


      </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="submit()">提 交</el-button>
          <el-button @click="updateVisible(false)">取 消</el-button>
      </span>
  </el-dialog>
</template>
<script>
  import Tinymce from "@/components/Tinymce";
  import { insertData, editData, getDetail } from '@/api/contentManage'
  import { getDict } from '@/api/common'
  import { uploadPrivateFile, uploadPublicFile } from "@/utils/uploadQiniu.js";

  export default {
      components: {
          Tinymce
      },
      props: {
          id: {
              type: String
          },
          timeStamp: {
              type: String
          },
        classifyArray: {
            required: true,
            type: Array,
        },
      },
      updated() {
      },
      data() {
          return {
              rules: {
                name: [
                    { required: true, message: '请输入内容名称', trigger: 'blur' },
                    { min: 3, max: 60, message: '长度在 3 到 60 个字符', trigger: 'blur' }
                ],
                kfTypeId: [
                    {
                        required: true,
                        message: "请选择分类",
                        trigger: "change",
                    },
                ],

              },
              form: {
                  name: "",
                  kfTypeId: "",
                  contentState:'1',
                  pushTime: new Date().getTime(),
                  endPushTime: new Date().getTime(),
                  inventory: "",
                  photoList: [], // 内容图片列表
                  photoUrl: "", //内容图片
                  introduction: "" ,// 富文本
                  
                  
              },
              goodsType: [],
              goodsState: [],
              visible: false,
          }
      },
      watch: {
          visible(newVal, oldVal) {
              if (newVal === true) {
                  if (this.id) {
                      this.getDetail()
                  }
              }
          }
      },
      created() {
          this.getDict();
          this.getDictType();
      },
      mounted() { },
      methods: {
          submit() {
              this.$refs.insertForm.validate((valid) => {
                  if (valid) {
                      const parm = { ...this.form }
                      if (!this.id) {
                          insertData(parm).then(({ res }) => {
                              this.submitSuccess(res)
                          })
                      } else {
                          parm.id = this.id
                          editData(parm, this.timeStamp).then(({ res }) => {
                              this.submitSuccess(res)
                          })
                      }
                  }
              })
          },
          submitSuccess(res) {
              this.$message({
                  showClose: true,
                  message: '操作成功',
                  type: 'success'
              })
              this.$emit('submit')
              this.updateVisible(false)
          },
          getDetail() {
              const param = {}
              if (this.id) {
                  param.id = this.id
              }
              getDetail(param).then(({ res, timeStamp }) => {
                  const { id, ...temp } = res
                  this.form = temp
                  this.form.photoList = this.form.photoUrl.split(",")
              })
          },
          getDictType() {
              getDict({ type: 'goods_type' }).then(({ res }) => {
                  this.goodsType = res.goods_type;
              });
          },
          getDict() {
              getDict({ type: 'goods_state' }).then(({ res }) => {
                  this.goodsState = res.goods_state;
              });
          },
          updateVisible(val) {
              if (val === false) {
                  this.$refs.insertForm.resetFields()
                  this.form = {
                    name: "",
                  kfTypeId: "",
                  contentState:'1',
                  pushTime: new Date().getTime(),
                  endPushTime: new Date().getTime(),
                  inventory: "",
                  photoList: [], // 内容图片列表
                  photoUrl: "", //内容图片
                  introduction: "" ,// 富文本
                  }
              }
              this.visible = val
          },
          removePhoto(index) {
              const { $delMsg, $delTitle, $messageBoxConfig, $handleSuccess } = this.commonConfig
              this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
                  this.form.photoList.splice(index, 1)
                  this.form.photoUrl = this.form.photoList.join()
              })
          },

          setLogoClick() {
              this.$refs.logoInput.click();
          },

          uploadPublicFile(file) {
              return new Promise((resolve) => {
                  uploadPublicFile(file)
                      .then((success) => {
                          console.log("upload success------", success)
                          this.form.photoList.push(success)
                          resolve(success)
                      })
                      .catch((handleError) => {
                          console.log("handleError====", handleError);
                      });
              })
          },

          /**
          * @description: 上传图片
          * @param {*} event
          * @return {*}
          */
          async changeLogoImage(event) {
              console.log("files------", event.target.files)
              let file = event.target.files[0];
              const thiz = this
              for (let file of event.target.files) {
                  await this.uploadPublicFile(file)
              }
              this.form.photoUrl = this.form.photoList.join()
              // thiz.form.photoUrl = success
              // uploadPublicFile(file)
              //     .then((success) => {
              //         console.log("success====", success);
              //         thiz.form.photoUrl = success
              //     })
              //     .catch((handleError) => {
              //         console.log("handleError====", handleError);
              //     });

          },
      }
  }
</script>
<style scoped lang='scss'>
  @import "~@/styles/variables.scss";

  .imgBox {
      width: 150px;
      height: 150px;
      object-fit: contain;
      border: 1px solid #333;
  }

  .imgList {
      display: flex;
      column-gap: 10px;
      flex-wrap: wrap;
      row-gap: 10px;
      margin: 10px 0;
  }

  .remove {
      position: absolute;
      right: -0.5em;
      top: -0.5em;
      line-height: 1;
      font-size: 16px;
      color: #aaaaaa;
      background-color: #ffffff;
      cursor: pointer;
  }

  .defalutImg {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 150px;
      height: 150px;
      border: 1px solid #333;
      border-radius: 4px;
      cursor: pointer;
      position: relative;
  }
</style>