import moment from 'moment';

/**
 * px转vw
 */
export function convertPixelToViewPort(px: number, fractionDigits = 3) {
	let result = (px * (100 / 375)).toFixed(fractionDigits);
	return `${result}vw`;
}

/**
 * 格式化时间戳
 */
export function formatDate(timeStamp: number, format = 'YYYY-MM-DD') {
	return moment(timeStamp).format(format);
}

/**
 * 格式化消息时间戳
 */
export function formatNoticeTime(timeStamp) {
	if (timeStamp == 0) return '';
	if (!timeStamp) {
		timeStamp = new Date().getTime();
	}
	/*
	 * 假设当前时间为2022年2月25日
	 */
	// 当天消息 显示形如 --> 15:45
	if (moment(timeStamp).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
		return moment(timeStamp).format('HH:mm');
	}
	// 跨天未跨月 显示形如 --> 02-13 15:45
	if (moment(timeStamp).format('YYYY-MM') === moment().format('YYYY-MM')) {
		return moment(timeStamp).format('MM-DD HH:mm');
	}
	// 跨月未跨年 显示形如 --> 01-30
	if (moment(timeStamp).format('YYYY') === moment().format('YYYY')) {
		return moment(timeStamp).format('MM-DD');
	}
	// 跨年 显示形如 --> 2021-11-15
	if (moment(timeStamp).format('YYYY') !== moment().format('YYYY')) {
		return moment(timeStamp).format('YYYY-MM-DD');
	}
}
