<!--
 * @Author: sixteen
 * @Date: 2022-02-14 13:50:42
 * @LastEditTime: 2022-03-21 11:17:06
 * @LastEditors: Please set LastEditors
 * @Description: 首页
 * @FilePath: /recovery_home_management/src/views/home/index.vue
-->
<template>
    <div class="home-container">
        <el-row>
            <el-col :span="24" class="title">
                <div class="iconTitle"></div>
                <div class="textTitle">居家康复后台管理系统</div>
            </el-col>
            <el-button @click="logout">退出登录</el-button>
        </el-row>
        <el-row :gutter="24">
            <el-col :span="12">
                <el-button type="primary" @click="addRouter(1)"
                    ><span>医生管理</span></el-button
                >
            </el-col>
            <el-col :span="12">
                <el-button type="success" @click="addRouter(2)"
                    ><span>患者管理</span></el-button
                >
            </el-col>
            <el-col :span="12">
                <el-button type="warning" @click="addRouter(3)"
                    ><span>运用管理</span></el-button
                >
            </el-col>
            <el-col :span="12">
                <el-button type="danger" @click="addRouter(4)"
                    ><span>商品管理</span></el-button
                >
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },
    computed: {},
    created() {},
    methods: {
        addRouter(num) {
            this.$store.dispatch("SetTabRouter", num).then(() => {
                let path = "";
                // 根据roles权限生成可访问的路由表
                switch (num) {
                    case 1:
                        path = "/test";
                        break;
                    case 2:
                        path = "/demo";
                        break;
                    case 3:
                        path = "/demo";
                        break;
                    case 4:
                        path = "/demo";
                        break;
                }
                this.$router.push({ path: path });
            });
        },

        async logout() {
            this.$router.push(`/login`);
            await this.$store.dispatch("logout");
            this.$router.push(`/login`);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.home-container {
    padding: 40px;
    background: #f5f5f5;
    width: 100%;
    min-height: 100%;
}
img {
    width: 100%;
    cursor: pointer;
}
.title {
    display: flex;
    align-items: center;
    padding: 34px 0;
    .iconTitle {
        width: 8px;
        height: 34px;
        background: #304156;
        border-radius: 8px;
        margin-right: 24px;
    }
    .textTitle {
        font-family: SimHei;
        font-size: 24px;
        color: #333333;
    }
}
.el-button {
    width: 100%;
    height: 250px;
    margin: 20px;
    span {
        font-size: 20px;
        letter-spacing: 1px;
    }
}
</style>

