/*
 * @Author: wangz
 * @Date: 2022-05-18 10:58:11
 * @LastEditTime: 2022-04-24 11:55:51

 */
/**
 * 医生账号信息
 * pageName: 
 **/
import request from '@/utils/request'

/**
 * @description: 医生账号信息
 */
export function amountList(data) {
  return request({
    url: 'admin/userDoctor/amountList',
    method: 'GET',
    params: data,
    showLoading: true
  });
}


