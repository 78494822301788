<!--
* @Description: 医生提现审核页面
* @Reference:
-->

<template>
	<el-container>
		<el-header height="auto">
			<searchForm ref="mainSearch" :searchoptions="searchOptions" @search="search" :backStatus="backStatus" />
		</el-header>
		<el-main class="basic-main">
			<el-tabs style="width: 100%" v-model="tabValue" @tab-click="clickTab">
				<el-tab-pane :lazy="true" name="1" label="待审核">
					<generalTable ref="mainTable" :table-data="tableData" :config="tableConfig" :total="total"
						@updatePage="getList" @onClickRow="onClickRow">
						<template slot="image" slot-scope="scope">
							<div style="display: flex;align-items: center;justify-content: center;">
								<el-image style="width: 60px; height: 40px;margin:5px 10px;" :src="scope.data.row.idFrontPicView
									" :preview-src-list="[scope.data.row.idFrontPic]">
								</el-image>
								<el-image style="width: 60px; height: 40px;margin:5px 10px" :src="scope.data.row.idBackPicView
									" :preview-src-list="[scope.data.row.idBackPic]">
								</el-image>
							</div>
						</template>
						<template slot="status" slot-scope="scope">
								<span>{{scope.data.row.infoStatusLabel}}</span>
						</template>
						<template slot="handle" slot-scope="scope">
							<el-button type="text" @click="toDetail(scope)">审核</el-button>
							<!-- <el-button type="text" class="delBtn" @click="toDel(scope)">拒绝</el-button> -->
						</template>
					</generalTable>
				</el-tab-pane>
				<el-tab-pane :lazy="true" name="2" label="已审核"> 
					<generalTable ref="finishedMainTable" :table-data="tableData" :config="finishedTableConfig" :total="total"
						@updatePage="getList" @onClickRow="onClickRow">
						<template slot="image" slot-scope="scope">
							<div style="display: flex;align-items: center;justify-content: center;">
								<el-image style="width: 60px; height: 40px;margin:5px 10px;" :src="scope.data.row.idFrontPicView
									" :preview-src-list="[scope.data.row.idFrontPic]">
								</el-image>
								<el-image style="width: 60px; height: 40px;margin:5px 10px" :src="scope.data.row.idBackPicView
									" :preview-src-list="[scope.data.row.idBackPic]">
								</el-image>
							</div>
						</template>
						<template slot="status" slot-scope="scope">
							<!-- 已拒绝 审批不通过  infoStatus 提现信息状态 0-待审核，1-已通过，2-已拒绝，3-审核不通过，4-已解绑-->
								<span v-if="scope.data.row.infoStatus === '2' 
								|| scope.data.row.infoStatus === '3' || scope.data.row.infoStatus === '4'" style="color:#D9001B;">{{scope.data.row.infoStatusLabel}}</span>
								<span v-else>{{scope.data.row.infoStatusLabel}}</span>
									<el-tooltip :content="scope.data.row.errorReason" placement="bottom"><i v-show="scope.data.row.infoStatus === '3'" class="el-icon-warning" style="color: #D9001B;"></i>
								</el-tooltip>
						</template>
					</generalTable>
				</el-tab-pane>
			</el-tabs>
			<detailDialog :id="checkId" :detailData="detailData" ref="detail" @audit="search"/>
		</el-main>
	</el-container>
</template>

<script>
import { getList, deleteData } from "@/api/withdraw";
import searchForm from "@/components/SearchForm";
import generalTable from "@/components/Table";
import detailDialog from '@/views/financial/withdraw/detailDialog'

export default {
	name: "Withdraw",
	components: {
		searchForm,
		generalTable,
		detailDialog,
	},
	data() {
		return {
			tableData: [],
			finishedTableData: [],
			searchOptions: [
				{
					type: "input",
					placeholder: "请输入...",
					key: "doctorName",
					label: "医生姓名",
					defaultVal: "",
				},
				{
					type: "input",
					placeholder: "请输入...",
					key: "doctorPhone",
					label: "手机号",
					defaultVal: "",
				},
			],
			tableConfig: {
				id: "withdraw",
				align: "center", // 不必填 默认为center
				selection: "single", // 不必填 多选: multiple  单选: single  默认为 normal
				index: true, // 不必填 序号
				pagination: true, // 不必填 分页 默认为false
				column: [
					{
						label: "医生姓名", // 必填
						prop: "doctorName", // 必填
						width: 120
						// formatter: (row, column) => {
						//     return row.archivesInfoId.name
						// }
					},
					{
						label: "手机号", // 必填
						prop: "doctorPhone", // 必填
						width: 150,
					},
					{
						label: "医院", // 必填
						prop: "hospitalName", // 必填
					},
					{
						label: "申请时间", // 必填
						prop: "applyDate", // 必填
						formatter: (row, column) => {
							return this.moment(row.applyDate).format(
									'YYYY-MM-DD HH:mm:ss'
							)
					}
					},
					{
						label: "卡号", // 必填
						prop: "bankNo", // 必填
					},
					{
						label: "预留手机号", // 必填
						prop: "phone", // 必填
						width: 150,
					},
					{
						label: "身份证信息", // 必填
						slot: "image", // 必填
					},
					{
						label: "状态", // 必填
						prop: "infoStatusLabel", // 必填
						width: 120,
						slot: "status"
					},
					{
						slot: "handle", // 不必填 单列插槽
						label: "操作", // 必填
						fixed: "right", // 不必填 固定列  left right
						width: "220", // 不必填
					},
				],
			},
			finishedTableConfig: {
				id: "withdraw",
				align: "center", // 不必填 默认为center
				selection: "single", // 不必填 多选: multiple  单选: single  默认为 normal
				index: true, // 不必填 序号
				pagination: true, // 不必填 分页 默认为false
				column: [
					{
						label: "医生姓名", // 必填
						prop: "doctorName", // 必填
						width: 120
						// formatter: (row, column) => {
						//     return row.archivesInfoId.name
						// }
					},
					{
						label: "手机号", // 必填
						prop: "doctorPhone", // 必填
						width: 150,
					},
					{
						label: "医院", // 必填
						prop: "hospitalName", // 必填
					},
					{
						label: "申请时间", // 必填
						prop: "applyDate", // 必填
						formatter: (row, column) => {
							return this.moment(row.applyDate).format(
									'YYYY-MM-DD HH:mm:ss'
							)
					}
					},
					{
						label: "卡号", // 必填
						prop: "bankNo", // 必填
					},
					{
						label: "预留手机号", // 必填
						prop: "phone", // 必填
						width: 150,
					},
					{
						label: "身份证信息", // 必填
						slot: "image", // 必填
					},
					{
						label: "状态", // 必填
						prop: "infoStatusLabel", // 必填
						width: 120,
						slot: "status"
					},
				],
			},
			paramObj: null, // 筛选项
			total: 0, // 总条数
			checkId: "", // 单条数据id 用于详情或编辑请求数据用
			backStatus: true, //返回按钮的隐藏显示
			timeStamp: "", //时间戳 用于修改数据或删除数据
			tabValue: "1",
			detailData: null, // 审核详情信息
		};
	},
	created() {
		this.getList();
	},
	mounted: function () { 
	},
	methods: {
		search(data) {
			Object.assign(this.paramObj, data)
			if(this.tabValue == 1){
				this.$refs.mainTable.handleCurrentChange(1);
			}else{
				this.$refs.finishedMainTable.handleCurrentChange(1);
			}
		},
		clickTab(tab, event) {
			this.getList();
			this.$refs.mainSearch.resetForm();
		},

		getList() {
			this.$nextTick(() => {
				const param = this.getParam();
				const auditStatus = this.tabValue == 1 ? '0': '1';				
				const paramobj = Object.assign({}, param, {auditStatus: auditStatus});
				getList(paramobj).then(({ res, timeStamp }) => {
					this.tableData = res.records;
					this.timeStamp = timeStamp;
					this.total = res.total;
				});
			});
		},
		toInsert() {
			this.checkId = "";
			this.$refs.insert.updateVisible(true);
		},
		toDetail(scope) {
			this.checkId = scope.data.row.id;
			this.detailData = scope.data.row;
			this.$refs.detail.updateVisible(true);
		},
		toEdit(scope) {
			this.checkId = scope.data.row.id;
			this.$refs.insert.updateVisible(true);
		},
		toDel(scope) {
			// const { $delMsg, $delTitle, $messageBoxConfig, $handleSuccess } =
			// 	this.commonConfig;
			// this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
			// 	const param = { id: scope.data.row.id };
			// 	deleteData(param, this.timeStamp).then(({ res }) => {
			// 		this.$message({ ...$handleSuccess });
			// 	});
			// });
			this.checkId = scope.data.row.id;
			this.detailData = scope.data.row;
			this.$refs.detail.updateVisible(true);
		},
		onClickRow(row) {
			console.log("点击行:", row);
		},
		getParam() {
			const paramObj = this.$refs.mainSearch.packageData();
			let page = null;
			if(this.tabValue == 1){
				page = this.$refs.mainTable.getPage();
			}else{
				page = this.$refs.finishedMainTable.getPage();
			}
			
			const param = Object.assign({}, this.paramObj, page, paramObj);
			this.paramObj = param;
			return param;
		},
	},
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
</style>
