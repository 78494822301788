<!--
 * @Author: your name
 * @Date: 2022-03-21 11:50:58
 * @LastEditTime: 2022-04-20 16:46:21
 * @LastEditors: Please set LastEditors
 * @Description: 评估分类详情
 * @FilePath: /recovery_home_management/src/views/rehabilitation/classify/detailDialog.vue
-->

<template>
    <el-dialog
        width="60%"
        title="评估分类详情"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        custom-class="basicDialog"
        append-to-body
    >
        <el-descriptions class="margin-top" title="基本信息" :column="4">
            <el-descriptions-item label="分类名称">{{
                detail.officesName
            }}</el-descriptions-item>
            <el-descriptions-item label="平台标识">
                <el-tag size="small">{{ detail.platform }}</el-tag>
            </el-descriptions-item>
            <el-descriptions-item label="上级分类">{{detail.parentName}}</el-descriptions-item>
            <el-descriptions-item label="排序">{{
                detail.sort
            }}</el-descriptions-item>
            <el-descriptions-item label="级别">{{detail.level}}</el-descriptions-item>
            <el-descriptions-item label="简介">{{
                detail.introduction
            }}</el-descriptions-item>
        </el-descriptions>
        <template slot="footer" class="dialog-footer">
            <el-button @click="updateVisible(false)">取 消</el-button>
        </template>
    </el-dialog>
</template>
<script>
import { getDetail } from "@/api/assessmentClass";
export default {
    props: {
        id: {
            type: String,
            required: true,
        },
        platformArray: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            visible: false,
            detail: [], // 详情数据
        };
    },
    watch: {
        visible(newVal, oldVal) {
            if (newVal && this.id) this.getDetail();
        },
    },
    created() {},
    mounted() {},
    methods: {
        getDetail() {
            getDetail({ id: this.id }).then(({ res }) => {
                this.detail = res;
                const currentType = this.platformArray.filter(
                    (type) => type.value === res.platform
                );
                
                if (currentType.length) {
                    this.detail.platform = currentType[0].label;
                }
            });
        },
        updateVisible(flag) {
            this.visible = flag;
        },
    },
};
</script>
<style scoped lang='scss'>
</style>
