/*
 * @Author: shangxue
 * @Date: 2023-06-27 17:24:07
 * @LastEditTime: 2023-06-28 09:39:27
 * @LastEditors: Please set LastEditors
 * @Description: 
 * @FilePath: /recovery_home_management_dev/src/api/reconciliation.js
 */
import request from '@/utils/request'

/**
 * @description: 康复师提现对账列表
 */
export function getReconciliationList(data) {
  return request({
      url: 'admin/finance/reconciliation',
      method: 'GET',
      params: data,
      showLoading: true
  })
}
