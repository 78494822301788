<template>
  <div>
    <el-card>
      <el-form ref="insertForm" :model="form" label-position="right" label-width="130px" :rules="rules">
        <el-form-item label="计划名称" prop="planName">
          <el-input v-model="form.planName" placeholder="请输入..." clearable maxlength="200" />
        </el-form-item>
        <el-form-item label="计划介绍" prop="planDesc">
          <el-input type="textarea" v-model="form.planDesc" placeholder="请输入..." clearable maxlength="200" />
        </el-form-item>
        <el-form-item label="计划类型" prop="type">
          <el-input v-model="form.type" placeholder="请输入..." clearable maxlength="200" />
        </el-form-item>
        <el-form-item label="计划标签" prop="tag">
          <el-input v-model="form.tag" placeholder="请输入..." clearable maxlength="200" />
        </el-form-item>
        <el-form-item label="计划周期" prop="period">
          {{form.period}}天
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>

  export default {
    name: 'project-addConfig',
    components: {},
    props: {
      form: {
        type: Object,
        default: () => { return { taskList: [] } }
      }
    },
    data() {
      return {
        rules: {
          planName: [
            {
              required: true,
              message: "请输入计划名称",
              trigger: "blur",
            },
          ],
        },
      }
    },
    computed: {
    },
    created() {
    },
    methods: {
      submit() {
          this.$refs.insertForm.validate((valid) => {
              if (valid) {
                  const param = JSON.parse(JSON.stringify(this.form));
                  this.$emit("submit", param);
              }
          })
      },
    }
  }
</script>

<style lang='scss' scoped>
  .el-timeline li:hover .timeline-btnWrap{ display: flex; }
  .timeline-btnWrap{ display: none; justify-content: space-around; width: 150px; }
</style>
