/*
 * @Author: 358617935@qq.com 358617935@qq.com
 * @Date: 2023-05-30 08:56:27
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-06-05 13:51:08
 * @FilePath: /recovery_home_management_dev/src/api/healthData.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

/**
 * 页面: 患者健康数据导入
 * pageName: healthData
 **/
import request from '@/utils/request'

/**
 * @description: 获取患者健康数据导入列表数据
 */
export function getList(data) {
    return request({
        url: '/kfData/list',
        method: 'GET',
        params: data,
        showLoading: true
    })
}


/**
 * @description: 删除患者健康数据导入数据
 */
export function deleteData(data, timeStamp) {
    return request({
        url: '/kfData/delete',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}

/**
 * @description: 患者健康数据导入数据
 */
export function importData(data, timeStamp) {
    return request({
        url: '/kfData/import',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}


/**
 * @description: 模板下载
 */
export function templateDownload(data) {
    return request({
        url: '/kfData/template',
        method: 'GET',
        responseType: 'blob',
    })
}