<template>
    <el-container>
        <el-header height="auto">
            <searchForm ref="mainSearch" :searchoptions="searchOptions" @search="search" :backStatus="backStatus" />
        </el-header>
        <el-main class="basic-main">
            <generalTable ref="mainTable" :table-data="tableData" :config="tableConfig" :total="total"
                @updatePage="getList">
                <template slot="plan" slot-scope="scope">
                    <img v-if="scope.data.row.url != null" style="width: 100px; height: 100px" @click="vidoClick(scope)"
                        :src="commonConfig.$filePublicUrl + scope.data.row.url + '?vframe/jpg/offset/5/w/100/h/100'">
                    <img v-else style="width: 100px; height: 100px" src="@/assets/img/placeholder.png">
                </template>
                <template slot="handle" slot-scope="scope">
                    <el-button type="text" @click="Check(scope)">查看</el-button>
                    <el-button v-if="scope.data.row.status =='1'" type="text" @click="pass(scope)">通过</el-button>
                    <el-button v-if="scope.data.row.status =='1'" type="text" class="delBtn"
                        @click="reject(scope)">拒绝</el-button>
                </template>
            </generalTable>
        </el-main>
        <el-dialog width="60%" title="视频详情" :visible.sync="dialogVisible" :close-on-click-modal="false"
            :close-on-press-escape="false" :show-close="false" custom-class="basicDialog" append-to-body>
            <div style="width: 100%;height: 100%;">
                <video style=" width: 100%;max-height: 500px;" :src="commonConfig.$filePublicUrl + vidoData" controls
                    controlslist="">
                </video>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
            </span>
        </el-dialog>

        <reviewUps :id="itemID" ref="reviewUps" @reviewUpsClick="reviewUpsClick"></reviewUps>
    </el-container>
</template>

<script>
import searchForm from "@/components/SearchForm";
import generalTable from "@/components/Table";
import reviewUps from "@/views/rehabilitation/review/reviewUps"
import { getList, modifyState } from '@/api/review'

export default {
    name: "Project",
    components: {
        searchForm,
        generalTable,
        reviewUps
    },
    data() {
        return {
            vidoData: '',
            dialogVisible: false,
            itemID: '',
            tableConfig: {
                id: "Project",
                align: "center", // 不必填 默认为center
                selection: "single", // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: true, // 不必填 分页 默认为false
                column: [
                    {
                        label: "方案编号", // 必填
                        prop: "id", // 必填
                    },
                    {
                        label: "方案名称", // 必填
                        prop: "guidanceName", // 必填
                    },
                    {
                        label: "方案", // 必填
                        slot: "plan", // 必填
                        width: "150", // 不必填
                    },
                    {
                        label: "上传医师", // 必填
                        prop: "doctorName", // 必填
                    },
                    {
                        label: "上传时间", // 必填
                        prop: "uploadTime", // 必填
                        formatter: (row, column) => {
                            return this.moment(row.uploadTime).format("YYYY-MM-DD HH:mm");
                        },
                    },
                    {
                        label: "审核状态", // 必填
                        prop: "status", // 必填
                        formatter: (row, column) => {
                            const stampContainer = {
                                // 1:审核中，2:拒绝，3:通过，4:未发布
                                1: "审核中",
                                2: "拒绝",
                                3: "通过",
                                4: "未发布"
                            };
                            return stampContainer[row.status];
                        },
                    },
                    {
                        slot: "handle", // 不必填 单列插槽
                        label: "操作", // 必填
                        fixed: "right", // 不必填 固定列  left right
                        width: "220", // 不必填
                    },
                ],
            },
            tableData: [],
            searchOptions: [
                {
                    type: "input",
                    placeholder: "方案名称",
                    key: "guidanceName",
                    label: "方案名称",
                    defaultVal: "",
                },
                {
                    type: "input",
                    placeholder: "医生名称",
                    key: "doctorName",
                    label: "上传医生",
                    defaultVal: "",
                },
                {
                    type: "select",
                    placeholder: "请选择",
                    key: "status",
                    filterable: true,
                    multiple: false,
                    label: "审核状态",
                    options: [
                        {
                            value: "1",
                            label: "审核中",
                        },
                        {
                            value: "2",
                            label: "拒绝",
                        },
                        {
                            value: "3",
                            label: "通过",
                        },
                        {
                            value: "4",
                            label: "未发布",
                        },
                    ],
                    defaultVal: [],
                },
                {
                    type: "daterange",
                    startPlaceholder: "开始日期",
                    endPlaceholder: "结束日期",
                    key: "publishDate",
                    label: "上传时间",
                    defaultVal: [],
                },
            ],
            paramObj: null, // 筛选项
            total: 0, // 总条数
            checkId: '', // 单条数据id 用于详情或编辑请求数据用
            backStatus: true, //返回按钮的隐藏显示
            timeStamp: '', //时间戳 用于修改数据或删除数据
        };
    },
    created() {
        this.getList();
    },
    methods: {
        vidoClick(val) {
            this.vidoData = val.data.row.url
            this.dialogVisible = true
        },
        getList() {
            this.$nextTick(() => {
                const param = this.getParam()
                getList(param).then(({ res, timeStamp }) => {
                    console.log(res, 'data')
                    this.tableData = res.records;
                    this.timeStamp = timeStamp;
                    this.total = parseInt(res.total)
                })
            })
        },
        getParam() {
            const paramObj = this.$refs.mainSearch.packageData()
            const page = this.$refs.mainTable.getPage()
            const param = Object.assign(page, paramObj)
            return param
        },
        reviewUpsClick() {
            this.getList()
        },
        // 查看
        Check(val) {
            this.itemID = val.data.row.id
            this.$refs.reviewUps.updateVisible(true)
        },
        // 通过
        pass(val) {
            let data = {
                id: val.data.row.id,
                status: '3'
            }
            this.$confirm('确认通过该方案?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                if (val.data.row.status == '1') {
                    modifyState(data).then((res => {
                        this.$message({
                            message: '操作成功',
                            type: 'success'
                        });
                        this.getList()
                    }))
                }
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '操作取消'
                });
            });

        },
        // 拒绝
        reject(val) {
            let data = {
                id: val.data.row.id,
                status: '2'
            }
            this.$confirm('确认拒绝该方案?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                if (val.data.row.status == '1') {
                    modifyState(data).then((res => {
                        this.$message({
                            message: '操作成功',
                            type: 'success'
                        });
                        this.getList()
                    }))
                }
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '操作取消'
                });
            });

        },
        search(data) {
            this.paramObj = data
            this.$refs.mainTable.handleCurrentChange(1)
        },
        sonFunc() {
            console.log("2");
        },

    },
};
</script>

<style>

</style>
