/*
 * @Author: your name
 * @Date: 2022-03-31 17:16:29
 * @LastEditTime: 2022-04-01 11:08:09
 * @LastEditors: your name
 * @Description: 评估视频
 * @FilePath: /recovery_home_management/src/api/assessmentVideo.js
 */

/**
 * 页面: 评估视频
 * pageName: assessmentVideo
 **/
import request from '@/utils/request'

/**
 * @description: 获取评估视频列表数据
 */
export function getList(data) {
    return request({
        url: '/admin/kfAssessmentVideoInfo/list',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 新增评估视频数据
 */
export function insertData(data) {
    return request({
        url: '/admin/kfAssessmentVideoInfo/add',
        method: 'POST',
        data,
        showLoading: true
    })
}

/**
 * @description: 修改评估视频数据
 */
export function editData(data, timeStamp) {
    return request({
        url: '/admin/kfAssessmentVideoInfo/update',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}
            

/**
 * @description: 获取评估视频详情数据
 */
export function getDetail(data) {
    return request({
        url: '/admin/kfAssessmentVideoInfo/info',
        method: 'GET',
        params: data,
        showLoading: true
    })
}
            
/**
 * @description: 删除评估视频数据
 */
    export function deleteData(data, timeStamp) {
    return request({
        url: '/admin/kfAssessmentVideoInfo/delete',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}
        