<!--
 * @Author: your name
 * @Date: 2022-03-31 17:20:07
 * @LastEditTime: 2022-04-02 15:01:25
 * @LastEditors: Please set LastEditors
 * @Description: 评估管理
 * @FilePath: /recovery_home_management/src/views/assessment/index.vue
-->

<template>
    <MenuBox :roles="secondMenu" />
</template>

<script>
import { mapGetters } from "vuex";
import MenuBox from "@/components/MenuBox";
export default {
    name: "System",
    components: { MenuBox },
    data() {
        return {
            secondMenu: [],
        };
    },
    computed: {
        ...mapGetters(["roles"]),
    },
    created() {
        const { roles } = this.$store.getters;

        for (let i = 0; i < roles.length; i++) {
            const element = roles[i];
            if (element.name === "assessmentMain") {
                element.children.filter((obj) => {});
                this.secondMenu = element.children.filter(
                    (obj) =>
                        obj.hidden === false && element.redirect !== obj.path
                );
            }
        }
    },
    methods: {},
};
</script>

<style lang='scss' scoped>
</style>
