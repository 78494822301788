<!--
 * @Author: your name
 * @Date: 2022-03-22 15:28:04
 * @LastEditTime: 2022-04-20 14:39:36
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \recovery_home_management\src\views\basicSetting\officesManagement.vue
-->

<!--
* @Description: 擅长领域管理页面
* @Reference:
-->

<template>
  <el-container>
    <el-header height="auto">
      <searchForm ref="mainSearch" :searchoptions="searchOptions" @search="search" :backStatus="backStatus"/>
    </el-header>
    <el-main class="basic-main">
      <el-row class="table-btn-group"><el-button type="primary" @click="toInsert()">新增</el-button></el-row>
      <generalTable ref="mainTable" :table-data="tableData" :config="tableConfig" :total="total" @updatePage="getList" @objectSpanMethod="objectSpanMethod" @onClickRow="onClickRow">
        <template slot="handle" slot-scope="scope">
          <el-button type="text" style="margin-right: 10px" @click="toEdit(scope)">编辑</el-button>
          <el-button type="text" class="delBtn" @click="toDel(scope)">删除</el-button>
        </template>
      </generalTable>
      <addDialog :id="checkId" ref="insert" :submit="search" :timeStamp="timeStamp" @submit='getList()' />
    </el-main>
  </el-container>
</template>
<script>
import { getList, deleteData } from '@/api/basicSetting'
import searchForm from '@/components/SearchForm'
import generalTable from '@/components/Table'
import addDialog from '@/views/basicSetting/hospitalLevel/addDialog'

export default {
    name: 'Test',
    components: {
        searchForm,
        generalTable,
        addDialog
    },
    data() {
        return {
            tableData: [
            ],
            searchOptions: [
                {
                    type: 'input',
                    placeholder: '请输入...',
                    key: 'code',
                    label: '编码',
                    defaultVal: ''
                },
                {
                    type: 'input',
                    placeholder: '请输入...',
                    key: 'name',
                    label: '系数名称',
                    defaultVal: ''
                }
            ],
            tableConfig: {
                id: 'test',
                align: 'center', // 不必填 默认为center
                selection: 'single', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: true, // 不必填 分页 默认为false
                column: [
                    {
                    label: '排序', // 必填
                    prop: 'sort' // 必填
                },
                {
                    label: '级别编码', // 必填
                    prop: 'code' // 必填
                },
                {
                    label: '系数名称', // 必填
                    prop: 'name' // 必填
                },
                {
                    label: '系数值', // 必填
                    prop: 'introduction' // 必填
                },
                {
                    slot: 'handle', // 不必填 单列插槽
                    label: '操作', // 必填
                    fixed: 'right', // 不必填 固定列  left right
                    width: '220' // 不必填
                }
                ]
            },
            paramObj: null, // 筛选项
            total: 0, // 总条数
            checkId: '', // 单条数据id 用于详情或编辑请求数据用
            timeStamp: '',
            backStatus: true, //返回按钮的隐藏显示
        }
    },
    created() {
        this.getList();
    },
    mounted: function() {},
    methods: {
        search(data) {
            this.paramObj = data
            this.$refs.mainTable.handleCurrentChange(1)
        },
        getList() {
            // 解决未挂载完组件时调用子组件方法报错问题
            this.$nextTick(() => {
                const param = this.getParam()
                param.types = '6'
                console.log("------------type----------",param)
                getList(param).then((res, timeStamp) => {
                    this.tableData = res.res.records;
                    this.timeStamp = timeStamp;
                    this.total = res.res.total;
                })
            })
            
        },
        objectSpanMethod(param, cb) {
            cb([])
        },
        toInsert() {
            this.checkId = ''
            this.$refs.insert.updateVisible(true)
        },
        toEdit(scope) {
            this.checkId = scope.data.row.id
            this.$refs.insert.updateVisible(true)
        },
        toDel(scope) {
            const { $delMsg, $delTitle, $messageBoxConfig, $handleSuccess } =
                this.commonConfig;
            this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
            const param = { id: scope.data.row.id };
            deleteData(param, this.timeStamp).then(({ res }) => {
                this.$message({ ...$handleSuccess });
                this.getList();
                });
            });
        },
        onClickRow(checkArray) {
            console.log('testststst')
            console.log('checkArray', checkArray)
        },
        getParam() {
            // 获取参数
            const paramObj = this.$refs.mainSearch.packageData()
            this.paramObj = paramObj
            const page = this.$refs.mainTable.getPage()
            const param = Object.assign(page, paramObj)
            return param
        },
        
    }
}
</script>

<style lang='scss'>
@import "~@/styles/variables.scss";

</style>
