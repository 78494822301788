
<template>
    <el-dialog
        width="70%"
        :title="id === '' ? '新增评估视频设备' : '修改评估视频设备'"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        append-to-body
    >
        <el-form
            ref="insertForm"
            :model="form"
            label-position="right"
            label-width="120px"
            class="formBox"
            :rules="rules"
        >
            <div class="mdrcc-info-title">基本信息</div>
            <el-row :gutter="10" type="flex" justify="start">
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="名称" prop="name">
                        <el-input
                            v-model="form.name"
                            placeholder="请输入..."
                            clearable
                            maxlength="20"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="分类" prop="kfAssessmentTypeId">
                        <el-cascader
                            v-model="form.kfAssessmentTypeId"
                            :options="classifyArray"
                            :props="{
                                checkStrictly: true,
                                emitPath: false,
                                label: 'officesName',
                                value: 'id',
                            }"
                            clearable
                            filterable
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="排序" prop="sort">
                        <el-input
                            v-model="form.sort"
                            placeholder="请输入..."
                            clearable
                            maxlength="20"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="12">
                    <el-form-item label="视频或者设备地址" prop="url">
                        <div v-if="form.url" class="remove" @click="removeVideo()"><i class="el-icon-error"></i></div>
                        <div class="video">
                            <video
                                :src="commonConfig.$filePublicUrl + form.url"
                                v-show="form.url"
                                controls
                                width="100%"
                                height="150px"
                            ></video>
                            <div
                                :src="form.url"
                                v-show="!form.url"
                                @click="setVideoClick"
                                class="defalutImg"
                                v-loading="loading"
                            >
                                <i class="el-icon-plus"></i>
                            </div>
                        </div>
                        <input
                            style="display: none"
                            type="file"
                            accept="*"
                            @change="changeModelFile($event)"
                            ref="ModelInput"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="24" :md="24" :lg="24" :xl="24">
                    <el-form-item label="描述" prop="describe">
                        <el-input
                            v-model="form.describe"
                            type="textarea"
                            clearable
                            :autosize="{ minRows: 2, maxRows: 4 }"
                            maxlength="200"
                            placeholder="请输入内容"
                        />
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submit()">提 交</el-button>
            <el-button @click="updateVisible(false)">取 消</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { insertData, editData, getDetail } from "@/api/assessmentVideo";
import { uploadPrivateFile, uploadPublicFile } from "@/utils/uploadQiniu.js";

export default {
    props: {
        id: {
            type: String,
        },
        timeStamp: {
            type: String,
        },
        classifyArray: {
            required: true,
            type: Array,
        },
    },
    data() {
        /**
         * 排序验证
         * @description: 排序字段必须为数字
         * @param {*} rule 验证规则
         * @param {*} value 要验证的数据
         * @param {*} callback 回调
         * @return {*}
         */
        const validatorSort = (rule, value, callback) => {
            if (/^[0-9]+$/.test(value) === false) {
                callback(new Error("请输入数字"));
            } else {
                callback();
            }
        };
        return {
            checkId: "",
            rules: {
                name: [
                    {
                        required: true,
                        message: "请输入活动名称",
                        trigger: "blur",
                    },
                    {
                        max: 20,
                        message: "长度在 3 到 5 个字符",
                        trigger: "blur",
                    },
                ],
                kfAssessmentTypeId: [
                    {
                        required: true,
                        message: "请选择分类",
                        trigger: "change",
                    },
                ],
                describe: [
                    {
                        required: true,
                        message: "请输入描述",
                        trigger: "blur",
                    },
                ],
                sort: [
                    {
                         required: true,
                        validator: validatorSort,
                        trigger: "blur",
                    },
                ],
            },
            form: {
                id:'',
                name: "", //名称
                sort: "", //排序
                describe: "", //描述
                url: "", //视频或者设备地址
                logoFile: "",
                kfAssessmentTypeId: "", //分类ID
            },
            visible: false,
            loading:false,
            dialogVisible: false,
        };
    },
    watch: {
        visible(newVal, oldVal) {
            if (newVal === true) {
                if (this.id) {
                    this.getDetail();
                }
                //...todo
            }
        },
    },
    created() {},
    mounted() {},
    methods: {
        submit() {
            this.$refs.insertForm.validate((valid) => {
                if (valid) {
                    const parm = this.form;
                    if (this.id === "") {
                        insertData(parm).then(({ res }) => {
                            this.submitSuccess(res);
                        });
                    } else {
                        editData(parm, this.timeStamp).then(({ res }) => {
                            this.submitSuccess(res);
                        });
                    }
                }
            });
        },
        submitSuccess(res) {
            this.$message({ ...this.commonConfig.$handleSuccess });
            this.updateVisible(false);
            this.$emit("submit");
        },
        getDetail() {
            getDetail({ id: this.id }).then(({ res, timeStamp }) => {
                this.form = res;
            });
        },
        setLogoClick() {
            this.$refs.logoInput.click();
        },

        setVideoClick() {
            this.$refs.ModelInput.click();
        },
        /**
         * @description: 上传视频
         */
        changeModelFile(event) {
            this.loading = true;
            let file = event.target.files[0];
            const thiz = this;
            uploadPublicFile(file)
                .then((success) => {
                    console.log("success====", success);
                    thiz.loading = false;
                    thiz.form.url = success;
                })
                .catch((handleError) => {
                    thiz.loading = false;
                    this.$message({
                        message: "上传失败，请重试",
                        type: "warning",
                    });
                    console.log("handleError====", handleError);
                });
        },
        updateVisible(val) {
            this.visible = val;
            if (val === false) {
                this.$refs.insertForm.resetFields();
                this.form.id = '';
            }
        },
        removeVideo() {
            this.form.url = "";
        },
    },
};
</script>
<style scoped lang='scss'>
@import "~@/styles/variables.scss";

.imgBox {
    width: 150px;
    height: 150px;
    object-fit: contain;
    border: 1px solid $tableColor;
}
.remove {
    position: absolute;
    right: -0.5em;
    top: -0.5em;
    line-height: 1;
    font-size: 16px;
    color: #aaaaaa;
    // background-color: #ffffff;
    cursor: pointer;
    z-index: 10;
}
.defalutImg {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 150px;
    border: 1px solid $tableColor;
    border-radius: 4px;
    cursor: pointer;
}
</style>
      
        