
<template>
    <el-dialog
        width="70%"
        :title="id === '' ? '新增评估分类' : '修改评估分类'"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        append-to-body
    >
        <el-form
            ref="insertForm"
            :model="form"
            label-position="right"
            label-width="120px"
            class="formBox"
            :rules="rules"
        >
            <div class="mdrcc-info-title">评估分类信息</div>
            <el-row :gutter="10" type="flex" justify="start">
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="评估分类名称	" prop="officesName">
                        <el-input
                            v-model="form.officesName"
                            placeholder="请输入..."
                            clearable
                            maxlength="20"
                        />
                    </el-form-item>
                </el-col>

                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="平台标识" prop="platform">
                        <el-select
                            v-model="form.platform"
                            clearable
                            placeholder="请选择"
                            :filterable="true"
                        >
                            <el-option
                                v-for="option in platformArray"
                                :key="option.value"
                                :label="option.label"
                                :value="option.value"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="上级分类" prop="parentId">
                        <el-cascader
                            v-model="form.parentId"
                            :options="tableData"
                            :props="{
                                checkStrictly: true,
                                emitPath:false,
                                label: 'officesName',
                                value: 'id',
                            }"
                            clearable
                            filterable
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="排序" prop="sort">
                        <el-input
                            v-model="form.sort"
                            placeholder="请输入..."
                            clearable
                            maxlength="20"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="级别" prop="level">
                        <el-input
                            v-model="form.level"
                            placeholder="请输入..."
                            clearable
                            maxlength="5"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="24" :md="24" :lg="24" :xl="24">
                    <el-form-item label="简介" prop="introduction">
                        <el-input
                            v-model="form.introduction"
                            type="textarea"
                            clearable
                            :autosize="{ minRows: 2, maxRows: 4 }"
                            maxlength="200"
                            placeholder="请输入内容"
                        />
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submit()">提 交</el-button>
            <el-button @click="updateVisible(false)">取 消</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { insertData, editData, getDetail } from "@/api/assessmentClass";

export default {
    props: {
        id: {
            type: String,
        },
        timeStamp: {
            type: String,
        },
        platformArray: {
            type:Array,
            required:true
        },
        tableData:{
            type:Array,
            required:true
        },
    },
    data() {
        /**
         * 排序验证
         * @description: 排序字段必须为数字
         * @param {*} rule 验证规则
         * @param {*} value 要验证的数据
         * @param {*} callback 回调
         * @return {*}
         */
        const validatorSort = (rule, value, callback) => {
            if (/^[0-9]+$/.test(value) === false) {
                callback(new Error("请输入数字"));
            } else {
                callback();
            }
        };
        return {
            rules: {
                officesName: [
                    {
                        required: true,
                        message: "请输入活动名称",
                        trigger: "blur",
                    },
                    {
                        min: 1,
                        max: 10,
                        message: "长度在 1 到 10 个字符",
                        trigger: "blur",
                    },
                ],
                sort: [
                    {
                         required: true,
                        validator: validatorSort,
                        trigger: "blur",
                    },
                ],
                level: [
                    {
                         required: true,
                        validator: validatorSort,
                        trigger: "blur",
                    },
                ],
                platform: [
                    {
                        required: true,
                        message: "平台标识",
                        trigger: "change",
                    },
                ],
                parentId: [
                    {
                        required: true,
                        message: "请选择上级分类",
                        trigger: "blur",
                    },
                ],
            },
            options: [
                {
                    value: "1",
                    label: "后台管理",
                },
                {
                    value: "2",
                    label: "医生端",
                },
            ],
            form:{
                officesName: "",
                sort: "",
                platform: "",
                parentId: "", //父级编码
                level: "", //级别
                introduction: "",
            },
            visible: false,
            classifyTree: [],
            // timeStamp: '', //时间戳 用于修改数据或删除数据
        };
    },
    watch: {
        visible(newVal, oldVal) {
            if (newVal === true) {
                if (this.id) {
                    this.getDetail();
                }
                //...todo
            }
        },
    },
    created() {},
    mounted() {},
    methods: {
        submit() {
            console.log("form---", this.form);
            this.$refs.insertForm.validate((valid) => {
                if (valid) {
                    const parm = this.form;
                    if (this.id === "") {
                        insertData(parm).then(({ res }) => {
                            this.submitSuccess(res);
                        });
                    } else {
                        editData(parm, this.timeStamp).then(({ res }) => {
                            this.submitSuccess(res);
                        });
                    }
                }
            });
        },
        submitSuccess(res) {
            this.$message({ ...this.commonConfig.$handleSuccess });
            this.updateVisible(false);
            this.$emit("submit");
        },
        getDetail() {
            getDetail({ id: this.id }).then(({ res, timeStamp }) => {
                this.form = res;
                // this.timeStamp = timeStamp
            });
        },

        updateVisible(val) {
            this.visible = val;
            if (val === false) {
                this.$refs.insertForm.resetFields();
                this.classifyTree = []; // 新的树结构
                this.form = {}
            }
        },
    },
};
</script>
<style scoped lang='scss'>
</style>
      
        