/*
 * @Author: 358617935@qq.com 358617935@qq.com
 * @Date: 2023-05-24 09:53:49
 * @LastEditors: 358617935@qq.com 358617935@qq.com
 * @LastEditTime: 2023-06-01 15:25:57
 * @FilePath: /recovery_home_management/src/api/recommend.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

/**
 * 页面: 推荐医生
 * pageName: recommend
 **/
import request from '@/utils/request'

/**
 * @description: 获取推荐医生列表数据
 */
export function getList(data) {
    return request({
        url: '/doctorRecommend/list',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 新增推荐医生数据
 */
export function insertData(data) {
    return request({
        url: '/doctorRecommend/add',
        method: 'POST',
        data,
        showLoading: true
    })
}

/**
 * @description: 修改推荐医生数据
 */
export function editData(data, timeStamp) {
    return request({
        url: '/doctorRecommend/update',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}


/**
 * @description: 获取推荐医生详情数据
 */
export function getDetail(data) {
    return request({
        url: '/doctorRecommend/info',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 删除推荐医生数据
 */
export function deleteData(data, timeStamp) {
    return request({
        url: '/doctorRecommend/delete',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}

/**
 * @description: 获取待推荐医生列表数据
 */
export function getRecommendList(data) {
    return request({
        url: '/doctorRecommend/doctorRecommendChooseList',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 客服推荐康复师给患者
 */
export function sendDoctorCard(data) {
  return request({
      url: '/admin/onlineConsult/sendDoctorCard',
      method: 'POST',
      data,
      showLoading: true
  })
}

/**
 * @description: 客服推荐商品给患者
 */
export function sendGoodsCard(data) {
  return request({
      url: '/admin/onlineConsult/sendGoodsCard',
      method: 'POST',
      data,
      showLoading: true
  })
}
