<template>
  <el-dialog width="60%" title="Banner管理" :visible.sync="visible" :close-on-click-modal="false"
    :close-on-press-escape="false" :show-close="false" custom-class="basicDialog" append-to-body>
    <el-descriptions class="margin-top" title="Banner管理" :column="3">
      <el-descriptions-item label="名称">{{detail.name||commonConfig.$nullData}}</el-descriptions-item>
      <el-descriptions-item label="描述">{{detail.content||commonConfig.$nullData}}</el-descriptions-item>
      <el-descriptions-item label="平台">{{
        detail.platform==0?'通用':detail.platform==1?'医生端':detail.platform==2?'患者端':'小程序患者端'
      }}</el-descriptions-item>
      <el-descriptions-item label="跳转路径">{{detail.jumpUrl||commonConfig.$nullData}}</el-descriptions-item>
      <el-descriptions-item label="跳转类型">{{detail.jumpType||commonConfig.$nullData}}</el-descriptions-item>
      <el-descriptions-item label="排序"> {{detail.sort||commonConfig.$nullData}}</el-descriptions-item>
      <el-descriptions-item label="状态"> {{
        detail.status==0?'启用':'禁用'}}</el-descriptions-item>
    
   
      <el-divider />
    </el-descriptions>
    <el-divider />
    <el-form ref="insertForm" v-model="detail" label-position="right" label-width="120px" class="formBox"
      :rules="rules">
      <el-row :gutter="10" type="flex" justify="start">
      
        <el-col :sm="24" :md="24" :lg="24" :xl="24">
          <el-form-item label="素材图片" prop="photoList">
            <div class="imgList">
              <div v-for="(url, index) in detail.photoList" class="defalutImg " :key="url+index">
                <el-image style="width: 150px; height: 150px" :src="commonConfig.$filePublicUrl + url" fit="contain">
                </el-image>
              </div>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>


    <template slot="footer" class="dialog-footer">
      <el-button @click="updateVisible(false)">取 消</el-button>
    </template>
  </el-dialog>
</template>
<script>
  import { getDetail } from '@/api/bannerManage'
  import generalTable from '@/components/Table'

  export default {
    props: {
      id: {
        type: String,
        required: true
      }
    },
    components: {
      generalTable,
    },
    data() {
      return {
        rules: {},
        visible: false,
        detail: {
          photoList: [],
          photoUrl: ""
        }, // 详情数据
        tableData: [],
        tableConfig: {
          align: 'center', // 不必填 默认为center
          selection: 'normal', // 不必填 多选: multiple  单选: single  默认为 normal
          index: true, // 不必填 序号
          pagination: false, // 不必填 分页 默认为false
          column: [
            {
              label: '名称', // 名称
              prop: 'name' // 
            },
            {
              label: '所属治疗', // 所属治疗
              prop: 'operTypeLabel' // 
            }
          ]
        },
        stand: [],
        total: 0 // 总条数
      }
    },
    watch: {
      visible(newVal, oldVal) {
        newVal && this.id && this.getDetail()
      }
    },
    created() {
    },
    mounted() {
    },
    methods: {
      /**
       * 获取商品详情
       * @Description: 
        * @Param null
        * @Return null
       * @Author wangz
       * @Date 
       */
      getDetail() {
        const param = {}
        if (this.id) {
          param.id = this.id

        }

        getDetail(param).then(({ res }) => {
          this.detail = res;
          this.detail.photoList = this.detail.imageUrl.split(",")
        })
      },
      uploadPublicFile(file) {
        return new Promise((resolve) => {
          uploadPublicFile(file)
            .then((success) => {
              console.log("upload success------", success)
              this.photoList.push(success)
              resolve(success)
            })
            .catch((handleError) => {
              console.log("handleError====", handleError);
            });
        })
      },
      setLogoClick() {
        this.$refs.logoInput.click();
      },
      updateVisible(flag) {
        this.visible = flag
      }
    }
  }
</script>
<style scoped lang='scss'>
  @import "~@/styles/variables.scss";

  .imgList {
    display: flex;
    column-gap: 10px;
    flex-wrap: wrap;
    row-gap: 10px;
    margin: 10px 0;
  }

  .defalutImg {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 150px;
    border: 1px solid #333;
    border-radius: 4px;
    cursor: pointer;
    position: relative;
  }
</style>