<!--
 * @Author: 邹博文
 * @Date: 2023-08-14 19:24:05
 * @LastEditTime: 2023-11-24 09:06:08
 * @LastEditors: Please set LastEditors
 * @Description: 康复方案
 * @FilePath: /wx-mall-management-web_dev/Users/shangxue/newgit_workspace/recovery_home_management_dev/src/views/project/index.vue
-->
<template>
  <el-container>
    <el-header height="auto">
      <searchForm ref="mainSearch" :searchoptions="searchOptions" @search="search" :backStatus="false" />
    </el-header>
    <el-main class="basic-main">
      <el-button size="small" type="primary" @click="handleEdit()">新增康复方案</el-button>
      <br><br>
      <el-table ref="mainTable" border stripe row-contextmenu size="mini" header-row-class-name="table-header"
        :data="tableData" style="width: 100%" @row-click="handleRowClick">
        <el-table-column type="expand" width="50">
          <template slot-scope="parentScope">
            <generalTable newHeight="auto" :table-data="parentScope.row.schemeDoctorList || []" :config="tableConfig">
              <template slot="ongoingCount" slot-scope="scope">
                <span v-if="scope.data.row.isOnSale === null">
                  --
                </span>
                <el-button size="text" type="primary" v-else
                  @click="goPatient(scope.data.row, parentScope.row, 1)">{{scope.data.row.ongoingCount}}</el-button>
              </template>

              <template slot="waitFinishCount" slot-scope="scope">
                <span v-if="scope.data.row.isOnSale === null">
                  --
                </span>
                <el-button size="text" type="primary" v-else
                  @click="goPatient(scope.data.row, parentScope.row, 2)">{{scope.data.row.waitFinishCount}}</el-button>
              </template>

              <template slot="finishedCount" slot-scope="scope">
                <span v-if="scope.data.row.isOnSale === null">
                  --
                </span>
                <el-button size="text" type="primary" v-else
                  @click="goPatient(scope.data.row, parentScope.row, 3)">{{scope.data.row.finishedCount}}</el-button>
              </template>

              <template slot="heDte" slot-scope="scope">
                <span v-if="scope.data.row.isOnSale === null">
                  商城未绑定商品
                </span>
                <el-switch v-else inactive-value="0" active-value="1" inactive-text="禁用"  active-text="启用"
                v-model="scope.data.row.isOnSale" @change="handleOff(scope.data.row, parentScope.row)" />
              </template>
              <template slot="controls" slot-scope="scope">
                <el-button size="text" type="primary"
                  @click="goRecommended(scope.data.row, parentScope.row)">推荐商品管理</el-button>
              </template>
            </generalTable>
          </template>
        </el-table-column>
        <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>
        <el-table-column label="ID" prop="id" align="center"></el-table-column>
        <el-table-column label="方案名称" prop="schemeName" align="center"></el-table-column>
        <el-table-column label="是否定制化" prop="customize" align="center" :formatter="cusFormatter">
        </el-table-column>
        <el-table-column label="服务周期（天）" prop="servicePeriod" width="120" align="center"></el-table-column>
        <el-table-column label="适用病种" prop="diseaseType" align="center" show-overflow-tooltip></el-table-column>
        <!--版权归属 0 平台 1 机构 2 医生-->
        <el-table-column label="版权归属" prop="copyright" align="center" :formatter="formatter" show-overflow-tooltip ></el-table-column>

        <el-table-column label="版权授权" prop="schemeAuth" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column label="方案状态" prop="schemeState" width="150" align="center">
          <template slot-scope="scope">
            <el-switch inactive-value="0" active-value="1" inactive-text="禁用"  active-text="启用"
              v-model="scope.row.schemeState" @change="handleOffAll(scope.row)" />
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150" align="center">
          <template slot-scope="scope">
            <el-button size="text" type="primary" @click="handleEdit(scope.row)">编辑</el-button>
            <el-button size="text" class="delBtn" @click="handleDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <br>
      <el-pagination background layout="total, sizes, prev, pager, next" style="text-align: right;"
        :current-page.sync="page.current" :page-size.sync="page.size" :total="total"
        :page-sizes="[10, 20, 30, 40, 50, 100]" @current-change="getList()" @size-change="handlePageSizeChange">
      </el-pagination>
    </el-main>
    <physicalGoodsDialog
      ref="physicalGoods"
    />
  </el-container>
</template>

<script>
  import request from '@/utils/request'
  import searchForm from '@/components/SearchForm'
  import generalTable from '@/components/Table'
  import physicalGoodsDialog from './physicalGoodsDialog'
  import { mapGetters } from 'vuex'
  export default {
    name: 'project',
    components: {
      searchForm, generalTable, physicalGoodsDialog
    },
    data() {
      return {
        page: { current: 1, size: 20, },
        total: 0,
        paramObj: {},
        searchOptions: [
          {
            type: 'input',
            placeholder: '请输入方案名称',
            key: 'schemeName',
            label: '方案名称',
            defaultVal: ''
          },
          {
            type: 'input',
            placeholder: '请输入服务医生',
            key: 'doctorName',
            label: '服务医生',
            defaultVal: ''
          },
        ],
        tableData: [
        ],
        tableConfig: {
          id: 'project-child',
          index: false,
          column: [
            {
              label: '服务医生',
              prop: 'doctorName',
            },
            {
              label: '服务中患者',
              prop: 'ongoingCount',
              slot: 'ongoingCount',
              width: 150,
            },
            {
              label: '待结案患者',
              prop: 'waitFinishCount',
              slot: 'waitFinishCount',
              width: 150,
            },
            {
              label: '已结案患者',
              prop: 'finishedCount',
              slot: 'finishedCount',
              width: 150,
            },

            {
              label: '上/下架',
              prop: 'heDte',
              slot: 'heDte',
              width: 150,
            },
            {
              label: '操作',
              prop: 'controls',
              slot: 'controls',
              width: 150,
            },
          ]
        },
      }
    },
    computed: {
      ...mapGetters([
      ])
    },
    created() {
      this.getList();
    },
    methods: {
      search(data) {
        Object.assign(this.paramObj, data);
        this.page.current = 1;
        this.getList();
        // this.$refs.mainTable.handleCurrentChange(1)
      },
      getList(openId) {
        request({
          url: 'admin/kfSchemeInfo/list',
          method: 'GET',
          params: {
            ...this.paramObj,
            ...this.page,
          },
          showLoading: true
        }).then(({res}) => {
          // console.log('getList -----------> ', res);
          this.tableData = res.records;
          this.total = parseInt(res.total);
          if(openId) {
            this.$nextTick(() => {
              const openRow = this.tableData.find( r => r.id == openId)
              openRow && this.handleRowClick(openRow);
            })
          }
        })
      },

      handlePageSizeChange() {
        this.page.current = 1;
        this.getList();
      },
      handleRowClick(row = {}, column, event) {
        this.$refs.mainTable.toggleRowExpansion(row);
      },
      handleEdit(row = {}) {
        // console.log('handleEdit --------> ', row);
        this.$router.push({path: '/project/add', query: {id: row.id}});
      },
      handleDelete(row) {
        this.handleRowClick(row);
        this.$confirm("确定删除？", "删除确认").then(() => {
          // console.log('handleDelete --------> ', row);
          const { id } = row;
          request({
            url: 'admin/kfSchemeInfo/delete',
            method: 'POST',
            data: {id},
            showLoading: true
          }).then(({res}) => {
            this.getList()
          })
        });
      },
      handleOffAll(row = {}) {
        // console.log('handleOffAll --------> ', row);
        const { id, schemeState } = row;
        request({
          url: 'admin/kfSchemeInfo/updateSchemeState',
          method: 'POST',
          data: {
            id,
            schemeState,
          },
          showLoading: true
        }).then(({res}) => {
          this.getList()
        })
      },

      goPatient(row = {}, parentRow = {}, tabIndex) {
        const {doctorId, goodsId} = row;
        const {id: schemeId } = parentRow;
        // console.log('handleOff --------> ', row, parentRow, tabIndex, doctorId, goodsId);
        this.$router.push({path: '/project/patient', query: {tabIndex, doctorId, schemeId}});
      },
      goRecommended(row = {}, parentRow = {}) {
        // 推荐实体商品列表
        const {doctorId, doctorName} = row;
        console.log('row=====',row);
        const {id: schemeId } = parentRow;
        this.$refs.physicalGoods.open(doctorId, schemeId, doctorName);
      },
      // 子 table 上/下架
      handleOff(row = {}, parentRow = {}) {
        // console.log('handleOff --------> ', row.isOnSale, row.goodsId);
        const {isOnSale, goodsId: id} = row
        request({
          url: 'admin/kfSchemeInfo/updateGoodsState',
          method: 'POST',
          data: {
            id,
            isOnSale,
          },
          showLoading: true
        }).then(({res}) => {
          this.getList(parentRow.id)
        })
      },
      formatter(row, column){
        //版权归属 0 平台 1 机构 2 医生
        switch (row.copyright) {
        case '0':
          return "平台"
        case '1':
          return "机构"
        case '2':
          return "医生"
        default:
            return row.copyright;
        }
      },
      cusFormatter(row, column){
        return row.customize == true ? '是' : '否'
      },
    }
  }
</script>

<style scoped>
  >>>.el-table__expanded-cell {
    position: relative;
    padding-left: 100px;
    padding-right: 150px;
  }

  >>>.el-table__expanded-cell::before {
    content: "";
    position: absolute;
    top: 8%;
    bottom: 8%;
    left: 75px;
    width: 1px;
    background-color: #ddd;
  }
</style>
