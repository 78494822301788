<template>
    <el-dialog width="60%" title="方案详情" :visible.sync="visible" :close-on-click-modal="false"
        :close-on-press-escape="false" :show-close="false" custom-class="basicDialog" append-to-body>
        <el-form ref="insertForm" v-model="detail" label-position="right" label-width="120px" class="formBox">
            <el-row>
                <el-col :span="10">
                    <el-form-item label="方案编号：">
                        <el-input v-model="detail.id" :disabled="true"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="10">
                    <el-form-item label="方案名称：">
                        <el-input v-model="detail.guidanceName" :disabled="true"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="10">
                    <el-form-item label="方案内容：">
                        <video style="width: 180px; height: 120px" :src="commonConfig.$filePublicUrl + detail.url"
                            controls controlslist="nodownload"></video>
                    </el-form-item>
                </el-col>
                <el-col :span="10">
                    <el-form-item label="时长(分钟)：">
                        <el-input v-model="detail.length" :disabled="true"></el-input>
                    </el-form-item>
                    <el-form-item label="频次(次)：">
                        <el-input v-model="detail.number" :disabled="true"></el-input>
                    </el-form-item>
                    <el-form-item label="周期(天)：">
                        <el-input v-model="detail.lengthExercise" :disabled="true"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="10">
                    <el-form-item label="上传医师：">
                        <el-input v-model="detail.doctorName" :disabled="true"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="10">
                    <el-form-item label="上传时间：">
                        <el-input v-model="detail.uploadTime" :disabled="true"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="10">
                    <el-form-item label="审核状态：">
                        <el-select v-model="detail.status" placeholder="待审核" :disabled="optionDisabled">
                            <el-option v-for="item in options" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="20">
                    <el-form-item label="方案备注：">
                        <el-input type="textarea" v-model="detail.remark" resize='none' rows="4"
                            :disabled="true"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <template slot="footer" class="dialog-footer">
            <el-button type="primary" @click="determineClick(false)">确 定</el-button>
            <el-button @click="updateVisible(false)">取 消</el-button>
        </template>
    </el-dialog>
</template>

<script>
import { getList, modifyState } from '@/api/review'
export default {
    name: 'reviewUps',
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    watch: {
        visible(newVal, oldVal) {
            if (newVal && this.id) this.getDetail();
        },
    },
    data() {
        return {
            optionDisabled: false,
            visible: false,
            detail: {},
            // (1:审核中，2:拒绝，3:通过，4:未发布
            options: [{
                value: '1',
                label: '审核中'
            }, {
                value: '2',
                label: '拒绝'
            },
            {
                value: '3',
                label: '通过'
            },
            {
                value: '4',
                label: '未发布'
            },
            ],
        }
    },
    methods: {
        getDetail() {
            getList({ id: this.id }).then(({ res }) => {
                if (res.records[0].status != 1 ) {
                    this.optionDisabled = true
                } else {
                    this.optionDisabled = false
                }
                this.detail = res.records[0]
                this.detail.uploadTime = this.moment(res.records[0].uploadTime).format("YYYY-MM-DD HH:mm")
            })
        },
        updateVisible(val) {
            this.visible = val
        },
        determineClick(val) {
            let data = {
                id: this.id,
                status: this.detail.status
            }
            modifyState(data).then((res => {
                this.$emit('reviewUpsClick')
                this.visible = val
                this.$message({
                    message: '操作成功',
                    type: 'success'
                });
            }))
        }
    }
}
</script>

<style>

</style>