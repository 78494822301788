
/**
 * 页面: 消息管理
 * pageName: newsManage
 **/
import request from '@/utils/request'

/**
 * @description: 获取消息管理列表数据
 */
export function getList(data) {
    return request({
        url: '/admin/sysPushMessage/list',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 新增消息管理数据
 */
export function insertData(data) {
    return request({
        url: '/admin/sysPushMessage/add',
        method: 'POST',
        data,
        showLoading: true
    })
}

/**
 * @description: 修改消息管理数据
 */
export function editData(data, timeStamp) {
    return request({
        url: '/admin/sysPushMessage/update',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}
            

/**
 * @description: 获取消息管理详情数据
 */
export function getDetail(data) {
    return request({
        url: '/admin/sysPushMessage/info',
        method: 'GET',
        params: data,
        showLoading: true
    })
}
            
/**
 * @description: 删除消息管理数据
 */
    export function deleteData(data, timeStamp) {
    return request({
        url: '/admin/sysPushMessage/delete',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}
        