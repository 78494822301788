<!--
 * @Description: 模板详情
 * @Author: yzw
 * @Date: 2021-08-13 10:03:22
 * @LastEditTime: 2023-10-08 13:18:31
 * @LastEditors: Please set LastEditors
 * @Reference:
-->
<template>
  <section>
    <el-table
      ref="mainTable"
      v-tableHeight="{ bottomOffset: config.pagination ? 80 : 25}"
      :class="'table-' + 'config.id' || 'main'"
      :data="tableData"
      style="width: 100%"
      size="mini"
      stripe
      v-loading="loading ? true : false"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(255, 255, 255, 0.8)"
      :border="config.border == false ? false : true"
      :show-summary="config.summary"
      :sum-text="config.summaryText || '合计'"
      highlight-current-row
      :row-key="getRowKey"
      tooltip-effect="light"
      :height="tableHeight"
      :row-class-name="tableRowClassName"
      :span-method="objectSpanMethod"
      :default-expand-all="config.expandOpen == true ? true : false"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      :expand-row-keys="config.expandKeys"
      header-row-class-name="table-header"
      @selection-change="handleSelectionChange"
      @row-click="handleRowClick"
      @row-dblclick="handleRowDoubleClick"
      @select-all="handleSelectAll"
    >
      <el-table-column
        v-if="config.selection === 'multiple'"
        type="selection"
        width="55"
        :align="config.align || 'center'"
        :resizable="false"
        :selectable="selectable||selectableTrue"
        :reserve-selection="true"
      />
      <el-table-column
        v-if="config.index"
        type="index"
        label="序号"
        width="50"
        :align="config.align || 'center'"
        :resizable="false"
      />
      <!-- 循环 配置-列 -->
      <template v-for="item in config.column">
        <!-- 通过children字段判断是否为二级表头 -->
        <template v-if="item.children">
          <el-table-column
            :key="item.label"
            :label="item.label"
            :align="item.align || config.align || 'center'"
          >
            <template v-for="obj in item.children">
              <el-table-column
                v-if="obj.slot"
                :key="obj.prop"
                :type="obj.expand"
                :label="obj.label"
                :align="obj.align || config.align || 'center'"
                :fixed="obj.fixed"
                :width="obj.width"
                :resizable="false"
                @click.stop
              >
                <template slot-scope="scope">
                  <slot :name="obj.slot" :data="scope" />
                </template>
              </el-table-column>
              <el-table-column
                v-else
                :key="obj.prop"
                :label="obj.label"
                :prop="obj.prop"
                :align="obj.align || config.align || 'center'"
                :fixed="obj.fixed"
                :width="obj.width"
                :sortable="obj.sortable === undefined ? true : obj.sortable"
                :resizable="false"
                :formatter="obj.formatter"
                :show-overflow-tooltip="true"
              />
            </template>
          </el-table-column>
        </template>
        <template v-else>
          <el-table-column
            v-if="item.slot"
            :key="item.prop"
            :type="item.expand"
            :label="item.label"
            :align="item.align || config.align || 'center'"
            :fixed="item.fixed"
            :width="item.width"
            :resizable="false"
            @click.stop
          >
            <template slot-scope="scope">
              <slot :name="item.slot" :data="scope" />
            </template>
          </el-table-column>
          <el-table-column
            v-else
            :key="item.prop"
            :label="item.label"
            :prop="item.prop"
            :align="item.align || config.align || 'center'"
            :fixed="item.fixed"
            :width="item.width"
            :sortable="item.sortable === undefined ? true : item.sortable"
            :resizable="false"
            :formatter="item.formatter"
            :show-overflow-tooltip="true"
          />
        </template>
      </template>
    </el-table>
    <el-pagination
      v-if="config.pagination || false"
      background
      :current-page="pageNo"
      :page-sizes="commonConfig.pageSizes"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next"
      :total="parseInt(total)"
      style="float: right; margin-top: 15px; padding-right: 0"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </section>
</template>

<script>
export default {
  name: "Temp",
  props: {
    // 选中项
    checkIds: {
      type: Array,
      required: false
    },
    tableData: {
      type: Array,
      required: true
    },
    config: {
      type: Object,
      required: true
    },
    total: {
      type: Number | String,
    },
    newHeight: {
      type: String | Number,
      required: false
    },
    loading: {
      type: Boolean,
      required: false
    },
    selectable: {
      type: Function,
      required: false
    }
  },
  watch: {
    total(val) {
      // this.pageNo * this.pageSize > parseInt(val)
      if(this.pageNo != 1 && this.tableData.length == 0) {
        this.handleCurrentChange(parseInt(parseInt(val) / this.pageSize) + (parseInt(val) % this.pageSize ? 1 : 0));
      }
    },
    newHeight(newVal, orgVal) {
      this.tableHeight = newVal;
    },
    /** 监听tableData数据
     * @description: 页面表格数据发生改变，且配置项中约定了saveSelected为true时，
     *      筛选出选中ids与当前页面数据的交集，此数据为页面需要回显checkbox的数据
     *      页面渲染完成后，将要回显的数据重新打上checkbox
     *      !!!必须在页面渲染结束后才能回显，不然页面渲染会刷新掉原本已经回填的checkbox!!!
     * @param {*} newVal
     * @param {*} orgVal
     * @return {*}
     */

    tableData(newVal, orgVal) {
      if (this.config.saveSelected) {
        const tableChecked = newVal.filter(item =>
          this.checkIds.includes(item.id)
        );
        this.tableChecked = this.checkIds
        console.log('init tableChecked', this.tableChecked)
        if (this.config.selection == 'single') {
             // 单选模式下
             if (this.tableChecked.length > 0) {
              this.$nextTick(() => {
                console.log('_this.tableChecked[0]',tableChecked[0]);
                this.$refs.mainTable.setCurrentRow(tableChecked[0])
              });
             }
             return;
        }
        this.$nextTick(() => {
          this.toggleSelection(tableChecked);
        });
      }
    }
  },
  data() {
    return {
      tableHeight: this.newHeight || 10,
      tableChecked: [], // 表格选中内容
      pageNo: 1,
      pageSize: 20
    };
  },
  created() {
    // if (this.config.summarySpan > 0) {
    //   this.setColSpan('table-' + this.config.id, this.config, this.config.summarySpan)
    // }
  },
  mounted: function() {},
  destroyed() {},
  methods: {
    //selectable 默认值
    selectableTrue(row){
      if(row.canNotSelect) {
        return false
      }
      return true
    },
    // 页面条数
    handleSizeChange(val) {
      this.pageSize = val;
      this.pageNo = 1;
      this.config.saveSelected && this.getAllSelectedIds();
      this.$emit("updatePage");
    },
    // 页码
    handleCurrentChange(val) {
      this.pageNo = val;
      this.config.saveSelected && this.getAllSelectedIds();
      this.$emit("updatePage");
    },
    /** 获取全部页面选中的ids
     * @description: 传入的checkIds与该页面数据做差值，此差值即为其他页面选中项
     *      this.tableChecked为当前页选中的数据，取出每项数据中的id
     *      将其他页选中的id与当前页选中的id进行拼接，将新数据传入父级"@updateSelectedIds"方法的payload中
     * @param {*}
     * @return {*}
     */
    getAllSelectedIds() {
      const currentPageIds = this.tableData.map(item => item.id);
      const otherPageSelectedIds = this.checkIds.filter(
        item => !currentPageIds.includes(item)
      );
      let currentPageSelectedIds;
      if (Array.isArray(this.tableChecked)) {
        currentPageSelectedIds = this.tableChecked.map(item => {
          if(item.id != undefined){
              return item.id
          }else{
              return item
          }
         }
        );
      } else {
        currentPageSelectedIds = [this.tableChecked];
      }
      console.log('currentPageSelectedIds', currentPageSelectedIds)
      this.$emit("updateSelectedIds", Array.from(new Set([
        ...otherPageSelectedIds,
        ...currentPageSelectedIds
      ])));
    },
    //点击全选按钮时触发
    handleSelectAll(selection){
    },
    // 点击复选框触发，复选框样式的改变
    handleSelectionChange(val) {
      this.tableChecked = val;
      this.config.saveSelected && this.getAllSelectedIds();
      if (this.$listeners['selection-change']) {
        this.$emit("selection-change", val);
      }
      if (this.$listeners['clearFailed']) {
        this.$emit("clearFailed");
      }
    },
    // 点击行触发，选中或不选中复选框/单选
    handleRowClick(row, column, event) {
      if (this.config.selection === "multiple") {
        this.$refs.mainTable.toggleRowSelection(row);
        this.$emit("onClickRow", row, this.tableChecked);
      } else if (this.config.selection === "single") {
        // this.$refs.mainTable.setCurrentRow(row)
        this.tableChecked = row;
        if (this.$listeners['onClickRow']) {
          this.$emit("onClickRow", this.tableChecked);
        }
      }
    },
    // 双击点击行触发，选中或不选中复选框/单选

    handleRowDoubleClick(row, column, event){
      if (this.config.selection === "single") {
        this.tableChecked = row;
        if (this.$listeners['onDoubleClickRow']) {
          this.$emit("onDoubleClickRow", this.tableChecked);
        }
      }
    },
    // 回显选中项
    toggleSelection(rows) {
      if (rows && rows.length) {
        rows.forEach(row => {
          this.$refs.mainTable.toggleRowSelection(row, true);
        });
      } else {
        this.$refs.mainTable.clearSelection();
      }
    },
    setCurrent(row) {
        this.$refs.mainTable.setCurrentRow(row);
    },
    clearSelection(){
        this.$refs.mainTable.clearSelection();
    },
    getChecked() {
      if (this.tableChecked.length === 0) {
        this.$message({
          message: '请选择待操作的记录',
          type: 'warning'
        });
        return
      }
      console.log('getChecked---', this.tableChecked)
      return this.tableChecked;
    },
    // 清除过滤器
    clearFilter() {
      this.$refs["mainTable"].clearFilter();
    },
    getPage() {
      // 获取page对象
      return {
        current: this.pageNo,
        size: this.pageSize
      };
    },
    tableRowClassName({ row, index }) {
      if (row.className) {
        return row.className;
      } else {
        return "";
      }
    },
    getRowKey(row) {
      return row.id;
    },
    objectSpanMethod(param) {
      // 合并行或列
      // param包含 { row, column, rowIndex, columnIndex }
      let span = [];
      // 因为$emit返回的是vue对象，而该方法需要父组件方法的return，所以通过callback方式获取父组件方法的return
      this.$emit("objectSpanMethod", param, arr => {
        span = arr;
      });
      if (span.length > 0) {
        return span;
      }
    },
    setColSpan(query, tableConfig, colSpan) {
      const that = this;
      const queryClass = `.${query}`;
      setTimeout(function() {
        if (that.$el.querySelector(queryClass)) {
          const current = that.$el
            .querySelector(queryClass)
            .querySelector(".el-table__footer-wrapper")
            .querySelector(".el-table__footer");
          const cell = current.rows[0].cells;
          if (tableConfig.index && tableConfig.selection !== "normal") {
            cell[0].style.display = "none";
            cell[1].style.display = "none";
            cell[2].colSpan = colSpan;
          } else if (tableConfig.index || tableConfig.selection !== "normal") {
            cell[0].style.display = "none";
            cell[1].colSpan = colSpan;
          } else {
            cell[0].colSpan = colSpan;
          }
        }
      }, 50);
    }
  }
};
</script>

<style lang="scss">
@import "~@/styles/variables.scss";
</style>
