<!--
 * @Author: shangxue
 * @Date: 2024-03-20 13:10:21
 * @LastEditTime: 2024-03-20 13:24:08
 * @LastEditors: 
 * @Description: 
 * @FilePath: /miniprogram_patient_dev/Users/shangxue/newgit_workspace/recovery_home_management_dev/src/views/order/schemeOrderRemark.vue/index.vue
-->
<template>
  <el-dialog width="60%" title="商品订单备注" :visible.sync="visible" :close-on-click-modal="false"
      :close-on-press-escape="false" :show-close="false" custom-class="basicDialog" append-to-body>
      <el-form ref="insertForm" v-model="detail" label-position="right" label-width="120px" class="formBox">
          <el-row>
              <el-col :span="20">
                  <el-form-item label="订单编号：">
                      <label >{{ detail.orderId }}</label>
                  </el-form-item>
              </el-col>           
          </el-row>
          <el-row>
              <el-col :span="20">
                  <el-form-item label="患者名称：">
                      <label >{{ detail.patientName }}</label>
                  </el-form-item>
              </el-col>           
          </el-row>
          <el-row>
              <el-col :span="20">
                  <el-form-item label="商品名称：">
                      <label >{{ detail.goodsName }}</label>
                  </el-form-item>
              </el-col>             
          </el-row>
          <el-row>
              <el-col :span="20">
                  <el-form-item label="方案备注：">
                      <el-input type="textarea" v-model="detail.remark" resize='none' rows="4" :show-word-limit="true"
                      :maxlength="200"></el-input>
                  </el-form-item>
              </el-col>
          </el-row>
      </el-form>
      <template slot="footer" class="dialog-footer">
          <el-button type="primary" @click="determineClick(false)">确 定</el-button>
          <el-button @click="updateVisible(false)">取 消</el-button>
      </template>
  </el-dialog>
</template>

<script>
import { getkfSchemeOrderInfoById, kfSchemeOrderUpdate } from '@/api/order'
export default {
  name: 'schemeOrderRemark',
  props: {
      id: {
          type: String,
          required: true,
      }
  },
  watch: {
      visible(newVal, oldVal) {
          if (newVal && this.id) this.getDetail();
      },
  },
  data() {
      return {
          optionDisabled: false,
          visible: false,
          detail: {}
      }
  },
  methods: {
      getDetail() {
          getkfSchemeOrderInfoById({ id: this.id }).then(({ res }) => {
              this.detail = res
          })
      },
      updateVisible(val) {
          this.visible = val
      },
      determineClick(val) {
          let data = {
              id: this.id,
              remark: this.detail.remark
          }
          kfSchemeOrderUpdate(data).then((res => {
              this.$emit('remarkClick')
              this.visible = val
              this.$message({
                  message: '操作成功',
                  type: 'success'
              });
          }))
      }
  }
}
</script>

<style>

</style>