
<!--
* @Description: 测试页面
* @Reference:
-->

<template>
  <el-container>
    <el-header height="auto">
      <searchForm ref="mainSearch" :searchoptions="searchOptions" @search="search" />
    </el-header>
    <el-main class="basic-main">
      <el-row class="table-btn-group"><el-button type="primary" @click="toInsert()">新增</el-button></el-row>
      <generalTable ref="mainTable" :table-data="tableData" :config="tableConfig" :total="total" @updatePage="getList" @objectSpanMethod="objectSpanMethod" @onClickRow="onClickRow">
        <template slot="handle" slot-scope="scope">
          <el-button type="text" @click="toDetail(scope)">详情</el-button>
          <el-button type="text" style="margin-right: 10px" @click="toEdit(scope)">编辑</el-button>
          <el-button type="text" class="delBtn" @click="toDel(scope)">删除</el-button>
        </template>
      </generalTable>
      <detailDialog :id="checkId" ref="detail" />
      <addDialog :id="checkId" ref="insert" :submit="search" :time-stamp="timeStamp" />
    </el-main>
  </el-container>
</template>

<script>
import { getList, deleteData } from '@/api/test'
import searchForm from '@/components/SearchForm'
import generalTable from '@/components/Table'
import addDialog from '@/views/test/addDialog'
import detailDialog from '@/views/test/detailDialog'

export default {
    name: 'Test',
    components: {
        searchForm,
        generalTable,
        detailDialog,
        addDialog
    },
    data() {
        return {
            tableData: [
                {
                    id: 1,
                    date: '2016-05-02',
                    name: '王小虎1',
                    address: '上海市普陀区金沙江路 1518 弄'
                }, {
                    id: 2,
                    date: '2016-05-04',
                    name: '王小虎2',
                    address: '上海市普陀区金沙江路 1517 弄'
                }, {
                    id: 3,
                    date: '2016-05-01',
                    name: '王小虎3',
                    address: '上海市普陀区金沙江路 1519 弄'
                }, {
                    id: 4,
                    date: '2016-05-03',
                    name: '王小虎6',
                    address: '上海市普陀区金沙江路 1516 弄'
                }
            ],
            searchOptions: [
                {
                    type: 'input',
                    placeholder: '请输入...',
                    key: 'name',
                    label: '姓名',
                    defaultVal: ''
                },
                {
                    type: 'select',
                    placeholder: '请选择',
                    key: 'role',
                    filterable: true,
                    multiple: true,
                    label: '多选',
                    options: [
                        {
                            value: '1',
                            label: '内容1'
                        },
                        {
                            value: '2',
                            label: '内容2'
                        },
                        {
                            value: '3',
                            label: '内容3'
                        },
                        {
                            value: '4',
                            label: '内容4'
                        },
                        {
                            value: '5',
                            label: '内容5'
                        }
                    ],
                    defaultVal: []
                },
                {
                    type: 'daterange',
                    startPlaceholder: '开始日期',
                    endPlaceholder: '结束日期',
                    key: 'date',
                    label: '日期',
                    defaultVal: []
                }
            ],
            tableConfig: {
                id: 'test',
                align: 'center', // 不必填 默认为center
                selection: 'single', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: true, // 不必填 分页 默认为false
                column: [
                {
                    label: '内容', // 必填
                    prop: 'name' // 必填
                    // formatter: (row, column) => {
                    //     return row.archivesInfoId.name
                    // }
                },
                {
                    label: '内容1', // 必填
                    prop: 'date' // 必填
                },
                {
                    label: '内容2', // 必填
                    prop: 'address' // 必填
                },
                {

                    slot: 'handle', // 不必填 单列插槽
                    label: '操作', // 必填
                    fixed: 'right', // 不必填 固定列  left right
                    width: '220' // 不必填
                }
                ]
            },
            paramObj: null, // 筛选项
            total: 0, // 总条数
            checkId: '', // 单条数据id 用于详情或编辑请求数据用
            timeStamp: ''
        }
    },
    created() {
    },
    mounted: function() {},
    methods: {
        search(data) {
            this.paramObj = data
            this.$refs.mainTable.handleCurrentChange(1)
        },
        getList() {
            // 解决未挂载完组件时调用子组件方法报错问题
            this.$nextTick(() => {
                const param = this.getParam()
                getList(param).then((res, timeStamp) => {
                    this.tableData = res
                    this.timeStamp = timeStamp
                })
            })
        },
        objectSpanMethod(param, cb) {
            cb([])
        },
        toInsert() {
            this.checkId = ''
            this.$refs.insert.updateVisible(true)
        },
        toDetail(scope) {
            this.checkId = scope.data.row.id
            this.$refs.detail.updateVisible(true)
        },
        toEdit(scope) {
            this.checkId = scope.data.row.id
            this.$refs.insert.updateVisible(true)
        },
        toDel(scope) {
            const { $delMsg, $delTitle, $messageBoxConfig } = this.commonConfig
            this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
                const param = { id: scope.data.row.id }
                deleteData(param).then(({ res }) => {
                    this.$message({
                    message: '删除成功',
                    type: 'success'
                    })
                })
            })
        },
        onClickRow(checkArray) {
            console.log('testststst')
            console.log('checkArray', checkArray)
        },
        getParam() {
            // 获取参数
            const paramObj = this.$refs.mainSearch.packageData()
            this.paramObj = paramObj
            const page = this.$refs.mainTable.getPage()
            const param = Object.assign(page, paramObj)
            return param
        }
    }
}
</script>

<style lang='scss'>
@import "~@/styles/variables.scss";

</style>
