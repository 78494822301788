<template>
  <div class="custom">
    <template v-if="isCustom.businessID === CHAT_MSG_CUSTOM_TYPE.SERVICE">
      <div>
        <h1>
          <label>{{ extension.title }}</label>
          <a
            v-if="extension.hyperlinks_text"
            :href="extension.hyperlinks_text.value"
            target="view_window"
            >{{ extension.hyperlinks_text.key }}</a
          >
        </h1>
        <ul v-if="extension.item && extension.item.length > 0">
          <li v-for="(item, index) in extension.item" :key="index">
            <a
              v-if="isUrl(item.value)"
              :href="item.value"
              target="view_window"
              >{{ item.key }}</a
            >
            <p v-else>{{ item.key }}</p>
          </li>
        </ul>
        <article>{{ extension.description }}</article>
      </div>
    </template>
    <template v-else-if="isCustom.businessID === CHAT_MSG_CUSTOM_TYPE.EVALUATE">
      <div class="evaluate">
        <h1>{{ TUITranslateService.t("message.custom.对本次服务评价") }}</h1>
        <ul class="evaluate-list">
          <li
            class="evaluate-list-item"
            v-for="(item, index) in Math.max(isCustom.score, 0)"
            :key="index"
          >
            <Icon :file="star" class="file-icon"></Icon>
          </li>
        </ul>
        <article>{{ isCustom.comment }}</article>
      </div>
    </template>
    <template v-else-if="isCustom.businessID === CHAT_MSG_CUSTOM_TYPE.ORDER">
      <div class="order" @click="openLink(isCustom.link)">
        <img :src="isCustom.imageUrl" alt="" />
        <main>
          <h1>{{ isCustom.title }}</h1>
          <p>{{ isCustom.description }}</p>
          <span>{{ isCustom.price }}</span>
        </main>
      </div>
    </template>
    <template v-else-if="isCustom.businessID === CHAT_MSG_CUSTOM_TYPE.LINK">
      <div class="textLink">
        <p>{{ isCustom.text }}</p>
        <a :href="isCustom.link" target="view_window">{{
          TUITranslateService.t("message.custom.查看详情>>")
        }}</a>
      </div>
    </template>
    <template
      v-else-if="message.payload.data === CHAT_MSG_CUSTOM_TYPE.NOTIFICATION"
    >
      <div>
        <p class="notification">
          <span>----</span>{{ message.payload.description }}<span>----</span>
        </p>
      </div>
    </template>
    <template
      v-else-if="message.payload.data === CHAT_MSG_CUSTOM_TYPE.PATIENT_INFO"
    >
      <div class="patient-info">
        <img class="header-bg" :src="getPicsUrl"/>
        <span class="title">基本信息</span>
        <div>{{ getPatientBaseInfo() }}</div>
        <span class="title border_top">病情描述</span>
        <div v-html="getPatientDiseaseInfo()"></div>
        <span class="title border_top" v-if="getPatientDiseasePics.length > 0" @click="previewImage">
          病例、处方、检查报告等</span
        >
        <div class="carousel" v-if="getPatientDiseasePics.length > 0">
          <div v-for="(item, index) in getPatientDiseasePics" :key="index" @click="previewImage(index)" style="cursor: pointer;">
            <el-image
              ref="previewRef"
              class="image"
              :src="commonConfig.$filePublicUrl + item"
              :preview-src-list="getPatientDiseaseImages"
            ></el-image>
          </div>
        </div>
      </div>
    </template>
    <template
      v-else-if="message.payload.data === CHAT_MSG_CUSTOM_TYPE.DOCTOR_INFO"
    >
      <div class="doctor-info">
        <img class="header-bg" :src="getDoctorAvatar"/>
        <div class="left" v-html="getDoctorInfo"></div>
      </div>
    </template>
    <template
      v-else-if="message.payload.data === CHAT_MSG_CUSTOM_TYPE.MALL_GOODS_INFO"
    >
      <div class="goods-info">
        <div class="left">
          <img class="good-image" :src="getGoodImage"/>
        </div>
        <div class="right">
          <p class="title">{{getGoodTitle}}</p>
          <p class="brief">{{getGoodBrief}}</p>
          <p class="price">{{getGoodRetailPrice}}{{extension.startPrice && extension.goodsType != 5 ? '起' : ''}}</p>
        </div>
      </div>
    </template>
    <template v-else>
      <span v-html="content.custom"></span>
    </template>
  </div>
</template>

<script lang="ts">
import { watchEffect, ref, computed } from "../../../../adapter-vue";
import { TUITranslateService, IMessageModel } from "@tencentcloud/chat-uikit-engine";
import { isUrl, JSONToObject } from "../../../../utils/index";
import { CHAT_MSG_CUSTOM_TYPE } from "../../../../constant";
import { ICustomMessagePayload } from "../../../../interface";
import commonConfig from "@/config/common";
import Icon from "../../../common/Icon.vue";
import star from "../../../../assets/icon/star-light.png";
interface Props {
  messageItem: IMessageModel;
  content: any;
}
const __sfc_main = {};
__sfc_main.props = {
  messageItem: {
    key: "messageItem",
    required: false,
    type: null,
    default: undefined
  },
  content: {
    key: "content",
    required: false,
    type: null,
    default: undefined
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const custom = ref();
  const previewRef = ref();
  const message = ref<IMessageModel>();
  const extension = ref();
  const isCustom = ref<ICustomMessagePayload>({
    businessID: ""
  });
  watchEffect(() => {
    custom.value = props.content;
    message.value = props.messageItem;
    const {
      payload
    } = props.messageItem;
    isCustom.value = payload.data || "";
    isCustom.value = JSONToObject(payload.data);
    if (payload.data === CHAT_MSG_CUSTOM_TYPE.SERVICE || CHAT_MSG_CUSTOM_TYPE.PATIENT_INFO) {
      extension.value = JSONToObject(payload.extension);
    }
    // 解析患者卡片信息
    if (payload.data === CHAT_MSG_CUSTOM_TYPE.PATIENT_INFO) {
      extension.value = JSONToObject(payload.description.replace(new RegExp(/(\n)/g), "</br>"));
      if (extension.value.diseasePics) {
        extension.value.diseasePics = extension.value.diseasePics.split(",");
      } else {
        extension.value.diseasePics = [];
      }
    }
    // 解析推荐方案、实体商品信息
    if (payload.data === CHAT_MSG_CUSTOM_TYPE.MALL_GOODS_INFO) {
      extension.value = JSONToObject(payload.extension);
    }
  });
  const openLink = (url: any) => {
    window.open(url);
  };
  const getPatientBaseInfo = () => {
    if (extension.value) {
      return `${extension.value.patientName},${extension.value.patientGender == 2 ? "女" : "男"},${extension.value.patientAge}`;
    }
    return "";
  };

  // 获取推荐医生基本信息
  const getDoctorInfo = computed(() => {
    if (extension.value) {
      let doctorInfo = extension.value;
      if (doctorInfo) {
        return `<p>【${doctorInfo.name}】<span style="margin: 0px 10px 0px 0px">${doctorInfo.functionalLabel || ''}</span>${doctorInfo.hospitalName}<span style="margin: 0px 10px">${doctorInfo.sectionLabel || ''}</span></p>`;
      }
    }
    return '';
  });

  // 获取推荐医生头像
  const getDoctorAvatar = computed(() => {
    if (extension.value) {
      if (!extension.value.photo) {
        // 医生默认头像
        return commonConfig.$filePublicUrl + 'miniprogram/im/header-panda-bg.svg';
      }
      // 判断头像是否是url，还是七牛云KEY
      if (/^(http|https):\/\/[^ "]+$/.test(extension.value.photo)) {
        return extension.value.photo;
      } else {
        // 七牛云图片，指定宽度200，高度等比缩小
        return commonConfig.$filePublicUrl + extension.value.photo + '?imageView2/2/h/200';
      }
    }
    return '';
  });

  // 获取推荐实体商品图片
  const getGoodImage = computed(() => {
    if (extension.value) {
      return extension.value.listPicUrl;
    }
    return '';
  });

  // 获取推荐实体商品标题
  const getGoodTitle = computed(() => {
    if (extension.value) {
      return extension.value.name;
    }
    return '';
  });
  const getGoodBrief = computed(() => {
    console.log('getGoodBrief----', extension.value);
    if (extension.value) {
      if (extension.value.goodsType == 5) {
        return extension.value.goodsBrief ? extension.value.goodsBrief : '暂无描述';
      } else {
        return extension.value.doctorName ? extension.value.doctorName + ' ' + extension.value.functionalLabel : '暂无描述';
      }
    }
    return '';
  });
  const getGoodRetailPrice = computed(() => {
    if (extension.value) {
      if (extension.value.retailPrice) {
        if (extension.value.goodsType == 4) {
          // 定制定制化方案
          return `￥${extension.value.retailPrice}起`;
        } else if (extension.value.goodsType == 5 && extension.value.startPrice) {
          // 定制定制化方案
          return `￥${extension.value.startPrice}起`;
        } else {
          return `￥${extension.value.retailPrice}`;
        }
      }
      if (extension.value.doctorId) {
        // 推荐设备
        return `￥${extension.value.retailPrice}`;
      } else {
        // 推荐方案
        return `免费`;
      }
    }
    return '';
  });
  const getPicsUrl = computed(() => {
    return commonConfig.$filePublicUrl + 'miniprogram/im/header-panda-bg.svg';
  });
  const previewImage = index => {
    if (index <= previewRef.value.length) {
      previewRef.value[index].clickHandler();
    }
  };
  const getPatientDiseasePics = computed(() => {
    if (extension.value) {
      console.log('extension.value========', extension.value);
      return extension.value.diseasePics || [];
    }
    return [];
  });
  const getPatientDiseaseImages = computed(() => {
    if (extension.value) {
      return extension.value.diseasePics.map(item => commonConfig.$filePublicUrl + item);
    }
    return [];
  });
  const getPatientDiseaseInfo = () => {
    if (extension.value) {
      return `${extension.value.disease}`;
    }
    return "";
  };
  return {
    TUITranslateService,
    isUrl,
    CHAT_MSG_CUSTOM_TYPE,
    commonConfig,
    star,
    previewRef,
    message,
    extension,
    isCustom,
    openLink,
    getPatientBaseInfo,
    getDoctorInfo,
    getDoctorAvatar,
    getGoodImage,
    getGoodTitle,
    getGoodBrief,
    getGoodRetailPrice,
    getPicsUrl,
    previewImage,
    getPatientDiseasePics,
    getPatientDiseaseImages,
    getPatientDiseaseInfo
  };
};
__sfc_main.components = Object.assign({
  Icon
}, __sfc_main.components);
export default __sfc_main;
</script>
<style lang="scss" scoped>
@import "../../../../assets/styles/common.scss";
a {
  color: #679ce1;
}
.custom {
  font-size: 14px;
  h1 {
    font-size: 14px;
    color: #000000;
  }
  h1,
  a,
  p {
    font-size: 14px;
  }
  .evaluate {
    ul {
      display: flex;
      padding-top: 10px;
    }
    &-list {
      display: flex;
      flex-direction: row;
      &-item {
        padding: 0px 2px;
      }
    }
  }
  .order {
    display: flex;
    main {
      padding-left: 5px;
      p {
        font-family: PingFangSC-Regular;
        width: 145px;
        line-height: 17px;
        font-size: 14px;
        color: #999999;
        letter-spacing: 0;
        margin-bottom: 6px;
        word-break: break-word;
      }
      span {
        font-family: PingFangSC-Regular;
        line-height: 25px;
        color: #ff7201;
      }
    }
    img {
      width: 67px;
      height: 67px;
    }
  }

  .notification {
    color: #999999;
  }

  .patient-info {
    display: flex;
    width: 300px;
    flex-direction: column;
    &:first-child{
      margin-top: 5px;
    }

    .header-bg {
      width: 100%;
      height: 28px;
      object-fit: cover;
      position: absolute;
      border-top-right-radius: 10px;
      left:0px;
      top:0px;
    }

    .title {
      font-size: 14px;
      color: #b2b3b2;
      margin: 10px 0px;
      padding: 5px 0px;
      border-bottom: 0.5px solid #e7e7e7;
    }

    .carousel {
      display: flex;
      flex-wrap: nowrap;
      width: 300px;
      // background-color: red;
      overflow-x: auto;
      overflow-y: hidden;
      height: 70px;
    }

    .image {
      width: 60px;
      height: 60px;
      margin-left: 5px;
      border-radius: 6px;
      object-fit: contain;
      flex-shrink: 0;
    }

    .border_bottom {
      border-bottom: 0.5px solid #e7e7e7;
    }

    .border_top {
      border-top: 0.5px solid #e7e7e7;
    }
  }
  .goods-info{
    display: flex;
    flex-direction: row;
    width: 300px;
    .left{
      .good-image{
        width: 90px;
        height: 90px;
        border-radius: 6px;
        object-fit: cover;
      }
    
    }
    .right{
      display: flex;
      flex: 1;
      flex-direction: column;
      //justify-content: space-between;
      margin-left: 10px;
      margin-top: 5px;
      width: 168px;
      .title{
        font-size: 16px;
        color: #322C21;
        font-weight: 400;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .brief{
        font-size: 14px;
        color: #999999;
        margin-top: 5px;
        word-break: break-all;
        overflow: hidden;
        display: -webkit-box;
        max-height: 40px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .price{
        position: absolute;
        bottom: 7px;
        font-size: 14px;
        text-align: right;
        color: #469cf8;
      }
    }
  }
  .doctor-info{
      display: flex;
      flex-direction: row;
      width: 300px;
      .header-bg {
        width: 54px;
        height: 54px;
        border-radius: 6px;
        object-fit: cover;
      }

      .left {
          flex:1;
          height: 60px;
          margin-left: 10px;
          margin-top: 5px;
          font-size: 16px;
          color: #322C21;
		      font-weight: 400;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

      }
  }
}
</style>
