<!--
 * @Author: your name
 * @Date: 2022-03-22 10:34:21
 * @LastEditTime: 2022-03-22 15:46:20
 * @LastEditors: Please set LastEditors
 * @Description: 康复指导
 * @FilePath: /recovery_home_management/src/views/rehabilitation/index.vue
-->
<template>
  <MenuBox :roles="secondMenu" />
</template>

<script>
import { mapGetters } from 'vuex'
import MenuBox from '@/components/MenuBox'
export default {
  name: 'System',
  components: { MenuBox },
  data() {
    return {
      secondMenu: []
    }
  },
  computed: {
    ...mapGetters([
      'roles'
    ])
  },
  created() {
    const { roles } = this.$store.getters

    for (let i = 0; i < roles.length; i++) {
      const element = roles[i]
      if (element.name === 'rehabilitationMain') {
        console.log('element.redirect==',element.redirect)
        element.children.filter(obj=>{console.log('obj==',obj.path,obj.hidden)})
        this.secondMenu = element.children.filter(obj => obj.hidden === false && element.redirect !== obj.path)

        console.log('======',this.secondMenu)
      }
    }
  },
  methods: {}
}
</script>

<style lang='scss' scoped>
</style>
