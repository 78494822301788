/*
 * @Author: your name
 * @Date: 2022-03-22 15:33:46
 * @LastEditTime: 2022-03-23 19:19:39
 * @LastEditors: Please set LastEditors
 * @Description: ��koroFileHeader�鿴���� ��������: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \recovery_home_management\src\api\basicSetting.js
 */

/**
 * ҳ��: ��������
 * pageName: patientTreatment
 **/
 import request from '@/utils/request'

 /**
  * @description: ���ҹ����б�
  */
 export function getList(data) {
   return request({
     url: 'admin/kfType/list',
     method: 'GET',
     params: data,
     showLoading: true
   });
 }

 /**
 * @description: ��ȡ���һ�����Ϣ
 * @param {Object} data
 */
  export function getUserInfo(data) {
    return request({
      url: 'admin/kfType/info',
      method: 'GET',
      params: data,
      showLoading: true
    })
  }
  
  /**
   * @description: ��������
   * @param {Object} data
   */
   export function createUser(data) {
    return request({
      url: 'admin/kfType/add',
      method: 'POST',
      data,
      showLoading: true,
      // timeStamp
    })
  }
  
  /**
   * @description: ���¿�����Ϣ
   * @param {Object} data
   */
  export function updateUserInfo(data, timeStamp) {
    return request({
      url: 'admin/kfType/update',
      method: 'POST',
      data,
      showLoading: true,
      timeStamp
    })

  }

   /**
  * @description: ɾ��ҽ��
  */
  export function deleteData(data, timeStamp) {
     return request({
         url: 'admin/kfType/delete',
         method: 'POST',
         data,
         showLoading: true,
         timeStamp
     })
 }

 /**
 * @description: ��ȡȫ�ַ���
 * @param {Object} data
 */
  export function getKfType(data) {
    return request({
      url: 'admin/kfType/getKfType',
      method: 'GET',
      params: data,
      showLoading: true
    })
  }
 
 
