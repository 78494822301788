<template>
  <el-container>
    <el-header height="auto">
      <searchForm
          ref="mainSearch"
          :searchoptions="searchOptions"
          @search="search"
          :backStatus="backStatus"
      />
    </el-header>
    <el-main class="basic-main">
      <div class="table-btn-group">
        <div>
          <el-button type="primary" @click="toInsert()">上传视频</el-button>
          <el-button type="default" @click="batchDelete()">批量删除</el-button>
          <el-button type="default" @click="batchGroup()">批量分组</el-button>
        </div>
        <div>
          <el-button type="primary" @click="groupConfig()">分组管理</el-button>
        </div>
      </div>
      <div class="tag-container" ref="tagContainer"   :class="showExpandButton &&!hidetext ? 'hidetext' : ''">
        <el-tag  
          v-for="videoType in videoTypeList"  
          :key="videoType.id"  
          :type="selectedTag.id === videoType.id ? '' : 'info'"
          @click="selectTag(videoType)">
          {{ videoType.videoTypeName }} {{ '(' + videoType.videoCount + ')'}}</el-tag>  
        <el-button  class="more-btn" v-show="showExpandButton"  @click="toggleExpand" size="mini">{{ moretext }}</el-button>  
      </div>  
      <el-row :gutter="12" class="card-box" v-if="data.length > 0">
        <el-col :span="6" v-for="item in data" :key="item.id">
          <el-card class="card" shadow="hover" :body-style="{ padding: '12px'}">
            <!-- 卡片的头部位 -->
            <template #header>
              <div class="card-header">
                <el-checkbox v-model="item.checked" style="margin-top: 5px;" @change="handleCheckboxChange(item)"></el-checkbox>
                <div>
                  <el-dropdown trigger="click" @command="handleCommand($event, item)">
                    <el-button type="text" icon="el-icon-more" />
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="rename">重命名</el-dropdown-item>
                      <el-dropdown-item command="grouping">分组</el-dropdown-item>
                      <el-dropdown-item command="sort">排序</el-dropdown-item>
                      <el-dropdown-item command="delete" divided>删除</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>
            </template>
            <!-- 卡片显示的内容 -->
            <div class="card-content">
              <div class="left">
                <video
                    class="video"
                    :src="commonConfig.$filePublicUrl + item.videoUrl"
                    controls
                    controlslist="nodownload"
                    width="75px"
                    height="75px"
                ></video>
              </div>
              <div class="right">
                <div class="video-title truncate">{{item.videoName}}</div>
                <div class="video-info">
                  <div class="size">{{item.videoSize}}</div>
                  <div class="length">{{initVideoLength(item.videoLength)}}</div>
                </div>
              </div>
            </div>
            </el-card>
        </el-col>
      </el-row>
      <el-row :gutter="12" class="card-box" v-else>
        <div class="no-data">暂无数据</div>
      </el-row>
      <div class="bottom">
        <el-checkbox v-model="isAllChecked" :label="1" @change="toggleAllSelections">全选</el-checkbox>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="onLoad"
          :current-page.sync="page.current" 
          :page-sizes="[12, 16, 20, 24, 32]"
          :page-size="page.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </el-main>
    <input
      style="display: none"
      type="file"
      accept="video/*"
      @change="changeModelFile($event)"
      ref="ModelInput"
    />
    <groupConfigDialog :id="checkId" ref="groupConfig"  :time-stamp="timeStamp" @submit='updateVideoType()' />
    <renameDialog :id="checkId" ref="renameDialog"  :time-stamp="timeStamp" @submit='onLoad()' />
    <groupDialog :id="checkId" :ids="selectedItems" :isBatchGroup="isBatchGroup" ref="groupDialog"  :time-stamp="timeStamp" @submit='loadGroup()' />
    <sortDialog :id="checkId" ref="sortDialog"  :time-stamp="timeStamp" @submit='onLoad()' />
  </el-container>
</template>
 
<script>
import searchForm from "@/components/SearchForm";
import groupConfigDialog from "./groupConfigDialog"
import groupDialog from "./groupDialog"
import renameDialog from "./renameDialog"
import sortDialog from "./sortDialog"
import {getGeneralVideoList, addGeneralVideoInfo, deleteGeneralVideoInfo, deleteBatchGeneralVideoInfo, getGeneralVideoTypeListNoPage } from "@/api/generalVideoManagement"
import { uploadPublicFile } from "@/utils/uploadQiniu.js";
import {
  initVideoLength
} from "@/utils/common";
export default {
  components: {
    searchForm,
    groupConfigDialog,
    renameDialog,
    groupDialog,
    sortDialog
  },
  data() {
    return {
      initVideoLength,
      checkId: '',
      isBatchGroup: false,
      loading:false,
      videoTypeId: '',
      isAllChecked: '',
      timeStamp: '',
      data: [],
      selectedItems: [],
      paramObj: {},
      total: 0,
      page: { current: 1, size:12, },
      multipleSelection: [], // 用于存储选中的卡片ID
      backStatus: true, //返回按钮的隐藏显示
      searchOptions: [{
          type: "input",
          placeholder: "请输入视频名称",
          key: "videoName",
          label: "视频名称",
          defaultVal: "",
        },
      ],
      selectedTag: {},
      videoTypeList: [],
      showExpandButton: false,
      hidetext: false,
      moretext: '展开',
    };
  },

  mounted() {
    this.getVideoTypeList()
    this.onLoad(this.page);
   
  },
  updated() {
    this.changeHeight()
  },
  watch: {
    selectedTag: {
        handler(newVal, oldVal) {
          console.log('selectedTag---', newVal)
          this.onLoad()
        },
        immediate: true,
        deep: true,
      },
    },

  methods: {
    changeHeight() {
      //通过ref获取对应dom节点的高度，注意20为单行时的高度
      let height = this.$refs.tagContainer.clientHeight;
      console.log('height---', height)
      //当获取的高度大于20时，即当前文本为多行，设置添加收起文本的class，
      //当获取的高度等于20时，隐藏查看更多按钮
      if (height > 36) {
        //this.hidetext = true;
        this.showExpandButton = true;
      } 
      // else if (height <= 32) {
      //   this.showExpandButton = false;
      // }
    },
    toggleExpand() {
      this.hidetext = !this.hidetext;
      this.moretext = this.hidetext == true ? '收起' : '展开'
    },
    selectTag(tag) {
      this.selectedTag = this.selectedTag.id === tag.id ? null : tag;
      this.paramObj = {
        videoTypeId: tag.id
      }
      this.page.current = 1
      //this.onLoad()
    },
    search(data) {
        Object.assign(this.paramObj, data);
        this.page.current = 1;
        this.onLoad();
      },
    ids(val) {
    },
    handleSizeChange(val) {
      this.page.current = 1;
      this.page.size = val;
      this.onLoad();
    },
    handleCurrentChange(val) {
      this.onLoad();
    },
    handleCheckboxChange(item) {
      if (item.checked) {
        this.selectedItems.push(item.id);
      } else {
        const index = this.selectedItems.indexOf(item.id);
        if (index > -1) {
          this.selectedItems.splice(index, 1);
        }
      }
    },
    toInsert() {
      console.log('setVideoClick')
      this.$refs.ModelInput.click();
    },
    /**
     * @description: 上传视频
     */
    changeModelFile(event) {
      this.loading = true;
      let file = event.target.files[0];
      const that = this;
      uploadPublicFile(file)
      .then((success) => {    
        console.log('file name===', file)
        const param = {
          videoName: file.name,
          videoUrl: success,
          videoTypeId: that.paramObj.videoTypeId
        }
        console.log('file name===', file)
        addGeneralVideoInfo(param).then(res => {
          this.$message({
              message: "上传成功",
              type: "success",
              duration: "2000"
          });
          that.loading = false;
          that.getVideoTypeList()
          that.onLoad()
        });  
      })
      .catch((handleError) => {
          that.loading = false;
          this.$message({
              message: "上传失败，请重试",
              type: "warning",
          });
          console.log("handleError====", handleError);
      });
    },
    groupConfig() {
      this.$refs.groupConfig.updateVisible(true)
    },
    updateVideoType() {
      this.getVideoTypeList()
    },
    toggleAllSelections() {
      console.log('toggleAllSelections---', this.checkId)
      if (this.isAllChecked) {
        this.data.forEach(item => {
          item.checked = true;
          this.selectedItems.push(item.id);
        });
      } else {
        this.data.forEach(item => {
          item.checked = false;
        });
        this.selectedItems = [];
      }
      console.log('toggleAllSelections---', this.data)
    },
    batchGroup() {
      if(this.selectedItems.length == 0) {
        this.$message({
          type: 'warning',
          message: '请选择视频'
        });
        return
      }
      this.isBatchGroup = true
      this.$refs.groupDialog.updateVisible(true)
    },
    batchDelete() {
      if(this.selectedItems.length == 0) {
        this.$message({
          type: 'warning',
          message: '请选择视频'
        });
        return
      }
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log('this.selectedItems---', this.selectedItems)
        deleteBatchGeneralVideoInfo(this.selectedItems).then(res => {
          this.selectedItems = [];
          this.page.current = 1;
          this.onLoad();
          this.getVideoTypeList()
          this.$message({
            type: 'success',
            message: '操作成功!'
          });
        });
      });
    },
    getVideoTypeList() {
      getGeneralVideoTypeListNoPage().then(({ res }) => {
        console.log('getGeneralVideoTypeListNoPage--', res)
        this.videoTypeList = res.videoTypeResList
        this.videoTypeList.unshift({
          videoTypeName: '全部',
          videoCount: res.totalCount
        })
        // 初始进入页面 selectedTag 为空，默认显示全部
        if(!this.selectedTag){
          this.selectedTag =  this.videoTypeList[0]
        }else if (this.containsId(this.videoTypeList, this.selectedTag)) {
          console.log('视频类型列表包含选定标签');
        } else {
          this.selectedTag =  this.videoTypeList[0]
          this.paramObj = {
            videoTypeId: this.selectedTag.id
          }
        }
        this.changeHeight()
      })
    },
  containsId(videoTypeList, selectedTag) {
    for (let i = 0; i < videoTypeList.length; i++) {
      if (videoTypeList[i].id === selectedTag.id) {
        return true;
      }
    }
    return false;
  },
    loadGroup() {
      this.isBatchGroup = false
      this.ids = []
      this.checkId = ''
      this.isAllChecked = false
      this.getVideoTypeList()
      this.onLoad()
    },
    //分页接口
    onLoad() {
      this.selectedItems = []
      this.checkId = ''

      const params = {
        ...this.paramObj,
        ...this.page,
      }
      this.loading = true;
      getGeneralVideoList(params).then(res => {
        console.log('getGeneralVideoList', res.res)
        const data = res.res;
        this.page.size = parseInt(data.size);
        this.total =  parseInt(data.total);
        this.data = data.records;
        this.data = this.data.map((item) => ({
              ...item,
              checked: false
            }));
        this.loading = false;
      });
    },
    renameVideo(item) {
      this.checkId = item.id
      this.$refs.renameDialog.updateVisible(true)
      
    },
    groupingVideo(item) {
      this.checkId = item.id
      this.$refs.groupDialog.updateVisible(true)
    },
    sortVideo(item) {
      this.checkId = item.id
      this.$refs.sortDialog.updateVisible(true)
    },
    rowDel(item) {
      const that = this;
      const { $delMsg, $delTitle, $messageBoxConfig, $handleSuccess } =
this.commonConfig;
      this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
      const param = {
        id: item.id
      }
        deleteGeneralVideoInfo(param).then(res => {
          this.$message({
              message: "删除成功",
              type: "success",
              duration: "2000"
          });
          that.loading = false;
          that.onLoad()
          that.getVideoTypeList()
        });  
      });
    },
    handleCommand(command, item) {
      switch (command) {
        case 'rename':
          this.renameVideo(item);
          break;
        case 'grouping':
          this.groupingVideo(item);
          break;
        case 'sort':
          this.sortVideo(item);
          break;
        case 'delete':
        this.rowDel(item);
        break;
        default:
          break;
      }
    },
  },

};
</script>
 
<style scoped lang="scss">
::v-deep(.el-card__header){
  padding: 8px 12px;
}
.card {
  margin-bottom: 20px;
}

.table-btn-group{
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 12px;
}
.card-box{
  height: calc(100% - 120px);
  overflow-y: scroll;
}
.card-header{
  display: flex;
  justify-content: space-between;
}
.card-content{
  //display: flex;
  height: 75px;
  .left{
    float: left;
    width: 75px;
    height: 75px;
  }
  .right{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 82px;
    height: 100%;
    .video-title{
      font-size: 14px;
    }
    .video-info{
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
    }
  }
}
.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.bottom{
  display: flex;
  justify-content: space-between;
}

.tag-container {  
  display: flex;
  flex-wrap: wrap; /* 不换行 */
  overflow-x: hidden; /* 隐藏横向滚动条 */
  transition: max-height 0.3s ease;
  position: relative;
  padding-right: 80px;
  margin-bottom: 8px;
  .more-btn {
    position: absolute;
    top: 0px;
    right: 0px;
    color: #2878ff;
  }
}  
.tag-container.hidetext {
  flex-wrap: wrap; /* 展开后允许换行 */
  overflow-x: auto; /* 显示横向滚动条 */
  height: 34px;
  overflow: hidden;
}


.el-tag {  
  margin-right: 8px;
  margin-bottom: 8px; /* 防止标签在垂直方向上重叠 */  
  padding: 8px 16px;
  height: 32px;
  line-height: 14px;
  border-radius: 50px;
  cursor: pointer;
}  
.no-data{
  display: flex;
  width: 100%;
  color: #666;
  justify-content: center;
  margin-top: 30px;
}
</style>