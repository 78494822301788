<template>
  <el-dialog
    width="70%"
    :title="id === '' ? '新增栏目管理' : '修改栏目管理'"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    append-to-body
  >
    <el-form
      ref="insertForm"
      :model="form"
      label-position="right"
      label-width="120px"
      class="formBox"
      :rules="rules"
    >
      <div class="mdrcc-info-title">基本信息</div>
      <el-row :gutter="10" type="flex" justify="start">
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="栏目名称" prop="name">
            <el-input
              v-model="form.name"
              placeholder="请输入..."
              clearable
              maxlength="20"
            />
          </el-form-item>
        </el-col>
        
      </el-row>
      <el-divider />
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit()">提 交</el-button>
      <el-button @click="updateVisible(false)">取 消</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { insertData, editData, getDetail } from "@/api/columnManage";

export default {
  props: {
    id: {
      type: String,
    },
    timeStamp: {
      type: String,
    },
  },
  data() {
    return {
      checkId: "",
      
      tableConfig: {
        align: "center", // 不必填 默认为center
        selection: "normal", // 不必填 多选: multiple  单选: single  默认为 normal
        index: true, // 不必填 序号
        pagination: false, // 不必填 分页 默认为false
      },
      form: {
        checkbox: [],
      },
      rules: {
        name: [
          { required: true, message: "请输入活动名称", trigger: "blur" },
          // { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
        ],
      },
      total: 0,
      visible: false,
      // timeStamp: '', //时间戳 用于修改数据或删除数据
    };
  },
  watch: {
    visible(newVal, oldVal) {
      if (newVal === true) {
        if (this.id) {
          this.getDetail();
        }
        //...todo
      }
    },
  },
  created() {},
  mounted() {},
  methods: {
    submit() {
      this.$refs.insertForm.validate((valid) => {
        if (valid) {
          const parm = this.form;
          if (this.id === "") {
            insertData(parm).then(({ res }) => {
              this.submitSuccess(res);
            });
          } else {
            editData(parm, this.timeStamp).then(({ res }) => {
              this.submitSuccess(res);
            });
          }
        }
      });
    },
    submitSuccess(res) {
      this.$message({ ...this.commonConfig.$handleSuccess });
      this.updateVisible(false);
      this.$emit("submit");
    },
    getDetail() {
      getDetail({ id: this.id }).then(({ res, timeStamp }) => {
        this.form = res;
        // this.timeStamp = timeStamp
      });
    },
    toDel(scope) {
      const { $delMsg, $delTitle, $messageBoxConfig } = this.commonConfig;
      this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
        const param = { id: scope.data.row.id };
        deleteData(param, this.timeStamp).then(({ res }) => {
          this.$message({ ...this.commonConfig.$handleSuccess });
        });
      });
    },
    updateVisible(val) {
      this.visible = val;
      if (val === false) {
        this.$refs.insertForm.resetFields();
      }
    },
  },
};
</script>
<style scoped lang="scss"></style>
