/*
 * @Author: sixTeen
 * @Date: 2022-03-25 14:33:27
 * @LastEditTime: 2022-06-23 15:00:45
 * @LastEditors: chenm 6518182+luo_chen123@user.noreply.gitee.com
 * @Description: 上传七牛文件
 * @FilePath: /recovery_home_management/src/utils/upload.js
 */

import * as qiniu from 'qiniu-js'
import { getQiNiuPrivateToken, getQiNiuPublicToken, getVideoInfo } from '@/api/qiniu'
/**
 * @description: 上传私有七牛文件
 * @param {*} file
 * @return {*}
 */
export function uploadPrivateFile(file) {
    let resultKey = ""
    console.log(file)
    return new Promise((resolve, reject) => {
        getQiNiuPrivateToken()
            .then((res) => {
                const uptoken = res.res
                const key = null
                const config = {
                    useCdnDomain: true, //cdn
                };
                const putExtra = {
                    fname: file.name, //文件原文件名
                    mimeType: file.type, //格式
                };
                let observable = qiniu.upload(
                    file,
                    key,
                    uptoken,
                    putExtra,
                    config
                );
                observable.subscribe({
                    next(result) {
                        // 主要用来展示进度
                        console.log("上传中======".result)

                    },
                    error(errResult) {
                        // 失败报错信息
                        //   console.log(errResult);
                        console.log("----errResult:---:", errResult);
                        reject(false);
                    },
                    complete(result) {
                        // 接收成功后返回的信息
                        console.log("-完成---result:---:", result);
                        resultKey = result.key
                        resolve(resultKey);
                    },
                });
            })
            .catch((err) => {
                console.log('error');
                reject(false);
            });
    });
}

/**
 * @description: 上传公有七牛文件
 * @param {*} file
 * @return {*}
 */
export function uploadPublicFile(file) {
    let resultKey = ""
    console.log(file)
    return new Promise((resolve, reject) => {
        getQiNiuPublicToken()
            .then((res) => {
                const uptoken = res.res
                const key = null
                const config = {
                    useCdnDomain: true, //cdn
                };
                const putExtra = {
                    fname: file.name, //文件原文件名
                    mimeType: file.type, //格式
                };
                let observable = qiniu.upload(
                    file,
                    key,
                    uptoken,
                    putExtra,
                    config
                );
                observable.subscribe({
                    next(res) {
                        // 主要用来展示进度
                        console.log("-上传---res:---:", res.total.percent);

                    },
                    error(errResult) {
                        // 失败报错信息
                        //   console.log(errResult);
                        console.log("----errResult:---:", errResult);
                        reject(false);
                    },
                    complete(result) {
                        // 接收成功后返回的信息
                        console.log("-完成---result:---:", result);
                        resultKey = result.key
                        resolve(resultKey);
                    },
                });
            })
            .catch((err) => {
                console.log(err);
                reject(false);
            });
    });
}


/**
 * @description: 上传公有七牛文件
 * @param {*} file
 * @return {*}
 */
export function getPublicVideoInfo(fileKey) {
  console.log(fileKey)
  return new Promise((resolve, reject) => {
    getVideoInfo(fileKey)
    .then((res) => {
        console.log('res.res===',res)
        resolve(res.res);

    })
    .catch((err) => {
        console.log(err);
        reject(false);
    });
  });
}
