/*
 * @Description:
 * @Author: yzw
 * @Date: 2021-09-22 14:24:59
 * @LastEditTime: 2021-10-13 08:59:52
 * @LastEditors: yzw
 * @Reference:
 */

/**
 * 页面: 推荐人
 * pageName: test
 **/
import request from '@/utils/request'

/**
 * @description: 推荐人列表
 */
export function getList(data) {
    return request({
        url: 'admin/promoter/list',
        method: 'GET',
        params: data,
        showLoading: true
    })
}




