/*
 * @Author: 358617935@qq.com 358617935@qq.com
 * @Date: 2023-05-24 17:28:39
 * @LastEditors: 358617935@qq.com 358617935@qq.com
 * @LastEditTime: 2023-05-31 09:37:11
 * @FilePath: /recovery_home_management/src/api/evaluation.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

/**
 * 页面: 评价管理
 * pageName: evaluation
 **/
import request from '@/utils/request'

/**
 * @description: 获取评价管理列表数据
 */
export function getList(data) {
    return request({
        url: '/admin/orderMain/list',
        method: 'GET',
        params: data,
        showLoading: true
    })
}


/**
 * @description: 获取评价管理详情数据
 */
export function getDetail(data) {
    return request({
        url: '/admin/order/list',
        method: 'GET',
        params: data,
        showLoading: true
    })
}
            
/**
 * @description: 删除评价管理数据
 */
    export function deleteData(data, timeStamp) {
    return request({
        url: '',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}
        