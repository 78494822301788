
<template>
    <el-dialog
        width="70%"
        title="服务配置"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        append-to-body
    >
        <el-form
            ref="insertForm"
            label-position="right"
            label-width="120px"
            class="formBox"
        >
            <el-divider />
            <!-- <div class="mdrcc-info-title">
              关联信息
            </div> -->
            <el-row :gutter="20">
                 <el-main class="basic-main">
                      <el-row class="table-btn-group">
                          <el-button type="primary" @click="toInsert()">新增</el-button>
                      </el-row>
                      <generalTable ref="mainTable" :table-data="tableData" :config="tableConfig" :total="total" @updatePage="getServiceConfig" @onClickRow="onClickRow">
                        <template slot="recommended" slot-scope="scope">
                            <el-switch
                                v-model="scope.data.row.recommended"
                                inactive-value="1"
                                active-value="0"
                                @change="recommendedStatus(scope,$event)"
                            />
                        </template>
                          <template slot="handle" slot-scope="scope">
                              <el-button type="text" style="margin-right: 10px" @click="toEdit(scope)">编辑</el-button>
                              <el-button type="text" class="delBtn" @click="toDel(scope)">删除</el-button>
                          </template>
                      </generalTable>
                      <el-divider />
                       <addDialogConfig :id="checkId" :userDoctorId="checkUserDoctorId" ref="insert"  :time-stamp="timeStamp" @submit='getServiceConfig()' />
                 </el-main>
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="updateVisible(false)">关闭</el-button>
        </span>
    </el-dialog>
</template>

<script>
import generalTable from '@/components/Table'
import {getServiceConfig,deleteServiceConfigInfo,recommendedStatus } from '@/api/doctorManagement'
import addDialogConfig from '@/views/doctorManagement/addDialogConfig'
export default {
    props: {
        id: {
            type: String
        }
    },
    components: {
      generalTable,
      addDialogConfig
    },
    data() {
        return {
            checkId: '',
            checkUserDoctorId:'',
            tableData: [],
            tableConfig: {
                align: 'center', // 不必填 默认为center
                selection: 'normal', // 不必填 多选: multiple  单选: single  默认为 normal
                index: false, // 不必填 序号
                pagination: false, // 不必填 分页 默认为false
                column: [
                    {
                        label: '服务次数', // 必填
                        prop: 'number', // 必填
                    },
                    {
                        label: '打折前价格(原价(单位为分))', // 必填
                        prop: 'modalBeforePrices' // 必填
                    },
                    {
                        label: '打折后价格(现价(单位为分))', // 必填
                        prop: 'modalAfterPrices' // 必填
                    },
                    {
                        label: "不推荐/推荐", // 必填
                        slot: "recommended", // 必填
                    },
                    {
                        slot: 'handle', // 不必填 单列插槽
                        label: '操作', // 必填
                        fixed: 'right',
                        width: '220' // 不必填
                    }
                ]
            },
            total: 0,
            visible: false,
            form:[],
            timeStamp: '', //时间戳 用于修改数据或删除数据
        }
    },
    watch: {
        visible(newVal, oldVal) {
          if (newVal === true) {
            if (this.id) {
                this.getServiceConfig()
            } 
          }
        }
    },
    created() {},
    mounted() {},
    methods: {
      onClickRow(checkArray) {
            console.log('testststst')
            console.log('checkArray', checkArray)
        },
        toInsert() {
            this.checkId = ''
            this.checkUserDoctorId = this.id
            this.$refs.insert.updateVisible(true)
        },
        toEdit(scope) {
            this.checkId = scope.data.row.id
            this.$refs.insert.updateVisible(true)
            
        },
        toDel(scope) {
            const param = { id: scope.data.row.id };
            deleteServiceConfigInfo(param, this.timeStamp).then(({ res, timeStamp }) => {
                this.getServiceConfig();
                this.$message({
                    message: '删除成功',
                    type: 'success'
                    })
            });
        },
        //是否推荐
        recommendedStatus(checkArray){
            const parm = {
                id:checkArray.data.row.id,
                status:checkArray.data.row.recommended
            }
            recommendedStatus(parm,this.timeStamp).then(({res}) =>{
                this.getList()
            })
         

        },
    //获取服务配置列表
      getServiceConfig() {
        const param = {}
                param.userDoctorId = this.id
            getServiceConfig(param).then(({ res, timeStamp }) => {
                this.timeStamp = timeStamp
                this.tableData = res
            })
      },
      updateVisible(val) {
            this.visible = val
            if (val === false) {
                this.$refs.insertForm.resetFields()
            }
      },

    }
}
</script>
<style scoped lang='scss'>
</style>
      
