
<!--
* @Description: 
* @Reference:
-->
<template>
  <el-dialog
      width="40%"
      :title="'重命名'"
      :visible.sync="visible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      append-to-body
  >
      <el-form
          ref="insertForm"
          :model="form"
          label-position="right"
          label-width="140px"
          class="formBox"
          :rules="rules"
      >
          <el-row :gutter="15" type="flex" justify="start">
              <el-col>
                  <el-form-item label="名称" prop="videoName">
                      <el-input
                          v-model="form.videoName"
                          placeholder="请输入..."
                          clearable
                          maxlength="20"
                      />
                  </el-form-item>
              </el-col>
          </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="submit()">提 交</el-button>
          <el-button @click="updateVisible(false)">取 消</el-button>
      </span>
  </el-dialog>
</template>
<script>
import {getGeneralVideoInfo, updateGeneralVideoInfo } from '@/api/generalVideoManagement'
export default {
  props: {
    id: {
      type: String
    }
  },
  data() {
    return {
      rules: {
        videoName: [
            { required: true, message: '请输入视频名称', trigger: 'blur' },
            {
                min: 2,
                max: 20,
                message: '长度在 2 到 20 个字符',
                trigger: 'blur'
            }
        ],
          
      },
      form: {
          videoName: '', 
      },
      total: 0,
      visible: false,
      timeStamp: '', // 时间戳
      submitFlag: true // 防抖
    }
  },
  watch: {
    visible(newVal, oldVal) {
      if (newVal === true && this.id ) {
        this.getGeneralVideoInfo()
      }
    }
  },
  created() {

  },
  mounted() {

  },
  methods: {
    submit() {
      if (!this.submitFlag) {
        return
      }

      this.$refs.insertForm.validate((valid) => {
        if (valid) {
          const parm = this.form
          this.submitFlag = false
          updateGeneralVideoInfo(parm, this.timeStamp).then(({ res }) => {
            this.submitSuccess(res)
            this.form.id = ''
          }).catch((error)=> {
              this.submitFailure(error)
          })
      } else{
        this.submitFlag = true
      }
    })
  },
    submitFailure(error) {
      this.submitFlag = true
    },
    submitSuccess(res) {
      this.submitFlag = true
      this.$message({ ...this.commonConfig.$handleSuccess })
      this.$emit('submit')
      this.updateVisible(false)
    },
    getGeneralVideoInfo() {
      const param = {}
      if (this.id) {
        param.id = this.id
      }
      getGeneralVideoInfo(param).then(({ res, timeStamp }) => {
        this.timeStamp = timeStamp
        this.form = res
      })
    },
    updateVisible(val) {
      this.visible = val
      if (val === false) {
          this.$refs.insertForm.resetFields()
          this.form.id = ''
      }
    },
  }
}
</script>
<style scoped lang='scss'>
@import "~@/styles/variables.scss";

.imgBox {
  width: 150px;
  height: 150px;
  border-radius: 4px;
  object-fit: contain;
  border: 1px solid #333;
}
.defalutImg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  border: 1px solid #333;
  border-radius: 4px;
  cursor: pointer;
}

.id-card-img {
  width: 145px;
  height: 120px;
}
.el-col {
margin-bottom: 5px;
}

.delete-btn {
// position: relative;
width: 18px;
height: 18px;
font-size: 18px;
color: red;
position: absolute;
margin-top: 4px;
cursor: pointer;
}
</style>

