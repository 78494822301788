<template>
  <el-dialog width="800px" title="执行进度" :visible.sync="visible" :close-on-click-modal="false"
    :close-on-press-escape="false" append-to-body>

    <el-card>
      <div style="display: flex;">
        <p style="flex: auto;">服务周期：{{basicInfo.period}}</p>
        <p style="flex: auto;">当天天数：{{basicInfo.finishDays}}</p>
        <p style="flex: auto;" v-if="basicInfo.consultTotalCount">在线咨询：{{basicInfo.consultFinishCount}} / {{basicInfo.consultTotalCount}}</p>
      </div>
    </el-card>
    <br>
    <el-timeline>
      <el-timeline-item placement="top" v-for="(row, index) in dataList" :key="index" :timestamp="moment(row.startDate).format('yyyy-MM-DD')">
        <el-tag :type="stateColorList[row.state]" size="medium">{{ row.stateLabel }}</el-tag>
        <p>任务名称：{{row.taskName}}</p>
        <p>任务类型：{{row.sourceSubType}}</p>
        <template v-if="!(row.sourceSubKey == 'online_consultation' || row.sourceSubKey == 'select_goods')">
          <p>执行时间：{{moment(row.startDate).format('HH:mm')}}</p>
          <p v-if="row.state == '1' && row.rehabilitationEndTime != null">完成时间：{{moment(row.rehabilitationEndTime).format('HH:mm')}}</p>
        </template>
      </el-timeline-item>
    </el-timeline>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">关 闭</el-button>
    </span>
  </el-dialog>
</template>
<script>
  import request from '@/utils/request'
  import { getDict } from "@/api/common";


  export default {
    components: {
    },
    data() {
      return {
        visible: false,
        basicInfo: {},
        dataList: [],
        stateList: [],
        stateColorList: ['info', '', 'success'],
      };
    },
    computed: {
    },
    watch: {
    },
    created() {
      getDict({ type: "kf_state" }).then(({ res }) => {
        this.stateList = res.kf_state;
      });
    },
    mounted() {
    },
    methods: {
      open(row) {
        const { orderNo } = row;
        this.basicInfo = row;
        this.dataList = []
        // 资源类型树
        request({
          url: 'admin/kfSchemeInfo/getSchemeDetails',
          method: 'GET',
          params: {orderNo},
          showLoading: true
        }).then(({ res }) => {
          const { finishDays, consultFinishCount, consultTotalCount, period } = res;
          // exePeriodDays
          // for (const key in res) {
          //   this.dataList.push(res[key])
          // }
          // console.log('dataList ---------> ', res, this.dataList);
          this.basicInfo = {
            ...this.basicInfo,
            finishDays, consultFinishCount, consultTotalCount, period
          }
          this.dataList = res.kfTaskList;
          this.visible = true;
        })
      },
    },
  };
</script>
<style scoped lang='scss'>
  ::v-deep {
    .el-dialog__body{
      max-height: 70vh;
    }
    .el-timeline-item__timestamp{
      color: #666;
    }
    .el-timeline-item__timestamp::after{
      content: "发送";
    }
  }
  .el-timeline{ padding-left: 120px; }
  .el-tag{
    position: absolute;
    top: 2px;
    left: -80px;
  }
</style>
