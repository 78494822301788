
<!--
* @Description: 康复训练权限配置页面
* @Reference:
-->

<template>
    <el-container>
        <el-header height="auto">
            <searchForm ref="mainSearch" :searchoptions="searchOptions" @search="search" :backStatus="backStatus" />
        </el-header>
        <el-main class="basic-main">
            <el-row class="table-btn-group"><el-button type="primary" @click="toInsert()">新增</el-button></el-row>
            <generalTable ref="mainTable" :table-data="tableData" :config="tableConfig" :total="total" @updatePage="getList"
                @onClickRow="onClickRow">
                <template slot="handle" slot-scope="scope">
                    <el-button type="text" @click="toEdit(scope)">编辑</el-button>
                    <el-button type="text" class="delBtn" @click="toDel(scope)">删除</el-button>
                </template>
            </generalTable>

            <addDialog :id="checkId" ref="insert" :timeStamp="timeStamp" @submit="search" />
        </el-main>
    </el-container>
</template>

<script>
import { getList, deleteData } from '@/api/trainingAuthority'
import searchForm from '@/components/SearchForm'
import generalTable from '@/components/Table'
import addDialog from '@/views/operation/trainingAuthority/addDialog'


export default {
    name: 'TrainingAuthority',
    components: {
        searchForm,
        generalTable,

        addDialog
    },
    data() {
        return {
            tableData: [],
            searchOptions: [
                {
                    type: 'input',
                    placeholder: '请输入...',
                    key: 'userName',
                    label: '患者姓名：',
                    defaultVal: ''
                },
                {
                    type: 'input',
                    placeholder: '请输入...',
                    key: 'phoneNum',
                    label: '手机号：',
                    defaultVal: ''
                },
            ],
            tableConfig: {
                id: 'trainingAuthority',
                align: 'center', // 不必填 默认为center
                selection: 'single', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: true, // 不必填 分页 默认为false
                column: [
                    {
                        label: '患者姓名', // 必填
                        prop: 'userName',// 必填
                        width: '220' // 不必填
                        // formatter: (row, column) => {
                        //     return row.archivesInfoId.name
                        // }
                    },
                    {
                        label: '手机号', // 必填
                        prop: 'phoneNum', // 必填
                        width: '200' // 不必填
                    },
                    {
                        label: '游戏配置', // 必填
                        prop: 'gameNames' // 必填
                    },
                    {

                        slot: 'handle', // 不必填 单列插槽
                        label: '操作', // 必填
                        fixed: 'right', // 不必填 固定列  left right
                        width: '220' // 不必填
                    }
                ]
            },
            paramObj: null, // 筛选项
            total: 0, // 总条数
            checkId: '', // 单条数据id 用于详情或编辑请求数据用
            backStatus: true, //返回按钮的隐藏显示
            timeStamp: '', //时间戳 用于修改数据或删除数据
        }
    },
    created() {
        this.getList()
    },
    mounted: function () { },
    methods: {
        search(data) {
            Object.assign(this.paramObj, data)
            this.$refs.mainTable.handleCurrentChange(1)
        },
        getList() {
            this.$nextTick(() => {
                const param = this.getParam()
                getList(param).then(({ res, timeStamp }) => {
                    this.tableData = res.records
                    this.total = res.total
                    this.timeStamp = timeStamp
                })
            })
        },
        toInsert() {
            this.checkId = ''
            this.$refs.insert.updateVisible(true)
        },
        toDetail(scope) {
            this.checkId = scope.data.row.id
            this.$refs.detail.updateVisible(true)
        },
        toEdit(scope) {
            this.checkId = scope.data.row.userId
            this.$refs.insert.updateVisible(true)
        },
        toDel(scope) {
            const { $delMsg, $delTitle, $messageBoxConfig, $handleSuccess } = this.commonConfig
            this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
                const param = { id: scope.data.row.userId }
                deleteData(param, this.timeStamp).then(({ res }) => {
                    this.$message({ ...$handleSuccess })
                    this.getList()
                })
            })
        },
        onClickRow(row) {
            console.log('点击行:', row)
        },
        getParam() {
            const paramObj = this.$refs.mainSearch.packageData()
            const page = this.$refs.mainTable.getPage()
            const param = Object.assign({}, this.paramObj, page, paramObj)
            this.paramObj = param
            return param
        }
    }
}
</script>

<style lang='scss' scoped>
@import "~@/styles/variables.scss";
</style>

