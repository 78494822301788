/*
 * @Author: 358617935@qq.com 358617935@qq.com
 * @Date: 2023-05-29 09:31:46
 * @LastEditors: 358617935@qq.com 358617935@qq.com
 * @LastEditTime: 2023-05-31 10:35:57
 * @FilePath: /recovery_home_management/src/api/invoice.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

/**
 * 页面: 开票信息查看
 * pageName: invoice
 **/
import request from '@/utils/request'

/**
 * @description: 获取开票信息查看列表数据
 */
export function getList(data) {
    return request({
        url: '/admin/companyInvoice/list',
        method: 'GET',
        params: data,
        showLoading: true
    })
}


/**
 * @description: 获取开票信息查看详情数据
 */
export function getDetail(data) {
    return request({
        url: '',
        method: 'GET',
        params: data,
        showLoading: true
    })
}
            
/**
 * @description: 删除开票信息查看数据
 */
    export function deleteData(data, timeStamp) {
    return request({
        url: '',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}
        