
import Layout from "@/layout";
export const constantRoutes = [
  {
    path: "/sourceMain",
    pageName: "sourceMain",
    component: Layout,
    name: "sourceMain",
    redirect: "/source",
    children: [
      {
        path: "/source",
        name: "source",
        hidden: false,
        component: () => import("@/views/source/index"),
        meta: {
          title: "资源管理",
          icon: "home",
          breadcrumb: true,
          keepAlive: true,
        },
      },
      {
        path: "/source/add",
        name: "source-add",
        hidden: true,
        component: () => import("@/views/source/add"),
        meta: {
          title: "资源新增",
          icon: "home",
          breadcrumb: true,
          keepAlive: true,
        },
      },
      {
        path: "/source/edit",
        name: "source-edit",
        hidden: true,
        component: () => import("@/views/source/add"),
        meta: {
          title: "资源编辑",
          icon: "home",
          breadcrumb: true,
          keepAlive: true,
        },
      },
    ]
  },

];

export default constantRoutes;
