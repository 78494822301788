<!--
 * @Author: your name
 * @Date: 2022-03-22 16:54:34
 * @LastEditTime: 2022-04-20 16:36:57
 * @LastEditors: Please set LastEditors
 * @Description: 评估方法详情
 * @FilePath: /recovery_home_management/src/views/assessment/assessmentMethods/detailDialog.vue
-->

<template>
    <el-dialog
        width="60%"
        title="评估方法详情"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        custom-class="basicDialog"
        append-to-body
    >
        <el-descriptions class="margin-top" title="基本信息" :column="4">
            <el-descriptions-item label="名称">{{
                detail.name
            }}</el-descriptions-item>
            <el-descriptions-item label="分类">{{
                detail.guidanceTypeName
            }}</el-descriptions-item>
            <el-descriptions-item label="来源">
                <el-tag size="small">{{ detail.source }}</el-tag>
            </el-descriptions-item>
            <el-descriptions-item label="使用类型">
                <el-tag size="small">{{ detail.useType }}</el-tag>
            </el-descriptions-item>
            <el-descriptions-item label="封面图片">
                <el-image
                    class="imgBox"
                    :src="commonConfig.$filePublicUrl + detail.photo"
                    :preview-src-list="[
                        commonConfig.$filePublicUrl + detail.photo,
                    ]"
                    fit="contain"
                />
            </el-descriptions-item>
            <el-descriptions-item label="描述">{{
                detail.describes
            }}</el-descriptions-item>
        </el-descriptions>
        <div>
            <generalTable
                ref="mainTable"
                :table-data="tableData"
                :config="tableConfig"
            >
                <template slot="photo" slot-scope="scope">
                    <el-image
                        style="width: 50px; height: 50px; margin-top: 10px"
                        :src="
                            commonConfig.$filePublicUrl + scope.data.row.photo
                        "
                        :preview-src-list="[
                            commonConfig.$filePublicUrl + scope.data.row.photo,
                        ]"
                    >
                    </el-image>
                </template>
                <template slot="handle" slot-scope="scope">
                    <el-button type="text" @click="toDetailVideo(scope)"
                        >详情</el-button
                    >
                </template>
            </generalTable>
        </div>
        <!-- </el-descriptions> -->

        <el-divider />
        <detailDialog
            :id="checkId"
            :identifier-array="identifierArray"
            ref="detail"
        />
        <template slot="footer" class="dialog-footer">
            <el-button @click="updateVisible(false)">取 消</el-button>
        </template>
    </el-dialog>
</template>
<script>
import { getDetail, getVideoList } from "@/api/assessmentMethods";
import detailDialog from "@/views/assessment/assessmentVideo/detailDialog";
import generalTable from "@/components/Table";

export default {
    props: {
        id: {
            type: String,
            required: true,
        },
        source: {
            required: true,
            type: Array,
        },
        useType: {
            required: true,
            type: Array,
        },
        identifierArray: {
            required: true,
            type: Array,
        },
    },
    components: {
        generalTable,
        detailDialog,
    },
    data() {
        return {
            checkId: "",
            detail: [], // 详情数据
            tableData: [],
            tableConfig: {
                align: "center", // 不必填 默认为center
                selection: "normal", // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: false, // 不必填 分页 默认为false
                column: [
                    {
                        label: "视频名称", // 必填
                        prop: "name", // 必填
                        // formatter: (row, column, cellValue, index) => {
                        //     return row.first + index;
                        // },
                    },
                    {
                        label: "类型", // 必填
                        prop: "identifier", // 必填
                        formatter: (row) => {
                            // 根据字典表展示对应项目的label值
                            const currentType = this.identifier.filter(
                                (type) => type.value === row.identifier
                            );
                            if (currentType.length) {
                                return currentType[0].label;
                            }
                        },
                        width: "100", // 不必填
                    },
                    {
                        label: "分类", // 必填
                        prop: "guidanceTypeName", // 必填
                    },
                    {
                        label: "描述", // 必填
                        prop: "describe", //
                        width: "220", // 不必填
                    },
                    {
                        label: "封面图片", // 必填
                        slot: "photo", // 必填
                        width: "100",
                    },
                    {
                        slot: "handle", // 不必填 单列插槽
                        label: "操作", // 必填
                        fixed: "right",
                        width: "100", // 不必填
                    },
                ],
            },
            identifier: [],
            visible: false,
            kfGuidanceId: "", //康复方案表id
        };
    },
    watch: {
        visible(newVal, oldVal) {
            if (newVal && this.id) {
                this.getDetail();
                this.identifier = this.identifierArray;
            }
        },
    },
    created() {},
    mounted() {},
    methods: {
        getDetail() {
            getDetail({ id: this.id }).then(({ res }) => {
                this.detail = res;
                const currentType = this.source.filter(
                    (type) => type.value === res.source
                );
                if (currentType.length) {
                    this.detail.source = currentType[0].label;
                }

                const useType = this.useType.filter(
                    (type) => type.value === res.useType
                );
                if (useType.length) {
                    this.detail.useType = useType[0].label;
                }
                this.kfGuidanceId = res.id;
                this.getVideoList();
            });
        },
        getVideoList() {
            this.$nextTick(() => {
                getVideoList({ kfGuidanceId: this.kfGuidanceId }).then(
                    ({ res, timeStamp }) => {
                        this.tableData = res;
                        // this.timeStamp = timeStamp;
                    }
                );
            });
        },
        /**
         * @description: 视频详情
         * @param {*} scope
         * @return {*}
         */
        toDetailVideo(scope) {
            this.checkId = scope.data.row.id;
            this.$refs.detail.updateVisible(true);
        },
        updateVisible(flag) {
            this.visible = flag;
        },
    },
};
</script>
<style scoped lang='scss'>
@import "~@/styles/variables.scss";

.imgBox {
    width: 150px;
    height: 150px;
    object-fit: contain;
    border: 1px solid $tableColor;
}
</style>
