<template>
  <el-dialog width="600px" title="预览" :visible.sync="visible" :close-on-click-modal="false"
    :close-on-press-escape="false" append-to-body>

    <el-timeline>
      <el-timeline-item placement="top" v-for="(row, index) in dataList" :key="index" :timestamp="`第 ${row.day} 天`">
        <el-tag size="medium" v-for="tag in row.tags" :key="tag.name" style="margin-right: 1em;">
          {{tag.taskName}}
        </el-tag>
      </el-timeline-item>
    </el-timeline>

    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">关 闭</el-button>
    </span>
  </el-dialog>
</template>
<script>
  import request from '@/utils/request'
  import { getDict } from "@/api/common";


  export default {
    components: {
    },
    data() {
      return {
        visible: false,
        dataList: []
      };
    },
    computed: {
    },
    watch: {
    },
    created() {
    },
    mounted() {
    },
    methods: {
      open({ planTemplateId }) {
        console.log({ planTemplateId });
        this.dataList = []
        // 资源类型树
        request({
          url: 'admin/kfTaskTemplate/generateTaskDetails',
          method: 'GET',
          params: { kfPlanTemplateId: planTemplateId },
          showLoading: true
        }).then(({ res }) => {
          for (const day in res) {
            this.dataList.push({
              day,
              tags: res[day]
            })
          }
          console.log('dataList ---------> ', res, this.dataList);
          this.visible = true;
        })
      },
    },
  };
</script>
<style scoped>
  >>> .el-timeline-item__timestamp{
    padding-top: 2px;
    margin-bottom: 12px;
    color: #666;
  }
</style>
