/*
 * @Author: 358617935@qq.com 358617935@qq.com
 * @Date: 2023-05-30 08:57:50
 * @LastEditors: 358617935@qq.com 358617935@qq.com
 * @LastEditTime: 2023-06-02 10:00:12
 * @FilePath: /recovery_home_management/src/api/trainingRecord.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

/**
 * 页面: 患者康复训练记录
 * pageName: trainingRecord
 **/
import request from "@/utils/request";

/**
 * @description: 获取患者康复训练记录列表数据
 */
export function getList(data) {
  return request({
    url: "/trainingResult/list",
    method: "GET",
    params: data,
    showLoading: true,
  });
}

/**
 * @description: 删除患者康复训练记录数据
 */
export function deleteData(data, timeStamp) {
  return request({
    url: "/trainingResult/delete",
    method: "POST",
    data,
    showLoading: true,
    timeStamp,
  });
}

/**
 * @description: 获取患者康复训练记录列表数据
 */
export function getListV2(data) {
  return request({
    url: "/trainingResultV2/list",
    method: "GET",
    params: data,
    showLoading: true,
  });
}

/**
 * @description: 删除患者康复训练记录数据
 */
export function deleteDataV2(data, timeStamp) {
  return request({
    url: "/trainingResultV2/delete",
    method: "POST",
    data,
    showLoading: true,
    timeStamp,
  });
}
