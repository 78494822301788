import { TUILogin } from "@tencentcloud/tui-core";
import {
  setIMUserSig,
  setIMUserId,
} from "@/utils/common";
const SDKAppID = 1400654010; // Your SDKAppID
// IM 登录
export function imLogin(userID, secretKey) {
  console.log("SDKAppID===", SDKAppID);
  console.log("userID===", userID);
  console.log("secretKey===", secretKey);
  let userID1 = "test1";
  let secretKey1 =
    "eJwtzMEKgkAUheF3mXXoHZmZUmhTEZHZopI2bkxvcZF0HMcoonfPbJbnO-C-2Wl39B5oWMQCD9hk3FRibelKI1vsLHdHV1a51lSyiAsAJQVw*D-41GRwcCllAODU0v1nU5iJUCklXIVuQzfp45XZLqsA97pYJJlf52n7Cos*boRpz1Sll1St5Sbz8dDM2ecLiCEyUA__";
  // TUICore login
  TUILogin.login({
    SDKAppID,
    userID: userID,
    userSig: secretKey,
    // 如果您需要发送图片、语音、视频、文件等富媒体消息，请设置为 true
    useUploadPlugin: true,
    // 本地审核可识别、处理不安全、不适宜的内容，为您的产品体验和业务安全保驾护航
    // 此功能为增值服务，请参考：https://cloud.tencent.com/document/product/269/79139
    // 如果您已购买内容审核服务，开启此功能请设置为 true
    useProfanityFilterPlugin: false,
    framework: "vue2",
  }).then((res) => {
    console.log("res===", res);
    const { code } = res;
    if (code == 0) {
      // 保存IM登录信息
      setIMUserId(userID);
      setIMUserSig(secretKey);
    }
  }).catch(err=>{
    console.log("im login err===", err);
  });;
}

// IM 登出
export function imLogOut() {
  TUILogin.logout().then(() => {
    setIMUserSig("");
    setIMUserId("");
    console.log("im logout OK");
  }).catch(err=>{
    console.log("im logout err===", err);
  });
}
