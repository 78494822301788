<!--
 * @Author: 358617935@qq.com 358617935@qq.com
 * @Date: 2023-05-23 15:26:08
 * @LastEditors: 358617935@qq.com 358617935@qq.com
 * @LastEditTime: 2023-05-23 18:55:06
 * @FilePath: /recovery_home_management/src/views/doctor/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <MenuBox :roles="secondMenu" />
</template>

<script>
import { mapGetters } from "vuex";
import MenuBox from "@/components/MenuBox";
export default {
    name: "System",
    components: { MenuBox },
    data() {
        return {
            secondMenu: [],
        };
    },
    computed: {
        ...mapGetters(["roles"]),
    },
    created() {
        const { roles } = this.$store.getters;

        for (let i = 0; i < roles.length; i++) {
            const element = roles[i];
            if (element.name === "doctorMain") {
                element.children.filter((obj) => {});
                this.secondMenu = element.children.filter(
                    (obj) =>
                        obj.hidden === false && element.redirect !== obj.path
                );
            }
        }
    },
    methods: {},
};
</script>

<style lang='scss' scoped>
</style>