
<!--
* @Description: 新增医院，修改医院
* @Reference:
-->
<template>
    <el-dialog
        width="70%"
        :title="id === '' ? '新增医院' : '修改医院'"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        append-to-body
    >
        <el-form
            ref="insertForm"
            :model="form"
            label-position="right"
            label-width="120px"
            class="formBox"
            :rules="rules"
        >
            <div class="mdrcc-info-title">基本信息</div>
            <el-row :gutter="10" type="flex" justify="start">
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="医院名称" prop="name">
                        <el-input
                            v-model="form.name"
                            placeholder="请输入..."
                            clearable
                            maxlength="20"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6" >
                    <el-form-item label="所在地" prop="localArea">
                        <el-cascader
                            size="mini"
                            v-model="localArea"
                            :options="sysAreaTree"
                            :props="{
                                checkStrictly: true,
                                label: 'name',
                                value: 'code'
                            }"
                            clearable
                            filterable
                            @change="changeSysArea()"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="医院级别" prop="kfProductPricesId">
                        <el-select
                        v-model="form.kfProductPricesId"
                        clearable
                        placeholder="请选择"
                        :filterable="true"
                    >
                        <el-option
                        v-for="option in hospitalLevelList"
                        :key="option.value"
                        :label="option.label"
                        :value="option.value"
                        />
                    </el-select>
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="费用开关" prop="isCharge">
                        <el-switch
                            v-model="form.isCharge"
                            name="isCharge"
                            active-text="免费"
                            inactive-text="收费"
                            active-value="1"
                            inactive-value="2"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="签名开关" prop="signCharge">
                        <el-switch
                            v-model="form.signCharge"
                            name="signCharge"
                            active-text="签字"
                            inactive-text="不签字"
                            active-value="1"
                            inactive-value="2"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="24" :md="24" :lg="24" :xl="24">
                    <el-form-item label="备注" prop="remark">
                        <el-input
                            v-model="form.remark"
                            type="textarea"
                            clearable
                            :autosize="{ minRows: 2, maxRows: 4}"
                            maxlength="200"
                            placeholder="请输入内容"
                        />
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submit()">提 交</el-button>
            <el-button @click="updateVisible(false)">取 消</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { createHospital, updateHospitalInfo, getHospitalInfo } from '@/api/hospitalManagement'
import {getKfType} from '@/api/basicSetting'
import {getSysAreaTree} from '@/api/areaManagement'
export default {
    props: {
        id: {
            type: String
        },
    },
    data() {
        return {
            rules: {
                name: [
                    {
                        required: true,
                        message: '请输入分类编码',
                        trigger: 'blur'
                    }
                ],
                
            },
            
            form: {
                id: '', 
                area: '', // 区编号
                city: '', //市编号
                province: '', // 省编号
                name: '', //医院名称
                kfProductPricesId: '', //医院等级
                isCharge:'', //是否收费
                signCharge:'', //是否签字
                remark:'', //备注
            },
            localArea:[],//所在地
            hospitalLevelList:[],//医院级别
            sysAreaTree:[],//省市区结构树
            total: 0,
            visible: false,
            // timeStamp: '', // 时间戳
            submitFlag: true // 防抖
        }
    },
    watch: {
        visible(newVal, oldVal) {
            if (newVal === true && this.id ) {
                this.getHospitalInfo()
                // this.getHospitalLevel()
                // this.getAreaTree()
            }
        },
    },
    created() {
        this.getHospitalLevel()
        this.getAreaTree()
    },
    mounted() {},
    methods: {
        submit() {
            if (!this.submitFlag) {
                return
            }
            this.submitFlag = false
            this.$refs.insertForm.validate((valid) => {
                if (valid) {
                    const parm = this.form
                    if (!parm.id) {
                        createHospital(parm).then(({ res }) => {
                            this.submitSuccess(res)
                        }).catch((error)=> {
                            this.submitFailure(error)
                        })
                    } else {
                        updateHospitalInfo(parm, this.timeStamp).then(({ res }) => {
                                this.submitSuccess(res)
                                this.form.id = ''
                        }).catch((error)=> {
                            this.submitFailure(error)
                        })
                    }
                } else{
                    this.submitFlag = true
                }
            })
        },
        changeSysArea(){
            this.form.province = this.localArea[1]
            this.form.city = this.localArea[2]
            this.form.area = this.localArea[3]
        },
        submitFailure(error) {
            this.submitFlag = true
        },
        submitSuccess(res) {
            this.submitFlag = true
            this.$message({ ...this.commonConfig.$handleSuccess })
            this.$emit('submit')
            this.updateVisible(false)
        },
        getHospitalInfo() {
            const param = {}
            if (this.id) {
                param.id = this.id
            }
            getHospitalInfo(param).then(({ res ,timeStamp}) => {
                const {kfProductPricesId, ...others} = res
                this.timeStamp = timeStamp
                this.form = {
                    kfProductPricesId: Number(kfProductPricesId),
                    ...others
                }
                this.localArea = ['100000',res.province,res.city,res.area]
            })
        },
        updateVisible(val) {
            this.visible = val
            if (val === false) {
                this.$refs.insertForm.resetFields()
                this.form.id = ''
                this.localArea = ''
            }
        },
        getHospitalLevel() {
            getKfType({ types: "6" }).then(({ res }) => {
                this.hospitalLevelList = res.map((item) => {
                    return {
                        value: item.code,
                        label: item.name,
                    };
                });
            });
        },
        //获取省市区结构树
        getAreaTree() {
            getSysAreaTree( ).then(({ res }) => {
                this.sysAreaTree = res
                
            });
        },
        
    }
}
</script>
<style scoped>
</style>

