/*
 * @Author: magy
 * @Date: 2022-04-17 09:42:52
 * @LastEditTime: 2022-04-19 14:12:33
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \recovery_home_management\src\api\columnManage.js
 */

/**
 * 页面: 栏目管理
 * pageName: columnManage
 **/
import request from '@/utils/request'

/**
 * @description: 获取栏目管理列表数据
 */
export function getList(data) {
    return request({
        url: 'admin/kfTopicsType/getColumnList',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 新增栏目管理数据
 */
export function insertData(data) {
    return request({
        url: '',
        method: 'POST',
        data,
        showLoading: true
    })
}

/**
 * @description: 修改栏目管理数据
 */
export function editData(data, timeStamp) {
    return request({
        url: 'admin/kfTopicsType/updateName',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}
            

/**
 * @description: 获取栏目管理详情数据
 */
export function getDetail(data) {
    return request({
        url: 'admin/kfTopicsType/info',
        method: 'GET',
        params: data,
        showLoading: true
    })
}
            
/**
 * @description: 删除栏目管理数据
 */
    export function deleteData(data, timeStamp) {
    return request({
        url: '',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}


/**
 * @description: 修改栏目启用/禁用状态
 */
 export function changeStatus(data, timeStamp) {
    return request({
        url: 'admin/kfTopicsType/changeStatus',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}
        