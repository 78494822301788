/* eslint-disable no-undef */
/*
 * @Description: 公共方法
 * @Author: yzw
 * @Date: 2021-08-12 16:56:40
 * @LastEditTime: 2024-09-04 15:14:44
 * @LastEditors: Please set LastEditors
 * @Reference:
 */
import store from "@/store";
import PinYin from "@/utils/PinYin";
import { getToken } from '@/utils/auth' ;
import { showScreenLoading, hideScreenLoading } from './loading'

/**
 * @description: 多维数组合并为一维数组
 * @param {Array} arr 数组
 * @return {Array}
 */

export const flatten = arr => {
  return [].concat(...arr.map(x => (Array.isArray(x) ? flatten(x) : x)));
};

export const customQuery = (name = "user", staticCols = []) => {
    const menuConfig = store.state.user.menuConfig;
    const menuObj = menuConfig.filter(item => item.pageName === name);
    const colList = filterTableCol(menuObj[0].columnList)
    const searchList = null
    return {colList,searchList}
}

export const filterTableCol = columnList => {
    let colList = columnList.map(item => {
        for (let i = 0; i < staticCols.length; i++) {
            const ele = staticCols[i];
            item.label = ele.label || null;
            item.slot = ele.slot || null;
            item.prop = item.slot ? null : item.fieldName;
            item.formatter = ele.formatter || null;
            item.children = ele.children || null;
            item.expand = ele.expand || null;
            item.fixed = ele.fixed || null;
            item.width = ele.width || null;
            item.sortable = ele.sortable || null;
        }
        return item;
    });
    return colList
};

/**
 * @description: 验证是否有资源权限
 * @param {String} key 权限标识
 * @return {Boolean}
 */

export const hasPermission = key => {
  const { permissions } = store.state.user;
  return permissions.includes(key);
};

/** 汉字转拼音首字母
 * @description:
 *      替换掉输入框中所有空格
 *      遍历查找每一个字符
 *      如果是汉字，返回对应的英文大写首字母
 *      如果不是汉字，原样返回
 *      最后将数组连接起来，作为字符串返回
 * @param {String} str 传入的字符串
 * @return {String} 根据输入转换的拼音大写首字母
 */
export const CH2CYTPALL = str => {
  if (typeof str != "string") {
    throw new Error(
      -1,
      "\u51fd\u6570makePy\u9700\u8981\u5b57\u7b26\u4e32\u7c7b\u578b\u53c2\u6570!"
    );
  }
  str = str.replace(/\s/g, "");
  let arrResult = []; //保存中间结果的数组
  for (let i = 0, len = str.length; i < len; i++) {
    //获得unicode码
    let ch = str.charAt(i);
    //检查该unicode码是否在处理范围之内,在则返回该码对映汉字的拼音首字母,不在则调用其它函数处理
    // 汉字拼音首字母列表 本列表包含了20902个汉字,用于配合 ToChineseSpell
    //函数使用,本表收录的字符的Unicode编码范围为19968至40869, XDesigner 整理
    const strChineseFirstPY = PinYin;
    const uni = ch.charCodeAt(0);
    ch =
      uni > 40869 || uni < 19968 ? ch : strChineseFirstPY.charAt(uni - 19968);
    arrResult.push(ch);
  }
  arrResult = arrResult.map(item => item[0]);
  //将数组拼接成字符串
  return arrResult.join("");
};

/**
 * @description: 在sessionStorage中设置refreshFlag属性为true以在返回是刷新页面
 */

export const setRefreshFlag = () => {
  sessionStorage.setItem("refreshFlag", "true");
};

/**
 * @description: 返回在sessionStorage中查询的refreshFlag属性,并移除refreshFlag属性
 * @return {String|null}
 */

export const getRefreshFlag = () => {
  const refreshFlag = sessionStorage.getItem("refreshFlag");
  sessionStorage.removeItem("refreshFlag");
  return refreshFlag;
};

export const previewFile = () => {
  window.open("http://cn.createpdfonline.org/pdffiles/ee(20211216114159).pdf");
};

/**
 * 导出Excel文件下载
 * @param {*} exportUrl 后端接口地址
 * @param {*} headerParams http头参数
 * @param {*} bodyParams 请求参数
 * @param {*} prefixName 导出文件名
 * @param {*} suffixName 导出文件名扩展名默认(xlsx)
 * @returns
 */
export function exportExcel(exportUrl, headerParams, bodyParams, prefixName, suffixName = 'xlsx') {
	let responseReturn = new Response();
	let _headersDefault = {
		'Content-Type': 'application/json',
		Accept: 'application/json',
	}
	let _headers = Object.assign(getToken(), _headersDefault, headerParams || {});
  console.table(_headers);
  showScreenLoading();
	return fetch(exportUrl, {
		method: 'post',
		headers: _headers,
		body: JSON.stringify(bodyParams),
		mode: 'cors',
		credentials: 'include',
		cache: 'default',
	}).then(response => {
		responseReturn = response;
		return response.clone().json()
	}).catch(function (error) {
    return responseReturn.blob();
  }).then(data => {
		if (data instanceof Blob) {
      return data;
		} else if (data.state != 200){
      hideScreenLoading();
      // 导出失败抛出错误信息
      throw new Error(data.msg + ':' + data.data);
		}
	}).then((blob) => {
		if(blob instanceof Blob){
			let url = window.URL.createObjectURL(blob)
			let a = document.createElement('a')
			a.href = url
			a.download = `${prefixName}.${suffixName}`;
			document.body.appendChild(a)
			a.click()
			URL.revokeObjectURL(a.href); // 释放URL 对象
			document.body.removeChild(a);
		}
    hideScreenLoading();
	})
}

export function fillZero(str){
	let realNum;
	if (str < 10) {
		realNum = '0' + str;
	} else {
		realNum = str;
	}
	return realNum;
}

//格式化时间戳位XX:XX
export function formatTime(time) {
  let hours = Math.floor(time / 3600);
  let minutes = Math.floor(Math.floor(time % 3600) / 60);
  let seconds = Math.floor(time % 60);
  let result = [];
  if (Number(hours) > 0) {
    result.push(`${fillZero(hours)}时`)
  }
  if (Number(minutes) > 0) {
    result.push(`${fillZero(minutes)}分`)
  } else {
    if (result.length > 0) {
      // 有小时的情况下下
      result.push(`00分`)
    }
  }
  if (result.length == 0) {
    result.push(`${fillZero(seconds)}秒`)
  } else {
    if (Number(seconds) > 0) {
      result.push(`${fillZero(seconds)}秒`)
    } else {
      result.push(`00秒`)
    }
  }
  return result.join('');
}

/**
 * @description: 在sessionStorage中设置登录用户是在线客服
 */

export const setIsService = (isService) => {
  sessionStorage.setItem("IS_SERVICE", isService);
};

/**
 * @description: 返回用户是否是在线客服
 * @return {String|null}
 */

export const getIsService = () => {
  return sessionStorage.getItem("IS_SERVICE");
};

/**
 * @description: 在sessionStorage中设置登录IM用户ID
 */

export const setIMUserId = (imUserId) => {
  sessionStorage.setItem("IM_USER_ID", imUserId);
};

/**
 * @description: 返回IM用户ID
 * @return {String|null}
 */

export const getIMUserId = () => {
  return sessionStorage.getItem("IM_USER_ID");
};

/**
 * @description: 在sessionStorage中设置登录IM用户密钥
 */

export const setIMUserSig = (userSig) => {
  sessionStorage.setItem("IM_USER_SIG", userSig);
};

/**
 * @description: 返回IM用户密钥
 * @return {String|null}
 */

export const getIMUserSig = () => {
  return sessionStorage.getItem("IM_USER_SIG");
};

/**
 * @description: 在sessionStorage中设置登录用户账号
 */

export const setAccount = (account) => {
  sessionStorage.setItem("ACCOUNT", account);
};

/**
 * @description: 返回登录用户账号
 * @return {String|null}
 */

export const getAccount = () => {
  return sessionStorage.getItem("ACCOUNT");
};

/**
 * @description: 返回登录用户账号
 * @return {String|null}
 */

export const initVideoLength = (value) => {
  var secondTime = parseInt(value);// 秒
  var minuteTime = 0;// 分
  var hourTime = 0;// 小时
  if(secondTime > 60) {//如果秒数大于60，将秒数转换成整数
    //获取分钟，除以60取整数，得到整数分钟
    minuteTime = parseInt(secondTime / 60);
    //获取秒数，秒数取余，得到整数秒数
    secondTime = parseInt(secondTime % 60);
    //如果分钟大于60，将分钟转换成小时
    if(minuteTime > 60) {
      //获取小时，获取分钟除以60，得到整数小时
      hourTime = parseInt(minuteTime / 60);
      //获取小时后取余的分，获取分钟除以60取余的分
      minuteTime = parseInt(minuteTime % 60);
    }
  }
  //若秒数是个位数，前面用0补齐
	secondTime = secondTime < 10 ? '0' + secondTime : secondTime;
  //var result = "" + parseInt(secondTime);
  var result = '' + secondTime + '';
  if(minuteTime > 0) {
    //若分钟数是个位数，前面用0补齐
		minuteTime = minuteTime < 10 ? '0' + minuteTime : minuteTime;
    //result = "" + parseInt(minuteTime) + ":" + result;
    result = '' + minuteTime + ':' + result;
  }else{
      result = "" + "00" + ":" + result;
  }
  if(hourTime > 0) {
  //result = "" + parseInt(hourTime) + ":" + result;
    //若小时数是个位数，前面用0补齐
    hourTime = hourTime < 10 ? '0' + hourTime : hourTime;
    result = '' + hourTime + ':' + result;
  }else{
      result = "" + "00" + ":" + result;
  }
  console.log('result',result);
  return result
};
