<template>
  <div class="navbar">
    <div class="hosInfo-box">
      <el-image
        class="hosLogo"
        :src="commonConfig.$fileUrl + hospitalInfo.logo"
        fit="cover"
      />
      <span class="hospitalName">{{ hospitalInfo.hospitalName|| commonConfig.$nullData }}</span>
      <span class="systemName">{{ hospitalInfo.systemName|| commonConfig.$nullData }}</span>
    </div>
    <div class="right-menu">
      <div class="right-menu-box" @click="gotoCustomerService" v-if="isService">
          <svg-icon icon-class="customerService" class="right-menu-icon" />
          <el-badge :is-dot="totalUnreadCount > 0" class="right-menu-text">在线客服</el-badge><span class="right-menu-text">({{totalUnreadCount}}条)</span>
        </div>
      <el-dropdown class="avatar-container" trigger="click">
        <div class="right-menu-box">
          <svg-icon icon-class="qrcode" class="right-menu-icon" />
          <div class="right-menu-text">二维码</div>
        </div>
        <el-dropdown-menu slot="dropdown" class="user-dropdown">
          <el-dropdown-item
            ><el-image
              class="nav-qrcode"
              :src="commonConfig.$fileUrl + hospitalInfo.appDownloadAddress"
          /></el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-dropdown class="avatar-container" trigger="click">
        <div class="right-menu-box">
          <svg-icon icon-class="navUser" class="right-menu-icon" />
          <div class="right-menu-text">{{ trueName }}</div>
        </div>
        <el-dropdown-menu slot="dropdown" class="user-dropdown">
          <el-dropdown-item @click.native="dialogVisible = true">
            修改密码
          </el-dropdown-item>
          <el-dropdown-item divided @click.native="logout">
            <span style="display: block">退出登录</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-dialog
      title="修改密码"
      :visible.sync="dialogVisible"
      width="30%"
      center
      :modal-append-to-body="false"
    >
      <el-form
        ref="form"
        :model="form"
        label-width="80px"
        class="formBox"
        :rules="rules"
      >
        <el-row class="formRow">
          <el-form-item label="旧密码" class="formItem" prop="oldPassword">
            <el-input v-model="form.oldPassword" clearable show-password />
          </el-form-item>
        </el-row>
        <el-row class="formRow">
          <el-form-item label="新密码" class="formItem" prop="newPassword">
            <el-input v-model="form.newPassword" clearable show-password />
          </el-form-item>
        </el-row>
        <el-row class="formRow">
          <el-form-item label="确认密码" class="formItem" prop="newPassword1">
            <el-input v-model="form.newPassword1" clearable show-password />
          </el-form-item>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onSubmit()">确 定</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getList,updatePassword } from "@/api/userInfo";
import { TUIStore, StoreName } from "@tencentcloud/chat-uikit-engine";
import { mapGetters } from "vuex";
import { getToken } from "@/utils/auth";
import { List } from 'echarts';
export default {


  data() {
    const validatePassword = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.form.newPassword) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      dialogVisible: false,
      totalUnreadCount:0,
      form: {
        oldPassword: "",
        newPassword: "",
        newPassword1: ""
      },
      rules: {
        oldPassword: [
          { required: true, message: "请输入旧密码", trigger: "blur" },
          { min: 6, max: 12, message: "请输入 6 - 12 个字符", trigger: "blur" }
        ],
        newPassword: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          { min: 6, max: 12, message: "请输入 6 - 12 个字符", trigger: "blur" }
        ],
        newPassword1: [
          { validator: validatePassword, trigger: "blur", required: true }
        ]
      },

      unReadNum: 0, //未读数量
      ws: null,
      isDestroyed: false, // 页面是否销毁
      // lockReconnect: false, // 是否真正建立连接
      // timeout: 45 * 1000, // 45秒一次心跳
      // timeoutObj: null, // 心跳心跳倒计时
      // serverTimeoutObj: null, // 心跳倒计时
      // timeoutnum: null, // 断开 重连倒计时
    };
  },
  computed: {
    ...mapGetters(["trueName", "hospitalInfo",'unReadMsgList',"isService"])
  },
  created() {},
  mounted() {
    // this.initWebSocket();
    TUIStore.watch(StoreName.CONV, {
      totalUnreadCount: (count) => {
        console.log("count====",count);
        this.totalUnreadCount = count;
      },
    });
  },
  // 页面销毁
  beforeDestroy() {
    this.isDestroyed = true;
    // this.timeoutnum && clearTimeout(this.timeoutnum);
    // this.timeoutObj && clearTimeout(this.timeoutObj);
    // this.serverTimeoutObj && clearTimeout(this.serverTimeoutObj);
    // this.ws.close(); // 关闭ws
  },
  methods: {
    async logout() {
      this.$router.push(`/login`);
      await this.$store.dispatch("logout");
      this.$router.push(`/login`);
    },
    gotoCustomerService(){

      this.$router.push(`/customerService`);
    },
    // toMsg(item) {
    //    const para = {messageId:item.id};
    //   updateStatus(para,this.timeStamp).then(({res,timeStamp}) =>{
    //     this.getList();
    //   })


    //   switch (item.type) {
    //     case "0":
    //       this.$router.push(`/treatment/myPatient`);
    //       break;
    //     case "1":
    //       this.$router.push({
    //             path: "/teamwork/communication/detail",
    //             query: {
    //               id: JSON.parse(item.paramJson).id,
    //               archivesInfoId:JSON.parse(item.paramJson).archivesInfoId
    //             },
    //       });
    //       break;
    //     case "2":
    //       this.$router.push({
    //             path: "/teamwork/communication/detail",
    //             query: {
    //               id: JSON.parse(item.paramJson).id,
    //               archivesInfoId:JSON.parse(item.paramJson).archivesInfoId
    //             },
    //       });
    //       break;
    //     case "3":
    //       this.$router.push({
    //             path: "/teamwork/communication/detail",
    //             query: {
    //               id: JSON.parse(item.paramJson).id,
    //               archivesInfoId:JSON.parse(item.paramJson).archivesInfoId
    //             },
    //       });
    //       break;
    //     case "4":
    //       this.$router.push({ path: "/patient/list/detail", query: { id: item.paramJson}});
    //       break;
    //     case "5":
    //       this.$router.push({
    //             path: "/teamwork/communication/detail",
    //             query: {
    //               id: JSON.parse(item.paramJson).id,
    //               archivesInfoId:JSON.parse(item.paramJson).archivesInfoId
    //             },
    //       });
    //       break;
    //     case "6":
    //       this.$router.push({ path: "/patient/list/detail", query: { id: item.paramJson}});
    //       break;
    //     default:
    //         console.error(item)
    //         break;
    //   }

    // },
    // toPersonalSetting() {
    //   this.$router.push(`/personalSetting`);
    // },
    onSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          updatePassword(this.form, new Date().getTime()).then(({ res }) => {
            this.$message({
              showClose: true,
              message: "修改成功",
              type: "success"
            });
            this.dialogVisible = false;
            this.form = {
              //重置修改密码输入框状态
              oldPassword: "",
              newPassword: "",
              newPassword1: ""
            };
          });
        } else {
          return false;
        }
      });
    },
    // 进入页面创建websocket连接
    // initWebSocket() {
    //   // 判断页面有没有存在websocket连接
    //   const _this = this
    //   if (window.WebSocket) {
    //     const host = window.location.hostname;
    //     const auth = getToken();
    //     let authentication = auth.authentication
    //       .replace(/\//g, "@1@")
    //       .replace(/=/g, "@2@")
    //       .replace(/[+]/g, "@3@")
    //       .replace(/%/g, "@4@");
    //     this.ws = new WebSocket(
    //       `ws://${host}/rehabilitation/websocket/${auth.unique}/${authentication}`
    //     );
    //     this.ws.onopen = function(e) {
    //       // console.log("服务器连接成功");
    //       _this.start(); // 开启心跳
    //     };
    //     this.ws.onclose = function(e) {
    //       // console.log("服务器连接关闭");
    //       _this.reconnect(); // 重连
    //     };
    //     this.ws.onerror = function(e) {
    //       // console.log("服务器连接出错");
    //       _this.reconnect(); // 重连
    //     };
    //     this.ws.onmessage = function(e) {
    //       _this.reset(); // 心跳重置
    //       // 接收服务器返回的数据
    //       if (e.data !== 'pong') {
    //         if (e.data === 'scientificTask') {
    //           // ...todo
    //         } else {
    //           //获取未读消息
    //           _this.$store.dispatch('getUnReadMsgList')
    //         }
    //       }
    //     };
    //   }
    // },
    // 重新连接
    // reconnect() {
    //   var self = this;
    //   if (self.lockReconnect || self.isDestroyed) {
    //     return;
    //   }
    //   // console.log("重新连接。。。");
    //   self.lockReconnect = true;
    //   // 没连接上会一直重连，间隔5秒连接一次
    //   self.timeoutnum && clearTimeout(self.timeoutnum);
    //   self.timeoutnum = setTimeout(function() {
    //     // 新连接
    //     self.initWebSocket();
    //     self.lockReconnect = false;
    //   }, 5000);
    // },
    // 重置心跳
    // reset() {
    //   var self = this;
    //   // 清除时间
    //   self.timeoutObj && clearTimeout(self.timeoutObj);
    //   self.serverTimeoutObj && clearTimeout(self.serverTimeoutObj);
    //   // 重启心
    //   self.start();
    // },
    // 开启心跳
    // start() {
    //   var self = this;
    //   self.timeoutObj && clearTimeout(self.timeoutObj);
    //   self.serverTimeoutObj && clearTimeout(self.serverTimeoutObj);
    //   self.timeoutObj = setTimeout(function() {
    //     // 这里发送一个心跳，后端收到后，返回一个心跳消息
    //     if (self.ws.readyState === 1) {
    //       // 判断连接是否正常
    //       self.ws.send('ping');
    //     } else {
    //       // 否则重连
    //       self.reconnect();
    //     }
    //     self.serverTimeoutObj = setTimeout(function() {
    //       self.ws.close(); // 超过3秒没收到心跳消息关闭重连
    //     }, 3000);
    //   }, self.timeout);
    // }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";
.navbar {
  height: $navBarHeight;
  overflow: hidden;
  position: relative;
  background: $theme;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .right-menu {
    float: right;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    &-box {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      width: auto;
      padding-right: 20px;
      cursor: pointer;
      position: relative;
      .red-num{
        position: absolute;
        left: 5px;
        top: -5px;
        padding: 0 5px;
        line-height: 18px;
        font-size: 12px;
        text-align: center;
        color: $white;
        border-radius: 5px;
        background: $danger;
      }
    }
    &-icon {
      color: $white;
      font-size: 22px;
      margin-right: 10px;
      margin-left: 15px;
    }
    &-text {
      color: $white;
      font-size: 16px;
      min-width: 50px;
    }
  }
  .hosInfo-box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: $navBarHeight;
  }
  .hosLogo {
    width: auto;
    max-width: 70px;
    margin-left: 10px;
    margin-right: 10px;
    height: 38px;
  }
  .hospitalName {
    font-size: 28px;
    color: $white;
    font-weight: 600;
  }
  .systemName {
    font-size: 16px;
    color: $white;
    margin-left: 10px;
    margin-top: 11px;
  }
}
.user-dropdown {
  z-index: 100001 !important;
}
.nav-qrcode {
  width: 200px;
  height: 200px;
  margin-top: 10px;
}
.msg-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: 350px;
  overflow: auto;
}
.msg-item {
  width: 100%;
  margin: 5px 0;
  color: $titleColor;
  &-title {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
  }
  &-content {
    width: 100%;
    @include show_line(3);
    margin-bottom: 0;
  }
}
.msg-date {
  width: 100%;
  text-align: center;
  line-height: 30px;
  font-weight: bold;
  margin: 0;
  color: $text;
}
.el-card {
  cursor: pointer;
}
</style>
