<!--
 * @Author: magy
 * @Date: 2022-04-07 16:45:14
 * @LastEditTime: 2022-05-23 19:28:19
 * @LastEditors: chenm 6518182+luo_chen123@user.noreply.gitee.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \recovery_home_management\src\views\doctorManagement\addDialogConfig.vue
-->

<!--
* @Description: 新增服务配置，修改服务配置
* @Reference:
-->
<template>
    <el-dialog
        width="70%"
        :title="id === '' ? '新增服务配置' : '修改服务配置'"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        append-to-body
    >
        <el-form
            ref="insertForm"
            :model="form"
            label-position="right"
            label-width="120px"
            class="formBox"
            :rules="rules"
        >
            <div class="mdrcc-info-title">服务配置</div>
            <el-row :gutter="10" type="flex" justify="start">
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="服务次数" prop="number">
                        <el-input
                            v-model="form.number"
                            placeholder="请输入..."
                            clearable
                            maxlength="20"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="打折前价格(原价(单位为分))" prop="modalBeforePrices">
                        <el-input
                            v-model="form.modalBeforePrices"
                            placeholder="请输入..."
                            clearable
                            maxlength="20"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="打折后价格(现价(单位为分))" prop="modalAfterPrices">
                        <el-input
                            v-model="form.modalAfterPrices"
                            placeholder="请输入..."
                            clearable
                            maxlength="20"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="推荐" prop="recommended">
                      <el-switch
                        v-model="form.recommended"
                        name="recommended"
                        active-text="推荐"
                        inactive-text="不推荐"
                        inactive-value="1"
                        active-value="0"
                      />
                    </el-form-item>
                  </el-col>
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submit()">提 交</el-button>
            <el-button @click="updateVisible(false)">取 消</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { createServiceConfig,updateServiceConfig, getServiceConfigInfo } from '@/api/doctorManagement'
export default {
    props: {
        id: {
            type: String
        },
        userDoctorId:{
            type: String
        }
    },
    data() {
        return {
             rules: {
                number: [
                    {
                        required: true,
                        message: '请输入服务次数',
                        trigger: 'blur'
                    }
                ],
                modalBeforePrices: [
                    {
                        required: true,
                        message: '请输入价格',
                        trigger: 'blur'
                    }
                ],
                modalAfterPrices: [
                    {
                        required: true,
                        message: '请输入价格',
                        trigger: 'blur'
                    }
                ],
            },
            form: {
                id:'',
                userDoctorId:'',//医生用户code
                number:'',//服务次数
                modalBeforePrices:0,//系数前价格(默认0)
                modalAfterPrices:0,//系数后 价格（默认0）
                
            },
            total: 0,
            visible: false,
            timeStamp: '', // 时间戳
            submitFlag: true // 防抖
        }
    },
    watch: {
        visible(newVal, oldVal) {
            if (newVal === true && this.id ) {
                 this.getServiceConfigInfo()
            }
        }
    },
    created() {
       
    },
    mounted() {},
    methods: {
        submit() {
            if (!this.submitFlag) {
                return
            }
            this.submitFlag = false
            this.$refs.insertForm.validate((valid) => {
                if (valid) {
                    this.form.userDoctorId = this.userDoctorId
                    const parm = this.form
                    if (!parm.id) {
                        createServiceConfig(parm).then(({ res }) => {
                            this.submitSuccess(res)
                        }).catch((error)=> {
                            this.submitFailure(error)
                        })
                    }else{
                        updateServiceConfig(parm, this.timeStamp).then(({ res }) => {
                            this.submitSuccess(res)
                            this.form.id = ''
                        }).catch((error)=> {
                            this.submitFailure(error)
                        })
                    }
                } else{
                    this.submitFlag = true
                }
            })
        },
        
        submitFailure(error) {
            this.submitFlag = true
        },
        submitSuccess(res) {
            this.submitFlag = true
            this.$message({ ...this.commonConfig.$handleSuccess })
            this.$emit('submit')
            this.updateVisible(false)
        },
        getServiceConfigInfo() {
            const param = {}
                param.id = this.id
            getServiceConfigInfo(param).then(({ res, timeStamp }) => {
                this.timeStamp = timeStamp
                this.form = res
            })
        },
        updateVisible(val) {
            this.visible = val
            if (val === false) {
                this.fileList = []
                 this.uploadDisabled = ''
                this.$refs.insertForm.resetFields()
            }
        },
        
    }
}
</script>
<style scoped lang='scss'>
</style>

