
/**
 * 页面: 推送管理
 * pageName: pushManage
 **/
import request from '@/utils/request'

/**
 * @description: 获取推送管理列表数据
 */
export function getList(data) {
    return request({
        url: '',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 新增推送管理数据
 */
export function insertData(data) {
    return request({
        url: '',
        method: 'POST',
        data,
        showLoading: true
    })
}

/**
 * @description: 修改推送管理数据
 */
export function editData(data, timeStamp) {
    return request({
        url: '',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}
            

/**
 * @description: 获取推送管理详情数据
 */
export function getDetail(data) {
    return request({
        url: '',
        method: 'GET',
        params: data,
        showLoading: true
    })
}
            
/**
 * @description: 删除推送管理数据
 */
    export function deleteData(data, timeStamp) {
    return request({
        url: '',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}
        