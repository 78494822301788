<!--
 * @Author: your name
 * @Date: 2022-04-01 14:56:55
 * @LastEditTime: 2022-05-20 09:22:25
 * @LastEditors: magy “……”
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \recovery_home_management\src\views\patientManagement\index.vue
-->

<!--
* @Description: 患者管理页面
* @Reference:
-->

<template>
  <el-container>
    <el-header height="auto">
      <searchForm ref="mainSearch" :searchoptions="searchOptions" @search="search" :backStatus="backStatus"/>
    </el-header>
    <el-main class="basic-main">
      <generalTable ref="mainTable" :table-data="tableData" :config="tableConfig" :total="total" @updatePage="getList" @objectSpanMethod="objectSpanMethod" @onClickRow="onClickRow">
         <template slot="frozenAccount" slot-scope="scope">
                        <el-switch
                            v-model="scope.data.row.frozenAccount"
                            inactive-value="2"
                            active-value="1"
                            @change="changeStatus(scope,$event)"
                        />
                    </template>
        <template slot="handle" slot-scope="scope">
          <el-button type="text" style="margin-right: 10px" @click="toEdit(scope)">查看</el-button>

        </template>
      </generalTable>
            <patientRecordDialog
            :id="checkId"
            ref="patientRecord"
        />
    </el-main>
  </el-container>
</template>
<script>
import { getList, patientStatus } from '@/api/patientManagement'
import searchForm from '@/components/SearchForm'
import generalTable from '@/components/Table'
import patientRecordDialog from "@/views/patientManagement/patientRecordDialog";

export default {
    name: 'Test',
    components: {
        searchForm,
        generalTable,
        patientRecordDialog,
        // addDialog,
        // serviceConfig
    },
    data() {
        return {
            tableData: [
            ],
            searchOptions: [
                {
                    type: 'input',
                    placeholder: '请输入...',
                    key: 'loginName',
                    label: '姓名',
                    defaultVal: ''
                },
                {
                    type: 'select',
                    key: 'gender',
                    filterable: true,
                    label: '性别',
                    options: [
                        {
                            label: '男',
                            value: '1'
                        },
                        {
                            label: '女',
                            value: '2'
                        }
                    ],
                    defaultVal: ''
                },
                {
                    type: 'input',
                    placeholder: '请输入...',
                    key: 'phoneNumber',
                    label: '手机号',
                    defaultVal: ''
                },
                {
                    type: 'select',
                    key: 'frozenAccount',
                    filterable: true,
                    label: '状态',
                    options: [
                        {
                            label: '启用',
                            value: '1'
                        },
                        {
                            label: '禁用',
                            value: '2'
                        }
                    ],
                    defaultVal: ''
                }
            ],
            tableConfig: {
                id: 'test',
                align: 'center', // 不必填 默认为center
                selection: 'single', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: true, // 不必填 分页 默认为false
                column: [
                // {
                //     label: '排序', // 必填
                //     prop: 'sort' // 必填
                // },
                {
                    label: '姓名', // 必填
                    prop: 'loginName' // 必填
                },
                {
                    label: '手机号', // 必填
                    prop: 'phoneNumber', // 必填
                },
                {
                    label: '注册地', // 必填
                    prop: 'registerArea', // 必填
                },
                {
                    label: '年龄', // 必填
                    prop: 'age' // 必填
                },
                {
                    label: '性别', // 必填
                    prop: 'gender', // 必填
                    formatter: (row, column) => {
                            const stampContainer = {
                                1: "男",
                                2: "女",
                            };
                            return stampContainer[row.gender];
                        },
                },
                 {
                    label: '微信', // 必填
                    prop: 'wechat' // 必填
                },
                //  {
                //     label: 'QQ', // 必填
                //     prop: 'qq' // 必填
                // },
                {
                    label: '注册时间', // 必填
                    prop: 'createDate', // 必填
                    formatter: (row, column) => {
                            return this.moment(row.createDate).format(
                                'YYYY-MM-DD HH:mm'
                            )
                        }
                },
                {
                    label: '禁用/启用', // 必填
                    slot: 'frozenAccount' // 必填
                },
                {
                    slot: 'handle', // 不必填 单列插槽
                    label: '操作', // 必填
                    fixed: 'right', // 不必填 固定列  left right
                    width: '220' // 不必填
                }
                ]
            },
            paramObj: null, // 筛选项
            total: 0, // 总条数
            checkId: '', // 单条数据id 用于详情或编辑请求数据用
            timeStamp: '',
            backStatus: true, //返回按钮的隐藏显示
        }
    },
    created() {
        this.getList();
    },
    mounted: function() {},
    methods: {
        search(data) {
            this.paramObj = data
            this.$refs.mainTable.handleCurrentChange(1)
        },
        getList() {
            // 解决未挂载完组件时调用子组件方法报错问题
            this.$nextTick(() => {
                const param = this.getParam()
                getList(param).then((res, timeStamp) => {
                    this.tableData = res.res.records;
                    this.timeStamp = timeStamp;
                    this.total = res.res.total;
                })
            })

        },
        objectSpanMethod(param, cb) {
            cb([])
        },

        toEdit(scope) {
            this.checkId = scope.data.row.id
            this.$refs.patientRecord.updateVisible(true)
        },
        onClickRow(checkArray) {
            console.log('testststst')
            console.log('checkArray', checkArray)
        },
        getParam() {
            // 获取参数
            const paramObj = this.$refs.mainSearch.packageData()
            this.paramObj = paramObj
            const page = this.$refs.mainTable.getPage()
            const param = Object.assign(page, paramObj)
            return param
        },
        changeStatus(checkArray){
            const parm = {
                id:checkArray.data.row.id,
                frozenAccount:checkArray.data.row.frozenAccount
            }
            patientStatus(parm,this.timeStamp).then(({res}) =>{
                this.getList()
            })
            // .catch((err) => {
            //         if (err) {
            //             this.tableData[scope.data.$index].status =
            //                 event === 0 ? '1' : '0'
            //         }
            //     })

        },

    }
}
</script>

<style lang='scss'>
@import "~@/styles/variables.scss";

</style>
