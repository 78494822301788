/*
 * @Author: your name
 * @Date: 2022-04-17 09:42:52
 * @LastEditTime: 2022-04-19 15:43:13
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \recovery_home_management\src\api\classifyManage.js
 */

/**
 * 页面: 分类管理
 * pageName: classifyManage
 **/
import request from '@/utils/request'

/**
 * @description: 获取分类管理列表数据
 */
export function getList(data) {
    return request({
        url: 'admin/kfTopicsType/getClassifyList',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 新增分类管理数据
 */
export function insertData(data) {
    return request({
        url: 'admin/kfTopicsType/addType',
        method: 'POST',
        data,
        showLoading: true
    })
}

/**
 * @description: 修改分类管理数据
 */
export function editData(data, timeStamp) {
    return request({
        url: 'admin/kfTopicsType/updateType',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}
            

/**
 * @description: 获取分类管理详情数据
 */
export function getDetail(data) {
    return request({
        url: 'admin/kfTopicsType/info',
        method: 'GET',
        params: data,
        showLoading: true
    })
}
            
/**
 * @description: 删除分类管理数据
 */
    export function deleteData(data, timeStamp) {
    return request({
        url: 'admin/kfTopicsType/deleteType',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}

/**
 * @description: 修改分类启用/禁用状态
 */
 export function changeStatus(data, timeStamp) {
    return request({
        url: 'admin/kfTopicsType/changeStatus',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}
/**
 * @description: 获取全部栏目分类
 */
export function getAllList(data) {
    return request({
        url: 'admin/kfTopicsType/getColumnList',
        method: 'GET',
        params: data,
        showLoading: true
    })
}
        