
<!--
* @Description: 引荐医生
* @Reference:
-->

<template>
    <el-container>
        <el-header height="auto">
            <el-descriptions class="margin-top" title="基本信息" :column="3">
                <el-descriptions-item label="渠道商名称">{{ data.distributorName }}</el-descriptions-item>
                <el-descriptions-item label="联系人">{{ data.contactName }}</el-descriptions-item>
                <el-descriptions-item label="联系人电话">{{ data.contactPhone }}</el-descriptions-item>
                <!-- <el-descriptions-item label="地址">{{ data.distributorAddress }}</el-descriptions-item> -->
                 <el-descriptions-item label="地址" colon="true">
                    <el-tooltip :content="data.distributorAddress" placement="bottom" effect="light">
                        <div class="desc-text">{{data.distributorAddress}}</div>
                    </el-tooltip>
                </el-descriptions-item>
                <el-descriptions-item label="邮箱">{{ data.email }}</el-descriptions-item>
                <el-descriptions-item label="运营负责人">{{ data.operateManagement }}</el-descriptions-item>
            </el-descriptions>
        </el-header>
        <el-main class="basic-main">
            <el-row class="table-btn-group">
                <el-button type="primary" @click="toInsert()">新增</el-button>
                <el-button type="primary" plain @click="toExport">导出</el-button>
                <div class="table-btn-right-group">
                    <el-button type="default" @click="back()">返回</el-button>
                </div>
            </el-row>
            <generalTable ref="mainTable" :table-data="tableData" :config="tableConfig" :total="total"
                @updatePage="getDoctorList" @onClickRow="onClickRow">
                <template slot="handle" slot-scope="scope">

                    <el-button type="text" class="delBtn" @click="toDel(scope)">删除</el-button>
                </template>
            </generalTable>
            <detailDialog :id="checkId" ref="detail" />
            <addDoctorDialog :id="checkId" ref="insert" :distributorId="distributorId" @submit="search" />
             <exportDialog :id="distributorId" ref="exportDialog" :timeStamp="timeStamp" />
        </el-main>
    </el-container>
</template>

<script>
import { getDoctorList, deleteDoctorData } from '@/api/channel'
import searchForm from '@/components/SearchForm'
import generalTable from '@/components/Table'
import addDoctorDialog from '@/views/channel/addDoctorDialog'
import detailDialog from '@/views/channel/detailDialog'
import exportDialog from '@/views/channel/exportDialog'
import { exportExcel } from "@/utils/common";

export default {
    name: 'Channel',
    components: {
        searchForm,
        generalTable,
        detailDialog,
        addDoctorDialog,
        exportDialog
    },
    data() {
        return {
            tableData: [],
            tableConfig: {
                id: 'channel',
                align: 'center', // 不必填 默认为center
                selection: 'single', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: true, // 不必填 分页 默认为false
                column: [
                    {
                        label: '医生姓名', // 必填
                        prop: 'doctorName', // 必填
                        width: '150',
                    },
                    {
                        label: '手机号', // 必填
                        prop: 'doctorPhone' // 必填
                    },
                    {
                        label: '医院', // 必填
                        prop: 'hospitalName',// 必填
                        // formatter: (row, column) => {
                        //     return row.archivesInfoId.name
                        // }
                    },
                    {
                        label: '购买人次', // 必填
                        prop: 'purchaseCount', // 必填
                        width: '200',
                    },
                    {
                        label: '服务人次', // 必填
                        prop: 'serviceCount', // 必填
                        width: '200',
                    },
                    {

                        slot: 'handle', // 不必填 单列插槽
                        label: '操作', // 必填
                        fixed: 'right', // 不必填 固定列  left right
                        width: '220' // 不必填
                    }
                ]
            },
            paramObj: null, // 筛选项
            total: 0, // 总条数
            checkId: '', // 单条数据id 用于详情或编辑请求数据用
            backStatus: true, //返回按钮的隐藏显示
            timeStamp: '', //时间戳 用于修改数据或删除数据
            distributorId: "",
            data: {},
        }
    },


    created() {
        this.getDoctorList()
        this.distributorId = this.$route.query.distributorId
        this.data = this.$route.query.data
        console.log('this.data==', this.data)
    },
    mounted: function () { },
    methods: {
        search(data) {
            Object.assign(this.paramObj, data)
            this.$refs.mainTable.handleCurrentChange(1)
        },
        getDoctorList() {
            console.log('getDoctorList')
            this.$nextTick(() => {
                const param = this.getParam()
                getDoctorList(param).then(({ res, timeStamp }) => {
                    this.tableData = res.records
                    this.timeStamp = timeStamp
                    this.total = res.total
                })
            })
        },
        toInsert() {
            this.checkId = ''
            this.$refs.insert.updateVisible(true)
        },

        toDel(scope) {
            const { $delMsg, $delTitle, $messageBoxConfig, $handleSuccess } = this.commonConfig
            this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
                const param = { id: scope.data.row.id }
                deleteDoctorData(param, this.timeStamp).then(({ res }) => {
                    this.$message({ ...$handleSuccess })
                    this.getDoctorList()
                })
            })
        },
        onClickRow(row) {
            console.log('点击行:', row)
        },
        getParam() {
            // const paramObj = this.$refs.mainSearch.packageData()
            const page = this.$refs.mainTable.getPage()
            const param = Object.assign({}, this.paramObj, page, { distributorId: this.distributorId })
            this.paramObj = param
            return param
        },
         toExport() {
            this.$refs.exportDialog.updateVisible(true)
        },
        back() {
            this.$router.back()
        },

    }
}
</script>

<style lang='scss' scoped>
@import "~@/styles/variables.scss";
::v-deep .el-descriptions-item__label{
    min-width: initial;
}
.desc-text{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
.table-btn-right-group{
    float: right;
}
</style>

        