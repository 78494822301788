/*
 * @Author: your name
 * @Date: 2021-10-14 08:50:22
 * @LastEditTime: 2022-03-16 16:56:34
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /recovery_home_management/src/settings.js
 */
module.exports = {

  title: '居家康复管理系统',

  /**
   * @type {boolean} true | false
   * @description Whether fix the header
   */
  fixedHeader: true
}
