/*
 * @Author: shangxue
 * @Date: 2024-08-29 11:44:20
 * @LastEditTime: 2024-09-27 16:47:14
 * @LastEditors: Please set LastEditors
 * @Description: 
 * @FilePath: /recovery_home_management_dev/src/api/generalVideoManagement.js
 */
import request from '@/utils/request'


/**
 * @description: 获取通用视频类型列表
 */
 export function getGeneralVideoTypeList(data) {
  return request({
    url: 'videoType/list',
    method: 'GET',
    params: data,
    showLoading: true
  });
}
/**
 * @description: 获取通用视频类型信息
 */
export function getGeneralVideoTypeInfo(data) {
  return request({
    url: 'videoType/info',
    method: 'GET',
    params: data,
    showLoading: true
  });
}

/**
 * @description: 添加通用视频类型
 */
export function addGeneralVideoTypeInfo(data, timeStamp) {
  return request({
    url: 'videoType/add',
    method: 'POST',
    data,
    showLoading: true,
    timeStamp
  })
}

/**
 * @description: 修改通用视频类型
 */
export function updateGeneralVideoTypeInfo(data, timeStamp) {
  return request({
    url: 'videoType/update',
    method: 'POST',
    data,
    showLoading: true,
    timeStamp
  })
}
/**
 * @description: 删除通用视频类型
 */
export function deleteGeneralVideoTypeInfo(data, timeStamp) {
  return request({
    url: 'videoType/delete',
    method: 'POST',
    data,
    showLoading: true,
    timeStamp
  })
}
  

/**
 * @description: 获取通用视频信息列表
 */
 export function getGeneralVideoList(data) {
  return request({
    url: 'videoInfo/list',
    method: 'GET',
    params: data,
    showLoading: true
  });
}
/**
 * @description: 获取通用视频信息
 */
export function getGeneralVideoInfo(data) {
  return request({
    url: 'videoInfo/info',
    method: 'GET',
    params: data,
    showLoading: true
  });
}

/**
 * @description: 添加通用视频
 */
export function addGeneralVideoInfo(data, timeStamp) {
  return request({
    url: 'videoInfo/add',
    method: 'POST',
    data,
    showLoading: true,
    timeStamp
  })
}

/**
 * @description: 修改通用视频
 */
export function updateGeneralVideoInfo(data, timeStamp) {
  return request({
    url: 'videoInfo/update',
    method: 'POST',
    data,
    showLoading: true,
    timeStamp
  })
}
/**
 * @description: 删除通用视频
 */
export function deleteGeneralVideoInfo(data, timeStamp) {
  return request({
    url: 'videoInfo/delete',
    method: 'POST',
    data,
    showLoading: true,
    timeStamp
  })
}

/**
 * @description: 获取通用视频类型信息（不分页）
 */
export function getGeneralVideoTypeListNoPage(data) {
  return request({
    url: 'videoType/listNoPage',
    method: 'GET',
    params: data,
    showLoading: true
  });
}
/**
 * @description: 批量删除通用视频
 */
export function deleteBatchGeneralVideoInfo(data, timeStamp) {
  return request({
    url: 'videoInfo/deleteBatch',
    method: 'POST',
    data,
    showLoading: true,
    timeStamp
  })
}

/**
 * @description: 批量修改通用视频信息
 */
export function groupBatchGeneralVideoInfo(data, timeStamp) {
  return request({
    url: 'videoInfo/groupBatch',
    method: 'POST',
    data,
    showLoading: true,
    timeStamp
  })
}


/**
 * @description: 本地视频上传
 */
export function addOtherVideoInfo(data, timeStamp) {
  return request({
    url: 'videoInfo/addOther',
    method: 'POST',
    data,
    showLoading: true,
    timeStamp
  })
}
