<!--
 * @Author: magy
 * @Date: 2022-03-22 15:28:04
 * @LastEditTime: 2022-04-22 16:47:27
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \recovery_home_management\src\views\basicSetting\officesManagement.vue
-->

<!--
* @Description: 医院管理页面
* @Reference:
-->

<template>
  <el-container>
    <el-header height="auto">
      <searchForm ref="mainSearch" :searchoptions="searchOptions" @search="search" :backStatus="backStatus"/>
    </el-header>
    <el-main class="basic-main">
      <el-row class="table-btn-group"><el-button type="primary" @click="toInsert()">新增</el-button></el-row>
      <generalTable ref="mainTable" :table-data="tableData" :config="tableConfig" :total="total" @updatePage="getHospitalList" @objectSpanMethod="objectSpanMethod" @onClickRow="onClickRow">
        <template slot="isCharge" slot-scope="scope">
                        <el-switch
                            v-model="scope.data.row.isCharge"
                            inactive-value="2"
                            active-value="1"
                            @change="changeIsCharge(scope,$event)"
                        />
                    </template>
        <template slot="signCharge" slot-scope="scope">
                        <el-switch
                            v-model="scope.data.row.signCharge"
                            inactive-value="2"
                            active-value="1"
                            @change="changeSignCharge(scope,$event)"
                        />
                    </template>
        <template slot="handle" slot-scope="scope">
          <el-button type="text" style="margin-right: 10px" @click="toEdit(scope)">编辑</el-button>
          <el-button type="text" class="delBtn" @click="toDel(scope)">删除</el-button>
        </template>
      </generalTable>
      <addDialog :id="checkId" ref="insert" :submit="search" :timeStamp="timeStamp" @submit='getHospitalList()' />
    </el-main>
  </el-container>
</template>
<script>
import { getHospitalList, deleteData,changeIsCharge,changeSignCharge } from '@/api/hospitalManagement'
import {getKfType} from '@/api/basicSetting'
import searchForm from '@/components/SearchForm'
import generalTable from '@/components/Table'
import addDialog from '@/views/basicSetting/hospitalManagement/addDialog'

export default {
    name: 'Test',
    components: {
        searchForm,
        generalTable,
        addDialog
    },
    data() {
        return {
            tableData: [
            ],
            searchOptions: [
                {
                    type: 'input',
                    placeholder: '请输入...',
                    key: 'name',
                    label: '医院名称',
                    defaultVal: ''
                }
            ],
            tableConfig: {
                id: 'test',
                align: 'center', // 不必填 默认为center
                selection: 'single', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: true, // 不必填 分页 默认为false
                column: [
                    {
                    label: '医院名称', // 必填
                    prop: 'name' // 必填
                },
                {
                    label: '省', // 必填
                    prop: 'provinceName' // 必填
                },
                {
                    label: '市', // 必填
                    prop: 'cityName' // 必填
                },
                {
                    label: '区', // 必填
                    prop: 'areaName' // 必填
                },
                {
                    label: '医院级别', // 必填
                    prop: 'hospitalLevel' // 必填
                },
                {
                    label: '费用开关', // 必填
                    slot: 'isCharge' // 必填
                },
                {
                    label: '签名开关', // 必填
                    slot: 'signCharge' // 必填
                },
                {
                    slot: 'handle', // 不必填 单列插槽
                    label: '操作', // 必填
                    fixed: 'right', // 不必填 固定列  left right
                    width: '220' // 不必填
                }
                ]
            },
            paramObj: null, // 筛选项
            total: 0, // 总条数
            checkId: '', // 单条数据id 用于详情或编辑请求数据用
            timeStamp: '',
            backStatus: true, //返回按钮的隐藏显示
        }
    },
    created() {
        this.getHospitalList();
        this.getOffice();
    },
    mounted: function() {},
    methods: {
        search(data) {
            this.paramObj = data
            this.$refs.mainTable.handleCurrentChange(1)
        },
        getHospitalList() {
            // 解决未挂载完组件时调用子组件方法报错问题
            this.$nextTick(() => {
                const param = this.getParam()
                getHospitalList(param).then((res, timeStamp) => {
                    this.tableData = res.res.records;
                    this.timeStamp = timeStamp;
                    this.total = res.res.total;
                })
            })
        },
        changeIsCharge(checkArray){
            const parm = {
                id:checkArray.data.row.id,
                isCharge:checkArray.data.row.isCharge
            }
            changeIsCharge(parm,this.timeStamp).then(({res}) =>{
                this.getHospitalList()
            })
            // .catch((err) => {
            //         if (err) {
            //             this.tableData[scope.data.$index].status =
            //                 event === 0 ? '1' : '0'
            //         }
            //     })

        },
        changeSignCharge(checkArray){
            const parm = {
                id:checkArray.data.row.id,
                signCharge:checkArray.data.row.signCharge
            }
            changeSignCharge(parm,this.timeStamp).then(({res}) =>{
                this.getHospitalList()
            })
            // .catch((err) => {
            //         if (err) {
            //             this.tableData[scope.data.$index].status =
            //                 event === 0 ? '1' : '0'
            //         }
            //     })

        },
        objectSpanMethod(param, cb) {
            cb([])
        },
        toInsert() {
            this.checkId = ''
            this.$refs.insert.updateVisible(true)
        },
        toDetail(scope) {
            this.$router.push({
                path:"doctorManagement/detailDialog",
                query:{id:scope.data.row.id}
            })
        },
        toEdit(scope) {
            this.checkId = scope.data.row.id
            this.$refs.insert.updateVisible(true)
        },
        toDel(scope) {
            const { $delMsg, $delTitle, $messageBoxConfig, $handleSuccess } =
                this.commonConfig;
            this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
            const param = { id: scope.data.row.id };
            deleteData(param, this.timeStamp).then(({ res }) => {
                this.$message({ ...$handleSuccess });
                this.getHospitalList();
                });
            });
        },
        //获取医院等级
        getOffice() {
            getKfType({ types: "6" }).then(({ res }) => {
                this.officeList = res.map((item) => {
                    return {
                        value: item.code,
                        label: item.name,
                    };
                });
            });
        },
        onClickRow(checkArray) {
            console.log('testststst')
            console.log('checkArray', checkArray)
        },
        getParam() {
            // 获取参数
            const paramObj = this.$refs.mainSearch.packageData()
            this.paramObj = paramObj
            const page = this.$refs.mainTable.getPage()
            const param = Object.assign(page, paramObj)
            return param
        },
        
    }
}
</script>

<style lang='scss'>
@import "~@/styles/variables.scss";

</style>
