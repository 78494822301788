<template>
  <el-dialog width="60%" title="问题回复" :visible.sync="visible" :close-on-click-modal="false"
    :close-on-press-escape="false" :show-close="false" custom-class="basicDialog" append-to-body>
    <!-- <el-descriptions class="margin-top" title="反馈内容" :column="4" >
      <el-descriptions-item label="内容">{{detail.content}}</el-descriptions-item>
    </el-descriptions> -->
    <el-form ref="insertForm" v-model="detail" label-position="right" label-width="120px" class="formBox">
      <el-row>
        <el-col :span="10">
          <el-form-item label="问题编号：">
            <el-input v-model="detail.id" :disabled="true"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="反馈时间：">
            <el-input v-model="detail.serviceTime" :disabled="true"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10">
          <el-form-item label="反馈用户：">
            <el-input v-model="detail.username" :disabled="true"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="问题类型：">
            <el-input v-model="detail.problemType" :disabled="true"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="20">
          <el-form-item label="问题描述：">
            <el-input type="textarea" v-model="detail.content" resize='none' rows="4" :disabled="true"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="20">
          <el-form-item label="凭证截图：">
            <div v-for="(url, index) in detail.photoList" :key="index" @click="previewImage(index)" style="display: inline-block;cursor: pointer;">
            <el-image 
            ref="previewRef" 
            style="width: 100px; height: 100px; margin-right: 20px;"  :src="commonConfig.$filePublicUrl + url"
              :preview-src-list="[commonConfig.$filePublicUrl + url]">
            </el-image>
            </div>
            <label v-if="detail.photoList.length == 0">暂无凭证截图</label>
          </el-form-item>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <el-row>
        <el-col :span="20">
          <el-form-item label="问题回复：">
            <el-input type="textarea" v-model="detail.serviceContent" resize='none' rows="4" maxlength="100"
              show-word-limit :disabled="detail.problemStatus"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <!-- <el-row :gutter="10" type="flex" justify="start">
      <el-col :sm="24" :md="24" :lg="24" :xl="24">
        <el-form-item label="反馈图片" prop="photoList">
          <div class="imgList">
            <div v-for="(url, index) in detail.photoList" class="defalutImg " :key="url+index">
              <el-image style="width: 150px; height: 150px" :src="commonConfig.$filePublicUrl + url" fit="contain">
              </el-image>
            </div>
          </div>
        </el-form-item>
      </el-col>
    </el-row> -->
    </el-form>
    <template slot="footer" class="dialog-footer">
      <el-button type="primary" @click="determineClick(false)">确 定</el-button>
      <el-button @click="updateVisible(false)">取 消</el-button>
    </template>
  </el-dialog>
 
</template>
<script>
import { getDetail, updateStatus } from "@/api/feedbackManage";
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  components: {
  },
  data() {
    return {
      timeStamp: '',
      visible: false,
      detail: {
        photoList: [],
        photoUrl: "",
        previewList: [],
      }, // 详情数据
      tableData: [],
      tableConfig: {
        align: "center", // 不必填 默认为center
        selection: "normal", // 不必填 多选: multiple  单选: single  默认为 normal
        index: true, // 不必填 序号
        pagination: false, // 不必填 分页 默认为false
        column: [
          {
            label: "标题一", // 必填
            prop: "first", // 必填
            formatter: (row, column, cellValue, index) => {
              return row.first + index;
            },
          },
          {
            label: "标题二", // 必填
            prop: "second", // 必填
          },
          {
            label: "标题三", // 必填
            prop: "third", // 必填
          },
          {
            label: "标题四", // 必填
            prop: "fourth", // 必填
          },
        ],
      },
      total: 0, // 总条数
    };
  },
  watch: {
    visible(newVal, oldVal) {
      if (newVal && this.id) this.getDetail();
    },
  },
  created() { },
  mounted() { },
  methods: {
    getDetail() {
      const param = {};
      if (this.id) {
        param.id = this.id;
      }
      getDetail(param).then((data) => {
        console.log(data, 'data')
        // 0: "产品建议", 1: "程序错误", 9: "其他",
        let res = data.res
        this.timeStamp = data.timeStamp
        this.detail = res;
        this.detail.serviceTime = res.serviceTime ? this.moment(res.serviceTime).format("YYYY-MM-DD HH:mm") : ''
        this.detail.problemType = res.type == 0 ? '产品建议' : res.type == 1 ? '程序错误' : res.type == 9 ? '其他' : ''
        this.detail.photoList = res.picture.split(",").filter(function(item) {
          // 返回真实值（去掉空字符串）
          return item;
        });
        this.detail.previewList = this.detail.photoList.map(item => {
          return this.commonConfig.$filePublicUrl + item
        })
        this.detail.problemStatus = res.status == 1 ? false : true
      });
    },
    uploadPublicFile(file) {
      return new Promise((resolve) => {
        uploadPublicFile(file)
          .then((success) => {
            console.log("upload success------", success);
            this.photoList.push(success);
            resolve(success);
          })
          .catch((handleError) => {
            console.log("handleError====", handleError);
          });

      });
    },
    previewImage (index) {
        if (index <= this.$refs.previewRef.length) {
          this.$refs.previewRef[index].clickHandler();
        }
      },
    setLogoClick() {
      this.$refs.logoInput.click();
    },
    updateVisible(flag) {
      this.visible = flag;
    },
    determineClick(flag) {
      let data = {
        id: this.detail.id,
        serviceContent: this.detail.serviceContent
      }
      updateStatus(data, this.timeStamp).then(res => {
        if (res.res == true) {
          this.visible = flag;
          this.$emit('sonFunc')
        }
      })
    },
  },
};
</script>
<style scoped lang="scss">
@import "~@/styles/variables.scss";

.imgList {
  display: flex;
  column-gap: 10px;
  flex-wrap: wrap;
  row-gap: 10px;
  margin: 10px 0;
}

.defalutImg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  border: 1px solid #333;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
}
</style>
