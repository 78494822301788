/*
 * @Description:
 * @Author: yzw
 * @Date: 2021-09-22 14:24:59
 * @LastEditTime: 2021-10-13 08:59:52
 * @LastEditors: yzw
 * @Reference:
 */

/**
 * 页面: 测试
 * pageName: test
 **/
import request from '@/utils/request'

/**
 * @description: 获取测试列表数据
 */
export function getList(data) {
    return request({
        url: '',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 新增测试数据
 */
export function insertData(data) {
    return request({
        url: '',
        method: 'POST',
        data,
        showLoading: true
    })
}

/**
 * @description: 修改测试数据
 */
export function editData(data) {
    return request({
        url: '',
        method: 'POST',
        data,
        showLoading: true
    })
}

/**
 * @description: 获取测试详情数据
 */
export function getDetail(data) {
    return request({
        url: '',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 删除测试数据
 */
    export function deleteData(data) {
    return request({
        url: '',
        method: 'POST',
        data,
        showLoading: true
    })
}

