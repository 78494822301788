/*
 * @Description:
 * @Author: yzw
 * @Date: 2020-06-23 06:15:04
 * @LastEditTime: 2023-06-13 15:52:39
 * @LastEditors: Please set LastEditors
 * @Reference:
 */
/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * @description 验证是否为手机号
 * @param {Number} path
 * @returns {Boolean}
 */
export function validatorPhone(rule, value, callback) {
  console.log(value)
  const exp = /^1[0-9]{10}$/
  if (value === '' || value === null || value === undefined) {
    callback(new Error('请输入手机号'))
  } else {
    if (exp.test(value)) {
      callback()
    } else {
      callback(new Error('请输入正确的手机号'))
    }
  }
}

/**
 * @description 验证身份证是否合法
 * @param {Number} path
 * @returns {Boolean}
 */
export function validID(rule,value,callback) {
  // 身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，最后一位是校验位，可能为数字或字符X
  let reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
  if (!value) {
    callback()
  } else if (reg.test(value)) {
    callback()
  } else {
    callback(new Error('身份证号码不正确'))
  }
}

/**
 * @description 验证银行卡是否合法
 * @param {Number} path
 * @returns {Boolean}
 */
export function validBankId(rule, value, callback) {
  const strBin = '10,18,30,35,37,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,58,60,62,65,68,69,84,87,88,94,95,98,99'
  if (!value) {
    callback()
  } else if (value.trim().length < 12 || value.trim().length > 19) {
    callback(new Error('银行卡号长度必须在12到19之间'))
  } else if (strBin.indexOf(value.substring(0, 2)) === -1) {
    callback(new Error('银行卡号开头6位不符合规范'))
  } else {
    callback()
  }
};


/**
 * @description 验证是否为邮箱
 * @param {Number} path
 * @returns {Boolean}
 */
export function validatorMail(rule, value, callback) {
  console.log(value)
  const exp = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/
  if (value === '' || value === null || value === undefined) {
    callback() // 暂时邮箱校验可以为空
  } else {
    if (exp.test(value)) {
      callback()
    } else {
      callback(new Error('请输入正确的邮箱'))
    }
  }
}
