import { render, staticRenderFns } from "./addDialogConfig.vue?vue&type=template&id=89b24f5e&scoped=true"
import script from "./addDialogConfig.vue?vue&type=script&lang=js"
export * from "./addDialogConfig.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "89b24f5e",
  null
  
)

export default component.exports