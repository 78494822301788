
<!--
* @Description: 商品管理
* @Reference:
-->

<template>
<el-container>
    <el-header height="auto">
        <searchForm ref="mainSearch" :searchoptions="searchOptions" @search="search" :backStatus="backStatus"/>
    </el-header>
    <el-main class="basic-main">
        <el-row class="table-btn-group">
            <el-button type="primary"  @click="toInsert()">新增</el-button>
            <el-button type="primary" @click="toDelete()">批量删除</el-button>
            <el-button type="primary" @click="toState()">批量上架</el-button>
            <el-button type="primary" @click="toShelf()">批量下架</el-button>
        </el-row>
        <generalTable ref="mainTable" :table-data="tableData" :config="tableConfig" :total="total" @updatePage="getList">
            <template slot="handle" slot-scope="scope">
            <el-button type="text" @click="toDetail(scope)">详情</el-button>
            <el-button type="text"  @click="toEdit(scope)">编辑</el-button>
            <el-button type="text" class="delBtn" @click="toDel(scope)">删除</el-button>
            </template>
            <template slot="goodsState" slot-scope="scope">
                <el-switch
                v-if="goodsState"
                    v-model="scope.data.row.goodsState"
                    active-value="1"
                    inactive-value="2"
                    @change="toGoodsState(scope.data.row)"
                />
            </template>
            <template slot="photoUrl" slot-scope="scope">
                <el-image
                    style="width: 50px; height: 50px; margin-top: 10px"
                    :src="
                        commonConfig.$filePublicUrl + scope.data.row.photoUrl
                    "
                    :preview-src-list="[commonConfig.$filePublicUrl + scope.data.row.photoUrl]"
                >
                </el-image>
            </template>
        </generalTable>
        <detailDialog :id="checkId" ref="detail" />
        <addDialog :id="checkId" :timeStamp="timeStamp" ref="insert" @submit="search" />
    </el-main>
</el-container>
</template>

<script>
import { getList, deleteData,toState,toShelf,toGoodsState} from '@/api/products'
import searchForm from '@/components/SearchForm'
import generalTable from '@/components/Table'
import addDialog from '@/views/order/addDialog'
import detailDialog from '@/views/order/detailDialog'
import { getDict } from '@/api/common'

export default {
    name: 'order',
    components: {
        searchForm,
        generalTable,
        detailDialog,
        addDialog
    },
    data() {
        return {
            tableData: [],
            searchOptions: [
                {
                    type: 'input',
                    placeholder: '请输入',
                    key: 'goodsId',
                    label: '商品ID',
                    defaultVal: ''
                },
                {
                    type: 'input',
                    placeholder: '请输入',
                    key: 'name',
                    label: '商品名称',
                    defaultVal: ''
                },
                {
                    type: "select",
                    placeholder: "请选择",
                    key: "goodsType",
                    filterable: true,
                    label: "商品分类",
                    options: [],
                    defaultVal: [],
                  },
                  {
                    type: "select",
                    placeholder: "请选择",
                    key: "goodsState",
                    filterable: true,
                    label: "商品状态",
                    options: [],
                    defaultVal: [],
                  },
                  {
                    type: 'input',
                    placeholder: '请输入最低价',
                    key: 'lowestPrice',
                    label: '商品最低价',
                    defaultVal: ''
                },
                {
                    type: 'input',
                    placeholder: '请输入最高价',
                    label: '商品最高价',
                    key: 'highestPrice',
                    defaultVal: ''
                },
            ],
            tableConfig: {
                id: 'products',
                align: 'center', // 不必填 默认为center
                selection: 'multiple', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: true, // 不必填 分页 默认为false
                column: [
                {
                    label: '商品ID', // 必填
                    prop: 'goodsId' // 必填
                },
                {
                    label: '商品名称', // 必填
                    prop: 'name' // 必填
                },
                 {
                    label: '商品分类', // 必填
                    prop: 'goodsTypeLabel' // 必填
                },
                {
                    label: '商品图片', // 必填
                    slot: "photoUrl", // 必填
                    width: "100",
                },
                  {
                    label: '商品价格', // 必填
                    prop: 'actualPrice' // 必填
                },
               
                 {
                    label: '商品库存', // 必填
                    prop: 'inventory', // 必填
                     width: '120' // 不必填
                },
                {
                    label: '商品状态（上架/下架）', // 必填
                    slot: 'goodsState', // 必填
                     
                },
                {
                    label: '备注', // 必填
                    prop: 'remark', // 必填
                     width: '120' // 不必填
                },
                {

                    slot: 'handle', // 不必填 单列插槽
                    label: '操作', // 必填
                    fixed: 'right', // 不必填 固定列  left right
                    width: '220' // 不必填
                }
                ]
            },
            paramObj: null, // 筛选项
            total: 0, // 总条数 
            goodsState:'1',
            checkId: '', // 单条数据id 用于详情或编辑请求数据用
            backStatus: true, //返回按钮的隐藏显示
            timeStamp: '', //时间戳 用于修改数据或删除数据
          
            ids:[]
        }
    },
    created() {
        this.getList()
        this.getDict();  
        this.getDictType();  
        
        
    },
    mounted: function() {},
    methods: {
        search(data = {}) {
            Object.assign(this.paramObj, data)
            this.$refs.mainTable.handleCurrentChange(1)
        },
        getList() {
            this.$nextTick(() => {
                const param = this.getParam()
                getList(param).then(({ res, timeStamp }) => {
                    
                    this.tableData = res.records
                    this.timeStamp = timeStamp
                    this.total=parseInt(res.total)
                })
            })
        },
        toInsert() {
            this.checkId = ''
            this.$refs.insert.updateVisible(true)
        },
        // toInsert (scope) {
        //     const query = Object.assign({},{
        //       orderId: '',
              
        //     })
        //     console.log("ssssssssssss",query)
        //     this.$router.push({ path: '/products/addDialog',})
            
        // },
        toDetail(scope) {
            this.checkId = scope.data.row.id
            this.$refs.detail.updateVisible(true)
        },
        toEdit(scope) {
            this.checkId = scope.data.row.id
        
            this.$refs.insert.updateVisible(true)
        },
        toDel(scope) {
            const { $delMsg, $delTitle, $messageBoxConfig, $handleSuccess } = this.commonConfig
            this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
               var ids =[scope.data.row.id]
                deleteData(ids, this.timeStamp).then(res => {
                    this.$message({...this.commonConfig.$handleSuccess})
                    this.getList()
                })
            })
        },
         /**
     　* 批量删除
     　*
       * @Param  null
       * @Return null
     　* @Author wangz
     　* @Date 2021/11/11 9:20
     　*/
        toDelete() {
             var checkObj = this.$refs.mainTable.getChecked();
            const {$delMsg, $delTitle, $messageBoxConfig , $handleSuccess} = this.commonConfig
            this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
                var ids =checkObj.map((element) => {
                    return element.id;
                });
                deleteData(ids, this.timeStamp).then(res => {
                    this.$message({...this.commonConfig.$handleSuccess});
                    this.getList();
                });
            });
           
        },
        
         /**
     　* 批量上架
     　*
       * @Param  null
       * @Return null
     　* @Author wangz
     　* @Date 2021/11/11 9:20
     　*/
     toState() {
             var checkObj = this.$refs.mainTable.getChecked();
            const {$delMsg, $delTitle, $messageBoxConfig , $handleSuccess} = this.commonConfig
            this.$confirm("此操作将批量设置上架,是否继续?", $delTitle, $messageBoxConfig).then(() => {
                var ids =checkObj.map((element) => {
                    return element.id;
                });
                toState(ids, this.timeStamp).then(res => {
                    this.$message({...this.commonConfig.$handleSuccess});
                    this.getList();
                });
            });
        },
           
              /**
     　* 批量下架
     　*
       * @Param  null
       * @Return null
     　* @Author wangz
     　* @Date 2021/11/11 9:20
     　*/
     toShelf() {
             var checkObj = this.$refs.mainTable.getChecked();
            const {$delMsg, $delTitle, $messageBoxConfig , $handleSuccess} = this.commonConfig
            this.$confirm("此操作将批量设置下架,是否继续?", $delTitle, $messageBoxConfig).then(() => {
                var ids =checkObj.map((element) => {
                    return element.id;
                });
                toShelf(ids, this.timeStamp).then(res => {
                    this.$message({...this.commonConfig.$handleSuccess});
                    this.getList();
                });
            });
           
        },
      
        getParam() {
            const paramObj = this.$refs.mainSearch.packageData()
            const page = this.$refs.mainTable.getPage()
            const param = Object.assign({}, this.paramObj, page, paramObj)
            this.paramObj = param
            return param
        },
        getDictType(){
            getDict({ type: 'goods_type' }).then(({res}) => {
                this.searchOptions[2].options = res.goods_type;
            });
         },
         getDict(){
            getDict({ type: 'goods_state' }).then(({res}) => {
                this.searchOptions[3].options = res.goods_state;
            });
         },
         toGoodsState(item) {       //商品状态 id:角色ID status:上架/下架状态
          const parm = {
            id: item.id,
            goodsState: item.goodsState
        }
        toGoodsState(parm, this.timeStamp).then(({ res }) => {
                // 成功消息提示，需要使用扩展运算符的形式，否则容易造成某些成功提示语不消失一直显示的问题
                this.$message({...this.commonConfig.$handleSuccess})
                this.getList()
            });
        },
        
    }
}
</script>

<style lang='scss' scoped>
@import "~@/styles/variables.scss";
</style>

        