/*
 * @Author: magy “……”
 * @Date: 2022-05-12 16:57:17
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-07-03 14:21:47
 * @FilePath: /recovery_home_management_dev/src/api/embodyAndRefund.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request'

/**
 * @description: 退款列表
 */
export function getRefundList(data) {
  return request({
    url: 'admin/finance/getRefundList',
    method: 'GET',
    params: data,
    showLoading: true
  })
}

/**
 * @description: 提现列表
 */
 export function getEmbodyList(data) {
  return request({
      url: 'admin/finance/getWithdrawList',
      method: 'GET',
      params: data,
      showLoading: true
  })
}

/**
 * @description: 修改提现状态
 */
export function changeEmbodyState(data) {
  return request({
      url: 'admin/finance/edit',
      method: 'POST',
      data,
      showLoading: true
  })
}

/**
 * @description: 修改提现状态
 */
export function auditData(data) {
  return request({
      url: 'admin/finance/audit',
      method: 'POST',
      data,
      showLoading: true
  })
}


/**
 * @description: 医院管理列表-查询参数 不分页
 */
export function getAllHospitalList(data) {
  return request({
    url: 'admin/kfHospital/downList',
    method: 'GET',
    params: data,
    showLoading: true
  });
}
