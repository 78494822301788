<template>
  <div class="header">
    <div class="left">
      <div v-if="showType === 'menu'" class="menu" @click="toggleMenu">
        <Icon :file="menuPNG"></Icon>
        <div class="menu-guide">{{ TUITranslateService.t("使用指引") }}</div>
      </div>
      <div v-else-if="showType === 'logo'" class="logo">
          <img class="logo-img" src="../../assets/im/image/txc-logo.svg" alt="" />
          <div class="logo-name label-tencent">{{ TUITranslateService.t("腾讯云") }}</div>
          <div class="logo-name label-im">{{ TUITranslateService.t("即时通信IM") }}</div>
      </div>
    </div>
    <div class="right">
      <el-dropdown @command="changeLanguage">
        <div class="dropdown">
          <Icon :file="globalPNG"></Icon>
          <div class="dropdown-label">{{ localeLabelMap[locale] }}</div>
          <Icon :file="arrowDownPNG" width="10px" height="5px"></Icon>
        </div>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item command="zh" class="language-item">简体中文</el-dropdown-item>
            <el-dropdown-item command="en" class="language-item">English</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>

<script lang="ts">
import { withDefaults, defineProps, defineEmits, ref } from "../../TUIKit/adapter-vue";
import { TUITranslateService } from "@tencentcloud/chat-uikit-engine";
import Icon from "../../TUIKit/components/common/Icon.vue";
import menuPNG from "../../assets/im/icon/menu.png";
import globalPNG from "../../assets/im/icon/global.png";
import arrowDownPNG from "../../assets/im/icon/arrow-down.png";
const __sfc_main = {};
__sfc_main.props = {
  showType: {
    key: "showType",
    required: false,
    type: String,
    default: "logo"
  },
  defaultLanguage: {
    key: "defaultLanguage",
    required: false,
    type: String,
    default: "zh"
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const localeLabelMap: {
    [propsName: string]: string;
  } = {
    zh: "简体中文",
    en: "English"
  };
  const emits = __ctx.emit;
  const locale = ref<string>(props.defaultLanguage);
  function toggleMenu() {
    emits("toggleMenu");
  }
  function changeLanguage(value: string) {
    TUITranslateService.changeLanguage(value).then(() => {
      locale.value = value;
      emits("changeLanguage", locale.value);
    });
  }
  return {
    TUITranslateService,
    menuPNG,
    globalPNG,
    arrowDownPNG,
    localeLabelMap,
    locale,
    toggleMenu,
    changeLanguage
  };
};
__sfc_main.components = Object.assign({
  Icon
}, __sfc_main.components);
export default __sfc_main;
</script>

<style scoped lang="scss">
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  box-sizing: border-box;
  padding: 20px 34px;
  user-select: none;
  .left {
    .menu,
    .logo {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }
    .menu {
      .menu-guide {
        margin-left: 15px;
      }
    }
    .logo {
      .logo-img {
        width: 30px;
        margin-right: 7px;
      }
      .logo-name {
        font-family: PingFangSC-Regular;
        font-size: 18px;
        font-weight: 500;
        display: flex;
      }
      .label-tencent {
        padding-right: 15px;
        border-right: 1px solid #ddd;
      }
      .label-im {
        padding-left: 15px;
      }
    }
  }
  .right {
    .dropdown {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      .dropdown-label {
        padding: 0 8px;
      }
    }
  }
}
</style>
