/*
 * @Author: your name
 * @Date: 2022-04-17 09:42:52
 * @LastEditTime: 2022-04-18 09:22:48
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \recovery_home_management\src\api\bannerManage.js
 */

/**
 * 页面: 轮播图管理
 * pageName: bannerManage
 **/
import request from '@/utils/request'

/**
 * @description: 获取轮播图管理列表数据
 */
export function getList(data) {
    return request({
        url: 'admin/kfBanner/list',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 新增轮播图管理数据
 */
export function insertData(data) {
    return request({
        url: 'admin/kfBanner/add',
        method: 'POST',
        data,
        showLoading: true
    })
}

/**
 * @description: 修改轮播图管理数据
 */
export function editData(data, timeStamp) {
    return request({
        url: 'admin/kfBanner/update',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}
            

/**
 * @description: 获取轮播图管理详情数据
 */
export function getDetail(data) {
    return request({
        url: 'admin/kfBanner/info',
        method: 'GET',
        params: data,
        showLoading: true
    })
}
            
/**
 * @description: 删除轮播图管理数据
 */
    export function deleteData(data, timeStamp) {
    return request({
        url: 'admin/kfBanner/delete',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}

/**
 * @description: 修改轮播图管理数据
 */
 export function bannerStatus(data, timeStamp) {
    return request({
        url: 'admin/kfBanner/changeStatus',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}
        