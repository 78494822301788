<!--
 * @Description:
 * @Author: yzw
 * @Date: 2020-06-23 06:15:04
 * @LastEditTime: 2022-02-10 09:08:57
 * @LastEditors: yzw
 * @Reference:
-->
<template>
  <el-breadcrumb class="app-breadcrumb" separator="/">
    <transition-group name="breadcrumb">
      <el-breadcrumb-item v-for="(item,index) in levelList" :key="item.path">
        <span v-if="item.redirect==='noRedirect'||index==levelList.length-1" class="no-redirect">{{ item.meta.title }}</span>
        <a v-else @click.prevent="handleLink(item)">{{ item.meta.title }}</a>
      </el-breadcrumb-item>
    </transition-group>
  </el-breadcrumb>
</template>

<script>
import * as pathToRegexp from 'path-to-regexp'

export default {
  data() {
    return {
      levelList: null,
    }
  },
  watch: {
    $route() {
      this.getBreadcrumb()
    }
  },
  created() {
    this.getBreadcrumb()
  },
  methods: {
    getBreadcrumb() {
      // only show routes with meta.title
      let matched = null
      try {
        matched = this.$route.matched.filter(item => item.meta && item.meta.title)
      } catch (error) {
        return false
      }
      if (matched.length == 0) {
        this.levelList = [{
          path:'customerService',
          redirect:'noRedirect',
          meta:{
            breadcrumb: true,
            icon : "home",
            keepAlive: true,
            title: "康复助理",
          }
        }];
        console.table('this.levelList====',this.levelList);
        return;
      }
      const first = matched[0]
      const second = matched[1]
      if (first.name === 'Home' || first.name === 'home') {
        matched = [{ path: '/home', meta: { title: '工作台' }}].concat(matched)
      }
      if (second && (first.name === second.name)) {
        matched.shift()
      }
      this.levelList = matched.filter(item => item.meta && item.meta.title && item.meta.breadcrumb !== false)
      console.table('this.levelList====',this.levelList);
    },
    pathCompile(path) {
      const { params } = this.$route
      var toPath = pathToRegexp.compile(path)
      return toPath(params)
    },
    handleLink(item) {
      const { redirect, path } = item
      if (redirect) {
        this.$router.push(redirect)
        return
      }
      this.$router.push(this.pathCompile(path))
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.app-breadcrumb.el-breadcrumb {
  display: inline-block;
  font-size: 14px;
  line-height: $hamBarHeight;
  margin-left: 60px;

  .no-redirect {
    color: $text;
    cursor: text;
  }
  span{
    font-weight: 600;
  }
}
</style>
