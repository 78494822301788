
/**
 * 页面: 内容管理
 * pageName: feeItems
 **/
 import request from '@/utils/request'

 /**
  * @description: 获取内容列表数据
  */
 export function getList(data) {
     return request({
         url: 'admin/kfTopics/list',
         method: 'GET',
         params: data,
         showLoading: true
     })
 }
  /**
  * @description: 获取内容栏目列表数据
  */
   export function getColumnList(data) {
    return request({
        url: 'admin/kfTopicsType/getColumnList',
        method: 'GET',
        params: data,
        showLoading: true
    })
}
  /**
  * @description: 获取内容分类列表数据
  */
   export function getClassifyList(data) {
    return request({
       // url: 'admin/kfTopicsType/getClassifyList',
       url: 'admin/kfTopics/getClassifyList',
        method: 'GET',
        params: data,
        showLoading: true
    })
}
 
 /**
  * @description: 获取内容状态上架/下架
  */
  export function toContentState(data) {
     return request({
         url: 'admin/kfTopics/toContentState',
         method: 'POST',
         data,
         showLoading: true
     })
 }
 
 /**
  * @description: 新增内容数据
  */
 export function insertData(data) {
     return request({
         url: 'admin/kfTopics/add',
         method: 'POST',
         data,
         showLoading: true
     })
 }
 
 /**
  * @description: 修改内容数据
  */
 export function editData(data, timeStamp) {
     return request({
         url: 'admin/kfTopics/update',
         method: 'POST',
         data,
         showLoading: true,
         timeStamp
     })
 }
             
 
 /**
  * @description: 获取内容详情数据
  */
 export function getDetail(data) {
     return request({
         url: 'admin/kfTopics/info',
         method: 'GET',
         params: data,
         showLoading: true
     })
 }
             
 /**
  * @description: 批量删除内容数据
  */
     export function deleteData(data, timeStamp) {
     return request({
         url: 'admin/kfTopics/delete',
         method: 'POST',
         data,
         showLoading: true,
         timeStamp
     })
 }
 /**
  * @description: 批量上架商品
  */
  export function toState(data, timeStamp) {
     return request({
         url: 'admin/kfTopics/toState',
         method: 'POST',
         data,
         showLoading: true,
         timeStamp
     })
 }
 /**
  * @description: 批量下架内容
  */
  export function toShelf(data, timeStamp) {
     return request({
         url: 'admin/kfTopics/toShelf',
         method: 'POST',
         data,
         showLoading: true,
         timeStamp
     })
 }

         
 