<!--
 * @Author: your name
 * @Date: 2022-03-21 11:50:58
 * @LastEditTime: 2022-04-24 13:03:14
 * @LastEditors: Please set LastEditors
 * @Description: 评估视频详情
 * @FilePath: /recovery_home_management/src/views/assessment/assessmentVideo/detailDialog.vue
-->

<template>
    <el-dialog
        width="60%"
        title="评估视频设备详情"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        custom-class="basicDialog"
        append-to-body
    >
        <el-descriptions class="margin-top" title="基本信息" :column="3">
            <el-descriptions-item label="名称">{{
                detail.name
            }}</el-descriptions-item>
            <el-descriptions-item label="分类">{{
                detail.assessmentTypeName
            }}</el-descriptions-item>
            <el-descriptions-item label="排序">{{
                detail.sort
            }}</el-descriptions-item>
            <el-descriptions-item label="视频" :span="4">
                <video
                    :src="commonConfig.$filePublicUrl + detail.url"
                    controls
                    width="100%"
                    height="350px"
                ></video>
            </el-descriptions-item>
            <el-descriptions-item label="描述" :span="4">{{
                detail.describe
            }}</el-descriptions-item>
        </el-descriptions>
        <template slot="footer" class="dialog-footer">
            <el-button @click="updateVisible(false)">取 消</el-button>
        </template>
    </el-dialog>
</template>
<script>
import { getDetail } from "@/api/assessmentVideo";
export default {
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            visible: false,
            detail: [], // 详情数据
        };
    },
    watch: {
        visible(newVal, oldVal) {
            if (newVal && this.id) this.getDetail();
        },
    },
    created() {},
    mounted() {},
    methods: {
        getDetail() {
            getDetail({ id: this.id }).then(({ res }) => {
                this.detail = res;
            });
        },
        updateVisible(flag) {
            this.visible = flag;
        },
    },
};
</script>
<style scoped lang='scss'>
@import "~@/styles/variables.scss";

.imgBox {
    width: 150px;
    height: 150px;
    object-fit: contain;
    border: 1px solid $tableColor;
}
</style>
