<!--
 * @Author: your name
 * @Date: 2022-04-01 12:46:15
 * @LastEditTime: 2022-04-01 13:03:25
 * @LastEditors: Please set LastEditors
 * @Description: 评估视频 绑定
 * @FilePath: /recovery_home_management/src/views/assessment/assessmentMethods/addVideoDialog.vue
-->

<template>
    <el-dialog
        width="70%"
        :title="id === '' ? '新增视频' : '修改视频方案'"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        append-to-body
    >
        <el-header height="auto">
            <searchForm
                ref="mainSearch"
                :searchoptions="searchOptions"
                @search="search"
                :backStatus="backStatus"
            />
        </el-header>
        <generalTable
            ref="mainTable"
            :table-data="tableData"
            :config="tableConfig"
            :total="total"
            @updatePage="getList"
        >
            <template slot="photo" slot-scope="scope">
                <el-image
                    style="width: 50px; height: 50px; margin-top: 10px"
                    :src="commonConfig.$filePublicUrl + scope.data.row.photo"
                    :preview-src-list="[
                        commonConfig.$filePublicUrl + scope.data.row.photo,
                    ]"
                >
                </el-image>
            </template>
            <template slot="handle" slot-scope="scope">
                <el-button type="text" @click="toDetail(scope)">查看</el-button>
                <el-button type="text" @click="toEdit(scope)">编辑</el-button>
                <el-button type="text" class="delBtn" @click="toDel(scope)"
                    >删除</el-button
                >
            </template>
        </generalTable>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submit()">确 定</el-button>
            <el-button @click="updateVisible(false)">取 消</el-button>
        </span>
    </el-dialog>
</template>


<script>
import { getList } from "@/api/assessmentVideo";
import generalTable from "@/components/Table";
import searchForm from "@/components/SearchForm";

export default {
    props: {
        id: {
            type: String,
        },
        classifyArray: {
            required: true,
            type: Array,
        },
        identifierArray: {
            required: true,
            type: Array,
        },
    },
    components: {
        searchForm,
        generalTable,
    },
    watch: {
        visible(newVal, oldVal) {
            if (newVal === true) {
                //...todo
            }
        },
    },
    data() {
        return {
            tableData: [],
            searchOptions: [
                {
                    type: "input",
                    placeholder: "请输入...",
                    key: "name",
                    label: "名称",
                    defaultVal: "",
                },
                {
                    type: "select",
                    placeholder: "请选择",
                    key: "identifier",
                    filterable: true,
                    label: "类型",
                    options: [],
                    defaultVal: [],
                },
                {
                    type: "cascader",
                    placeholder: "请选择",
                    key: "guidanceTypeName",
                    filterable: true,
                    multiple: true,
                    label: "分类",
                    options: [],
                    defaultVal: [],
                    props: {
                        checkStrictly: true,
                        emitPath: false,
                        label: "officesName",
                        value: "id",
                    },
                },
            ],
            tableConfig: {
                id: "chooseVideo",
                align: "center", // 不必填 默认为center
                selection: "multiple", // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: true, // 不必填 分页 默认为false
                column: [
                    {
                        label: "名称", // 必填
                        prop: "name", // 必填
                    },
                    {
                        label: "类型", // 必填
                        prop: "identifier", // 必填
                        formatter: (row) => {
                            // 根据字典表展示对应项目的label值
                            const currentType = this.identifierArray.filter(
                                (type) => type.value === row.identifier
                            );
                            if (currentType.length) {
                                return currentType[0].label;
                            }
                        },
                        width: "100", // 不必填
                    },
                    {
                        label: "分类", // 必填
                        prop: "guidanceTypeName", // 必填
                    },
                    {
                        label: "描述", // 必填
                        prop: "describe", // 必填
                    },
                    {
                        label: "封面图片", // 必填
                        slot: "photo", // 必填
                        width: "100",
                    },
                    {
                        slot: "handle", // 不必填 单列插槽
                        label: "操作", // 必填
                        fixed: "right", // 不必填 固定列  left right
                        width: "180", // 不必填
                    },
                ],
            },
            paramObj: null, // 筛选项
            total: 0, // 总条数
            visible: false,
            backStatus: true, //返回按钮的隐藏显示
        };
    },
    created() {
        this.getList();
    },
    methods: {
        search(data) {
            Object.assign(this.paramObj, data);
            this.$refs.mainTable.handleCurrentChange(1);
        },
        getParam() {
            // setTimeout(() => {
            // const paramObj = this.$refs.mainSearch.packageData();
            // const page = this.$refs.mainTable.getPage();
            // const param = Object.assign({}, this.paramObj, page, paramObj);
            // this.paramObj = param;
            // return param;
            // }, 50);
        },
        /**
         * @description:
         * @param {*}
         * @return {*}
         */
        getList() {
            this.$nextTick(() => {
                // setTimeout(() => {
                const param = this.getParam();
                getList(param).then(({ res, timeStamp }) => {
                    const { total, records } = res;
                    this.tableData = records;
                    this.total = parseInt(total);
                    this.timeStamp = timeStamp;
                });
                // }, 200);
            });
        },
        submit() {
            // console.log('=====',this.$refs.mainTable.getChecked())
            if (this.$refs.mainTable.getChecked().length > 0) {
                this.updateVisible(false);
                this.$emit("submit", this.$refs.mainTable.getChecked());
            } else {
                this.$message("请选择视频");
            }
        },
        updateVisible(val) {
            this.visible = val;
            if (val === false) {
                // this.$refs.insertForm.resetFields();
            }
        },
    },
};
</script>