<template>
  <el-card class="basic-card">
    <el-form ref="insertForm" :model="form" label-position="right" label-width="130px" :rules="rules">
      <el-form-item label="方案名称" prop="schemeName">
        <el-input v-model="form.schemeName" placeholder="请输入..." clearable maxlength="200" />
      </el-form-item>
      <el-form-item label="副标题" prop="subTitle">
        <el-input type="textarea" v-model="form.subTitle" placeholder="请输入..." clearable maxlength="200" />
      </el-form-item>
      <el-form-item label="服务周期" prop="servicePeriod">
        <el-input type="number" v-model="form.servicePeriod" min="1" max="999" @change="periodChange"
          style="width: 7em;" />
        <span> 天</span>
      </el-form-item>
      <el-form-item label="适用病种" prop="diseaseIdList">
        <!-- <el-input v-model="form.diseaseIdList" placeholder="请输入..." clearable maxlength="200" /> -->
        <el-select v-model="form.diseaseIdList" :multiple-limit="10" multiple filterable clearable placeholder="请选择">
          <el-option v-for="op in diseaseList" :key="op.id" :label="op.name" :value="op.id" />
        </el-select>
      </el-form-item>
      
      <el-form-item label="方案标签" prop="diseaseTags">
        <!-- <el-row :gutter="8">
          <el-col :span="22"> -->
            <el-cascader 
              :options="options"
              ref="dieaseTagRef"
              :props="{ multiple: true, checkStrictly: true, label: 'tagName', value: 'tagValue', disabled: 'disable'}"
              v-model="selectTag"
              popper-class="addscheme-cascader-parent"
              @change="diseaseTagChange($event)"
              clearable>
            </el-cascader>
          <!-- </el-col> -->
          <!-- <el-col :span="8">
            <el-button type="primary" @click="addMultipleTags">添加</el-button>
          </el-col> -->
        <!-- </el-row> -->
        <!-- <el-row class="select-tags">
          <el-tag
            v-for="tag in diseaseSelectTags"
            :key="tag.tagValue"
            closable
            :disable-transitions="false"
            @close="handleClose(tag)">
            {{tag.tagName}}
          </el-tag>
        </el-row> -->
      </el-form-item>
      <!-- <el-form-item label="版权归属" prop="copyright">
        <el-input v-model="form.copyright" placeholder="请输入..." clearable maxlength="200" />
      </el-form-item> -->
      <el-form-item label="版权归属" prop="copyright"
          :style="form.sourceOwnerType == '0' ? 'flex: 0 0 100%;': ''">
          <el-radio-group v-model="form.copyright">
            <el-radio-button label="0">平台</el-radio-button>
            <el-radio-button label="1">机构</el-radio-button>
            <el-radio-button label="2">医生</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <template v-if="form.copyright == '1'">
          <el-form-item label="机构名称" prop="institutionIdList">
            <el-select v-model="form.institutionIdList" :filterable="true" :multiple-limit="10" multiple clearable
              placeholder="请选择">
              <el-option v-for="op in officeTree" :key="op.id" :label="op.name" :value="op.id" />
            </el-select>
          </el-form-item>
        </template>
        <template v-if="form.copyright == '2'">
          <el-form-item label="版权归属医生" prop="copyrightDoctorNameList">
            <div style="position: relative;">
              <el-input v-model="form.copyrightDoctorNameList" placeholder="请输入..." />
              <div style="position: absolute; inset: 0;" @click="openCopyrightDocDialog"></div>
            </div>
          </el-form-item>
        </template>
      <el-form-item label="服务医生" prop="doctorNameList">
        <div style="position: relative;">
          <el-input v-model="form.doctorNameList" placeholder="请输入..." />
          <div style="position: absolute; inset: 0;" @click="openDocDialog"></div>
        </div>
      </el-form-item>
      <el-form-item label="版权授权" prop="schemeAuth">
        <el-input v-model="form.schemeAuth" placeholder="请输入..." clearable maxlength="200" />
      </el-form-item>
      <el-form-item label="是否生成结案报告" prop="genFinReport">
        <el-switch v-model="form.genFinReport" :inactive-value="false" :active-value="true" inactive-text="否"
          active-text="是" />
      </el-form-item>
      <!-- <el-form-item label="添加标签" prop="tags">
        <el-row class="select-tags">
          <el-tag
            v-for="tag in diseaseSelectTags"
            :key="tag.tagValue"
            closable
            :disable-transitions="false"
            @close="handleClose(tag)">
            {{tag.tagName}}
          </el-tag>
          <el-button class="button-new-tag" size="small" @click="showInput">+ 添加标签</el-button>
        </el-row>  
      </el-form-item> -->
      <!-- <div style="margin: 30px 0 20px 130px; " v-if="showSubmit">
                <el-button type="primary" @click="submit()">保存并继续</el-button>
                <el-button @click="$router.back()">返回</el-button>
            </div> -->
    </el-form>
    <doctorListDialog ref="doctorListDialog" :defaltCheckIds="form.doctorIdList" @submit="setDoctorList" />
    <doctorListDialog ref="copyrightDoctorDialog" :defaltCheckIds="form.copyrightDoctorIdList" @submit="setCopyrightDocList" />
  </el-card>
</template>
<script>
  import request from '@/utils/request'
  // import { getMyPatientList, getAllPatientList } from "@/api/mySchedule";
  import { getDict } from "@/api/common";
  import { getDoctorNames } from "@/api/doctorManagement";
  import doctorListDialog from './doctorListDialog'

  export default {
    name: 'project-addBasic',
    components: {
      doctorListDialog
    },
    props: {
      form: {
        type: Object,
        default: () => { return {} }
      },
      // showSubmit: {
      //     type: Boolean,
      // }
    },
    data() {
      return {
        officeTree: [],
        diseaseList: [],
        selectTag: [
            [
                "TT1",
                "TT1-T1"
            ],
            [
                "TT3",
                "TT3-T2"
            ],
            [
                "TT3",
                "TT3-T2",
                "TT3-T2-T2"
            ],
            [
                "TT5",
                "TT5-T2"
            ]
        ],
        selectTagIds: '',
        selectTagLabel: '',
        diseaseSelectTags:[
          // {
          //   id: '1',
          //   name: '脑卒中',
          // },
          // {
          //   id: '2',
          //   name: '脑卒中/手',
          // }
        ],
        options:   [
        {
            "tagValue": "TT1",
            "tagName": "患病",
            "disable": true,
            "children": [
                {
                    "id": "1",
                    "remark": null,
                    "tagName": "脑卒中",
                    "tagType": "TT1",
                    "tagTypeName": "患病",
                    "tagValue": "TT1-T1",
                    "tagDes": "脑卒中",
                    "tagState": 1,
                    "tagWeight": 1,
                    "parentId": "0",
                    "tagOwnership": "patient,source,scheme",
                    "children": null,
                    "disable": false
                }
            ]
        },
        {
            "tagValue": "TT3",
            "tagName": "患病部位",
            "disable": true,
            "children": [
                {
                    "id": "4",
                    "remark": null,
                    "tagName": "上肢",
                    "tagType": "TT3",
                    "tagTypeName": "患病部位",
                    "tagValue": "TT3-T1",
                    "tagDes": "上肢",
                    "tagState": 1,
                    "tagWeight": 1,
                    "parentId": "0",
                    "tagOwnership": "patient,source,scheme",
                    "children": [
                        {
                            "id": "7",
                            "remark": null,
                            "tagName": "左",
                            "tagType": "TT3",
                            "tagTypeName": "患病部位",
                            "tagValue": "TT3-T1-T1",
                            "tagDes": "左",
                            "tagState": 1,
                            "tagWeight": 1,
                            "parentId": "4",
                            "tagOwnership": "patient,source,scheme",
                            "children": null,
                            "disable": false
                        },
                        {
                            "id": "8",
                            "remark": null,
                            "tagName": "右",
                            "tagType": "TT3",
                            "tagTypeName": "患病部位",
                            "tagValue": "TT3-T1-T2",
                            "tagDes": "右",
                            "tagState": 1,
                            "tagWeight": 1,
                            "parentId": "4",
                            "tagOwnership": "patient,source,scheme",
                            "children": null,
                            "disable": false
                        }
                    ],
                    "disable": false
                },
                {
                    "id": "5",
                    "remark": null,
                    "tagName": "下肢",
                    "tagType": "TT3",
                    "tagTypeName": "患病部位",
                    "tagValue": "TT3-T2",
                    "tagDes": "下肢",
                    "tagState": 1,
                    "tagWeight": 1,
                    "parentId": "0",
                    "tagOwnership": "patient,source,scheme",
                    "children": [
                        {
                            "id": "9",
                            "remark": null,
                            "tagName": "左",
                            "tagType": "TT3",
                            "tagTypeName": "患病部位",
                            "tagValue": "TT3-T2-T1",
                            "tagDes": "左",
                            "tagState": 1,
                            "tagWeight": 1,
                            "parentId": "5",
                            "tagOwnership": "patient,source,scheme",
                            "children": null,
                            "disable": false
                        },
                        {
                            "id": "10",
                            "remark": null,
                            "tagName": "右",
                            "tagType": "TT3",
                            "tagTypeName": "患病部位",
                            "tagValue": "TT3-T2-T2",
                            "tagDes": "右",
                            "tagState": 1,
                            "tagWeight": 1,
                            "parentId": "5",
                            "tagOwnership": "patient,source,scheme",
                            "children": null,
                            "disable": false
                        }
                    ],
                    "disable": false
                },
                {
                    "id": "6",
                    "remark": null,
                    "tagName": "手",
                    "tagType": "TT3",
                    "tagTypeName": "患病部位",
                    "tagValue": "TT3-T3",
                    "tagDes": "手",
                    "tagState": 1,
                    "tagWeight": 1,
                    "parentId": "0",
                    "tagOwnership": "patient,source,scheme",
                    "children": [
                        {
                            "id": "11",
                            "remark": null,
                            "tagName": "左",
                            "tagType": "TT3",
                            "tagTypeName": "患病部位",
                            "tagValue": "TT3-T3-T1",
                            "tagDes": "左",
                            "tagState": 1,
                            "tagWeight": 1,
                            "parentId": "6",
                            "tagOwnership": "patient,source,scheme",
                            "children": null,
                            "disable": false
                        },
                        {
                            "id": "12",
                            "remark": null,
                            "tagName": "右",
                            "tagType": "TT3",
                            "tagTypeName": "患病部位",
                            "tagValue": "TT3-T3-T2",
                            "tagDes": "右",
                            "tagState": 1,
                            "tagWeight": 1,
                            "parentId": "6",
                            "tagOwnership": "patient,source,scheme",
                            "children": null,
                            "disable": false
                        }
                    ],
                    "disable": false
                }
            ]
        },
        {
            "tagValue": "TT2",
            "tagName": "障碍类型",
            "disable": true,
            "children": [
                {
                    "id": "2",
                    "remark": null,
                    "tagName": "运动障碍",
                    "tagType": "TT2",
                    "tagTypeName": "障碍类型",
                    "tagValue": "TT2-T1",
                    "tagDes": "运动障碍",
                    "tagState": 1,
                    "tagWeight": 1,
                    "parentId": "0",
                    "tagOwnership": "patient,source",
                    "children": null,
                    "disable": false
                },
                {
                    "id": "3",
                    "remark": null,
                    "tagName": "言语障碍",
                    "tagType": "TT2",
                    "tagTypeName": "障碍类型",
                    "tagValue": "TT2-T2",
                    "tagDes": "言语障碍",
                    "tagState": 1,
                    "tagWeight": 1,
                    "parentId": "0",
                    "tagOwnership": "patient,source",
                    "children": null,
                    "disable": false
                }
            ]
        },
        {
            "tagValue": "TT5",
            "tagName": "影响能力",
            "disable": true,
            "children": [
                {
                    "id": "18",
                    "remark": null,
                    "tagName": "社会参与",
                    "tagType": "TT5",
                    "tagTypeName": "影响能力",
                    "tagValue": "TT5-T1",
                    "tagDes": "社会参与",
                    "tagState": 1,
                    "tagWeight": 1,
                    "parentId": "0",
                    "tagOwnership": "patient,source,scheme",
                    "children": null,
                    "disable": false
                },
                {
                    "id": "19",
                    "remark": null,
                    "tagName": "日常生活",
                    "tagType": "TT5",
                    "tagTypeName": "影响能力",
                    "tagValue": "TT5-T2",
                    "tagDes": "日常生活",
                    "tagState": 1,
                    "tagWeight": 1,
                    "parentId": "0",
                    "tagOwnership": "patient,source,scheme",
                    "children": null,
                    "disable": false
                },
                {
                    "id": "20",
                    "remark": null,
                    "tagName": "工作",
                    "tagType": "TT5",
                    "tagTypeName": "影响能力",
                    "tagValue": "TT5-T3",
                    "tagDes": "工作",
                    "tagState": 1,
                    "tagWeight": 1,
                    "parentId": "0",
                    "tagOwnership": "patient,source,scheme",
                    "children": null,
                    "disable": false
                },
                {
                    "id": "21",
                    "remark": null,
                    "tagName": "运动能力",
                    "tagType": "TT5",
                    "tagTypeName": "影响能力",
                    "tagValue": "TT5-T4",
                    "tagDes": "运动能力",
                    "tagState": 1,
                    "tagWeight": 1,
                    "parentId": "0",
                    "tagOwnership": "patient,source,scheme",
                    "children": null,
                    "disable": false
                },
                {
                    "id": "22",
                    "remark": null,
                    "tagName": "认知",
                    "tagType": "TT5",
                    "tagTypeName": "影响能力",
                    "tagValue": "TT5-T5",
                    "tagDes": "认知",
                    "tagState": 1,
                    "tagWeight": 1,
                    "parentId": "0",
                    "tagOwnership": "patient,source,scheme",
                    "children": null,
                    "disable": false
                }
            ]
        },
        {
            "tagValue": "TT4",
            "tagName": "程度",
            "disable": true,
            "children": [
                {
                    "id": "13",
                    "remark": null,
                    "tagName": "正常",
                    "tagType": "TT4",
                    "tagTypeName": "程度",
                    "tagValue": "TT4-T1",
                    "tagDes": "正常",
                    "tagState": 1,
                    "tagWeight": 1,
                    "parentId": "0",
                    "tagOwnership": "patient",
                    "children": null,
                    "disable": false
                },
                {
                    "id": "14",
                    "remark": null,
                    "tagName": "轻度",
                    "tagType": "TT4",
                    "tagTypeName": "程度",
                    "tagValue": "TT4-T2",
                    "tagDes": "轻度",
                    "tagState": 1,
                    "tagWeight": 1,
                    "parentId": "0",
                    "tagOwnership": "patient",
                    "children": null,
                    "disable": false
                },
                {
                    "id": "15",
                    "remark": null,
                    "tagName": "中度",
                    "tagType": "TT4",
                    "tagTypeName": "程度",
                    "tagValue": "TT4-T3",
                    "tagDes": "中度",
                    "tagState": 1,
                    "tagWeight": 1,
                    "parentId": "0",
                    "tagOwnership": "patient",
                    "children": null,
                    "disable": false
                },
                {
                    "id": "16",
                    "remark": null,
                    "tagName": "重度",
                    "tagType": "TT4",
                    "tagTypeName": "程度",
                    "tagValue": "TT4-T4",
                    "tagDes": "重度",
                    "tagState": 1,
                    "tagWeight": 1,
                    "parentId": "0",
                    "tagOwnership": "patient",
                    "children": null,
                    "disable": false
                }
            ]
        },
        {
            "tagValue": "TT7",
            "tagName": "治疗类型",
            "disable": true,
            "children": [
                {
                    "id": "24",
                    "remark": null,
                    "tagName": "PT",
                    "tagType": "TT7",
                    "tagTypeName": "治疗类型",
                    "tagValue": "TT7-T1",
                    "tagDes": "PT",
                    "tagState": 1,
                    "tagWeight": 1,
                    "parentId": "0",
                    "tagOwnership": "source,scheme",
                    "children": null,
                    "disable": false
                },
                {
                    "id": "25",
                    "remark": null,
                    "tagName": "OT",
                    "tagType": "TT7",
                    "tagTypeName": "治疗类型",
                    "tagValue": "TT7-T2",
                    "tagDes": "OT",
                    "tagState": 1,
                    "tagWeight": 1,
                    "parentId": "0",
                    "tagOwnership": "source,scheme",
                    "children": null,
                    "disable": false
                },
                {
                    "id": "26",
                    "remark": null,
                    "tagName": "ST",
                    "tagType": "TT7",
                    "tagTypeName": "治疗类型",
                    "tagValue": "TT7-T3",
                    "tagDes": "ST",
                    "tagState": 1,
                    "tagWeight": 1,
                    "parentId": "0",
                    "tagOwnership": "source,scheme",
                    "children": null,
                    "disable": false
                }
            ]
        },
        {
            "tagValue": "TT6",
            "tagName": "疗法",
            "disable": true,
            "children": [
                {
                    "id": "23",
                    "remark": null,
                    "tagName": "brunnstrom",
                    "tagType": "TT6",
                    "tagTypeName": "疗法",
                    "tagValue": "TT6-T1",
                    "tagDes": "brunnstrom",
                    "tagState": 1,
                    "tagWeight": 1,
                    "parentId": "0",
                    "tagOwnership": "source,scheme",
                    "children": null,
                    "disable": false
                }
            ]
        },
        {
            "tagValue": "TT8",
            "tagName": "级别",
            "disable": true,
            "children": [
                {
                    "id": "27",
                    "remark": null,
                    "tagName": "Ⅰ",
                    "tagType": "TT8",
                    "tagTypeName": "级别",
                    "tagValue": "TT8-T1",
                    "tagDes": "Ⅰ",
                    "tagState": 1,
                    "tagWeight": 1,
                    "parentId": "0",
                    "tagOwnership": "source,scheme",
                    "children": null,
                    "disable": false
                },
                {
                    "id": "28",
                    "remark": null,
                    "tagName": "Ⅱ",
                    "tagType": "TT8",
                    "tagTypeName": "级别",
                    "tagValue": "TT8-T2",
                    "tagDes": "Ⅱ",
                    "tagState": 1,
                    "tagWeight": 1,
                    "parentId": "0",
                    "tagOwnership": "source,scheme",
                    "children": null,
                    "disable": false
                },
                {
                    "id": "30",
                    "remark": null,
                    "tagName": "Ⅳ",
                    "tagType": "TT8",
                    "tagTypeName": "级别",
                    "tagValue": "TT8-T4",
                    "tagDes": "Ⅳ",
                    "tagState": 1,
                    "tagWeight": 1,
                    "parentId": "0",
                    "tagOwnership": "source,scheme",
                    "children": null,
                    "disable": false
                },
                {
                    "id": "31",
                    "remark": null,
                    "tagName": "Ⅴ",
                    "tagType": "TT8",
                    "tagTypeName": "级别",
                    "tagValue": "TT8-T5",
                    "tagDes": "Ⅴ",
                    "tagState": 1,
                    "tagWeight": 1,
                    "parentId": "0",
                    "tagOwnership": "source,scheme",
                    "children": null,
                    "disable": false
                },
                {
                    "id": "32",
                    "remark": null,
                    "tagName": "Ⅵ",
                    "tagType": "TT8",
                    "tagTypeName": "级别",
                    "tagValue": "TT8-T6",
                    "tagDes": "Ⅵ",
                    "tagState": 1,
                    "tagWeight": 1,
                    "parentId": "0",
                    "tagOwnership": "source,scheme",
                    "children": null,
                    "disable": false
                }
            ]
          }
        ],
        rules: {
          schemeName: [
            {
              required: true,
              message: "请输入方案名称",
              trigger: "blur",
            },
          ],
          subTitle: [
            {
              required: true,
              message: "请输入副标题",
              trigger: "blur",
            },
          ],
          servicePeriod: [
            {
              required: true,
              message: "请输入服务周期",
              trigger: "blur",
            },
          ],
          diseaseIdList: [
            {
              required: true,
              message: "请输入适用病种",
              trigger: "blur",
            },
          ],
          doctorNameList: [
            {
              required: true,
              message: "请输入服务医生",
              trigger: "blur",
            },
          ],
          
          
        },
      };
    },
    computed: {

    },
    watch: {
    },
    created() {
      this.getDiseaseList();
      this.getOfficeTree();
    },
    mounted() { },
    methods: {
      getOfficeTree() {
         // 机构树
        request({
          url: 'admin/kfHospital/downList',
          method: 'GET',
          showLoading: true
        }).then(({ res }) => {
          this.officeTree = res.records;
        })
      },
      getDiseaseList() {
        request({
          url: 'admin/kfSchemeInfo/getDiseaseList',
          method: 'GET',
          showLoading: true
        }).then(({ res, timeStamp }) => {
          // console.log('getDiseaseList -------> ', res);
          this.diseaseList = res;
        });
      },
      openCopyrightDocDialog() {
        this.$refs.copyrightDoctorDialog.open();
      },
      openDocDialog() {
        this.$refs.doctorListDialog.open();
      },
      setDoctorList(rows) {
        let namesStr = '';
        const params = {
            ids: rows,
        }
        getDoctorNames(params).then(({ res }) => {
          console.log('getDoctorNames--', res)
          namesStr = res.map(r => r.name).join(',')
          console.log('namesStr--', namesStr)
          this.form.doctorIdList = rows;
          // this.form.doctorNameList = rows.map( r => r.name ).join(',');
          this.$set(this.form, 'doctorNameList',namesStr);
        });
       
      },
      setCopyrightDocList(rows) {
        let namesStr = '';
        const params = {
            ids: rows,
        }
        getDoctorNames(params).then(({ res }) => {
          console.log('getDoctorNames--', res)
          namesStr = res.map(r => r.name).join(',')
          console.log('namesStr--', namesStr)
          this.form.copyrightDoctorIdList = rows;
          // this.form.doctorNameList = rows.map( r => r.name ).join(',');
          this.$set(this.form, 'copyrightDoctorNameList',namesStr);
        });
       
      },
      submit() {
        console.log('this.selectTag---', this.selectTag)
        this.$refs.insertForm.validate((valid) => {
          if (valid) {
            const param = JSON.parse(JSON.stringify(this.form));
            delete param.doctorNameList;
            this.$emit("submit", param);
          }
        })
      },
      periodChange() {
        if (this.form.servicePeriod > 999) {
          this.form.servicePeriod = 999
        } else if (this.form.servicePeriod < 1) {
          this.form.servicePeriod = 1
        }
      },
      addMultipleTags(e){
        let that = this;
        this.$refs["dieaseTagRef"].getCheckedNodes().forEach(function(item){
          console.log('addMultipleTags--', item)
          const selectTagLabel = item.pathLabels.join('/');
          const selectTagIds = item.path.join('/');
          const tagIds = item.path;
          var flag = that.diseaseSelectTags.find(item => item.tagValue == selectTagIds)
          if(!flag){
            that.diseaseSelectTags.push({
              tagValue: selectTagIds,
              tagName: selectTagLabel,
              tagIds: tagIds
            })		// 如果没有就直接push
          }
        })
      },
      addTags(e){
        debugger
        this.selectTagLabel = this.$refs["dieaseTagRef"].getCheckedNodes()[0].pathLabels.join('/');
        this.selectTagIds = this.selectTag.join('/')
        var flag = this.diseaseSelectTags.find(item => item.tagValue == this.selectTagIds)
        if(!flag){
          this.diseaseSelectTags.push({
            tagValue: this.selectTagIds,
            tagName: this.selectTagLabel,
            tagIds: this.selectTag
          })		// 如果没有就直接push
        }
       
      },
      diseaseTagChange($event){
      },
      handleClose(tag) {
        debugger
        console.log('this.selectTag---', this.selectTag)
        const newtags = this.diseaseSelectTags.filter(item=>{
          return item.tagValue !== tag.tagValue
        })
        this.diseaseSelectTags = newtags;
        console.log('this.diseaseSelectTags----', this.diseaseSelectTags)
      },
    },
  };
</script>
<style lang="scss">
  .addscheme-cascader-parent{
    .el-cascader-panel .el-scrollbar:first-child .el-checkbox{
      display: none;
    }
    .el-cascader-menu__wrap{
      height: 300px;
    }
  }
</style>
