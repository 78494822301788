<template>
  <div>
    <generalTable ref="mainTable" :table-data="tableData" :config="tableConfig" :total="total" @updatePage="getList" @objectSpanMethod="objectSpanMethod" @onClickRow="onClickRow">
        <template slot="status" slot-scope="scope">
            <!-- 0. 提现失败  1. 已到账  2. 提现中 3. 待审批（操作：审批）4. 待提现：（操作：提现） 5. 审批拒绝 仅提现失败才显示原因 -->
            <span v-if="scope.data.row.withdrawStatus === '0'" style="color:#D9001B;">{{scope.data.row.withdrawStatusLabel}}</span>
            <span v-else>{{scope.data.row.withdrawStatusLabel}}</span>
            <el-tooltip :content="scope.data.row.platformRemark" placement="bottom"><i v-show="scope.data.row.withdrawStatus === '0'&& scope.data.row.platformRemark != null" class="el-icon-warning" style="color: #D9001B;"></i>
            </el-tooltip>
        </template>    
    </generalTable>
  </div>
</template>
<script>
import { getEmbodyList } from '@/api/embodyAndRefund'
import searchForm from '@/components/SearchForm'
import generalTable from '@/components/Table'
import { getHospitalList} from '@/api/hospitalManagement'
import editState from "./editState";
import { exportExcel } from "@/utils/common";
import { getDict } from "@/api/common";
export default {
    name: 'Test',
    components: {
        searchForm,
        generalTable,
        editState,
    },
      props: {
        searchObj: Object
    },
   
    data() {
        return {
            tableData: [
            ],
            tableConfig: {
                id: 'test',
                align: 'center', // 不必填 默认为center
                selection: 'single', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: true, // 不必填 分页 默认为false
                column: [
                // {
                //     label: '排序', // 必填
                //     prop: 'sort' // 必填
                // },
                {
                    label: '流水单号', // 必填
                    prop: 'withdrawSerial' // 必填
                },
                {
                    label: '医生姓名', // 必填
                    prop: 'userName' // 必填
                },
                {
                    label: '所属医院', // 必填
                    prop: 'userHospital' // 必填
                },
                {
                    label: '提现时间', // 必填
                    prop: 'withdrawTime', // 必填
                    formatter: (row, column) => {
                            return this.moment(row.withdrawTime).format(
                                'YYYY-MM-DD'
                            )
                        }
                },
                {
                    label: '提现金额(元)', // 必填
                    prop: 'withdrawAmount' // 必填
                },
                {
                    label: '提现方式', // 必填
                    prop: 'withdrawType', // 必填
                    formatter: (row, column) => {
                            const stampContainer = {
                                1: "微信",
                                0: "支付宝",
                                2: "银行卡",
                            };
                            return stampContainer[row.withdrawType];
                        },
                },
                {
                    label: '状态', // 必填
                    prop: 'withdrawStatus', // 必填
                    slot: "status",
                    formatter: (row, column) => {
                            // 根据字典表展示对应项目的label值
                            const currentType = this.withdrawStatusList.filter(
                                (type) => type.value === row.withdrawStatus
                            );
                            if (currentType.length) {
                                return currentType[0].label;
                            }
                        },
                },
                ]
            },
            paramObj: null, // 筛选项
            total: 0, // 总条数
            checkId: '', // 单条数据id 用于详情或编辑请求数据用
            timeStamp: '',
            backStatus: true, //返回按钮的隐藏显示
            hospitalList:[],//医院列表
            withdrawStatusList: null, // 提现状态列表
        }
    },
    created() {
        this.getList();
        //this.getHospital();
        this.getWithdrawStatusList();
    },
    mounted: function() {},
    watch: {
        searchObj(newVal, oldVal) {
            console.log('searchObj=', newVal)
            if (newVal) {
                this.getList()
                //...todo
            }
        }
    },
    methods: {
        search(data) {
            this.paramObj = data
            this.$refs.mainTable.handleCurrentChange(1)
        },
        toExport() {
          let params = this.getParam();
          console.table(params)
          let url = process.env.VUE_APP_BASE_API + "/admin/finance/export";
          let _this = this;
          exportExcel(url,{},params,'导入慧用工系统结算报表','xls').then(res=>{
          }).catch(function (error) {
             _this.$message({
                    message: error.message || '导出失败',
                    duration: 5000,
                    type: "warning",
              });
          });
        },
          /**
         * @description: 获取所属平台
         * @param {*}
         * @return {*}
         */
        getWithdrawStatusList() {
            getDict({ type: "withdraw_status" }).then(({ res }) => {
                this.withdrawStatusList = res.withdraw_status;
            });
        },
        getList() {
            // 解决未挂载完组件时调用子组件方法报错问题
            this.$nextTick(() => {
                const param = this.getParam()
                console.log('--------getParam--------', param)
                // tab 分组查询 List 分类参数
                // statusType：1. 待审批 2. 待提现  3. 已完结（提现中、提现失败、审批拒绝）
                const paramObj = Object.assign({}, this.getParam(), { statusType: "3" });
                getEmbodyList(paramObj).then((res, timeStamp) => {
                    this.tableData = res.res.records;
                    this.tableData.forEach(item=>{
                      if (item.withdrawAmount && !isNaN(item.withdrawAmount)) {
                        item.withdrawAmount = parseFloat(item.withdrawAmount);
                      }
                    })
                    this.timeStamp = timeStamp;
                    this.total = res.res.total;
                })
            })
            
        },
        objectSpanMethod(param, cb) {
            cb([])
        },

        toEdit(scope) {
            console.table(scope.data.row)
            this.checkId = scope.data.row.id
            this.$refs.editState.updateVisible(true,scope.data.row)
        },
        onClickRow(checkArray) {
        },
        getParam() {
            // 获取参数
            const page = this.$refs.mainTable.getPage();
            const param = Object.assign({}, this.searchObj, page, { statusType: "3" });
            this.paramObj = param;
            return param;
        },
         // 获取医院
        getHospital() {
            const param = []
            getHospitalList(param).then(({ res }) => {
                this.hospitalList = res.records.map((item) => {
                    return {
                        value: item.id,
                        label: item.name,
                    };
                });
                this.searchOptions[2].options = this.hospitalList
            });
        },
       
    }
}
</script>

<style lang='scss'>
@import "~@/styles/variables.scss";

</style>
