<!--
* @Description: 课程分润页面
* @Reference:
-->

<template>
  <el-container>
    <el-header height="auto">
      <searchForm
        ref="mainSearch"
        :searchoptions="searchOptions"
        @search="search"
        :backStatus="backStatus"
      />
    </el-header>
    <el-main class="basic-main">
      <generalTable
        ref="mainTable"
        :table-data="tableData"
        :config="tableConfig"
        :total="total"
        @updatePage="getList"
      >
        <template slot="handle" slot-scope="scope">
          <el-button
            type="text"
            @click="toEditOrAdd(scope.data.row)"
            v-if="!scope.data.row.doctorId"
            >添加医生</el-button
          >
          <div v-else>
            {{ scope.data.row.doctorName }}({{ scope.data.row.phoneNumber }})
            <el-button type="text" @click="toDel(scope.data.row)"
              >删除</el-button
            >
          </div>
        </template>
        <template slot="imgUrl" slot-scope="scope">
          <el-image
            style="width: 50px; height: 50px; margin-top: 10px"
            :src="scope.data.row.img_url_compressed"
            :preview-src-list="[scope.data.row.img_url]"
          >
          </el-image>
        </template>
        <template slot="editProportion" slot-scope="scope">
          <el-button
            type="text"
            @click="toEditOrAdd(scope.data.row)"
            v-if="scope.data.row.shareInProportion"
            >{{ scope.data.row.shareInProportion }}</el-button
          >
        </template>
      </generalTable>
      <addDialog :id="checkId" ref="insert" @submit="search" />
    </el-main>
  </el-container>
</template>

<script>
import { getXETGoodsList } from "@/api/courseProportion";
import searchForm from "@/components/SearchForm";
import generalTable from "@/components/Table";
import { deleteRatio } from "@/api/courseProportion";
import addDialog from "@/views/financial/courseProportion/addDialog";

export default {
  name: "Invoice",
  components: {
    searchForm,
    generalTable,
    addDialog
  },
  data() {
    return {
      tableData: [],
      searchOptions: [
        {
          type: "input",
          placeholder: "请输入...",
          key: "goods_name",
          label: "课程名称",
          defaultVal: ""
        }
      ],
      tableConfig: {
        id: "invoice",
        align: "center",
        selection: "single",
        index: true,
        pagination: true,
        column: [
          {
            label: "课程名称",
            prop: "goods_name"
          },
          {
            label: "封面图",
            slot: "imgUrl", // 必填
            width: 120
          },
          {
            label: "课程类型",
            prop: "resource_type",
            width: 120,
            formatter: (row, column) => {
              const resourceType = {
                1: "图文",
                2: "音频",
                3: "视频",
                4: "直播",
                5: "会员",
                6: "专栏",
                7: "圈子",
                8: "大专栏",
                9: "活动管理",
                16: "付费打卡",
                20: "电子书",
                21: "实物商品",
                23: "超级会员",
                25: "训练营",
                29: "线下课",
                31: "小班课",
                34: "练习",
                35: "班课",
                37: "大班课",
                41: "有价优惠券",
                42: "课时包",
                45: "AI互动课",
                46: "付费问答",
                47: "付费问答-答主",
                50: "课程（训练营pro）"
              };
              return resourceType[row.resource_type];
            }
          },
          {
            label: "商品最低价",
            prop: "price_low",
            width: 120,
            formatter: records => {
              return records.price_low / 100;
            }
          },
          {
            label: "商品高价",
            prop: "price_high",
            width: 120,
            formatter: records => {
              return records.price_high / 100;
            }
          },
          {
            label: "划线价",
            prop: "price_line",
            width: 120,
            formatter: records => {
              return records.price_line / 100;
            }
          },
          {
            label: "上架时间",
            prop: "sale_at",
            width: 200,
            formatter: (row, column) => {
              return this.moment(row.sale_at).format(
                "YYYY-MM-DD HH:mm:ss"
              );
            }
          },
          {
            label: "创建时间",
            prop: "created_at",
            width: 200,
            formatter: (row, column) => {
              return this.moment(row.created_at).format(
                "YYYY-MM-DD HH:mm:ss"
              );
            }
          },
          {
            label: "分成比例(%)",
            slot: "editProportion",
            width: 120,
            formatter: (row, column) => {
              return row.shareInProportion ? row.shareInProportion : "";
            }
          },
          {
            slot: "handle",
            label: "操作",
            fixed: "right",
            width: "220"
          }
        ]
      },
      paramObj: null,
      total: 0,
      checkId: "",
      backStatus: true,
      timeStamp: ""
    };
  },
  created() {
    this.getList();
  },
  mounted: function() {},
  methods: {
    search(data) {
      Object.assign(this.paramObj, data);
      this.$refs.mainTable.handleCurrentChange(1);
    },
    getList() {
      this.$nextTick(() => {
        const param = this.getParam();
        getXETGoodsList(param).then(({ res, timeStamp }) => {
          this.tableData = res.data.list;
          this.timeStamp = timeStamp;
          this.total = res.data.total;
        });
      });
    },
    toDel(item) {
      const {
        $delMsg,
        $delTitle,
        $messageBoxConfig,
        $handleSuccess
      } = this.commonConfig;
      this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
        const param = {
          id: item.goodsDoctorRelationId
        };
        deleteRatio(param, this.timeStamp).then(({ res }) => {
          this.$message({ ...$handleSuccess });
          this.search();
        });
      });
    },
    toEditOrAdd(item) {
      this.$refs.insert.updateVisible(true, item);
    },
    getParam() {
      const paramObj = this.$refs.mainSearch.packageData();
      paramObj["resource_type"] = 3;
      const page = this.$refs.mainTable.getPage();
      const param = Object.assign({}, this.paramObj, page, paramObj);
      this.paramObj = param;
      return param;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
</style>
