/*
 * @Author: magy
 * @Date: 2022-03-31 23:31:44
 * @LastEditTime: 2022-04-07 10:38:09
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \recovery_home_management\src\api\areaManagement.js
 */


/**
 * 页面: 地域管理
 * pageName: areaManagement
 **/
 import request from '@/utils/request'

 /**
  * @description: 地域管理列表
  */
 export function getList(data) {
   return request({
     url: 'admin/sysArea/areaManagement',
     method: 'GET',
     params: data,
     showLoading: true
   });
 }

 /**
  * @description:地域管理列表树
  */
  export function getSysAreaTree(data) {
    return request({
      url: 'admin/sysArea/getSysAreaTree',
      method: 'GET',
      params: data,
      showLoading: true
    });
  }