var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
    'message-input-toolbar',
    !_vm.isPC && 'message-input-toolbar-h5',
    _vm.isUniFrameWork && 'message-input-toolbar-uni',
  ]},[_c('div',{class:[
      'message-input-toolbar-list',
      !_vm.isPC && 'message-input-toolbar-h5-list',
      _vm.isUniFrameWork && 'message-input-toolbar-uni-list',
    ]},[(!_vm.isUniFrameWork && false)?_c('EmojiPicker',{on:{"insertEmoji":_vm.insertEmoji,"dialogShowInH5":_vm.dialogShowInH5,"dialogCloseInH5":_vm.dialogCloseInH5}}):_vm._e(),(_vm.isUniFrameWork)?_c('ImageUpload',{attrs:{"imageSourceType":"camera"}}):_vm._e(),_c('ImageUpload',{attrs:{"imageSourceType":"album"}}),(!_vm.isUniFrameWork && false)?_c('FileUpload'):_vm._e(),_c('VideoUpload',{attrs:{"videoSourceType":"album"}}),(_vm.isUniFrameWork)?_c('VideoUpload',{attrs:{"videoSourceType":"camera"}}):_vm._e(),(false)?_c('Words'):_vm._e(),(_vm.extensionListShowInStart[0] && false)?_vm._l((_vm.extensionListShowInStart),function(extension,index){return _c('ToolbarItemContainer',{key:index,attrs:{"iconFile":_vm.genExtensionIcon(extension),"title":_vm.genExtensionText(extension),"iconWidth":_vm.isUniFrameWork ? '25px' : '20px',"iconHeight":_vm.isUniFrameWork ? '25px' : '20px',"needDialog":false},on:{"onIconClick":function($event){return _vm.onExtensionClick(extension)}}})}):_vm._e()],2),(_vm.extensionListShowInEnd[0] && _vm.isPC && false)?_c('div',{class:['message-input-toolbar-list-end']},_vm._l((_vm.extensionListShowInEnd),function(extension,index){return _c('ToolbarItemContainer',{key:index,attrs:{"iconFile":_vm.genExtensionIcon(extension),"title":_vm.genExtensionText(extension),"iconWidth":_vm.isUniFrameWork ? '25px' : '20px',"iconHeight":_vm.isUniFrameWork ? '25px' : '20px',"needDialog":false},on:{"onIconClick":function($event){return _vm.onExtensionClick(extension)}}})}),1):_vm._e(),_c('UserSelector',{ref:"userSelectorRef",attrs:{"type":_vm.selectorShowType,"currentConversation":_vm.currentConversation,"isGroup":_vm.isGroup},on:{"submit":_vm.onUserSelectorSubmit,"cancel":_vm.onUserSelectorCancel}}),(_vm.isH5)?_c('div',{ref:"h5Dialog",class:['message-input-toolbar-h5-dialog']}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }