<template>
    <!-- <el-header height="auto">
        <searchForm ref="mainSearch" :backStatus="true" :searchoptions="searchOptions" @search="search" />
    </el-header> -->
    <!-- <el-main class="basic-main"> -->
    <generalTable ref="mainTable" :table-data="tableData" :config="tableConfig" :total="total" @updatePage="getList"
        @onClickRow="onClickRow">
    </generalTable>
    <!-- </el-main> -->
    <!-- <detailDialog :id="checkId" ref="detail" /> -->
</template>

<script>
import { getList, deleteData } from "@/api/evaluation";
import generalTable from "@/components/Table";
import detailDialog from "@/views/operation/evaluation/detailDialog";
export default {
    components: {
        // searchForm,
        generalTable,
        detailDialog,
    },
    props: {
        searchObj: Object
    },
    data() {
        return {
            tableData: [],
            tableConfig: {
                id: "commodity",
                align: "center", // 不必填 默认为center
                selection: "single", // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: true, // 不必填 分页 默认为false
                column: [
                    {
                        label: "订单号", // 必填
                        prop: "orderNo", // 必填
                        // formatter: (row, column) => {
                        //     return row.archivesInfoId.name
                        // }
                    },
                    {
                        label: "订单类型", // 必填
                        prop: "type", // 必填
                         formatter: (row, column) => {
                            // 后续讲调整为按类型查询判断 1-商品订单
                            return row.type == 1 ? "商品订单" : '商品订单';
                        }
                    },
                    {
                        label: "订单详情", // 必填
                        prop: "productName", // 必填
                    },
                    {
                        label: "评论人", // 必填
                        prop: "patName", // 必填
                    },
                    {
                        label: "手机号", // 必填
                        prop: "mobileNumber", // 必填
                    },
                    {
                        label: "评分", // 必填
                        prop: "starAvg", // 必填
                    },
                    {
                        label: "评价详情", // 必填
                        prop: "evaluate", // 必填

                    },
                ],
            },
            paramObj: null, // 筛选项
            total: 0, // 总条数 
            checkId: "", // 单条数据id 用于详情或编辑请求数据用
            timeStamp: "", //时间戳 用于修改数据或删除数据

        }
    },
    watch: {
        searchObj(newVal, oldVal) {
            console.log('searchObj=', newVal)
            if (newVal) {
                this.getList()
                //...todo
            }
        }
    },
    created() {
        this.getList();
    },
    mounted: function () { },
    methods: {
        search(data) {
            Object.assign(this.paramObj, data);
            this.$refs.mainTable.handleCurrentChange(1);
        },
        clickTab(tab, event) {
            this.tabValue = tab.index
        },
        getList() {
            this.$nextTick(() => {
                const param = this.getParam();
                getList(param).then(({ res, timeStamp }) => {
                    this.tableData = res.records;
                    this.timeStamp = timeStamp;
                    this.total = res.total;
                });
            });
        },
        toInsert() {
            this.checkId = "";
            this.$refs.insert.updateVisible(true);
        },
        toDetail(scope) {
            // this.checkId = scope.data.row.id;
            this.$refs.detail.updateVisible(true);
        },
        toDel(scope) {
            const { $delMsg, $delTitle, $messageBoxConfig, $handleSuccess } =
                this.commonConfig;
            this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
                const param = { id: scope.data.row.id };
                deleteData(param, this.timeStamp).then(({ res }) => {
                    this.$message({ ...$handleSuccess });
                });
            });
        },
        onClickRow(row) {
            console.log("点击行:", row);
        },
        getParam() {
            // const paramObj = this.$refs.mainSearch.packageData();
            const page = this.$refs.mainTable.getPage();
            const param = Object.assign({}, this.searchObj, page, { type: "1" });
            this.paramObj = param;
            return param;
        },
    },
}
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
</style>