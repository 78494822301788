<template>
  <el-container>
    <!-- <el-tabs style="width: 100%; background-color: #fff; padding: 1em;" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="基础信息配置" name="1">
        <addBasic :form.sync="basicForm" />
      </el-tab-pane>
      <el-tab-pane label="配置康复计划" name="2">
        <addPlan :form.sync="planForm" />
      </el-tab-pane>
    </el-tabs> -->
    <div style="width: 100%; background-color: #fff; padding: 1em; overflow: auto;">
      <div class="mdrcc-info-title">
        基础信息配置
        <div style="float: right;">
          <!-- <el-button type="primary" @click="$refs.addPlan.submit()" v-if="showPlan">完成</el-button> -->
          <el-button type="primary" @click="$refs.addBasic.submit()" v-if="basicFormSubmitBool">{{activeName == 1 ? '保存并继续'
            : '保存更新'}}</el-button>
          <el-button @click="$router.back()">返回</el-button>
        </div>
      </div>
      <addBasic ref="addBasic" :form.sync="basicForm" @submit="basicFormSubmit" />
      <br>
      <template v-if="activeName >= 2">
        <div class="mdrcc-info-title">
          计划基础信息
          <div style="float: right;">
            <el-button type="primary" @click="$refs.addPlan.submit()" v-if="planFormSubmitBool">{{activeName == 2 ? '保存并继续' : '保存更新'}}</el-button>
          </div>
        </div>
        <addPlan ref="addPlan" :form.sync="planForm" @submit="planFormSubmit" />
      </template>
      <br>
      <template v-if="activeName >= 3">
        <div class="mdrcc-info-title">
          配置任务模板
          <div style="float: right;">
            <el-button type="primary" @click="finish()">完成并返回</el-button>
          </div>
        </div>
        <addTask ref="addTask" :servicePeriod.sync="basicForm.servicePeriod"  :form.sync="taskForm" />
      </template>

    </div>
  </el-container>
</template>

<script>

  import request from '@/utils/request'
  import addBasic from './addBasic'
  import addPlan from './addPlan'
  import addTask from './addTask'
  export default {
    name: 'project-add',
    components: {
      addBasic, addPlan, addTask
    },
    data() {
      return {
        detailId: '',
        activeName: 1,
        basicForm: {},
        basicFormSubmitBool: true,
        planForm: {},
        planFormSubmitBool: true,
        taskForm: {},
      }
    },
    computed: {
    },
    watch: {
      basicForm: {
        handler() {
          this.basicFormSubmitBool = true;
        },
        deep: true,
      },
      planForm: {
        handler() {
          this.planFormSubmitBool = true;
        },
        deep: true,
      }
    },
    created() {
      const { id } = this.$route.query
      this.init(id);
    },
    methods: {
      init(id) {
        this.detailId = id;
        if (id) {
          request({
            url: 'admin/kfTaskTemplate/getAllInfoBySchemeId',
            method: 'GET',
            params: { schemeId: id },
            showLoading: true
          }).then(({ res, timeStamp }) => {
            console.log('init ------------> ', res);
            this.activeName = 2;
              this.$nextTick(() => {
                this.basicFormSubmitBool = false;
              })
            const {
              schemeName, subTitle, schemeAuth, servicePeriod, diseaseType, diseaseTypeList, serviceDoctorList, copyright, genFinReport,
              kfPlanTemplateList = [],
              schemeDoctorList, schemeIntroduce, schemeState, copyrightInstitutionList, copyrightDoctorList, tag, kfServiceType,customize
            } = res;
            const doctorIdList = serviceDoctorList.map(doc => doc.id);
            const doctorNameList = serviceDoctorList.map(doc => doc.name).join(',');
            const copyrightDoctorIdList = copyrightDoctorList.map(doc => doc.id);
            const copyrightDoctorNameList = copyrightDoctorList.map(doc => doc.name).join(',');
            this.basicForm = { id, schemeName, subTitle, schemeAuth, servicePeriod, diseaseIdList: diseaseTypeList.map(r => r.id), doctorIdList, doctorNameList, copyright, genFinReport, customize,copyrightInstitutionIdList: copyrightInstitutionList.map(r => r.id), copyrightDoctorIdList, copyrightDoctorNameList, tag, kfServiceType};

            if (kfPlanTemplateList != null && kfPlanTemplateList.length) {
              this.planForm = { ...kfPlanTemplateList[0] };
              this.taskForm.planTemplateId = this.planForm.id;
              this.taskForm.kfTaskTemplateList = JSON.parse(JSON.stringify(this.planForm.kfTaskTemplateList));
            } else {
              this.planForm = { schemeId: id, period: this.basicForm.servicePeriod };
            }
            if (this.planForm.id) {
              this.$nextTick(() => {
                this.planFormSubmitBool = false;
              })
              this.activeName = 3;
            };
            // this.planForm.period = this.basicForm.servicePeriod;
            // if(res.id) {
            //   this.basicForm.id = res.id;
            //   this.planForm.schemeId = res.id;
            //   this.planForm.period = this.basicForm.servicePeriod;
            //   this.activeName == 1 && this.activeName++;
            // }
          });

        } else {
          this.basicForm = { genFinReport: true, customize: false };
        }

      },
      // 基础信息配置
      basicFormSubmit(val) {
        // console.log('basicFormSubmit ------------> ', this.basicForm, val)
        request({
          url: 'admin/kfSchemeInfo/' + (this.activeName == 1 ? 'add' : 'update'),
          method: 'POST',
          data: this.basicForm,
          showLoading: true
        }).then(({ res, timeStamp }) => {
          this.$message.success('保存成功');
          // console.log('basicFormSubmit ------------> ', res);
          if (res.id) {
            this.basicForm.id = res.id;
            this.planForm.schemeId = res.id;
          }
          this.planForm.period = this.basicForm.servicePeriod;
          this.activeName == 1 && this.activeName++;

          this.basicFormSubmitBool = false;
        });
      },
      // 计划基础信息
      planFormSubmit(val) {
        // console.log('planFormSubmit ------------> ', this.planForm, val)
        request({
          url: 'admin/kfPlanTemplate/' + (this.activeName == 2 ? 'add' : 'update'),
          method: 'POST',
          data: this.planForm,
          showLoading: true
        }).then(({ res, timeStamp }) => {
          this.$message.success('保存成功');
          // console.log('planFormSubmit ------------> ', res);
          if (res.id) {
            this.planForm.id = res.id;
            this.taskForm.planTemplateId = res.id;
            // this.planForm.schemeId = res.id;
            // this.planForm.period = this.basicForm.servicePeriod;
            this.activeName == 2 && this.activeName++;
          }
          this.planFormSubmitBool = false;
        });
      },
      // 返回前校验
      finish() {
        if( this.basicFormSubmitBool || this.planFormSubmitBool ) {
          let txt = this.basicFormSubmitBool && '"基础信息配置"';
          let txt2 = this.planFormSubmitBool && '"计划基础信息"';
          let txtRes = txt && txt2 ? txt + '和' + txt2 : txt || txt2
          this.$confirm(txtRes + "有未保存内容，确认返回？", "确认返回？").then(() => {
            this.$router.back();
          })
          return
        }
        this.$message.success('保存成功');
        this.$router.back();
      }
    }
  }
</script>

<style lang='scss' scoped>
  ::v-deep {
    .el-form {
      display: flex;
      flex-wrap: wrap;
    }

    .el-form-item {
      flex: 0 0 50%;
    }

    .el-form-item__content {
      width: 70%;
    }
  }

  .finishSubmit {
    position: fixed;
    right: 5%;
    bottom: 10%;
    padding: 0;
    width: 50px;
    height: 50px;
    line-height: 50px;
    opacity: .5;
    transition: all .2s;
  }

  .finishSubmit:hover {
    opacity: 1;
  }
</style>
