<!--
 * @Author: 马广源
 * @Date: 2023-06-27 14:56:55
 * @LastEditTime: 2023-10-27 19:30:09
 * @LastEditors: Please set LastEditors
 * @Description: 提现对账管理
 * @FilePath: /recovery_home_management_dev/src/views/financial/reconciliation/index.vue
-->

<template>
  <el-container>
    <el-header height="auto">
      <searchForm ref="mainSearch" :searchoptions="searchOptions" exportStatus @exportFun="toExport" @search="search" :backStatus="backStatus"/>
    </el-header>
    <el-main class="basic-main">
      <generalTable ref="mainTable" :table-data="tableData" :config="tableConfig" :total="total" @updatePage="getList" @objectSpanMethod="objectSpanMethod" @onClickRow="onClickRow">
        <template slot="expandCol" slot-scope="prop">
            <el-descriptions title="订单服务明细">
            </el-descriptions>
            <el-form v-for="(item, index ) in prop.data.row.serviceList" label-position="left" inline class="service-table-expand" :key="item.resource_id">
            <el-form-item label="">
                <span class="service-no">{{ index + 1}}.</span>
            </el-form-item>
            <el-form-item label="开始时间">
                <span>{{ item.startTime}}</span>
            </el-form-item>
            <el-form-item label="结束时间">
                <span>{{ item.endTime}}</span>
            </el-form-item>
            <el-form-item label="服务费">
                <span>{{ item.amount}}</span>
            </el-form-item>
            </el-form>
    </template>
      </generalTable>
    </el-main>
  </el-container>
</template>
<script>
import { getReconciliationList } from '@/api/reconciliation';
import searchForm from '@/components/SearchForm';
import generalTable from '@/components/Table';
import { exportExcel } from "@/utils/common";
export default {
    name: 'Reconciliation',
    components: {
        searchForm,
        generalTable,
    },
    data() {
        return {
            tableData: [
            ],
            searchOptions: [
                {
                    type: 'input',
                    placeholder: '请输入...',
                    key: 'doctorName',
                    label: '医生姓名',
                    defaultVal: ''
                },
                {
                    type: 'daterange',
                    startPlaceholder: '服务完成开始日期',
                    endPlaceholder: '服务完成结束日期',
                    key: 'service',
                    label: ' 服务完成时间',
                    defaultVal: []
                },
               
            ],
            tableConfig: {
                id: 'Reconciliation',
                align: 'center', // 不必填 默认为center
                selection: 'single', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: true, // 不必填 分页 默认为false
                column: [
                // {
                //     label: '排序', // 必填
                //     prop: 'sort' // 必填
                // },
                {
                    label: ' ',
                    expand: 'expand',
                    slot:'expandCol',
                    prop: 'orderList'
                },
                {
                    label: '订单号', // 必填
                    prop: 'orderNo', // 必填
                    width: '200',
                },
                {
                    label: '方案名称', // 必填
                    prop: 'goodsName', // 必填
                    width: '200',
                },
                {
                    label: '订单支付时间', // 必填
                    prop: 'payTime', // 必填
                    formatter: (row, column) => {
                        return this.moment(row.payTime).format(
                            'YYYY-MM-DD HH:mm:ss'
                        )
                    },
                    width: '200',
                },
                {
                    label: '患者姓名', // 必填
                    prop: 'patientName', // 必填
                },
                {
                    label: '服务医生', // 必填
                    prop: 'doctorName', // 必填
                },
                {
                    label: '服务类型', // 必填
                    prop: 'serviceName', // 必填
                    width: '200',
                },
                // {
                //     label: '服务完成时间', // 必填
                //     prop: 'serviceTime', // 必填
                //     formatter: (row, column) => {
                //         if(row.serviceTime !== null){
                //             return this.moment( row.serviceTime).format(
                //                 'YYYY-MM-DD HH:mm:ss'
                //             )
                //         }
                //         }
                // },
                {
                    label: '支付渠道', // 必填
                    prop: 'payWay', // 必填
                },
                {
                    label: '订单原价', // 必填
                    prop: 'originalFee', // 必填
                },
                {
                    label: '订单折扣价', // 必填
                    prop: 'discountedFee', // 必填
                },
                {
                    label: '实际支付金额', // 必填
                    prop: 'actualFee', // 必填
                },
                {
                    label: '订单服务次数', // 必填
                    prop: 'serviceNo', // 必填
                    formatter: (row, column) => {
                        if(row.goodsName !== '--'){
                            return '--'
                        }else{
                            return row.serviceNo
                        }
                    }
                },
                {
                    label: '时间段内完成次数', // 必填
                    prop: 'completeNo', // 必填
                    width: '150',
                    formatter: (row, column) => {
                        if(row.goodsName !== '--'){
                            return '--'
                        }else{
                            return row.completeNo
                        }
                    }
                },
                // {
                //     label: '单笔后台服务费', // 必填
                //     prop: 'perDoneFee' // 必填
                // },
                {
                    label: '总计服务费', // 必填
                    prop: 'totalFee' ,// 必填
                },

               
                ]
            },
            paramObj: null, // 筛选项
            total: 0, // 总条数
            checkId: '', // 单条数据id 用于详情或编辑请求数据用
            timeStamp: '',
            backStatus: true, //返回按钮的隐藏显示
            hospitalList:[],//医院列表
        }
    },
    created() {
        this.getList();
    },
    mounted: function() {},
    methods: {
        search(data) {
            this.paramObj = data
            this.$refs.mainTable.handleCurrentChange(1)
        },
        toExport() {
          let params = this.getParam();
          console.table(params)
          let url = process.env.VUE_APP_BASE_API + "/admin/finance/reconciliationExport";
          let _this = this;
          exportExcel(url,{},params,'康复师提现对账表','xlsx').then(res=>{
          }).catch(function (error) {
             _this.$message({
                    message: error.message || '导出失败',
                    duration: 5000,
                    type: "warning",
              });
          });
        },
        getList() {
            // 解决未挂载完组件时调用子组件方法报错问题
            this.$nextTick(() => {
                const param = this.getParam()
                getReconciliationList(param).then((res, timeStamp) => {
                    this.tableData = res.res.records;
                    this.timeStamp = timeStamp;
                    this.total = res.res.total;
                })
            })
            
        },
        objectSpanMethod(param, cb) {
            cb([])
        },

        onClickRow(checkArray) {
        },
        getParam() {
            // 获取参数
            const paramObj = this.$refs.mainSearch.packageData()
            this.paramObj = paramObj
            const page = this.$refs.mainTable.getPage()
            const param = Object.assign(page, paramObj)
            return param
        },
       
    }
}
</script>

<style lang='scss'>
@import "~@/styles/variables.scss";

.service-table-expand {
    .service-no{
        display: inline-block;
        width: 20px;
        text-align: center;
        font-weight: bold;
        
    }
    .el-form-item--mini.el-form-item{
        margin-bottom: 5px;
    }
}
</style>
