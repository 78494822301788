<template>
  <el-card class="basic-card">
    <el-form ref="insertForm" :model="form" label-position="right" label-width="130px" :rules="rules">
      <el-form-item label="方案名称" prop="schemeName">
        <el-input v-model="form.schemeName" placeholder="请输入..." clearable maxlength="200" />
      </el-form-item>
      <el-form-item label="副标题" prop="subTitle">
        <el-input type="textarea" v-model="form.subTitle" placeholder="请输入..." clearable maxlength="200" />
      </el-form-item>
      <el-form-item label="服务周期" prop="servicePeriod">
        <el-input type="number" v-model="form.servicePeriod" min="1" max="999" @change="periodChange"
          style="width: 7em;" />
        <span> 天</span>
      </el-form-item>
      <el-form-item label="适用病种" prop="diseaseIdList">
        <!-- <el-input v-model="form.diseaseIdList" placeholder="请输入..." clearable maxlength="200" /> -->
        <el-select v-model="form.diseaseIdList" :multiple-limit="10" multiple filterable clearable placeholder="请选择">
          <el-option v-for="op in diseaseList" :key="op.id" :label="op.name" :value="op.id" />
        </el-select>
      </el-form-item>
      <el-form-item label="服务医生" prop="doctorNameList">
        <div style="position: relative;">
          <el-input v-model="form.doctorNameList" placeholder="请输入..." />
          <div style="position: absolute; inset: 0;" @click="openDocDialog"></div>
        </div>
      </el-form-item>
      <el-form-item label="版权授权" prop="schemeAuth">
        <el-input v-model="form.schemeAuth" placeholder="请输入..." clearable maxlength="200" />
      </el-form-item>
      <el-col :sm="12" :md="12" :lg="12" :xl="12">
        <el-form-item label="方案标签" prop="tag">    
          <el-cascader 
            :options="tagOptions"
            ref="dieaseTagRef"
            :props="{ multiple: true, checkStrictly: true, label: 'tagName', value: 'tagValue', disabled: 'disable'}"
            v-model="form.tag"
            popper-class="addscheme-cascader-parent" maxlength="200" 
            clearable>
          </el-cascader>
        </el-form-item>
      </el-col>
      <el-col :sm="24" :md="24" :lg="24" :xl="24">
      <el-form-item label="版权归属" prop="copyright"
          :style="form.sourceOwnerType == '0' ? 'flex: 0 0 100%;': ''">
          <el-radio-group v-model="form.copyright">
            <el-radio-button label="0">平台</el-radio-button>
            <el-radio-button label="1">机构</el-radio-button>
            <el-radio-button label="2">医生</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-col :sm="12" :md="12" :lg="12" :xl="12">
          <template v-if="form.copyright == '1'">
            <el-form-item label="机构名称" prop="copyrightInstitutionIdList">
              <el-select v-model="form.copyrightInstitutionIdList" :filterable="true" :multiple-limit="10" multiple clearable
                placeholder="请选择">
                <el-option v-for="op in officeTree" :key="op.id" :label="op.name" :value="op.id" />
              </el-select>
            </el-form-item>
          </template>
          <template v-if="form.copyright == '2'">
            <el-form-item label="版权归属医生" prop="copyrightDoctorNameList">
              <div style="position: relative;">
                <el-input v-model="form.copyrightDoctorNameList" placeholder="请输入..." />
                <div style="position: absolute; inset: 0;" @click="openCopyrightDocDialog"></div>
              </div>
            </el-form-item>
          </template>
         </el-col>    
         <el-col :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="细分类目" prop="kfServiceType">    
            <el-cascader 
              :options="segmentedCategoriesOptions"
              ref="segmentedCategoriesRef"
              :props="{ multiple: true, checkStrictly: true, label: 'typeName', value: 'id', disabled: 'disable'}"
              v-model="form.kfServiceType"
              popper-class="addscheme-cascader-parent" maxlength="200" 
              clearable>
            </el-cascader>
          </el-form-item>
        </el-col>
      </el-col>
     
      <el-form-item label="是否定制化" prop="customize">
        <el-switch v-model="form.customize" :inactive-value="false" :active-value="true" inactive-text="否"
          active-text="是" />
      </el-form-item>
      <el-form-item label="是否生成结案报告" prop="genFinReport">
        <el-switch v-model="form.genFinReport" :inactive-value="false" :active-value="true" inactive-text="否"
          active-text="是" />
      </el-form-item>
    </el-form>
    <doctorListDialog ref="doctorListDialog" :defaultCheckIds="form.doctorIdList" @submit="setDoctorList" />
    <doctorListDialog ref="copyrightDoctorDialog" :defaultCheckIds="form.copyrightDoctorIdList" @submit="setCopyrightDocList" />
  </el-card>
</template>
<script>
  import request from '@/utils/request'
  import { getDoctorNames } from "@/api/doctorManagement";
  import { getSchemeTagsOptions, getServiceTypeList } from "@/api/project"
  import doctorListDialog from './doctorListDialog'

  export default {
    name: 'project-addBasic',
    components: {
      doctorListDialog
    },
    props: {
      form: {
        type: Object,
        default: () => { return {} }
      },
      // showSubmit: {
      //     type: Boolean,
      // }
    },
    data() {
      return {
        officeTree: [],
        diseaseList: [],
        tagOptions: [],
        segmentedCategoriesOptions: [],
        rules: {
          schemeName: [
            {
              required: true,
              message: "请输入方案名称",
              trigger: "blur",
            },
          ],
          subTitle: [
            {
              required: true,
              message: "请输入副标题",
              trigger: "blur",
            },
          ],
          servicePeriod: [
            {
              required: true,
              message: "请输入服务周期",
              trigger: "blur",
            },
          ],
          tag: [
            {
              required: true,
              message: "请选择方案标签",
              trigger: "blur",
            },
          ],
          kfServiceType: [
            {
              required: true,
              message: "请选择",
              trigger: "blur",
            },
          ],
          copyrightInstitutionList: [
            {
              required: true,
              message: "请输入机构名称",
              trigger: "change",
            },
          ],
          doctorNameList: [
            {
              required: true,
              message: "请输入医生姓名",
              trigger: "change",
            },
          ],
          diseaseIdList: [
            {
              required: true,
              message: "请输入适用病种",
              trigger: "blur",
            },
          ],
          doctorNameList: [
            {
              required: true,
              message: "请输入服务医生",
              trigger: "blur",
            },
          ],
        },
      };
    },
    computed: {

    },
    watch: {
    },
    created() {
      this.getDiseaseList();
      this.getOfficeTree();
      this.getSchemeTagsOptionsList();
      this.getKfServiceTypeList();
    },
    mounted() { },
    methods: {
      getOfficeTree() {
         // 机构树
        request({
          url: 'admin/kfHospital/downList',
          method: 'GET',
          showLoading: true
        }).then(({ res }) => {
          this.officeTree = res.records;
        })
      },
      getDiseaseList() {
        request({
          url: 'admin/kfSchemeInfo/getDiseaseList',
          method: 'GET',
          showLoading: true
        }).then(({ res, timeStamp }) => {
          // console.log('getDiseaseList -------> ', res);
          this.diseaseList = res;
        });
      },
      //获取方案标签
      getSchemeTagsOptionsList() {
        getSchemeTagsOptions({ tagOwnership: 'scheme' }).then(({ res }) => {
          this.tagOptions = res;
        });
      },
      getKfServiceTypeList() {
        getServiceTypeList({  }).then(({ res }) => {
          this.segmentedCategoriesOptions = res;
        });
      },
      openCopyrightDocDialog() {
        this.$refs.copyrightDoctorDialog.open();
      },
      openDocDialog() {
        this.$refs.doctorListDialog.open();
      },
      setDoctorList(rows) {
        let namesStr = '';
        const params = {
            ids: rows,
        }
        getDoctorNames(params).then(({ res }) => {
          console.log('getDoctorNames--', res)
          namesStr = res.map(r => r.name).join(',')
          console.log('namesStr--', namesStr)
          this.form.doctorIdList = rows;
          // this.form.doctorNameList = rows.map( r => r.name ).join(',');
          this.$set(this.form, 'doctorNameList',namesStr);
        });
       
      },
      setCopyrightDocList(rows) {
        let namesStr = '';
        const params = {
            ids: rows,
        }
        getDoctorNames(params).then(({ res }) => {
          console.log('getDoctorNames--', res)
          namesStr = res.map(r => r.name).join(',')
          console.log('namesStr--', namesStr)
          this.form.copyrightDoctorIdList = rows;
          // this.form.doctorNameList = rows.map( r => r.name ).join(',');
          this.$set(this.form, 'copyrightDoctorNameList',namesStr);
        });
       
      },
      submit() {
        console.log('this.selectTag---', this.selectTag)
        this.$refs.insertForm.validate((valid) => {
          if (valid) {
            const param = JSON.parse(JSON.stringify(this.form));
            delete param.doctorNameList;
            this.$emit("submit", param);
          }
        })
      },
      periodChange() {
        if (this.form.servicePeriod > 999) {
          this.form.servicePeriod = 999
        } else if (this.form.servicePeriod < 1) {
          this.form.servicePeriod = 1
        }
      },
      addMultipleTags(e){
        let that = this;
        this.$refs["dieaseTagRef"].getCheckedNodes().forEach(function(item){
          console.log('addMultipleTags--', item)
          const selectTagLabel = item.pathLabels.join('/');
          const selectTagIds = item.path.join('/');
          const tagIds = item.path;
          var flag = that.diseaseSelectTags.find(item => item.tagValue == selectTagIds)
          if(!flag){
            that.diseaseSelectTags.push({
              tagValue: selectTagIds,
              tagName: selectTagLabel,
              tagIds: tagIds
            })		// 如果没有就直接push
          }
        })
      },
      addTags(e){
        this.selectTagLabel = this.$refs["dieaseTagRef"].getCheckedNodes()[0].pathLabels.join('/');
        this.selectTagIds = this.selectTag.join('/')
        var flag = this.diseaseSelectTags.find(item => item.tagValue == this.selectTagIds)
        if(!flag){
          this.diseaseSelectTags.push({
            tagValue: this.selectTagIds,
            tagName: this.selectTagLabel,
            tagIds: this.selectTag
          })		// 如果没有就直接push
        }
       
      },
      diseaseTagChange($event){
      },
      handleClose(tag) {
        console.log('this.selectTag---', this.selectTag)
        const newtags = this.diseaseSelectTags.filter(item=>{
          return item.tagValue !== tag.tagValue
        })
        this.diseaseSelectTags = newtags;
        console.log('this.diseaseSelectTags----', this.diseaseSelectTags)
      },
    },
  };
</script>
<style lang="scss">
  .addscheme-cascader-parent{
    .el-cascader-panel .el-scrollbar:first-child .el-checkbox{
      display: none;
    }
    .el-cascader-menu__wrap{
      height: 300px;
    }
  }
</style>
