
<!--
* @Description: 新增城市级别，修改城市级别
* @Reference:
-->
<template>
    <el-dialog
        width="70%"
        :title="id === '' ? '新增城市级别' : '修改城市级别'"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        append-to-body
    >
        <el-form
            ref="insertForm"
            :model="form"
            label-position="right"
            label-width="120px"
            class="formBox"
            :rules="rules"
        >
            <div class="mdrcc-info-title">基本信息</div>
            <el-row :gutter="10" type="flex" justify="start">
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="级别编码" prop="code">
                        <el-input
                            v-model="form.code"
                            placeholder="请输入..."
                            clearable
                            maxlength="20"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="系数名称" prop="name">
                        <el-input
                            v-model="form.name"
                            placeholder="请输入..."
                            clearable
                            maxlength="20"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="排序" prop="sort">
                        <el-input
                            v-model="form.sort"
                            placeholder="请输入..."
                            clearable
                            maxlength="20"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="系数值" prop="introduction">
                        <el-input
                            v-model="form.introduction"
                            placeholder="请输入..."
                            clearable
                            maxlength="20"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="图片地址" prop="photoUrl">
                        <el-input
                            v-model="form.photoUrl"
                            placeholder="请输入..."
                            clearable
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="24" :md="24" :lg="24" :xl="24">
                    <el-form-item label="备注" prop="remark">
                        <el-input
                            v-model="form.remark"
                            type="textarea"
                            clearable
                            :autosize="{ minRows: 2, maxRows: 4}"
                            maxlength="200"
                            placeholder="请输入内容"
                        />
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submit()">提 交</el-button>
            <el-button @click="updateVisible(false)">取 消</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { createUser, updateUserInfo, getUserInfo } from '@/api/basicSetting'
export default {
    props: {
        id: {
            type: String
        },
    },
    data() {
        return {
            rules: {
                code: [
                    {
                        required: true,
                        message: '请输入分类编码',
                        trigger: 'blur'
                    }
                ],
                name: [
                    { required: true, message: '请输入分类名称', trigger: 'blur' },
                    {
                        min: 2,
                        max: 20,
                        message: '长度在 2 到 20 个字符',
                        trigger: 'blur'
                    }
                ],
                sort: [
                    { required: true, message: '请输入排序', trigger: 'blur' },
                ],
            },
            
            form: {
                id: '', 
                name: '', // 分类名称
                code: '', //分类编码
                sort: '', // 排序
                introduction: '', //简介
                photoUrl: '', //图片地址
                types:7, //类别(1:科室)
                remark:'', //备注
            },
            total: 0,
            visible: false,
            // timeStamp: '', // 时间戳
            submitFlag: true // 防抖
        }
    },
    watch: {
        visible(newVal, oldVal) {
            if (newVal === true && this.id ) {
                console.log('----------------------------')
                this.getUserInfo()

            }
        },
    },
    created() {},
    mounted() {},
    methods: {
        submit() {
            console.log('======',this.timeStamp)
            if (!this.submitFlag) {
                return
            }
            this.submitFlag = false
            this.$refs.insertForm.validate((valid) => {
                if (valid) {
                    const parm = this.form
                    if (!parm.id) {
                        console.log("这是创建")
                        console.log("查看创建iD",parm.id)
                        createUser(parm).then(({ res }) => {
                            this.submitSuccess(res)
                        }).catch((error)=> {
                            this.submitFailure(error)
                        })
                    } else {
                        console.log("这是修改")
                        console.log("查看修改iD",parm.id)
                        updateUserInfo(parm, this.timeStamp).then(({ res }) => {
                                this.submitSuccess(res)
                                this.form.id = ''
                        }).catch((error)=> {
                            this.submitFailure(error)
                        })
                    }
                } else{
                    this.submitFlag = true
                }
            })
        },
        submitFailure(error) {
            this.submitFlag = true
        },
        submitSuccess(res) {
            this.submitFlag = true
            this.$message({ ...this.commonConfig.$handleSuccess })
            this.$emit('submit')
            this.updateVisible(false)
        },
        getUserInfo() {
            const param = {}
            if (this.id) {
                param.id = this.id
            }
            getUserInfo(param).then(({ res ,timeStamp}) => {
                this.timeStamp = timeStamp
                this.form = res
            })
        },
        updateVisible(val) {
            this.visible = val
            if (val === false) {
                this.$refs.insertForm.resetFields()
                this.form.id = ''
            }
        }
    }
}
</script>
<style scoped>
</style>

