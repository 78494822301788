
/**
 * 页面: 商品管理
 * pageName: feeItems
 **/
import request from '@/utils/request'

/**
 * @description: 获取商品列表数据
 */
export function getList(data) {
    return request({
        url: 'admin/quipment/list',
        method: 'GET',
        params: data,
        showLoading: true
    })
}
/**
 * @description: 获取商品状态上架/下架
 */
 export function toGoodsState(data) {
    return request({
        url: 'admin/quipment/toGoodsState',
        method: 'POST',
        data,
        showLoading: true
    })
}

/**
 * @description: 新增商品数据
 */
export function insertData(data) {
    return request({
        url: 'admin/quipment/add',
        method: 'POST',
        data,
        showLoading: true
    })
}

/**
 * @description: 修改商品数据
 */
export function editData(data, timeStamp) {
    return request({
        url: 'admin/quipment/update',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}
            

/**
 * @description: 获取商品详情数据
 */
export function getDetail(data) {
    return request({
        url: 'admin/quipment/info',
        method: 'GET',
        params: data,
        showLoading: true
    })
}
            
/**
 * @description: 批量删除商品数据
 */
    export function deleteData(data, timeStamp) {
    return request({
        url: 'admin/quipment/delete',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}
/**
 * @description: 批量上架商品
 */
 export function toState(data, timeStamp) {
    return request({
        url: 'admin/quipment/toState',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}
/**
 * @description: 批量下架商品
 */
 export function toShelf(data, timeStamp) {
    return request({
        url: 'admin/quipment/toShelf',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}
        
