<template>
    <el-dialog width="100%" :title="id ? '修改商品' : '新增商品'" :visible.sync="visible" :close-on-click-modal="false"
        :close-on-press-escape="false" :show-close="false" append-to-body>
        <el-form ref="insertForm" :model="form" label-position="right" label-width="120px" class="formBox"
            :rules="rules">

            <el-row :gutter="10" type="flex" justify="start">
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="商品ID" prop="goodsId">
                        <el-input v-model="form.goodsId" placeholder="请输入" clearable maxlength="10" />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="商品名称" prop="name">
                        <el-input v-model="form.name" placeholder="请输入" clearable maxlength="64" />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="商品分类" prop="select">
                        <el-select v-model="form.goodsType" clearable placeholder="请选择" :filterable="true">
                            <el-option v-for="stands in goodsType" :key="stands.value" :label="stands.label"
                                :value="stands.value" />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="商品分类" prop="select">
                        <el-select v-model="form.goodsState" clearable placeholder="请选择" :filterable="true">
                            <el-option v-for="stands in goodsState" :key="stands.value" :label="stands.label"
                                :value="stands.value" />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="商品活动价格" prop="activityPrice">
                        <el-input v-model="form.activityPrice" placeholder="请输入" clearable maxlength="8"
                            onkeyup="this.value=this.value.replace(/[^\d.]/g,'')"
                            oninput="this.value=this.value.replace(/[^\d.]/g,'')"
                            @change="(text) => {this.form.activityPrice = text}" />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="商品实际价格" prop="actualPrice">
                        <el-input v-model="form.actualPrice" placeholder="请输入" clearable maxlength="8"
                            onkeyup="this.value=this.value.replace(/[^\d.]/g,'')"
                            oninput="this.value=this.value.replace(/[^\d.]/g,'')" 
                            @change="(text) => {this.form.actualPrice = text}"
                            />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="商品库存" prop="inventory">
                        <el-input v-model="form.inventory" placeholder="请输入" clearable maxlength="8" 
                        onkeyup="this.value=this.value.replace(/[^\d.]/g,'')"
                        oninput="this.value=this.value.replace(/[^\d.]/g,'')"
                        @change="(text) => {this.form.inventory = text}" />
                    </el-form-item>
                </el-col>


                <el-col :sm="192" :md="120" :lg="880" :xl="666">
                    <el-form-item label="备注" prop="remark">
                        <el-input v-model="form.remark" placeholder="请输入" clearable type="textarea" :rows="3"
                            resize="none" maxlength="100" />
                    </el-form-item>
                </el-col>
                <el-col :sm="24" :md="24" :lg="24" :xl="24">
                    <el-form-item label="商品详情" prop="details" class="content-wrapper">
                        <tinymce :height="500" v-if="visible" name="details" class="details" v-model="form.details" id="tinymce">
                        </tinymce>
                    </el-form-item>
                </el-col>

                <el-col :sm="12" :md="12" :lg="8" :xl="12">
                    <el-form-item label="商品图片" prop="photoUrl">
                        <div class="imgList">
                            <div v-for="(url, index) in form.photoList" class="defalutImg " :key="url+index">
                                <div class="remove" @click="removePhoto(index)"><i class="el-icon-error"></i></div>
                                <el-image style="width: 150px; height: 150px" :src="commonConfig.$filePublicUrl + url"
                                    fit="contain"></el-image>
                            </div>
                        </div>
                        <div class="img">
                            <div :src="form.photoUrl" @click="setLogoClick" class="defalutImg">
                                <i class="el-icon-plus"></i>
                            </div>
                        </div>
                        <input type="file" name="form.photoUrl" accept="image/gif,image/jpeg,image/jpg,image/png"
                            style="display: none" multiple="multiple" @change="changeLogoImage($event)"
                            ref="logoInput" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-divider />


        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submit()">提 交</el-button>
            <el-button @click="updateVisible(false)">取 消</el-button>
        </span>
    </el-dialog>
</template>
<script>
    import Tinymce from "@/components/Tinymce";
    import { insertData, editData, getDetail } from '@/api/products'
    import { getDict } from '@/api/common'
    import { uploadPrivateFile, uploadPublicFile } from "@/utils/uploadQiniu.js";

    export default {
        components: {
            Tinymce
        },
        props: {
            id: {
                type: String
            },
            timeStamp: {
                type: String
            }
        },
        updated() {
            console.log("form", this.form)
        },
        data() {
            return {
                rules: {
                    name: [
                        { required: true, message: '请输入商品名称', trigger: 'blur' }
                    ],
                  

                },
                form: {
                    name: "",
                    goodsId: "",
                    goodsType: "",
                    goodsState: "",
                    activityPrice: "",
                    actualPrice: "",
                    remark: "",
                    inventory: "",
                    photoList: [], // 商品图片列表
                    photoUrl: "", //商品图片
                    details: "" ,// 富文本
                    id: "",
                    goodsState: "1",
                    goodsType: "1",
                },
                goodsType: [],
                goodsState: [],
                visible: false,
            }
        },
        watch: {
            visible(newVal, oldVal) {
                if (newVal === true) {
                    if (this.id) {
                        this.getDetail()
                    }
                }
            },
        },
        created() {
            this.getDict();
            this.getDictType();
        },
        mounted() { },
        methods: {
            changePrice(text){
                console.log("change price", text)
            },
            submit() {
                this.$refs.insertForm.validate((valid) => {
                    if (valid) {
                        const parm = { ...this.form }
                        console.log("------submit", parm)
                        if (!this.id) {
                            insertData(parm).then(({ res }) => {
                                this.submitSuccess(res)
                            })
                        } else {
                            parm.id = this.id
                            editData(parm, this.timeStamp).then(({ res }) => {
                                this.submitSuccess(res)
                            })
                        }
                    }
                })
            },
            submitSuccess(res) {
                this.$message({
                    showClose: true,
                    message: '操作成功',
                    type: 'success'
                })
                this.$emit('submit')
                this.updateVisible(false)
            },
            getDetail() {
                const param = {}
                if (this.id) {
                    param.id = this.id
                }
                getDetail(param).then(({ res, timeStamp }) => {
                    const { id, ...temp } = res
                    this.form = temp
                    this.form.photoList = this.form.photoUrl.split(",")
                })
            },
            getDictType() {
                getDict({ type: 'goods_type' }).then(({ res }) => {
                    this.goodsType = res.goods_type;
                });
            },
            getDict() {
                getDict({ type: 'goods_state' }).then(({ res }) => {
                    this.goodsState = res.goods_state;
                });
            },
            updateVisible(val) {
                if (val === false) {
                    this.$refs.insertForm.resetFields()
                    this.form = {
                        name: "",
                        goodsId: "",
                        details: "" ,// 富文本
                        goodsType: "",
                        goodsState: "",
                        activityPrice: "",
                        actualPrice: "",
                        remark: "",
                        inventory: "",
                        id: "",
                        goodsState: "1",
                        goodsType: "1",
                    }
                }
                this.visible = val
            },
            removePhoto(index) {
                const { $delMsg, $delTitle, $messageBoxConfig, $handleSuccess } = this.commonConfig
                this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
                    this.form.photoList.splice(index, 1)
                    this.form.photoUrl = this.form.photoList.join()
                })
            },

            setLogoClick() {
                this.$refs.logoInput.click();
            },

            uploadPublicFile(file) {
                return new Promise((resolve) => {
                    uploadPublicFile(file)
                        .then((success) => {
                            console.log("upload success------", success)
                            this.form.photoList.push(success)
                            resolve(success)
                        })
                        .catch((handleError) => {
                            console.log("handleError====", handleError);
                        });
                })
            },

            /**
            * @description: 上传图片
            * @param {*} event
            * @return {*}
            */
            async changeLogoImage(event) {
                console.log("files------", event.target.files)
                let file = event.target.files[0];
                const thiz = this
                for (let file of event.target.files) {
                    await this.uploadPublicFile(file)
                }
                this.form.photoUrl = this.form.photoList.join()
                // thiz.form.photoUrl = success
                // uploadPublicFile(file)
                //     .then((success) => {
                //         console.log("success====", success);
                //         thiz.form.photoUrl = success
                //     })
                //     .catch((handleError) => {
                //         console.log("handleError====", handleError);
                //     });

            },
        }
    }
</script>
<style scoped lang='scss'>
    @import "~@/styles/variables.scss";

    .imgBox {
        width: 150px;
        height: 150px;
        object-fit: contain;
        border: 1px solid #333;
    }

    .imgList {
        display: flex;
        column-gap: 10px;
        flex-wrap: wrap;
        row-gap: 10px;
        margin: 10px 0;
    }

    .remove {
        position: absolute;
        right: -0.5em;
        top: -0.5em;
        line-height: 1;
        font-size: 16px;
        color: #aaaaaa;
        background-color: #ffffff;
        cursor: pointer;
    }

    .defalutImg {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 150px;
        height: 150px;
        border: 1px solid #333;
        border-radius: 4px;
        cursor: pointer;
        position: relative;
    }
</style>