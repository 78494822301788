var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:['TUI-profile', !_vm.isPC && 'TUI-profile-h5']},[_c('div',{class:['TUI-profile-basic', !_vm.isPC && 'TUI-profile-h5-basic']},[_c('div',{class:['TUI-profile-basic-info', !_vm.isPC && 'TUI-profile-h5-basic-info']},[_c('div',{class:['TUI-profile-basic-info-id', !_vm.isPC && 'TUI-profile-h5-basic-info-id']},[_c('label',{class:[
            'TUI-profile-basic-info-id-label',
            !_vm.isPC && 'TUI-profile-h5-basic-info-id-label',
          ]},[_vm._v("账号:"+_vm._s(_vm.userAccount))])])])]),_c('div',{class:['TUI-profile-basic', !_vm.isPC && 'TUI-profile-h5-basic']},[_c('div',{class:['TUI-profile-basic-info', !_vm.isPC && 'TUI-profile-h5-basic-info']},[_c('div',{class:['TUI-profile-basic-info-id', !_vm.isPC && 'TUI-profile-h5-basic-info-id']},[_c('label',{class:[
            'TUI-profile-basic-info-id-label',
            !_vm.isPC && 'TUI-profile-h5-basic-info-id-label',
          ]},[_vm._v("昵称:康复助理")])])])]),_c('div',{class:['TUI-profile-basic', !_vm.isPC && 'TUI-profile-h5-basic']},[_c('div',{class:['TUI-profile-basic-info', !_vm.isPC && 'TUI-profile-h5-basic-info']},[_c('div',{class:['TUI-profile-basic-info-id', !_vm.isPC && 'TUI-profile-h5-basic-info-id']},[_c('label',{class:[
            'TUI-profile-basic-info-id-label',
            !_vm.isPC && 'TUI-profile-h5-basic-info-id-label',
          ]},[_vm._v("身份:客服专员")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }