<!--
 * @Author: 马广源
 * @Date: 2022-05-12 14:56:55
 * @LastEditTime: 2023-07-17 15:56:06
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /dph-platform-ui-dev/Users/shangxue/newgit_workspace/recovery_home_management_dev/src/views/financial/embody/index.vue
-->

<!--
* @Description: 提现管理
* @Reference:
-->

<template>
  <el-container>
    <el-header height="auto">
      <searchForm ref="mainSearch" :searchoptions="searchOptions" exportStatus @exportFun="toExport" @search="search" :backStatus="backStatus" />
    </el-header>
    <el-main class="basic-main">
        <div class="withBtnTabs">
            <el-tabs ref="tabs" style="width: 100%" v-model="activeName" @tab-click="clickTab">
                <el-tab-pane ref="approvalTab" :lazy="true" name="pendingApproval" label="待审批">
                    <pendingApproval ref="pendingApproval" v-if="activeName == 'pendingApproval'" :searchObj="paramObj" ></pendingApproval>
                </el-tab-pane>
                <el-tab-pane :lazy="true" ref="withdrawTab" name="pendingWithdraw" label="待提现">
                    <pendingWithdraw  ref="pendingWithdraw" v-if="activeName == 'pendingWithdraw'" :searchObj="paramObj" @changeBatchBtn="updateBatchBtnVisible"></pendingWithdraw>
                </el-tab-pane>
                <el-tab-pane :lazy="true" ref="completedTab" name="completedWithdraw" label="已完结">
                    <completedWithdraw  ref="completedWithdraw" v-if="activeName == 'completedWithdraw'" :searchObj="paramObj"></completedWithdraw>
                </el-tab-pane>
            </el-tabs>
            <el-button size="mini" v-show="batchBtnVisible" type="primary"  @click="batchWithDraw()" class="withdrawBtn">批量提现</el-button>         
        </div>
    </el-main>
  </el-container>
</template>
<script>
import searchForm from '@/components/SearchForm'
import generalTable from '@/components/Table'
import pendingApproval from "@/views/financial/embody/pendingApproval";
import pendingWithdraw from "@/views/financial/embody/pendingWithdraw";
import completedWithdraw from "@/views/financial/embody/completedWithdraw";
import { getAllHospitalList } from '@/api/embodyAndRefund';
import { getHospitalList } from '@/api/hospitalManagement';

import { exportExcel } from "@/utils/common";
export default {
    name: 'Test',
    components: {
        searchForm,
        generalTable,
        pendingApproval,
        pendingWithdraw,
        completedWithdraw
    },
    watch: {
        paramObj(newVal, oldVal) {
            if (newVal) {
                  // 0. 提现失败  1. 已到账  2. 提现中 3. 待审批（操作：审批）4. 待提现：（操作：提现） 5. 审批拒绝
                if(newVal.withdrawStatus == '3' || newVal.withdrawStatus == ''){
                    this.activeName = 'pendingApproval';
                }else if(newVal.withdrawStatus == '4'){
                    this.activeName = 'pendingWithdraw';
                }else{
                    this.activeName = 'completedWithdraw';
                }
            }
        }
    },
    data() {
        return {
            batchBtnVisible: false,
            tableData: [
            ],
            searchOptions: [
                {
                    type: 'input',
                    placeholder: '请输入...',
                    key: 'withdrawSerial',
                    label: '提现单号',
                    defaultVal: ''
                },
                {
                    type: 'input',
                    placeholder: '请输入...',
                    key: 'userName',
                    label: '医生姓名',
                    defaultVal: '',
                },
                {
                    type: 'select',
                    placeholder: '请选择',
                    key: 'kfHospitalId',
                    filterable: true,
                    multiple: false,
                    label: '所属医院',
                    options: [],
                    defaultVal: '',
                     page: 1,
                    remote: true,
                    filterable: true,
                    remoteMethod: (params) => this.getRemoteHospital(params),
                    getData: (params) => this.getMoreHospital(params),// load-select 使用接口，暂未使用代码，为以后下拉列表滚动加载获取医院 待完善
                    loading: false,
                },
                {
                    type: 'daterange',
                    startPlaceholder: '开始日期',
                    endPlaceholder: '结束日期',
                    key: 'withdrawTime',
                    label: '提现时间',
                    defaultVal: []
                },
                {
                    type: 'select',
                    key: 'withdrawType',
                    filterable: true,
                    label: '提现方式',
                    options: [
                        {
                            label: '微信',
                            value: '1'
                        },
                        {
                            label: '支付宝',
                            value: '0'
                        },
                        {
                            label: '银行卡',
                            value: '2'
                        }
                    ],
                    defaultVal: ''
                },
                {
                    type: 'select',
                    key: 'withdrawStatus',
                    filterable: true,
                    label: '提现状态',
                    options: [
                    // 0. 提现失败  1. 已到账  2. 提现中 3. 待审批（操作：审批）4. 待提现：（操作：提现） 5. 审批拒绝
                        {
                            label: '审批拒绝',
                            value: '5'
                        },
                        {
                            label: '待提现',
                            value: '4'
                        },
                        {
                            label: '待审批',
                            value: '3'
                        },
                        {
                            label: '提现中',
                            value: '2'
                        },
                        {
                            label: '已到账',
                            value: '1'
                        },
                        {
                            label: '提现失败',
                            value: '0'
                        }
                    ],
                    defaultVal: ''
                }
            ],
            paramObj: null, // 筛选项
            timeStamp: '',
            backStatus: true, //返回按钮的隐藏显示
            activeName: "pendingApproval",
            hospitalList: [] //医院
        }
    },
    created() {
       this.getHospital();
    },
    mounted: function() {},
    methods: {
        search(data) {
            this.paramObj = data
            //this.$refs.mainTable.handleCurrentChange(1)
        },
        getParam() {
           // 获取参数
            const paramObj = this.$refs.mainSearch.packageData()
            this.paramObj = paramObj
            const page = this.$refs.mainTable.getPage()
            const param = Object.assign(page, paramObj)
            return param
        },
        toExport() {
          let params = this.$refs[this.activeName].getParam();
          const paramObj = this.$refs.mainSearch.packageData();
          let url = process.env.VUE_APP_BASE_API + "/admin/finance/export";
          let _this = this;
          exportExcel(url,{},paramObj,'导入慧用工系统结算报表','xls').then(res=>{
          }).catch(function (error) {
             _this.$message({
                    message: error.message || '导出失败',
                    duration: 5000,
                    type: "warning",
              });
          });
        },
        clickTab(tab, event) {
            this.activeName = tab.name;
		},
        onQuery() {
            this.$refs[this.activeName].getList();
        },
         // 获取医院
        getHospital() {
            const param = {size:20}
            getAllHospitalList(param).then(({ res }) => {
                this.hospitalList = res.records.map((item) => {
                    return {
                        value: item.id,
                        label: item.name,
                    };
                });
                this.searchOptions[2].options = this.hospitalList
            });
        },
         // 未使用代码，为以后下拉列表滚动加载获取医院 待完善
        getMoreHospital(param) {
            const paramObject = param;
            return new Promise( resolve => {
                 getHospitalList(paramObject).then(({ res }) => {
                   const hospitalListTemp = res.records.map((item) => {
                        return {
                            value: item.id,
                            label: item.name,
                        };
                    });
                    this.searchOptions[2].options = [...this.searchOptions[2].options, ...hospitalListTemp];
                   // this.searchOptions[2].options = this.hospitalList;   
                    // resolve({current: res.current, pages: res.pages, options: this.hospitalList})
                });
            })
           
        },
        updateBatchBtnVisible( flag ) {
            this.batchBtnVisible = flag;
        },
        // 批量提现
        batchWithDraw(){
            this.$refs['pendingWithdraw'].toBatchWithdraw();
        },
        getRemoteHospital(query) {
            // 如果用户输入内容了，就发请求拿数据，远程搜索模糊查询
            if (query !== "") {
                this.loading = true; // 开始拿数据喽
                let allHospitalList = [];
                getAllHospitalList({name: query}).then(({ res }) => {
                allHospitalList = res.records.map((item) => {
                        return {
                            value: item.id,
                            label: item.name,
                        };
                    });
                    this.loading = false // 拿到数据喽
                    this.searchOptions[2].options = allHospitalList;
                });
            } else {
                this.searchOptions[2].options = [];
            }
        },
    }
}
</script>

<style lang='scss'>
@import "~@/styles/variables.scss";
.withBtnTabs{
    position: relative;
}
.withdrawBtn{
    position: absolute;
    top:0px;
    right:20px;
    z-index: 100;
}

</style>
