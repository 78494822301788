
<!--
* @Description: 新增医生，修改医生
* @Reference:
-->
<template>
    <el-dialog
        width="70%"
        :title="id === '' ? '新增用户' : '修改用户'"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        append-to-body
    >
        <el-form
            ref="insertForm"
            :model="form"
            label-position="right"
            label-width="140px"
            class="formBox"
            :rules="rules"
        >
            <div class="mdrcc-info-title">基本信息</div>
            <el-row :gutter="15" type="flex" justify="start">
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="排序" prop="sort">
                        <el-input
                            v-model="form.sort"
                            placeholder="请输入..."
                            clearable
                            maxlength="20"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="医生姓名" prop="name">
                        <el-input
                            v-model="form.name"
                            placeholder="请输入..."
                            clearable
                            maxlength="20"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="密码" prop="password">
                        <el-input
                            v-model="form.password"
                            placeholder="请输入..."
                            clearable
                            maxlength="20"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="手机号" prop="phoneNumber">
                        <el-input
                            v-model="form.phoneNumber"
                            placeholder="请输入..."
                            clearable
                            maxlength="11"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="登录名" prop="loginName">
                        <el-input
                            v-model="form.loginName"
                            placeholder="请输入..."
                            clearable
                            maxlength="20"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="微信" prop="wechat">
                        <el-input
                            v-model="form.wechat"
                            placeholder="请输入..."
                            clearable
                            maxlength="20"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="QQ" prop="qq">
                        <el-input
                            v-model="form.qq"
                            placeholder="请输入..."
                            clearable
                            maxlength="20"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="医生推荐码" prop="recommendationCode">
                        <el-input
                            v-model="form.recommendationCode"
                            placeholder="请输入..."
                            clearable
                            maxlength="20"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="余额" prop="balance">
                        <el-input
                            v-model="form.balance"
                            placeholder="请输入..."
                            clearable
                            maxlength="20"
                        />
                    </el-form-item>
                </el-col>

                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="医生类型" prop="type">
                        <el-select
                        v-model="form.type"
                        clearable
                        placeholder="请选择"
                        :filterable="true"
                    >
                        <el-option
                        v-for="option in doctorTypeList"
                        :key="option.value"
                        :label="option.label"
                        :value="option.value"
                        />
                    </el-select>
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="注册时间" prop="createDate">
                        <el-date-picker
                            v-model="form.createDate"
                            align="right"
                            type="date"
                            clear-icon="el-input__icon"
                            placeholder="选择日期"
                            :editable="false"
                            style="width: 100%"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="医院" prop="kfHospitalId">
                        <el-select
                        v-model="form.kfHospitalId"
                        clearable
                        placeholder="请选择"
                        :filterable="true"
                        remote
                        :remote-method="(params) => {getRemoteHospital(params)}"
                    >
                        <el-option
                        v-for="option in hospitalList"
                        :key="option.value"
                        :label="option.label"
                        :value="option.value"
                        />
                    </el-select>
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="平台技术服务费比例" prop="platformCharge">
                        <el-input
                            v-model="form.platformCharge"
                            placeholder="请输入..."
                            clearable
                            maxlength="10"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="税费比例" prop="taxRate">
                        <el-input
                            v-model="form.taxRate"
                            placeholder="请输入..."
                            clearable
                            maxlength="10"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6" v-if="false">
                    <el-form-item label="身份证号" prop="idNo">
                        <el-input
                            v-model="form.idNo"
                            placeholder="请输入..."
                            clearable
                            maxlength="18"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6"  v-if="false">
                    <el-form-item label="银行卡号" prop="bankNo">
                        <el-input
                            v-model="form.bankNo"
                            placeholder="请输入..."
                            clearable
                            maxlength="20"
                        />
                    </el-form-item>
                </el-col>
              </el-row>
                <el-row :gutter="10" type="flex" justify="start">
                <el-col :sm="12" :md="12" :lg="8" :xl="12"  v-if="false">
                    <el-form-item label="身份证人像面" prop="idFrontPic">
                        <div class="img">
                            <img
                                :src="form.idFrontPicUrl"
                                v-show="form.idFrontPic"
                                @click="setIdCardFrontClick"
                                class="imgBox"
                            />
                            <i style="font-size: 18px;color: red;" class="el-icon-error delete-btn"
                              @click="removeIdFrontPic()" v-show="form.idFrontPicUrl"></i>
                            <div
                                :src="form.idFrontPic"
                                v-show="!form.idFrontPic"
                                @click="setIdCardFrontClick"
                                class="defalutImg"
                            >
                            <img class="id-card-img" :src="obverse" mode=""/>
                            </div>
                        </div>
                        <input
                            type="file"
                            name="form.idFrontPic"
                            accept="image/gif,image/jpeg,image/jpg,image/png"
                            style="display: none"
                            @change="uploadIdCardFrontImage($event)"
                            ref="idFrontInput"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="12" v-if="false">
                    <el-form-item label="身份证国徽面" prop="idBackPic">
                        <div class="img">
                            <img
                                :src="form.idBackPicUrl"
                                v-show="form.idBackPic"
                                @click="setIdCardBackClick"
                                class="imgBox"
                            />
                            <i style="font-size: 18px;color: red;" class="el-icon-error delete-btn"
                              @click="removeIdBackPic()" v-show="form.idBackPicUrl"></i>
                            <div
                                :src="form.idBackPic"
                                v-show="!form.idBackPic"
                                @click="setIdCardBackClick"
                                class="defalutImg"
                            >
                            <img class="id-card-img" :src="reverse" mode=""/>
                            </div>
                        </div>
                        <input
                            type="file"
                            name="form.idBackPic"
                            accept="image/gif,image/jpeg,image/jpg,image/png"
                            style="display: none"
                            @change="uploadIdCardBackImage($event)"
                            ref="idBackInput"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="6" :md="6" :lg="8" :xl="6">
                    <el-form-item label="头像" prop="photo">
                        <div class="img">
                            <img
                                :src="commonConfig.$filePublicUrl + form.photo"
                                v-show="form.photo"
                                @click="setLogoClick"
                                class="imgBox"
                            />
                            <i style="font-size: 18px;color: red;" class="el-icon-error delete-btn"
                              @click="removePhoto()" v-show="form.photo"></i>
                            <div
                                :src="form.photo"
                                v-show="!form.photo"
                                @click="setLogoClick"
                                class="defalutImg"
                            >
                                <i class="el-icon-plus"></i>
                            </div>
                        </div>
                        <input
                            type="file"
                            name="form.photo"
                            accept="image/gif,image/jpeg,image/jpg,image/png"
                            style="display: none"
                            @change="changeLogoImage($event)"
                            ref="logoInput"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="6" :md="6" :lg="8" :xl="6">
                    <el-form-item label="签名" prop="signPhoto">
                        <div class="img">
                            <img
                                :src="commonConfig.$filePublicUrl + form.signPhoto"
                                v-show="form.signPhoto"
                                @click="setSignPhotoClick"
                                class="imgBox"
                            />
                            <i style="font-size: 18px;color: red;" class="el-icon-error delete-btn"
                              @click="removeSignPhoto()" v-show="form.signPhoto"></i>
                            <div
                                :src="form.signPhoto"
                                v-show="!form.signPhoto"
                                @click="setSignPhotoClick"
                                class="defalutImg"
                            >
                                <i class="el-icon-plus"></i>
                            </div>
                        </div>
                        <input
                            type="file"
                            name="form.signPhoto"
                            accept="image/gif,image/jpeg,image/jpg,image/png"
                            style="display: none"
                            @change="changeSignPhotoImage($event)"
                            ref="signPhotoInput"
                        />
                    </el-form-item>
                </el-col>
                <!-- <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="状态" prop="status">
                        <el-switch
                            v-model="form.status"
                            name="status"
                            active-text="启用"
                            inactive-text="禁用"
                            :active-value="0"
                            :inactive-value="1"
                        />
                    </el-form-item>
                </el-col> -->
                <el-col :sm="24" :md="24" :lg="24" :xl="24">
                    <el-form-item label="备注" prop="remark">
                        <el-input
                            v-model="form.remark"
                            type="textarea"
                            clearable
                            :autosize="{ minRows: 2, maxRows: 4}"
                            maxlength="200"
                            placeholder="请输入内容"
                        />
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submit()">提 交</el-button>
            <el-button @click="updateVisible(false)">取 消</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { createUser, updateUserInfo, getUserInfo } from '@/api/doctorManagement'
import { validatorPhone, validID, validBankId } from '@/utils/validate'
import {getKfType} from '@/api/basicSetting'
import { getHospitalList} from '@/api/hospitalManagement'
import { uploadPrivateFile, uploadPublicFile } from "@/utils/uploadQiniu.js";
import 	idCardFile from "@/utils/idCardImage";
import { getAllHospitalList } from '@/api/embodyAndRefund';
export default {
    props: {
        id: {
            type: String
        }
    },
    data() {
        return {
            obverse: idCardFile.obverse,
				    reverse: idCardFile.reverse,
            rules: {
                sort: [
                    {
                        required: true,
                        message: '请输入医生排序',
                        trigger: 'blur'
                    }
                ],
                name: [
                    { required: true, message: '请输入医生姓名', trigger: 'blur' },
                    {
                        min: 2,
                        max: 20,
                        message: '长度在 2 到 20 个字符',
                        trigger: 'blur'
                    }
                ],
                idNo: [{ required: false, message: '身份证号不能为空', trigger: 'change' },
                  { validator: validID, trigger: 'change' }
                ],
                bankNo: [{ required: false, message: '银行卡号不能为空', trigger: 'change' },
                  { validator: validBankId, trigger: 'change' }
                ],
                phoneNumber: [
                    {
                        required: true,
                        validator: validatorPhone,
                        trigger: 'blur'
                    }
                ],
                createDate: [
                    {
                        required: true,
                        message: '请选择日期',
                        trigger: 'change'
                    }
                ],
                kfHospitalId: [
                    { required: true, message: '请选择医院', trigger: 'change' }
                ],
                type: [
                    { required: true, message: '请选择医生类型', trigger: 'change' }
                ],
            },
            form: {
                sort: '', // 医生序号
                name: '', // 医生姓名
                idBackPic: '',// 身份证国徽面
                idFrontPic: '',// 身份证人像面
                bankNo: '',// 银行卡号
                idNo: '',// 身份证号
                password: '', // 密码
                phoneNumber: '', // 电话
                idBackPic:'',// 身份证国徽面
                idFrontPic:'',// 身份证人像面
                createDate:'',//注册时间
                type:'',//医生类型
                hospitalName: '', // 所属医院
                id: '', // 医生id
                kfHospitalId: '', // 医院所属id
                status: 0, // 状态(默认为启用)
                taxRate:'',//税费比例
                platformCharge:'',//平台技术服务费比例
                photo:'',//头像
                signPhoto: '', // 签名
                remark: '', // 备注
            },
            total: 0,
            visible: false,
            oldNum: '',
            // fileList: [],
            doctorTypeList: [],//医生类型
            hospitalList:[],//医院
            // uploadDisabled: '',
            timeStamp: '', // 时间戳
            submitFlag: true // 防抖
        }
    },
    watch: {
        visible(newVal, oldVal) {
            if (newVal === true && this.id ) {
                this.getUserInfo()
                // console.log(this.id)
                // delete this.rules['idNo']
                // delete this.rules['bankNo']
                // console.table('this.rules=',this.rules);
            }
        }
    },
    created() {
         this.getHospital()
    },
    mounted() {
        this.getDoctorTypes()
    },
    methods: {
        submit() {
            if (!this.submitFlag) {
                return
            }

            this.$refs.insertForm.validate((valid) => {
                if (valid) {
                    const parm = this.form
                    const time = new Date(parm.createDate).getTime()
                    parm.createDate = time
                    delete parm["idBackPicUrl"];
                    delete parm["idFrontPicUrl"];
                    if (!parm.id) {
                       this.submitFlag = false
                        createUser(parm).then(({ res }) => {
                            this.submitSuccess(res)
                        }).catch((error)=> {
                            this.submitFailure(error)
                        })
                    } else {
                        this.submitFlag = false
                        updateUserInfo(parm, this.timeStamp).then(({ res }) => {
                                this.submitSuccess(res)
                                this.form.id = ''
                        }).catch((error)=> {
                            this.submitFailure(error)
                        })
                    }
                } else{
                    this.submitFlag = true
                }
            })
        },
        submitFailure(error) {
            this.submitFlag = true
        },
        submitSuccess(res) {
            this.submitFlag = true
            this.$message({ ...this.commonConfig.$handleSuccess })
            this.$emit('submit')
            this.updateVisible(false)
        },
        removeIdFrontPic(){
          this.form.idFrontPic = null;
          this.form.idFrontPicUrl = null;
        },
        removeIdBackPic(){
          this.form.idBackPic = null;
          this.form.idBackPicUrl = null;
        },
        removePhoto(){
          this.form.photo = null;
        },


        setSignPhotoClick(){
            this.$refs.signPhotoInput.click();
        },
        removeSignPhoto(){
          this.form.signPhoto = null;
        },
        changeSignPhotoImage(event) {
          let file = event.target.files[0];
          if (!file) return;
          const thiz = this;
          uploadPublicFile(file)
              .then((success) => {
                  console.log("success====", success);
                  thiz.form.signPhoto = success;
                  console.log("success====11111111", thiz.form.signPhoto);
              }).catch((handleError) => {
                  this.$message({
                      message: "上传失败，请重试",
                      type: "warning",
                  });
                  console.log("handleError====", handleError);
              });
        },
        getUserInfo() {
            const param = {}
            if (this.id) {
                param.id = this.id
            }
            getUserInfo(param).then(({ res, timeStamp }) => {
                this.timeStamp = timeStamp
                this.form = res
            })
        },
        updateVisible(val) {
            this.visible = val
            if (val === false) {
                // this.fileList = []
                //  this.uploadDisabled = ''
                this.$refs.insertForm.resetFields()
                this.form.id = ''
                // 编辑状态时,以下自动未显示resetFields()不生效，需手动清空
                this.form.bankNo = ''
                this.form.idNo = ''
                this.form.idFrontPic = ''
                this.form.idBackPic = ''
                // 重置所属医院下拉选项
                this.getHospital();
            }
        },
        //上传头像
        setLogoClick() {
            this.$refs.logoInput.click();
        },
        setIdCardFrontClick() {
            this.$refs.idFrontInput.click();
        },
        setIdCardBackClick() {
            this.$refs.idBackInput.click();
        },
        /**
         * @description: 上传头像
         * @param {*} event
         * @return {*}
         */
        changeLogoImage(event) {
            let file = event.target.files[0];
            if (!file) return;
            const thiz = this;
            uploadPublicFile(file)
                .then((success) => {
                    console.log("success====", success);
                    thiz.form.photo = success;
                    console.log("success====11111111", thiz.form.photo);
                })
                .catch((handleError) => {
                    this.$message({
                        message: "上传失败，请重试",
                        type: "warning",
                    });
                    console.log("handleError====", handleError);
                });
        },
         /**
         * @description: 上传身份证正面
         * @param {*} event
         * @return {*}
         */
         uploadIdCardFrontImage(event) {
          console.table(event.target);
            let file = event.target.files[0];
            if (!file) return;
            const _this = this;
            uploadPrivateFile(file)
                .then((success) => {
                console.log("success====", success);
                let oFReader = new FileReader()
                oFReader.readAsDataURL(file)
                oFReader.onloadend = function(oFRevent) {
                  _this.form.idFrontPicUrl = oFRevent.target.result;
                  _this.form.idFrontPic = success
                }
                console.log("success====11111111");
            })
            .catch((handleError) => {
                this.$message({
                    message: "上传失败，请重试",
                    type: "warning",
                });
                console.log("handleError====", handleError);
            });
        },
        /**
         * @description: 上传身份证反面
         * @param {*} event
         * @return {*}
         */
         uploadIdCardBackImage(event) {
            let file = event.target.files[0];
            if (!file) return;
            const _this = this;
            uploadPrivateFile(file)
                .then((success) => {
                console.log("success====", success);
                let oFReader = new FileReader()
                oFReader.readAsDataURL(file)
                oFReader.onloadend = function(oFRevent) {
                  _this.form.idBackPicUrl = oFRevent.target.result;
                  _this.form.idBackPic = success;
                }
                console.log("success====11111111");
            })
            .catch((handleError) => {
              _this.$message({
                    message: "上传失败，请重试",
                    type: "warning",
                });
                console.log("handleError====", handleError);
            });
        },
        //获取医师生类型
        getDoctorTypes() {
            getKfType({ types: "2" }).then(({ res }) => {
                this.doctorTypeList = res.map((item) => {
                    return {
                        value: item.code,
                        label: item.name,
                    };
                });
            });
        },
        // 获取医院
        getHospital() {
            const param = []
            getAllHospitalList(param).then(({ res }) => {
                this.hospitalList = res.records.map((item) => {
                    return {
                        value: item.id,
                        label: item.name,
                    };
                });
            });
        },
        getRemoteHospital(query) {
            // 如果用户输入内容了，就发请求拿数据，远程搜索模糊查询
            if (query !== "") {
                this.loading = true; // 开始拿数据喽
                let allHospitalList = [];
                getAllHospitalList({name: query}).then(({ res }) => {
                allHospitalList = res.records.map((item) => {
                        return {
                            value: item.id,
                            label: item.name,
                        };
                    });
                    this.loading = false // 拿到数据喽
                    this.hospitalList = allHospitalList;
                    console.log('---------this.hospitalList-------------', this.hospitalList)
                });
            } else {
                this.hospitalList = [];
            }
        },
    }
}
</script>
<style scoped lang='scss'>
@import "~@/styles/variables.scss";

.imgBox {
    width: 150px;
    height: 150px;
    border-radius: 4px;
    object-fit: contain;
    border: 1px solid #333;
}
.defalutImg {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 150px;
    border: 1px solid #333;
    border-radius: 4px;
    cursor: pointer;
}

.id-card-img {
    width: 145px;
    height: 120px;
}
.el-col {
  margin-bottom: 5px;
 }

 .delete-btn {
  // position: relative;
  width: 18px;
  height: 18px;
  font-size: 18px;
  color: red;
  position: absolute;
  margin-top: 4px;
  cursor: pointer;
 }
</style>

