
<!--
* @Description: 医生账户页面
* @Reference:
-->

<template>
  <el-container>
    <el-header height="auto">
      <searchForm ref="mainSearch" :searchoptions="searchOptions" @search="search" :backStatus="backStatus" />
    </el-header>
    <el-main class="basic-main">
     
      <generalTable ref="mainTable" :table-data="tableData" :config="tableConfig" :total="total" @updatePage="amountList" @objectSpanMethod="objectSpanMethod" @onClickRow="onClickRow">
       
      </generalTable>
      <addDialog :id="checkId" ref="insert" :submit="search" :time-stamp="timeStamp" @submit='amountList()' />
      <serviceConfig :id="checkId" ref="conf" :submit="search" :time-stamp="timeStamp" />
    </el-main>
  </el-container>
</template>
<script>
import { amountList } from '@/api/amount'
import searchForm from '@/components/SearchForm'
import generalTable from '@/components/Table'
import addDialog from '@/views/doctorManagement/addDialog'
import serviceConfig from '@/views/doctorManagement/serviceConfig'
import { getHospitalList} from '@/api/hospitalManagement'
import { getAllHospitalList } from '@/api/embodyAndRefund';
export default {
    name: 'Test',
    components: {
        searchForm,
        generalTable,
        addDialog,
        serviceConfig
    },
    data() {
        return {
            tableData: [
            ],
            searchOptions: [
           
                {
                    type: 'input',
                    placeholder: '请输入...',
                    key: 'name',
                    label: '医生姓名',
                    defaultVal: ''
                },
                {
                    type: 'input',
                    placeholder: '请输入...',
                    key: 'phoneNumber',
                    label: '手机号',
                    defaultVal: ''
                },
                {
                    type: 'select',
                    placeholder: '请选择',
                    key: 'kfHospitalId',
                    filterable: true,
                    multiple: false,
                    label: '医院',
                    options: [],
                    defaultVal: [],
                    remoteMethod: (params) => this.getRemoteHospital(params),
                }
            ],
            tableConfig: {
                id: 'test',
                align: 'center', // 不必填 默认为center
                selection: 'single', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: true, // 不必填 分页 默认为false
                column: [
                {
                    label: '医生ID', // 必填
                    prop: 'id' // 必填
                },
                {
                    label: '医生姓名', // 必填
                    prop: 'name' // 必填
                },
                {
                    label: '手机号', // 必填
                    prop: 'phoneNumber' // 必填
                },
               
                {
                    label: '医院', // 必填
                    prop: 'hospitalName' // 必填
                },
                {
                    label: '账户余额', // 必填
                    prop: 'balance' ,// 必填
                    formatter: (records) => {
                        return records.balance/100
                    }
                },
                {
                    label: '累计收入', // 必填
                    prop: 'cumulativeIncome', // 必填
                    formatter: (records) => {
                        return records.cumulativeIncome/100
                    }
                },
                {
                    label: '未出账金额', // 必填
                    prop: 'amountFrozen', // 必填
                    formatter: (records) => {
                        return records.amountFrozen/100
                    }
                },
               
                ]
            },
            paramObj: null, // 筛选项
            total: 0, // 总条数
            checkId: '', // 单条数据id 用于详情或编辑请求数据用
            timeStamp: '',
            pages:'',//页数
            backStatus: true, //返回按钮的隐藏显示
            hospitalList:[],//医院列表
        }
    },
    created() {
        this.amountList();
        this.getHospital();
    },
    mounted: function() {},
    methods: {
        search(data) {
            this.paramObj = data
            this.$refs.mainTable.handleCurrentChange(1)
        },
        amountList() {
            // 解决未挂载完组件时调用子组件方法报错问题
            this.$nextTick(() => {
                const param = this.getParam()
                amountList(param).then((res, timeStamp) => {
                    this.tableData = res.res.records;
                    this.timeStamp = timeStamp;
                    this.total = res.res.total;
                })
            })
            
        },
        objectSpanMethod(param, cb) {
            cb([])
        },
        toInsert() {
            this.checkId = ''
            this.$refs.insert.updateVisible(true)
        },
        toDetail(scope) {
            this.$router.push({
                path: '/doctorManagement/detailDialog',
                query:{
                    id:scope.data.row.id,
                }
            })
        },
        toEdit(scope) {
            this.checkId = scope.data.row.id
            this.$refs.insert.updateVisible(true)
        },
        toServiceConfig(scope){
            this.checkId = scope.data.row.id
            this.$refs.conf.updateVisible(true)
        },
     
        onClickRow(checkArray) {
            console.log('testststst')
            console.log('checkArray', checkArray)
        },
        getParam() {
            // 获取参数
            const paramObj = this.$refs.mainSearch.packageData()
            this.paramObj = paramObj
            const page = this.$refs.mainTable.getPage()
            const param = Object.assign(page, paramObj)
            return param
        },
      
        // 获取医院
        getHospital() {
            const param = []
            getAllHospitalList(param).then(({ res }) => {
                this.hospitalList = res.records.map((item) => {
                    return {
                        value: item.id,
                        label: item.name,
                    };
                });
                this.searchOptions[2].options = this.hospitalList
            });
        },
        getRemoteHospital(query) {
            // 如果用户输入内容了，就发请求拿数据，远程搜索模糊查询
            if (query !== "") {
                this.loading = true; // 开始拿数据喽
                let allHospitalList = [];
                getAllHospitalList({name: query}).then(({ res }) => {
                allHospitalList = res.records.map((item) => {
                        return {
                            value: item.id,
                            label: item.name,
                        };
                    });
                    this.loading = false // 拿到数据喽
                    this.searchOptions[2].options = allHospitalList;
                });
            } else {
                this.searchOptions[2].options = [];
            }
        },
    }
}
</script>

<style lang='scss'>
@import "~@/styles/variables.scss";

</style>
