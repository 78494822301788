<!--
 * @Description: 内容显示区域
 * @Author: yzw
 * @Date: 2020-06-23 06:15:04
 * @LastEditTime: 2021-11-05 10:10:36
 * @LastEditors: yzw
 * @Reference:
-->
<template>
  <section class="app-main">
    <div class="ham-box">
      <el-image class="ham-img" :src="require('@/assets/img/zd.png')" fill="contain" />
      <breadcrumb class="breadcrumb-container" />
    </div>
    <!-- <transition name="fade-transform" mode="out-in"> -->
      <keep-alive v-if="keepAlive">
        <router-view :key="key" />
      </keep-alive>
      <template v-else>
        <router-view :key="key" />
      </template>
    <!-- </transition> -->
  </section>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb'

export default {
  name: 'AppMain',
  components: {
    Breadcrumb,
  },
  computed: {
    key() {
      return this.$route.path
    },
    keepAlive(){
      return this.$route.meta.keepAlive
    }
  },
  methods: {
  }
}
</script>

<style scoped lang='scss'>
@import "~@/styles/variables.scss";

.app-main {
  /*50 = navbar  */
  // min-height: calc(100vh - #{$navBarHeight});
  height: calc(100vh - 0px);
  width: calc(100vw - #{$sideBarWidth});
  margin-left: $sideBarWidth;
  padding-top: $navBarHeight;
  position: relative;
  overflow: hidden;
}
.fixed-header + .app-main {
  padding-top: 50px;
}
.ham-box{
  width: 100%;
  height: $hamBarHeight;
  border-bottom: 1px solid $tableColor;
  position: fixed;
  background: $white;
  z-index: 6;
}
.ham-img{
  position: absolute;
  left: -6px;
  top: -2px;
  width: 40px;
}
.hamburger-container {
  line-height: 40px;
  height: 100%;
  float: left;
  cursor: pointer;
  transition: background 0.3s;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    background: rgba(0, 0, 0, 0.025);
  }
}

.breadcrumb-container {
  float: left;
}
</style>

<style lang="scss">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
  .fixed-header {
    // padding-right: 15px;
  }
}
</style>
