/*
 * @Author: your name
 * @Date: 2022-03-31 17:15:47
 * @LastEditTime: 2022-03-31 17:53:24
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /recovery_home_management/src/api/assessmentClass.js
 */

/**
 * 页面: 评估分类
 * pageName: assessmentClass
 **/
import request from '@/utils/request'

/**
 * @description: 获取评估分类列表数据
 */
export function getAssessmentClassList(data) {
    return request({
        url: '/admin/kfAssessmentType/list',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 新增评估分类数据
 */
export function insertData(data) {
    return request({
        url: '/admin/kfAssessmentType/add',
        method: 'POST',
        data,
        showLoading: true
    })
}

/**
 * @description: 修改评估分类数据
 */
export function editData(data, timeStamp) {
    return request({
        url: '/admin/kfAssessmentType/update',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}
            

/**
 * @description: 获取评估分类详情数据
 */
export function getDetail(data) {
    return request({
        url: '/admin/kfAssessmentType/info',
        method: 'GET',
        params: data,
        showLoading: true
    })
}
            
/**
 * @description: 删除评估分类数据
 */
    export function deleteData(data, timeStamp) {
    return request({
        url: '/admin/kfAssessmentType/delete',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}
        