/*
 * @Description:
 * @Author: yzw
 * @Date: 2020-06-23 06:15:04
 * @LastEditTime: 2022-02-28 11:13:58
 * @LastEditors: ZF-WLY
 * @Reference:
 */
import axios from 'axios'
import { Message } from 'element-ui'
import store from '@/store'
import { getToken } from '@/utils/auth'
import { showScreenLoading, hideScreenLoading } from './loading'
import { addPending } from '@/utils/pending'
import router from '@/router'

/**
 * @description: 传入的参数及说明
 * @param {String} timeStamp 时间戳
 * @param {Boolean} showLoading 配置接口请求时，是否显示loading组件 true显示，false隐藏
 * @param {Boolean} upload 上传文件相关接口，需要将upload参数设置为true
 * @param {Number} timeout 设置timeout的毫秒数，默认为10*1000
 */

/**
 * 创建请求实例
 */
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API
})

/**
 * 请求拦截器
 */
service.interceptors.request.use(
  config => {
    // removePending(config) // 在请求开始前，对之前的请求做检查取消操作
    config.timeout = config.timeout || 10 * 1000 // timeout 按传入值设置||10s 
    addPending(config) // 将当前请求添加到 pending 中
    if (store.getters.token) {
      config.headers = getToken()
    }
    if (config.method === 'get') {
      config.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
    } else {
      config.headers['Content-Type'] = 'application/json'
    }
    if (config.upload === true) {
      config.headers['Content-Type'] = 'multipart/form-data;boundary = ' + new Date().getTime()
    }
    if (config.timeStamp) {
      config.headers['optimistic-locking-time-stamp'] = config.timeStamp
    }
    if (config.showLoading === true) {
      showScreenLoading()
    }
    return config
  },
  error => {
    console.error(error)
    hideScreenLoading()
    return Promise.reject(error)
  }
)

/**
 * 响应拦截器
 */
service.interceptors.response.use(
  response => {
      console.log("config------", response.config)
    // 关闭loading
    if (response.config.showLoading === true) {
      hideScreenLoading()
    }
    const { state, msg, data, timeStamp } = response.data
    if (state < 300) {
      console.log('HTTP------', data)
      return { res: data, timeStamp }
    } else if ((state >= 610 && state <= 614) || ([2010, 2011, 2014]).includes(state)) {
      store.dispatch('logout').then(() => {
        // Message.error('验证失败,请重新登录', msg)
        router.push(`/login`);
      })
      return false
    }else if(state === undefined){
      // 如果为文件流 则无任何处理 直接返回流数据
      return { res: response.data }
    }else {
      console.log('STATE_ERROR------',response.config, response.data)
      Message({
        message: msg || '请求异常',
        type: 'error',
        duration: 5 * 1000
      })
      // removePending(response.config) // 在请求结束后，移除本次请求
      return Promise.reject(response.data)
    }
  },
  error => {
    // 关闭loading
    // if (response.config.showLoading !== false) {
      hideScreenLoading()
    // }
    if (axios.isCancel(error)) {
      console.log('取消重复的请求: ', error)
    } else {
      console.log('RESPONSE_ERROR------', error)
      console.dir(error)
      Message({
        message: error.msg || '请求异常',
        type: 'error',
        duration: 5 * 1000
      })
    }
    return Promise.reject(error)
  }
)

export default service
