<!--
 * @Author: 358617935@qq.com 358617935@qq.com
 * @Date: 2023-05-26 11:27:20
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-06-28 10:27:12
 * @FilePath: /recovery_home_management_dev/src/views/financial/embody/detailDialog.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->

<template>
    <el-dialog width="40%" :title="title" :visible.sync="visible" :close-on-click-modal="false" :close-on-press-escape="false"
        :show-close="false" custom-class="basicDialog" append-to-body>
        <el-descriptions class="margin-top" title="" :column="2" size="medium">
            <el-descriptions-item label="流水单号">{{detailData.withdrawSerial === null ? '' :  detailData.withdrawSerial}}</el-descriptions-item>
            <el-descriptions-item label="医生姓名">{{detailData.userName}}</el-descriptions-item>
            <el-descriptions-item label="所属医院">{{detailData.userHospital}}</el-descriptions-item>
             <el-descriptions-item label="提现时间">{{moment(detailData.withdrawTime).format("yyyy-MM-DD")}}</el-descriptions-item>
            <el-descriptions-item label="提现金额(元)">{{detailData.withdrawAmount}}</el-descriptions-item>
        </el-descriptions>

        <template slot="footer" class="dialog-footer">
            <el-button type="primary" v-if="type == 'audit'" @click="auditData('4')" v-loading.fullscreen.lock="fullscreenLoading" element-loading-text="审核中, 请稍后...">审核通过</el-button>
            <el-button type="danger" v-if="type == 'audit'" @click="refuse('5')">拒绝</el-button>
            <el-button type="danger" v-if="type == 'withdraw'" @click="withdraw('2')">提现</el-button>
            <el-button @click="updateVisible(false)">取 消</el-button>
        </template>
    </el-dialog>
</template>
<script>
import { auditData } from '@/api/embodyAndRefund'
export default {
    props: {
        id: {
            type: String,
            required: true
        },
        detailData: {
            type: Object
        },
        type: {
            type: String,  // 判断提现、还是审核
        }
    },
    data() {
        return {
            visible: false,
            detail: [], // 详情数据
            fullscreenLoading: false,
            title: this.type ==='audit' ? '提现审批':'提现申请',

        }
    },
    watch: {
        visible(newVal, oldVal) {
            if (newVal && this.id) this.getDetail()
        }
    },
    created() { },
    mounted() {
    },
    methods: {
        getDetail() {
            // getDetail(this.id).then(({ res }) => {
            //     this.detail = res
            // })
        },
        toCheck() {
            setTimeout(() => {
                this.$alert(
                    `<i class="el-icon-success" style="color: #6B9BF1; font-size: 100px;"></i>
                    <div style="color: #6B9BF1; font-weight: bold; font-size: 16px;">审核通过</div>`, 
                    '审核结果', 
                    {
                        dangerouslyUseHTMLString: true,
                        confirmButtonText: '返回',
                        center: true,
                        callback: action => {
                            // this.$message({
                            //     type: 'success',
                            //     message: "审核通过"
                            // });
                            this.fullscreenLoading = false;
                            this.$emit('audit');
                            this.updateVisible(false);
                        }
                    });
            }, 2000);
        },
        toUnCheck(reason) {
             setTimeout(() => {
                const error_reason = ""
                this.$alert(
                    ` <i class="el-icon-error" style="color: #D9001B;font-size: 100px;"></i>
                    <div style="color: #D9001B; font-weight: bold; font-size: 16px;margin: 10px 0;">审核不通过</div>
                    <div><span style="color: #D9001B;">*</span><span>操作原因：`+ reason + `</span></div>`, 
                    '审核结果', 
                    {
                        dangerouslyUseHTMLString: true,
                        customClass: 'check-tip',
                        confirmButtonText: '返回',
                        center: true,
                        callback: action => {
                            this.fullscreenLoading = false;
                            this.$emit('audit');
                            this.updateVisible(false);
                        }
                    });
            }, 2000);
        },
        refuse(){
            const { $delMsg, $delTitle, $messageBoxConfig } =
                this.commonConfig;
            this.$confirm("是否拒绝该申请？", $delTitle, $messageBoxConfig).then(() => {
                this.auditData("5");
            });
        },
        withdraw(){
            const { $delMsg, $delTitle, $messageBoxConfig } =
                this.commonConfig;
            this.$confirm("是否申请提现？", $delTitle, $messageBoxConfig).then(() => {
                this.auditData("2");
            });
        },
        successMessage(message){
            this.$message({
                showClose: true,
                message: message,
                type: 'success'
            })
            this.$emit('audit');
            this.fullscreenLoading = false;
            this.updateVisible(false);
        },
        failMessage(reason){
            // this.$message({
            //     showClose: true,
            //     message: failReason + "，请到已完结列表中查看失败原因。",
            //     type: 'fail'
            // })
            // this.$emit('audit');
            // this.fullscreenLoading = false;
            // this.updateVisible(false);
           let error_reason = reason.failReasonList.length === 0 ?  '' : `<ul class="failreason-list">`;
            reason.failReasonList.forEach((item) => {
                error_reason += `<li><span>流水单号：` + item.withdrawSerial + `, </span><span class="fail-color">失败原因：` + item.reason +  `<span></li>`
            });
            error_reason += reason.failReasonList.length === 0 ?  '' : `</ul`;
            this.$alert(
                `
                <i class="el-icon-error error-icon"></i>
                <div ><p>您共提现&nbsp;` + reason.totalNo + `&nbsp;条。提现成功&nbsp;<span class="success-color">` + reason.successNo + `</span>&nbsp;条, 提现失败 <span class="fail-color">` + reason.failNo + `</span>&nbsp;条。<p>` + `<p>提现信息请到<span style="font-weight: bold;"> 已完结 </span>中查看。</p>
                <div>`+ error_reason + `</div>`, 
                '提现结果', 
                {
                    dangerouslyUseHTMLString: true,
                    customClass: 'check-tip',
                    confirmButtonText: '返回',
                    center: true,
                    callback: action => {
                        this.$emit('audit');
                        this.fullscreenLoading = false;
                        this.updateVisible(false);
                    }
                });
        },

        /**
         * @description: 操作  操作类型 5-拒绝 4-审核 2-提现
         * @return {*}
         */
        auditData(type) {
            this.fullscreenLoading = true;
            const param = { id: this.id, auditStatus: type };
            const paramSubmit =  {...this.detailData, ...param};

            auditData(paramSubmit, this.timeStamp).then(({ res }) => {
                if(type == "4"){// 审批
                    this.toCheck();
                }else if(type === "5"){ //5-拒绝
                    const message = '操作成功。';
                    this.successMessage(message);
                }else if(type === "2"){ //2-提现
                    if(res.status === true){
                        const message = '提现申请已提交，请耐心等待。';
                        this.successMessage(message);
                    }else{
                        const reason = {
                           totalNo: res.totalNo,
                            successNo: res.successNo,
                            failNo: res.failNo,
                            failReasonList: res.failReasonList,
                        }
                        // const reason = {
                        //     totalNo: "120",
                        //     successNo: "100",
                        //     failNo: "20",
                        //     failReasonList: [
                        //         {
                        //             withdrawSerial : "243q4242134214234235345325",
                        //             reason: '提现失败失败失败'
                        //         }
                        //     ],
                        // }
                        this.failMessage(reason);
                    }
                    
                }
            })
            .catch((error)=> {
                this.fullscreenLoading = false;
            })
        },
        updateVisible(flag) {
            this.visible = flag
        }
    }
}
</script>
<style scoped lang='scss'>
.dialog-footer {
    display: flex;
    justify-content: center;
}
.failreason-list{
    padding-inline-start: 25px;
    li{
        text-align: left;     
    }
}
.fail-color{
    color: #f56c6c;
}
.success-color{
    color: #67c23a;
}  
.error-icon{
    color: #D9001B;font-size: 100px;
}
</style>
