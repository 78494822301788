<template>
  <div class="welcome">
    <div class="welcome-title">
      {{ "欢迎使用熊猫优康复在线客服服务端" }}
    </div>
  </div>
</template>
<script lang="ts">
import { TUITranslateService, TUIStore, StoreName } from "@tencentcloud/chat-uikit-engine";
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const isOfficial = TUIStore.getData(StoreName.APP, "isOfficial");
  return {};
};
export default __sfc_main;
</script>
<style lang="scss" scoped>
.welcome {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding-left: 40px;
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  background: url("../../assets/im/image/login-background.png") no-repeat;
  background-size: cover;
  background-position-x: -17px;
  background-position-y: 173px;
  .welcome-title {
    font-size: 1.75rem;
    display: flex;
    align-items: center;
    font-family: PingFangSC-Medium;
    font-weight: 500;
    color: #000;
    .logo {
      width: 40px;
      padding-left: 0.98rem;
      padding-right: 0.98rem;
    }
  }
  .welcome-content {
    padding-top: 1.88rem;
    max-width: 393px;
    font-size: 16px;
    line-height: 24px;
    font-family: PingFangSC-Regular;
    font-weight: 400;
    color: #666;
  }
}
</style>
