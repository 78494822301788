
<template>
  <el-dialog
    width="78%"
    :title="id === '' ? '新增消息通知' : '编辑消息通知'"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    append-to-body
  >
    <el-form
      ref="insertForm"
      :model="form"
      label-position="right"
      label-width="120px"
      class="formBox"
      :rules="rules"
    >
      <div class="mdrcc-info-title">
        基本信息
      </div>
      <el-row :gutter="10" type="flex" justify="start">
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="消息名称" prop="title">
            <el-input
              v-model="form.title"
              placeholder="请输入..."
              clearable
              maxlength="20"
            />
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="内容" prop="content">
            <el-input
              v-model="form.content"
              placeholder="请输入..."
              clearable
              maxlength="200"
            />
          </el-form-item>
        </el-col>
          <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="备注" prop="remark">
            <el-input
              v-model="form.remark"
              placeholder="请输入..."
              clearable
              maxlength="20"
            />
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="发送平台" prop="select">
              <el-select v-model="form.identification" clearable placeholder="请选择" :filterable="true">
                  <el-option v-for="stands in identification" :key="stands.value" :label="stands.label"
                      :value="stands.value" />
              </el-select>
          </el-form-item>
      </el-col>
      <el-col :sm="12" :md="12" :lg="8" :xl="6">
        <el-form-item label="发送" prop="select">
            <el-select v-model="form.send" clearable placeholder="请选择" :filterable="true">
                <el-option v-for="stands in send" :key="stands.value" :label="stands.label"
                    :value="stands.value" />
            </el-select>
        </el-form-item>
    </el-col>
    <el-col :sm="120" :md="120" :lg="8" :xl="6">
      <el-form-item label="发送时间" prop="pushTime">
        <el-date-picker
          v-model="form.pushTime"
          value-format="timestamp" 
          type="datetime"
          placeholder="选择日期时间">
        </el-date-picker>
      </el-form-item>
    </el-col>
      </el-row>
      <el-divider />
     
     
     
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit()">提 交</el-button>
      <el-button @click="updateVisible(false)">取 消</el-button>
    </span>
    
  </el-dialog>

</template>
<script>
import { insertData, editData,getDetail } from '@/api/newsManage'
import generalTable from '@/components/Table'
import { getDict } from '@/api/common'
export default {
    components: {
        generalTable
        
    },
    props: {
        id: {
            type: String
        },
        timeStamp: {
            type: String
        }
    },
    data() {
        return {
        checkId: '',
        tableData: [  
        ],
        tableConfig: {
          
        },
        total: 0, // 总条数
        rules: {
          title: [
            { required: true, message: '请输入消息名称', trigger: 'blur' }
                ],
          content: [
            { required: true, message: '请输入内容', trigger: 'blur' }
          ],
          remark: [
            { required: true, message: '请输入备注', trigger: 'blur' }
          ],
        },
        options: [
           
        ],
        form: {
            checkbox: [],
            identification:"2",
            title:"",
            content:"",
            remark:"",
            send:"1",
            pushTime: new Date().getTime(),
        },
        identification:"",
            send:"",
        visible: false
        }
    },
    created() {
      this.getDict();
    },
    watch: {
            visible(newVal, oldVal) {
              this.form.pushTime=new Date().getTime();
                if (newVal === true) {
                    if (this.id) {
                        this.getDetail()
                    }
                }
            }
        },
    mounted() {},
    methods: {
        getTableData() {
            this.$emit('getTableData')
        },
        submit() {
          this.$refs.insertForm.validate((valid) => {
                    if (valid) {
                        const parm = { ...this.form }
                        if (!this.id) {
                            insertData(parm).then(({ res }) => {
                                this.submitSuccess(res)
                            })
                        } else {
                            parm.id = this.id
                            editData(parm, this.timeStamp).then(({ res }) => {
                                this.submitSuccess(res)
                            })
                        }
                    }
                })
                
        },
        submitSuccess(res) {
            this.$message({...this.commonConfig.$handleSuccess})
            this.updateVisible(false)
            this.$emit('submit')
        },
        toDetail(scope) {
            this.checkId = this.id
            this.$refs.detail.updateVisible(true)
        },
        getDetail() {
                const param = {}
                if (this.id) {
                    param.id = this.id
                }
                getDetail(param).then(({ res, timeStamp }) => {
                    const { id, ...temp } = res
                    this.form = temp
                    
                })
            },
        getDict(){
            getDict({ type: 'identification' }).then(({res}) => {
                this.identification = res.identification;
            });
            getDict({ type: 'send' }).then(({res}) => {
                this.send = res.send;
            });
         },
      
         
        toDel(scope) {
            const { $delMsg, $delTitle, $messageBoxConfig } = this.commonConfig
            this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
                const param = { id: scope.data.row.id }
                deleteData(param).then(({ res }) => {
                    this.$message({
                    message: '删除成功',
                    type: 'success'
                    })
                })
            })
        },
        add() {
          console.log(1)
        },
        updateVisible(val) {
          if (val === false) {
                this.$refs.insertForm.resetFields()
            }
            this.visible = val
        }
    }
}
</script>
<style scoped>
</style>

