/*
 * @Author: your name
 * @Date: 2022-03-25 14:02:51
 * @LastEditTime: 2022-03-25 14:04:02
 * @LastEditors: Please set LastEditors
 * @Description: 获取七牛上传token
 * @FilePath: /recovery_home_management/src/api/qiniu.js
 */

import request from '@/utils/request'
import commonConfig from "@/config/common";
/**
 * @description: 私有
 * @param {*}
 * @return {*}
 */
export function getQiNiuPrivateToken() {
    return request({
        url: '/file/getPrivateToken', 
        method: 'get'
    })
}

/**
 * @description: 私有
 * @param {*}
 * @return {*}
 */
 export function getQiNiuPublicToken() {
    return request({
        url: '/file/getPublicToken', 
        method: 'get'
    })
}

/**
 * @description: 获取音视频元信息
 * @param {*}
 * @return {*}
 */
export function getVideoInfo(fileKey) {
  return request({
      url: `${commonConfig.$filePublicUrl}${fileKey}?avinfo`,
      method: 'get'
  })
}



