
<!--
* @Description: 康复指导视频设备表页面
* @Reference:
-->

<template>
    <el-container>
        <el-header height="auto">
            <searchForm
                ref="mainSearch"
                :searchoptions="searchOptions"
                @search="search"
                :backStatus="backStatus"
            />
        </el-header>
        <el-main class="basic-main">
            <el-row class="table-btn-group"
                ><el-button type="primary" @click="toInsert()"
                    >新增</el-button
                ></el-row
            >
            <generalTable
                ref="mainTable"
                :table-data="tableData"
                :config="tableConfig"
                :total="total"
                @updatePage="getList"
                @onClickRow="onClickRow"
            >
                <template slot="photo" slot-scope="scope">
                    <el-image
                        style="width: 50px; height: 50px; margin-top: 10px"
                        :src="
                            commonConfig.$filePublicUrl + scope.data.row.photo
                        "
                        :preview-src-list="[
                            commonConfig.$filePublicUrl + scope.data.row.photo,
                        ]"
                    >
                    </el-image>
                </template>
                <template slot="handle" slot-scope="scope">
                    <el-button type="text" @click="toDetail(scope)"
                        >查看</el-button
                    >
                    <el-button type="text" @click="toEdit(scope)"
                        >编辑</el-button
                    >
                    <el-button type="text" class="delBtn" @click="toDel(scope)"
                        >删除</el-button
                    >
                </template>
            </generalTable>
            <detailDialog
                :id="checkId"
                :identifier-array="identifierArray"
                ref="detail"
            />
            <addDialog
                :id="checkId"
                :identifier-array="identifierArray"
                :limbs-type-array="limbsTypeArray"
                :treatment-type-array="treatmentTypeArray"
                :guidance-level-array="guidanceLevelArray"
                ref="insert"
                :timeStamp="timeStamp"
                @submit="search"
            />
        </el-main>
    </el-container>
</template>

<script>
import { getList, deleteData } from "@/api/videoEquipment";
import searchForm from "@/components/SearchForm";
import generalTable from "@/components/Table";
import addDialog from "@/views/rehabilitation/videoEquipment/addDialog";
import detailDialog from "@/views/rehabilitation/videoEquipment/detailDialog";
import { getDict } from "@/api/common";

export default {
    name: "VideoEquipment",
    components: {
        searchForm,
        generalTable,
        detailDialog,
        addDialog,
    },
    data() {
        return {
            tableData: [],
            searchOptions: [
                {
                    type: "input",
                    placeholder: "请输入...",
                    key: "name",
                    label: "名称",
                    defaultVal: "",
                },
                {
                    type: "select",
                    placeholder: "请选择",
                    key: "identifier",
                    filterable: true,
                    label: "类型",
                    options: [],
                    defaultVal: [],
                },
                {
                    type: "select",
                    placeholder: "请选择",
                    key: "limbsType",
                    filterable: true,
                    label: "肢体类型",
                    options: [],
                    defaultVal: [],
                },
                {
                    type: "select",
                    placeholder: "请选择",
                    key: "treatmentType",
                    filterable: true,
                    label: "治疗类型",
                    options: [],
                    defaultVal: [],
                },
                {
                    type: "select",
                    placeholder: "请选择",
                    key: "guidanceLevel",
                    filterable: true,
                    label: "方案等级",
                    options: [],
                    defaultVal: [],
                },
                // {
                //     type: "cascader",
                //     placeholder: "请选择",
                //     key: "kfGuidanceTypeId",
                //     filterable: true,
                //     multiple: true,
                //     label: "分类",
                //     options: [],
                //     defaultVal: [],
                //     props: {
                //         checkStrictly: true,
                //         emitPath: false,
                //         label: "officesName",
                //         value: "id",
                //     },
                // },
            ],
            tableConfig: {
                id: "videoEquipment",
                align: "center", // 不必填 默认为center
                selection: "single", // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: true, // 不必填 分页 默认为false
                column: [
                    {
                        label: "名称", // 必填
                        prop: "name", // 必填
                        width: "400", // 不必填
                    },
                    {
                        label: "类型", // 必填
                        prop: "identifierLabel", // 必填
                        // formatter: (row) => {
                        //     // 根据字典表展示对应项目的label值
                        //     const currentType = this.identifierArray.filter(
                        //         (type) => type.value === row.identifier
                        //     );
                        //     if (currentType.length) {
                        //         return currentType[0].label;
                        //     }
                        // },
                        width: "100", // 不必填
                    },
                    {
                        label: "肢体类型", // 必填
                        prop: "limbsTypeLabel", // 必填
                    },
                    {
                        label: "治疗类型", // 必填
                        prop: "treatmentTypeLabel", // 必填
                    },
                    {
                        label: "方案等级", // 必填
                        prop: "guidanceLevelLabel", // 必填
                    },
                    // {
                    //     label: "分类", // 必填
                    //     prop: "guidanceTypeName", // 必填
                    // },
                    {
                        label: "描述", // 必填
                        prop: "describe", // 必填
                        width:"300",
                    },
                    {
                        label: "封面图片", // 必填
                        slot: "photo", // 必填
                        width: "100",
                    },
                    {
                        label: "锻炼时间（一次多长分钟）", // 必填
                        prop: "length", // 必填
                        formatter: (row) => {
                            return row.length + "分钟";
                        },
                    },
                    {
                        label: "锻炼时长（锻炼几天）", // 必填
                        prop: "lengthExercise", // 必填
                        formatter: (row) => {
                            return row.lengthExercise + "天";
                        },
                    },
                    {
                        label: "锻炼次数（每天几次)", // 必填
                        prop: "number", // 必填
                        formatter: (row) => {
                            return row.number + "次";
                        },
                    },
                    {
                        slot: "handle", // 不必填 单列插槽
                        label: "操作", // 必填
                        fixed: "right", // 不必填 固定列  left right
                        width: "180", // 不必填
                    },
                ],
            },
            paramObj: null, // 筛选项
            total: 0, // 总条数
            checkId: "", // 单条数据id 用于详情或编辑请求数据用
            backStatus: true, //返回按钮的隐藏显示
            timeStamp: "", //时间戳 用于修改数据或删除数据
            identifierArray: [],
            // classifyArray: [],
            limbsTypeArray: [],
            treatmentTypeArray: [],
            guidanceLevelArray: [],
        };
    },
    created() {
        this.getList();
        this.getIdentifierList();
        this.getLimbsTypeList();
        this.getTreatmentTypeList();
        this.getGuidanceLevelList();
        // this.getClassifyList();
    },
    mounted: function () {},
    methods: {
        search(data) {
            Object.assign(this.paramObj, data);
            this.$refs.mainTable.handleCurrentChange(1);
        },
        /**
         * @description:
         * @param {*}
         * @return {*}
         */
        getList() {
            this.$nextTick(() => {
                const param = this.getParam();
                getList(param).then(({ res, timeStamp }) => {
                    this.tableData = res.records;
                    this.timeStamp = timeStamp;
                    this.total = parseInt(res.total)
                });
            });
        },
        /**
         * @description: 获取分类列表
         * @param {*}
         * @return {*}
         */
        // getClassifyList() {
        //     this.$nextTick(() => {
        //         const param = this.getParam();
        //         getClassifyList(param).then(({ res, timeStamp }) => {
        //             this.classifyArray = res;
        //             this.searchOptions[2].options = res;
        //         });
        //     });
        // },
        /**
         * @description: 新增
         * @param {*}
         * @return {*}
         */        
        toInsert() {
            this.checkId = "";
            this.$refs.insert.updateVisible(true);
        },
        /**
         * @description: 详情
         * @param {*} scope
         * @return {*}
         */        
        toDetail(scope) {
            this.checkId = scope.data.row.id;
            this.$refs.detail.updateVisible(true);
        },
        /**
         * @description: 编辑
         * @param {*} scope
         * @return {*}
         */        
        toEdit(scope) {
            this.checkId = scope.data.row.id;
            this.$refs.insert.updateVisible(true);
        },
        toDel(scope) {
            const { $delMsg, $delTitle, $messageBoxConfig, $handleSuccess } =
                this.commonConfig;
            this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
                const param = { id: scope.data.row.id };
                deleteData(param, this.timeStamp).then(({ res }) => {
                    this.getList();
                    this.$message({ ...$handleSuccess });
                });
            });
        },
        onClickRow(row) {
            console.log("点击行:", row);
        },
        getParam() {
            const paramObj = this.$refs.mainSearch.packageData();
            const page = this.$refs.mainTable.getPage();
            const param = Object.assign({}, this.paramObj, page, paramObj);
            this.paramObj = param;
            return param;
        },
        /**
         * @description: 获取所属平台
         * @param {*}
         * @return {*}
         */
        getIdentifierList() {
            getDict({ type: "identifier" }).then(({ res }) => {
                this.identifierArray = res.identifier;
                this.searchOptions[1].options = res.identifier;
            });
        },
        /**
         * @description: 获取肢体类型
         * @param {*}
         * @return {*}
         */
        getLimbsTypeList() {
            getDict({ type: "limbs_type" }).then(({ res }) => {
                this.limbsTypeArray = res.limbs_type;
                this.searchOptions[2].options = res.limbs_type;
            });
        },
        /**
         * @description: 获取治疗类型
         * @param {*}
         * @return {*}
         */
        getTreatmentTypeList() {
            getDict({ type: "treatment_type" }).then(({ res }) => {
                this.treatmentTypeArray = res.treatment_type;
                this.searchOptions[3].options = res.treatment_type;
            });
        },
        /**
         * @description: 获取方案等级
         * @param {*}
         * @return {*}
         */
        getGuidanceLevelList() {
            getDict({ type: "guidance_level" }).then(({ res }) => {
                this.guidanceLevelArray = res.guidance_level;
                this.searchOptions[4].options = res.guidance_level;
            });
        },
    },
};
</script>

<style lang='scss' scoped>
@import "~@/styles/variables.scss";
</style>

        