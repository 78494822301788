
<!--
* @Description: 商品订单管理
* @Reference:
-->

<template>
  <el-container>
      <el-header height="auto">
          <searchForm ref="mainSearch" :searchoptions="searchOptions" @search="search" :backStatus="backStatus" />
      </el-header>
      <el-main class="basic-main">
          <generalTable ref="mainTable" :table-data="tableData" :config="tableConfig" :total="total" @updatePage="getGoodsList">
            <template slot="ltjl" slot-scope="scope">
                <a class="clickCell" @click="gotoLtjl(scope,'')">{{scope.data.row.ltjl}}</a>
            </template>
            <template slot="handle" slot-scope="scope">
              <el-button type="text" @click="toDetail(scope)">详情</el-button>
              <el-button type="text" @click="toAdd(scope)">物流单号</el-button>
              <el-button type="text" @click="gotoIndex(scope)">服务记录</el-button>
            </template>
          </generalTable>
          <goodsDetailDialog :id="checkId" ref="detail" />
          <goodsaddDialog :id="checkId" ref="add" />
      </el-main>
  </el-container>
  </template>
  
  <script>
  
  import { getGoodsList } from '@/api/order'
  import searchForm from '@/components/SearchForm'
  import generalTable from '@/components/Table'
  import { getDict } from '@/api/common'
  import goodsDetailDialog from "@/views/order/goodsDetailDialog";
  import goodsaddDialog from "@/views/order/goodsaddDialog";
  
  export default {
      name: 'DoctorWorkload',
      components: {
          searchForm,
          generalTable,
          goodsDetailDialog,
          goodsaddDialog
      },
      data() {
          return {
              tableData: [],
              searchOptions: [
              {
                      type: 'input',
                      placeholder: '请输入...',
                      key: 'orderNo',
                      label: '订单编号',
                      defaultVal: ''
                  },
                  {
                      type: 'input',
                      placeholder: '请输入...',
                      key: 'patName',
                      label: '患者姓名',
                      defaultVal: ''
                  },
                  {
                      type: 'input',
                      placeholder: '请输入...',
                      key: 'docName',
                      label: '绑定医生',
                      defaultVal: ''
                  },
                 
                  {
                      type: 'daterange',
                      startPlaceholder: '开始日期',
                      endPlaceholder: '结束日期',
                      key: 'createTime',
                      label: '下单时间',
                      defaultVal: []
                  },
                 
                  {
                    type: "select",
                    placeholder: "请选择",
                    key: "status",
                    filterable: true,
                    label: "订单状态",
                    options: [],
                    defaultVal: [],
                  },
              ],
              tableConfig: {
                  id: 'doctorWorkload',
                  align: 'center', // 不必填 默认为center
                  selection: 'single', // 不必填 多选: multiple  单选: single  默认为 normal
                  index: true, // 不必填 序号
                  pagination: true, // 不必填 分页 默认为false
                  column: [
                  {
                    label: '订单编号', // 必填
                    prop: 'orderNo' // 必填
                  },
                  {
                    label: '商品名称', // 必填
                    prop: 'goodsName' // 必填
                  },
                  {
                    label: '商品ID', // 必填
                    prop: 'goodsId' // 必填
                  },
                 
                  {
                    label: '下单时间', // 必填
                    prop: 'createTime', // 必填
                    formatter: (row, column) => {
                        return this.moment(row.createTime).format("YYYY-MM-DD HH:mm:ss")
                    }
                  },
                  {
                    label: '订单支付时间', // 必填
                    prop: 'payTime', // 必填
                    formatter: (row, column) => {
                             
                    if(row.payTime==null){
                      return "--"
                    }else{
                        return this.moment(row.payTime).format("YYYY-MM-DD HH:mm:ss")
                      }
                    }
                  },
                  {
                    label: '用户昵称', // 必填
                    prop: 'patName' // 必填
                  },
                  {
                    label: '用户电话', // 必填
                    prop: 'mobileNumber' // 必填
                  },
                  {
                    label: '绑定医生', // 必填
                    prop: 'docName' // 必填
                },
                {
                    label: '医生推荐码', // 必填
                    prop: 'recommendationCode' // 必填
                },
                {
                    label: '订单状态', // 必填
                    prop: 'statusLabel' // 必填
                }, {
                  slot: "handle", // 不必填 单列插槽
                  label: "操作", // 必填
                  fixed: "right", // 不必填 固定列  left right
                  width: "220", // 不必填
                },
                 
                  ]
              },
              paramObj: null, // 筛选项
              total: 0, // 总条数
              orderNo:'',//订单号
              checkId: '', // 单条数据id 用于详情或编辑请求数据用
              backStatus: true, //返回按钮的隐藏显示
              timeStamp: '', //时间戳 用于修改数据或删除数据
             
  
          }
      },
      created() {
          this.getGoodsList();
          this.getDict();
          
      },
      mounted: function() {},
      methods: {
          search(data) {
              Object.assign(this.paramObj, data)
              this.$refs.mainTable.handleCurrentChange(1)
          },
          getGoodsList() {
              this.$nextTick(() => {
                  const param = this.getParam()
                  console.log("param",param);
                  getGoodsList(param).then(({ res, timeStamp }) => {
                      this.tableData = res.records
                      this.timeStamp = timeStamp
                      this.total = parseInt(res.total)
                  })
              })
          },
         
         
         
          getParam() {
              const paramObj = this.$refs.mainSearch.packageData()
            
              const page = this.$refs.mainTable.getPage()
              const param = Object.assign({}, this.paramObj, page, paramObj)
              this.paramObj = param
              console.log("param",param)
              return param
          },
          getDict(){
            getDict({ type: 'main_status' }).then(({res}) => {
                this.searchOptions[4].options = res.main_status;
            });
         },
         toDetail(scope) {
          this.checkId = scope.data.row.id;
          this.$refs.detail.updateVisible(true);
        },
        toAdd(scope) {
          this.checkId = scope.data.row.id;
          this.$refs.add.updateVisible(true);
        },
            /**
        　* 服务记录
        　* @Description:
          * @Param  {*} scope
          * @Param 
          * @Return
        　* @Author wangz
        　* @Date 2022/3/21 13:56
        　*/
        gotoIndex (scope) {
            const query = Object.assign({},{
              orderId: scope.data.row.id,
              
            })
            console.log("ssssssssssss",query)
            this.$router.push({ path: '/order/index', query: query})
            
        },
      }
  }
  </script>
  
  <style lang='scss' scoped>
  @import "~@/styles/variables.scss";
  </style>
  
          