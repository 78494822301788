<template>
  <el-dialog
    width="70%"
    :title="id === '' ? '新增分类管理' : '修改分类管理'"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    append-to-body
  >
    <el-form
      ref="insertForm"
      :model="form"
      label-position="right"
      label-width="120px"
      class="formBox"
      :rules="rules"
    >
      <div class="mdrcc-info-title">基本信息</div>
      <el-row :gutter="10" type="flex" justify="start">
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="分类名称" prop="name">
            <el-input
              v-model="form.name"
              placeholder="请输入..."
              clearable
              maxlength="20"
            />
          </el-form-item>
        </el-col>
        <!-- <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="所属栏目" prop="parentId">
            <el-input
              v-model="form.parentId"
              placeholder="请输入..."
              clearable
              maxlength="20"
            />
          </el-form-item>
        </el-col> -->
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="所属栏目" prop="parentId">
           <el-select
              v-model="form.parentId"
              clearable
              placeholder="请选择"
              :filterable="true"
            >
              <el-option
              v-for="option in departmentList"
              :key="option.value"
              :label="option.label"
              :value="option.value"
              />
          </el-select>
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="排序" prop="sort">
            <el-input
              v-model="form.sort"
              placeholder="请输入..."
              clearable
              maxlength="20"
            />
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="描述" prop="describe">
            <el-input
              v-model="form.describe"
              placeholder="请输入..."
              clearable
              maxlength="20"
            />
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="状态" prop="status">
            <el-switch
              v-model="form.status"
              name="status"
              active-text="启用"
              inactive-text="禁用"
              inactive-value="1"
              active-value="0"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-divider />
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit()">提 交</el-button>
      <el-button @click="updateVisible(false)">取 消</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { insertData, editData, getDetail,getAllList } from "@/api/classifyManage";

export default {
  props: {
    id: {
      type: String,
    },
    timeStamp: {
      type: String,
    },
  },
  data() {
    return {
      checkId: "",
      tableData: [],
      tableConfig: {
        align: "center", // 不必填 默认为center
        selection: "normal", // 不必填 多选: multiple  单选: single  默认为 normal
        index: true, // 不必填 序号
        pagination: false, // 不必填 分页 默认为false
        column: [],
      },
      rules: {
        name: [
          { required: true, message: "请输入活动名称", trigger: "blur" },
          // { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
        ],
        parentId: [
          { required: true, message: "请选择所属分类", trigger: "blur" },
          // { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
        ],
        sort: [
          { required: true, message: "请输入排序", trigger: "blur" },
          // { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
        ],
      },
      options: [],
      form: {
        // checkbox: [],
        // id:'',
        name:'',
        // status:'0',
        describe:'',
        // parentId:'',
        // sort:'',
      },
      total: 0,
      visible: false,
      departmentList:[],
      // timeStamp: '', //时间戳 用于修改数据或删除数据
    };
  },
  watch: {
    visible(newVal, oldVal) {
      if (newVal === true) {
        if (this.id) {
          this.getDetail();
        }
        //...todo
      }
    },
  },
  created() {
    this.getDepartmentList();
  },
  mounted() {},
  methods: {
    submit() {
      this.$refs.insertForm.validate((valid) => {
        if (valid) {
          const parm = this.form;
          if (this.id === "") {
            insertData(parm).then(({ res }) => {
              this.submitSuccess(res);
            });
          } else {
            editData(parm, this.timeStamp).then(({ res }) => {
              this.submitSuccess(res);
              this.form.id = '';
            });
          }
        }
      });
    },
    submitSuccess(res) {
      this.$message({ ...this.commonConfig.$handleSuccess });
      this.updateVisible(false);
      this.$emit("submit");
    },
    getDetail() {
      getDetail({ id: this.id }).then(({ res, timeStamp }) => {
        this.form = res;
        // this.timeStamp = timeStamp
      });
    },
    toDel(scope) {
      const { $delMsg, $delTitle, $messageBoxConfig } = this.commonConfig;
      this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
        const param = { id: scope.data.row.id };
        deleteData(param, this.timeStamp).then(({ res }) => {
          this.$message({ ...this.commonConfig.$handleSuccess });
        });
      });
    },
    updateVisible(val) {
      this.visible = val;
      if (val === false) {
        this.$refs.insertForm.resetFields();
        this.form.id = '';
      }
    },
    getDepartmentList() {
            getAllList().then(({ res }) => {
                this.departmentList = res.map((item) => {
                    return {
                        value: item.id,
                        label: item.name,
                    };
                    
                });
                console.log("回传字典项",this.departmentList)
            });
        },
  },
};
</script>
<style scoped lang="scss"></style>
