
<!--
* @Description: 认知系统患者训练记录
* @Reference:
-->

<template>
  <el-container>
      <el-header height="auto">
          <searchForm ref="mainSearch" :searchoptions="searchOptions" @search="search" :backStatus="backStatus" />
      </el-header>
      <el-main class="basic-main">
          <generalTable ref="mainTable" :table-data="tableData" :config="tableConfig" :total="total" @updatePage="getList"
              @onClickRow="onClickRow">
              <template slot="handle" slot-scope="scope">
                  <el-button type="text" class="delBtn" @click="toDel(scope)">删除</el-button>
              </template>
          </generalTable>
      </el-main>
  </el-container>
</template>

<script>
import { getListV2 as getList, deleteDataV2 as deleteData } from '@/api/trainingRecord'
import searchForm from '@/components/SearchForm'
import generalTable from '@/components/Table'



export default {
  name: 'TrainingRecordOfCognition',
  components: {
      searchForm,
      generalTable,
  },
  data() {
      return {
          tableData: [],
          searchOptions: [
              {
                  type: 'input',
                  placeholder: '请输入...',
                  key: 'patientName',
                  label: '患者姓名',
                  defaultVal: ''
              },
              {
                  type: 'input',
                  placeholder: '请输入...',
                  key: 'phoneNumber',
                  label: '手机号',
                  defaultVal: ''
              },
          ],
          tableConfig: {
              id: 'trainingRecord',
              align: 'center', // 不必填 默认为center
              selection: 'single', // 不必填 多选: multiple  单选: single  默认为 normal
              index: true, // 不必填 序号
              pagination: true, // 不必填 分页 默认为false
              column: [
                  {
                      label: '患者姓名', // 必填
                      prop: 'patientName' // 必填
                      // formatter: (row, column) => {
                      //     return row.archivesInfoId.name
                      // }
                  },
                  {
                      label: '手机号', // 必填
                      prop: 'phoneNumber' // 必填
                  },
                  {
                      label: '游戏名称', // 必填
                      prop: 'gameName', // 必填
                      width: '80',
                  },
                  {
                      label: '游戏时长（秒）', // 必填
                      prop: 'gameRunningTime' // 必填
                  },
                  {
                      label: '游戏分类', // 必填
                      prop: 'gameType', // 必填
                      width: '100',
                      // formatter: (row, column) => {
                      //     return ["上肢", "下肢", "手功能", "平衡", "认知"][row.gameType]
                      // }
                  },
                  {
                      label: '游戏难度', // 必填
                      prop: 'gameLevel',// 必填
                      // formatter: (row, column) => {
                      //     return ["简单", "普通", "困难"][row.gameLevel]
                      // }
                  },
                  {
                      label: '游戏失败次数', // 必填
                      prop: 'gameFailedTimes' // 必填
                  },
                  {
                      label: '游戏得分', // 必填
                      prop: 'gameScore' // 必填
                  },
                  {
                      label: '游戏开始时间', // 必填
                      prop: 'trainingTime', // 必填
                      width: '150',
                      formatter: (row, column) => {
                        return this.moment(row.trainingTime).format(
                          "YYYY-MM-DD HH:mm:ss"
                        );
                      }
                  },
                  // {
                  //     label: '游戏等级', // 必填
                  //     prop: 'gameLevel' // 必填
                  // },
                  {
                      label: '训练时间', // 必填
                      prop: 'recordTime', // 必填
                      width: '150',
                      formatter: (row, column) => {
                        return this.moment(row.recordTime).format(
                          "YYYY-MM-DD HH:mm:ss"
                        );
                      }
                  },
                  {

                      slot: 'handle', // 不必填 单列插槽
                      label: '操作', // 必填
                      fixed: 'right', // 不必填 固定列  left right
                      width: '60' // 不必填
                  }
              ]
          },
          paramObj: null, // 筛选项
          total: 0, // 总条数
          checkId: '', // 单条数据id 用于详情或编辑请求数据用
          backStatus: true, //返回按钮的隐藏显示
          timeStamp: '', //时间戳 用于修改数据或删除数据
      }
  },
  created() {
      this.getList()
  },
  mounted: function () { },
  methods: {
      search(data) {
        let systemType = '0' // 认知
          Object.assign(this.paramObj, data, { systemType })
          this.$refs.mainTable.handleCurrentChange(1)
      },
      getList() {
          this.$nextTick(() => {
              const param = this.getParam()
              getList(param).then(({ res, timeStamp }) => {
                  this.tableData = res.records
                  this.timeStamp = timeStamp
                  this.total = res.total
              })
          })
      },
      toDel(scope) {
          const { $delMsg, $delTitle, $messageBoxConfig, $handleSuccess } = this.commonConfig
          this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
              const param = { id: scope.data.row.id }
              deleteData(param, this.timeStamp).then(({ res }) => {
                  this.$message({ ...$handleSuccess })
                  this.getList()
              })
          })
      },
      onClickRow(row) {
          console.log('点击行:', row)
      },
      getParam() {
          const paramObj = this.$refs.mainSearch.packageData()
          const page = this.$refs.mainTable.getPage()
          let systemType = '0' // 认知
          const param = Object.assign({}, this.paramObj, page, paramObj, { systemType })
          this.paramObj = param
          return param
      }
  }
}
</script>

<style lang='scss' scoped>
@import "~@/styles/variables.scss";
</style>

