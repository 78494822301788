
<template>
    <el-dialog
        width="70%"
        title="分组管理"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        append-to-body
    >
      <el-header height="auto">
            <searchForm
                ref="mainSearch"
                :searchoptions="searchOptions"
                @search="search"
                :backStatus="backStatus"
            />
        </el-header>
        <el-form
            ref="insertForm"
            label-position="right"
            label-width="120px"
            class="formBox"
        >
            <el-divider />
            <!-- <div class="mdrcc-info-title">
              关联信息
            </div> -->
            <el-row :gutter="20">
                 <el-main class="basic-main">
                      <el-row class="table-btn-group">
                          <el-button type="primary" @click="toInsert()">新增</el-button>
                      </el-row>
                      <generalTable ref="mainTable" :table-data="tableData" :config="tableConfig" :total="total" @updatePage="getGeneralVideoTypeList" @onClickRow="onClickRow">
                          <template slot="handle" slot-scope="scope">
                              <el-button type="text" style="margin-right: 10px" @click="toEdit(scope)">编辑</el-button>
                              <el-button type="text" class="delBtn" @click="toDel(scope)">删除</el-button>
                          </template>
                      </generalTable>
                      <el-divider />
                       <addDialog :id="checkId" ref="insert"  :time-stamp="timeStamp" @submit='getGeneralVideoTypeList()' />
                 </el-main>
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="updateVisible(false)">关闭</el-button>
        </span>
    </el-dialog>
</template>

<script>
import generalTable from '@/components/Table'
import {getGeneralVideoTypeList,getGeneralVideoTypeInfo,addGeneralVideoTypeInfo,updateGeneralVideoTypeInfo,deleteGeneralVideoTypeInfo } from '@/api/generalVideoManagement'
import addDialog from '@/views/rehabilitation/generalVideo/addDialog'
import searchForm from "@/components/SearchForm";
export default {
    props: {
        id: {
            type: String
        }
    },
    components: {
      searchForm,
      generalTable,
      addDialog
    },
    data() {
      return {
        checkId: '',
        checkUserDoctorId:'',
        tableData: [],
        paramObj: {},
        searchOptions: [{
          type: "input",
          placeholder: "请输入...",
          key: "videoTypeName",
          label: "名称",
          defaultVal: "",
        }],
        tableConfig: {
            align: 'center', // 不必填 默认为center
            selection: 'normal', // 不必填 多选: multiple  单选: single  默认为 normal
            index: false, // 不必填 序号
            pagination: true, // 不必填 分页 默认为false
            column: [
                {
                    label: '分组名称', // 必填
                    prop: 'videoTypeName', // 必填
                },             
                {
                    slot: 'handle', // 不必填 单列插槽
                    label: '操作', // 必填
                    fixed: 'right',
                    width: '220' // 不必填
                }
            ]
        },
        total: 0,
        visible: false,
        form:[],
        backStatus: false, //返回按钮的隐藏显示
        timeStamp: '', //时间戳 用于修改数据或删除数据
      }
    },
    watch: {
        visible(newVal, oldVal) {
          if (newVal === true) {
            this.getGeneralVideoTypeList()
          }
        }
    },
    created() {},
    mounted() {},
    methods: {
      search(data) {
        Object.assign(this.paramObj, data);
        this.$refs.mainTable.handleCurrentChange(1);
      },
      onClickRow(checkArray) {
      },
      toInsert() {
        this.checkId = ''
        this.$refs.insert.updateVisible(true)
      },
      toEdit(scope) {
        this.checkId = scope.data.row.id
        this.$refs.insert.updateVisible(true) 
      },
      toDel(scope) {
        const { $delMsg, $delTitle, $messageBoxConfig, $handleSuccess } =
            this.commonConfig;
        this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
            const param = { id: scope.data.row.id };
            deleteGeneralVideoTypeInfo(param, this.timeStamp).then(({ res, timeStamp }) => {
            this.getGeneralVideoTypeList();
            this.$message({
                message: '删除成功',
                type: 'success'
                })
        });
        });          
      },
  //获取服务配置列表
      getGeneralVideoTypeList() {
        this.$nextTick(() => {
          const param = this.getParam();
            //param.id = this.id
            getGeneralVideoTypeList(param).then(({ res, timeStamp }) => {
              this.tableData = res.records;
              this.timeStamp = timeStamp;
              this.total = parseInt(res.total);
          })
        })
      },
      updateVisible(val) {
        this.visible = val
        if (val === false) {
            this.$refs.insertForm.resetFields()
            this.$emit('submit')
        }
      },
      getParam() {
        const paramObj = this.$refs.mainSearch.packageData();
        const page = this.$refs.mainTable.getPage();
        const param = Object.assign({}, this.paramObj, page, paramObj);
        this.paramObj = param;
        return param;
      },

    }
}
</script>
<style scoped lang='scss'>
</style>
      
