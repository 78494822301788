var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.showContactInfo)?_c('div',{class:['TUI-contact-info', !_vm.isPC && 'TUI-contact-info-h5']},[(!_vm.isPC)?_c('div',{class:[
      'TUI-contact-info-header',
      !_vm.isPC && 'TUI-contact-info-h5-header',
    ]},[_c('div',{class:[
        'TUI-contact-info-header-icon',
        !_vm.isPC && 'TUI-contact-info-h5-header-icon',
      ],on:{"click":_vm.resetContactSearchingUIData}},[_c('Icon',{attrs:{"file":_vm.backSVG}})],1),_c('div',{class:[
        'TUI-contact-info-header-title',
        !_vm.isPC && 'TUI-contact-info-h5-header-title',
      ]},[_vm._v(" "+_vm._s(_vm.TUITranslateService.t("TUIContact.添加好友/群聊"))+" ")])]):_vm._e(),_c('div',{class:['TUI-contact-info-basic', !_vm.isPC && 'TUI-contact-info-h5-basic']},[_c('div',{class:[
        'TUI-contact-info-basic-text',
        !_vm.isPC && 'TUI-contact-info-h5-basic-text',
      ]},[_c('div',{class:[
          'TUI-contact-info-basic-text-name',
          !_vm.isPC && 'TUI-contact-info-h5-basic-text-name',
        ]},[_vm._v(" "+_vm._s(_vm.generateContactInfoName(_vm.contactInfoData))+" ")]),_vm._l((_vm.contactInfoBasicList),function(item){return _c('div',{key:item.label,class:[
          'TUI-contact-info-basic-text-other',
          !_vm.isPC && 'TUI-contact-info-h5-basic-text-other',
        ]},[_vm._v(" "+_vm._s(`${_vm.TUITranslateService.t(`TUIContact.${item.label}`)}: ${item.data}`)+" ")])})],2),_c('img',{class:[
        'TUI-contact-info-basic-avatar',
        !_vm.isPC && 'TUI-contact-info-h5-basic-avatar',
      ],attrs:{"src":_vm.generateAvatar(_vm.contactInfoData)}})]),(_vm.contactInfoMoreList[0])?_c('div',{class:['TUI-contact-info-more', !_vm.isPC && 'TUI-contact-info-h5-more']},_vm._l((_vm.contactInfoMoreList),function(item){return _c('div',{key:item.key,class:[
        'TUI-contact-info-more-item',
        !_vm.isPC && 'TUI-contact-info-h5-more-item',
        item.labelPosition === _vm.CONTACT_INFO_LABEL_POSITION.TOP
          ? 'TUI-contact-info-more-item-top'
          : 'TUI-contact-info-more-item-left',
      ]},[_c('div',{class:[
          'TUI-contact-info-more-item-label',
          !_vm.isPC && 'TUI-contact-info-h5-more-item-label',
        ]},[_vm._v(" "+_vm._s(`${_vm.TUITranslateService.t(`TUIContact.${item.label}`)}`)+" ")]),_c('div',{class:[
          'TUI-contact-info-more-item-content',
          !_vm.isPC && 'TUI-contact-info-h5-more-item-content',
        ]},[(!item.editing)?_c('div',{class:[
            'TUI-contact-info-more-item-content-text',
            !_vm.isPC && 'TUI-contact-info-h5-more-item-content-text',
          ]},[_c('div',{class:[
              'TUI-contact-info-more-item-content-text-data',
              !_vm.isPC && 'TUI-contact-info-h5-more-item-content-text-data',
            ]},[_vm._v(" "+_vm._s(item.data)+" ")]),(item.editable)?_c('div',{class:[
              'TUI-contact-info-more-item-content-text-icon',
              !_vm.isPC && 'TUI-contact-info-h5-more-item-content-text-icon',
            ],on:{"click":function($event){return _vm.setEditing(item)}}},[_c('Icon',{attrs:{"file":_vm.editSVG,"width":"14px","height":"14px"}})],1):_vm._e()]):(item.editType === _vm.CONTACT_INFO_MORE_EDIT_TYPE.INPUT)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.data),expression:"item.data"}],class:[
            'TUI-contact-info-more-item-content-input',
            !_vm.isPC && 'TUI-contact-info-h5-more-item-content-input',
          ],attrs:{"type":"text"},domProps:{"value":(item.data)},on:{"confirm":function($event){return _vm.onContactInfoEmitSubmit(item)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.onContactInfoEmitSubmit(item)},"input":function($event){if($event.target.composing)return;_vm.$set(item, "data", $event.target.value)}}}):(item.editType === _vm.CONTACT_INFO_MORE_EDIT_TYPE.TEXTAREA)?_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(item.data),expression:"item.data"}],class:[
            'TUI-contact-info-more-item-content-textarea',
            !_vm.isPC && 'TUI-contact-info-h5-more-item-content-textarea',
          ],attrs:{"confirm-type":"done"},domProps:{"value":(item.data)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(item, "data", $event.target.value)}}}):(item.editType === _vm.CONTACT_INFO_MORE_EDIT_TYPE.SWITCH)?_c('div',{on:{"click":function($event){return _vm.onContactInfoEmitSubmit(item)}}},[_c('SwitchBar',{attrs:{"value":item.data}})],1):_vm._e()])])}),0):_vm._e(),_c('div',{class:[
      'TUI-contact-info-button',
      !_vm.isPC && 'TUI-contact-info-h5-button',
    ]},_vm._l((_vm.contactInfoButtonList),function(item){return _c('button',{key:item.key,class:[
        'TUI-contact-info-button-item',
        !_vm.isPC && 'TUI-contact-info-h5-button-item',
        item.type === _vm.CONTACT_INFO_BUTTON_TYPE.CANCEL
          ? `TUI-contact-info-button-item-cancel`
          : `TUI-contact-info-button-item-submit`,
      ],on:{"click":function($event){return _vm.onContactInfoButtonClicked(item)}}},[_vm._v(" "+_vm._s(_vm.TUITranslateService.t(`TUIContact.${item.label}`))+" ")])}),0)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }