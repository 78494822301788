var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"avatar-container",style:({
    width: _vm.avatarSize,
    height: _vm.avatarSize,
    borderRadius: _vm.avatarBorderRadius,
  })},[(_vm.isUniFrameWork)?[(!_vm.loadErrorInUniapp)?_c('image',{staticClass:"avatar-image",attrs:{"src":_vm.avatarImageUrl || _vm.defaultAvatarUrl},on:{"load":_vm.avatarLoadSuccess,"error":_vm.avatarLoadFailed}}):_c('image',{staticClass:"avatar-image",attrs:{"src":_vm.defaultAvatarUrl},on:{"load":_vm.avatarLoadSuccess,"error":_vm.avatarLoadFailed}})]:_c('img',{staticClass:"avatar-image",attrs:{"src":_vm.avatarImageUrl || _vm.defaultAvatarUrl},on:{"load":_vm.avatarLoadSuccess,"error":_vm.avatarLoadFailed}}),(_vm.useAvatarSkeletonAnimation)?_c('div',{class:{
      placeholder: true,
      hidden: _vm.isImgLoaded,
      'skeleton-animation': _vm.useAvatarSkeletonAnimation
    }}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }