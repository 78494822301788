/**
 * 页面: 分段视频
 * pageName: segmentedVideo
 **/
import request from '@/utils/request'
/**
 * @description: 分段视频查询列表
 */
export function getSegmentedVideoList(data, timeStamp) {
  return request({
      url: '/kfGuidanceDisassembleInfo/list',
      method: 'GET',
      params: data,
      showLoading: true
  })
}

/**
 * @description: 新增分段康复指导视频数据
 */
export function insertData(data) {
  return request({
      url: '/kfGuidanceDisassembleInfo/add',
      method: 'POST',
      data,
      showLoading: true
  })
}

/**
 * @description: 获取分段康复指导视频详情数据
 */
export function getDetail(data) {
  return request({
      url: '/kfGuidanceDisassembleInfo/info',
      method: 'GET',
      params: data,
      showLoading: true
  })
}

/**
 * @description:修改分段康复指导视频数据
 */
export function editData(data) {
  return request({
      url: '/kfGuidanceDisassembleInfo/update',
      method: 'POST',
      data,
      showLoading: true
  })
}

/**
 * @description: 删除康复指导视频数据
 */
export function deleteData(data, timeStamp) {
  return request({
      url: '/kfGuidanceDisassembleInfo/delete',
      method: 'POST',
      data,
      showLoading: true,
      timeStamp
  })
}

