<template>
  <div :class="['TUI-profile', !isPC && 'TUI-profile-h5']">
    <div
      :class="['TUI-profile-basic', !isPC && 'TUI-profile-h5-basic']"
    >
      <div :class="['TUI-profile-basic-info', !isPC && 'TUI-profile-h5-basic-info']">
        <div :class="['TUI-profile-basic-info-id', !isPC && 'TUI-profile-h5-basic-info-id']">
          <label
            :class="[
              'TUI-profile-basic-info-id-label',
              !isPC && 'TUI-profile-h5-basic-info-id-label',
            ]"
            >账号:{{ userAccount }}</label
          >
        </div>
      </div>
    </div>
    <div
      :class="['TUI-profile-basic', !isPC && 'TUI-profile-h5-basic']"
    >
      <div :class="['TUI-profile-basic-info', !isPC && 'TUI-profile-h5-basic-info']">
        <div :class="['TUI-profile-basic-info-id', !isPC && 'TUI-profile-h5-basic-info-id']">
          <label
            :class="[
              'TUI-profile-basic-info-id-label',
              !isPC && 'TUI-profile-h5-basic-info-id-label',
            ]"
            >昵称:康复助理</label
          >
        </div>
      </div>
    </div>
    <div
      :class="['TUI-profile-basic', !isPC && 'TUI-profile-h5-basic']"
    >
      <div :class="['TUI-profile-basic-info', !isPC && 'TUI-profile-h5-basic-info']">
        <div :class="['TUI-profile-basic-info-id', !isPC && 'TUI-profile-h5-basic-info-id']">
          <label
            :class="[
              'TUI-profile-basic-info-id-label',
              !isPC && 'TUI-profile-h5-basic-info-id-label',
            ]"
            >身份:客服专员</label
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { onMounted, defineProps, ref } from "../../TUIKit/adapter-vue";
import { getAccount } from "@/utils/common";
import { isPC } from "../../TUIKit/utils/env";
const __sfc_main = {};
__sfc_main.props = {};
__sfc_main.setup = (__props, __ctx) => {
  const userAccount = ref("");
  const props = __props;
  onMounted(() => {
    userAccount.value = getAccount();
  });
  return {
    isPC,
    userAccount
  };
};
export default __sfc_main;
</script>

<style lang="scss" scoped>
@import "../../styles/profile.scss";
</style>
