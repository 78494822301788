<template>
  <a
    class="message-location"
    :href="data.href"
    target="_blank"
    title="点击查看详情"
  >
    <span class="el-icon-location-outline">{{ data.description }}</span>
    <img :src="data.url" />
  </a>
</template>

<script lang="ts">
import { watchEffect, ref } from "../../../../adapter-vue";
const __sfc_main = {};
__sfc_main.props = {
  content: {
    type: Object,
    default: () => ({})
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const data = ref();
  watchEffect(() => {
    data.value = props.content;
  });
  return {
    data
  };
};
export default __sfc_main;
</script>
<style lang="scss" scoped>
@import "../../../../assets/styles/common.scss";
.message-location {
  display: flex;
  flex-direction: column;
}
</style>
