<!--
 * @Author: 358617935@qq.com 358617935@qq.com
 * @Date: 2023-05-23 14:24:06
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-06-14 14:28:46
 * @FilePath: /recovery_home_management_dev/src/views/channel/detailDialog.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->

<template>
  <el-dialog width="60%" title="渠道商管理详情" :visible.sync="visible" :close-on-click-modal="false"
    :close-on-press-escape="false" :show-close="false" custom-class="basicDialog" append-to-body>
    <el-descriptions class="margin-top" title="基本信息" :column="3">
      <el-descriptions-item label="渠道商名称">{{ detail.distributorName }}</el-descriptions-item>
      <el-descriptions-item label="联系人">{{ detail.contactName }}</el-descriptions-item>
      <el-descriptions-item label="联系人电话">{{ detail.contactPhone }}</el-descriptions-item>
      <el-descriptions-item label="地址" colon="true">
          <el-tooltip :content="detail.distributorAddress" placement="bottom" effect="light">
              <div class="desc-text">{{detail.distributorAddress}}</div>
          </el-tooltip>
      </el-descriptions-item>
      <el-descriptions-item label="邮箱">{{ detail.email }}</el-descriptions-item>
      <el-descriptions-item label="运营负责人">{{ detail.operateManagement }}</el-descriptions-item>
    </el-descriptions>
    <template slot="footer" class="dialog-footer">
      <el-button @click="updateVisible(false)">取 消</el-button>
    </template>
  </el-dialog>
</template>
<script>
import { getDetail } from "@/api/channel";
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      visible: false,
      detail: [], // 详情数据
    };
  },
  watch: {
    visible(newVal, oldVal) {
      if (newVal && this.id) this.getDetail();
    },
  },
  created() { },
  mounted() { },
  methods: {
    getDetail() {
      getDetail({ id: this.id }).then(({ res }) => {
        this.detail = res;
      });
    },
    updateVisible(flag) {
      this.visible = flag;
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep .el-descriptions-item__label{
    min-width: initial;
}
  .desc-text{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
</style>
