<template>
  <div class="flex flex_column flex_center patient-info">
    <div class="title">用户信息</div>
    <div class="item">
      <label class="item-label">姓名:</label>
      <span class="item-value">{{ userInfo.doctorName }}</span>
    </div>
    <div class="item">
      <label class="item-label">类型:</label>
      <span class="item-value">{{ userInfo.doctorTypeName }}</span>
    </div>
    <div class="item">
      <label class="item-label">职称:</label>
      <span class="item-value">{{ userInfo.functionalName }}</span>
    </div>
    <div class="item">
      <label class="item-label">科室:</label>
      <span class="item-value">{{ userInfo.sectionName }}</span>
    </div>
    <div class="item">
      <label class="item-label">所属医院:</label>
      <span class="item-value">{{ userInfo.hospitalName }}  </span>
      <div style="margin-top: 6px;" v-if="userInfo.hospitalLevel"><el-tag>{{ userInfo.hospitalLevel }}</el-tag></div>
    </div>
    <div class="item">
      <label class="item-label">性别:</label>
      <span class="item-value">{{ userInfo.genderName }}</span>
    </div>
    <div class="item">
      <label class="item-label">手机号:</label>
      <span class="item-value">{{ userInfo.phoneNumber }}</span>
    </div>
    <div class="item" v-if="userInfo.accountNumber">
      <el-button type="text" class="order_btn" @click="onClickOrder">查看订单</el-button>
    </div>
    <!-- <div class="item" >
      <el-button type="text" class="order_btn" @click="onClickRecommended">推荐康复师</el-button>
    </div>
    <div class="item" >
      <el-button type="text" class="order_btn" @click="onClickRecommendScheme">推荐方案</el-button>
    </div>
    <div class="item" >
      <el-button type="text" class="order_btn" @click="onClickRecommendDevice">推荐设备</el-button>
    </div> -->
    <doctorListDialog
      ref="doctorList"
      :defaultCheckIds="doctorId"
      selectMode="single"
      @submit="selectedDoctor"
    />
    <deviceListDialog
      ref="deviceList"
      :defaultCheckIds="doctorId"
      selectMode="single"
      @submitDevice="selectedDevice"
    />
    <schemeListDialog
      ref="schemeList"
      :defaultCheckIds="doctorId"
      selectMode="single"
      @submitScheme="selectedScheme"
    />
  </div>
</template>
<script lang="ts">
import { onMounted, watch } from "../../TUIKit/adapter-vue";
import doctorListDialog from "./doctorListDialog.vue";
import deviceListDialog from "./deviceListDialog.vue";
import schemeListDialog from "./schemeListDialog.vue";
import { ref, defineProps, nextTick } from "../../TUIKit/adapter-vue";
import { Toast, TOAST_TYPE } from "../../TUIKit/components/common/Toast";
interface IPatientInfo {
  accountNumber: string;
  age: string;
  disease: string;
  genderName: string;
  id: string;
  mobileNumber: string;
  name: string;
  userType: string;
}
import { getDoctorInfoByImIds } from '@/api/user';
import { sendDoctorCard, sendGoodsCard } from "@/api/recommend";
import router from "../../router/index";
const __sfc_main = {};
__sfc_main.props = {
  userID: {
    type: String,
    default: ""
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const userInfo = ref<IPatientInfo>({});
  const doctorList = ref();
  const deviceList = ref();
  const schemeList = ref();
  const doctorId = ref<any>([]);
  // 去订单
  const onClickOrder = () => {
    router.push({
      path: `/order/schemeOrder?mobileNumber=${userInfo.value.mobileNumber}&name=${userInfo.value.name}`
    });
  };
  // 推荐康复师
  const onClickRecommended = () => {
    console.log('doctorList =', doctorList);
    doctorList.value.open();
  };

  // 推荐设备
  const onClickRecommendDevice = () => {
    console.log('deviceList =', doctorList);
    deviceList.value.open();
  };

  // 推荐方案
  const onClickRecommendScheme = () => {
    console.log('doctorList =', doctorList);
    schemeList.value.open();
  };
  const getDoctorInfo = (imUserId: string) => {
    getDoctorInfoByImIds([imUserId]).then(res => {
      if (res.res.length) {
        userInfo.value = res.res[0];
      }
    });
  };
  const getUserType = () => {
    // 1.普通用户 2.付费用户 3.咨询超时用户
    if (userInfo.value) {
      console.log('userInfo.value.userType=', userInfo.value.userType);
      if (userInfo.value.userType == "1") {
        return '免费用户';
      } else if (userInfo.value.userType == "2") {
        return '付费用户';
      }
    }
    return "";
  };
  const selectedDoctor = rows => {
    console.log('rows=', rows);
    console.log('userInfo.value=', userInfo.value);
    if (rows && rows.length) {
      const params = {
        doctorId: rows[0],
        toAccount: userInfo.value.imId
      };
      console.log('params=', params);
      sendDoctorCard(params).then(res => {
        doctorId.value = [];
        Toast({
          message: '操作成功',
          type: TOAST_TYPE.SUCCESS
        });
      });
    }
  };
  const selectedDevice = ({
    rows,
    doctorId
  }) => {
    console.log('selectedDevice rows=', rows);
    console.log('selectedDevice doctorId=', doctorId);
    console.log('userInfo.value=', userInfo.value);
    if (rows) {
      const params = {
        goodsId: rows.id,
        isOnSale: rows.isOnSale,
        toAccount: userInfo.value.imId
      };
      if (doctorId != 'platform') {
        params.doctorId = doctorId;
      }
      console.log('selectedDevice params=', params);
      sendGoodsCard(params).then(res => {
        //doctorId.value = [];
        Toast({
          message: '操作成功',
          type: TOAST_TYPE.SUCCESS
        });
      });
    }
  };
  const selectedScheme = rows => {
    console.log('rows=', rows);
    console.log('userInfo.value=', userInfo.value);
    if (rows) {
      const params = {
        goodsId: rows.goodsId,
        toAccount: userInfo.value.imId,
        isOnSale: rows.isOnSale
      };
      console.log('params=', params);
      sendGoodsCard(params).then(res => {
        Toast({
          message: '操作成功',
          type: TOAST_TYPE.SUCCESS
        });
      });
    }
  };
  watch(() => props.userID, (newVal: any, oldVal: any) => {
    nextTick(() => {
      if (newVal) getDoctorInfo(newVal);
    });
  }, {
    immediate: true
  });

  // 规避TUIStore.watch userProfile 登录后暂时不能及时触发更新
  onMounted(() => {});
  return {
    userInfo,
    doctorList,
    deviceList,
    schemeList,
    doctorId,
    onClickOrder,
    selectedDoctor,
    selectedDevice,
    selectedScheme
  };
};
__sfc_main.components = Object.assign({
  doctorListDialog,
  deviceListDialog,
  schemeListDialog
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss" scoped>
.flex {
  box-sizing: border-box;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  border: 0px solid black;
  margin: 0;
  padding: 0;
  width: 100%;
}

.flex_column {
  flex-direction: column;
}

.flex_center {
  justify-content: center;
}

.order_btn{
  font-size: 14px;
}

.patient-info {
  .title {
    font-size: 16px;
    font-weight: 600;
    color: #333;
    line-height: 50px;
    height: 50px;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #f4f5f9;
  }
  .item {
    margin: 5px 20px;
    &-label {
      font-size: 14px;
    }
    &-value {
      margin-left: 10px;
      color: #999999;
      font-size: 14px;
    }
  }
}
</style>
