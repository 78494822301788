/*
 * @Author: your name
 * @Date: 2022-03-22 16:54:34
 * @LastEditTime: 2024-07-30 01:24:51
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /recovery_home_management_dev/src/api/project.js
 */

/**
 * 页面: 治疗方案
 * pageName: project
 **/
import request from '@/utils/request'

/**
 * @description: 获取治疗方案列表数据
 */
export function getList(data) {
    return request({
        url: '/admin/kfGuidance/list',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 新增治疗方案数据
 */
export function insertData(data) {
    return request({
        url: '/admin/kfGuidance/add',
        method: 'POST',
        data,
        showLoading: true
    })
}

/**
 * @description: 修改治疗方案数据
 */
export function editData(data, timeStamp) {
    return request({
        url: '/admin/kfGuidance/update',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}


/**
 * @description: 获取治疗方案详情数据
 */
export function getDetail(data) {
    return request({
        url: '/admin/kfGuidance/info',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 删除治疗方案数据
 */
export function deleteData(data, timeStamp) {
    return request({
        url: '/admin/kfGuidance/delete',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}

/**
 * @description: 启用/禁用
 */
export function changeState(data, timeStamp) {
    return request({
        url: '/admin/kfGuidance/changeState',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}

/**********************康复视频 ***************************/
/**
 * @description: 康复视频列表
 * @param {*} data
 * @return {*}
 */
export function getVideoList(data) {
    return request({
        url: '/admin/kfGuidanceVideoDetails/list',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 新增治疗方案数据
 */
export function insertVideoData(data) {
    return request({
        url: '/admin/kfGuidanceVideoDetails/add',
        method: 'POST',
        data,
        showLoading: true
    })
}

/**
 * @description: 修改治疗方案数据
 */
export function editVideoData(data, timeStamp) {
    return request({
        url: '/admin/kfGuidanceVideoDetails/update',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}


/**
 * @description: 删除治疗方案数据
 */
export function deleteVideoData(data, timeStamp) {
    return request({
        url: '/admin/kfGuidanceVideoDetails/delete',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}


/**
 * @description: 删除治疗方案数据
 */
export function getSchemeTagsOptions(data, timeStamp) {
    return request({
        url: '/sysTag/tagList',
        method: 'GET',
        data,
        timeStamp
    })
}


/**
 * 
 * @description: 新版方案管理 获取方案列表
 */

export function getSchemeList(data, timeStamp) {
    return request({
        url: '/admin/kfSchemeInfo/list',
        method: 'GET',
        params: data,
        showLoading: true
    })
}


/**
 * 
 * @description: 康复服务细则类目
 */

export function getServiceTypeList(data, timeStamp) {
    return request({
        url: '/kfServiceType/list',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

