<template>
  <div :class="['home', isH5 && 'home-h5']" :id="isPC ? 'preloadedImages' : ''">
    <div v-show="isMenuShow" class="home-menu">
      <Menu @closeMenu="toggleMenu(false)"></Menu>
    </div>
    <div :class="['home-container', isMenuShow && 'menu-expand']">
      <div v-if="isPC" class="home-header">
        <Header
          :class="[isMenuShow && 'header-menu-show']"
          showType="menu"
          v-if="false"
          :defaultLanguage="locale"
          @toggleMenu="toggleMenu(!isMenuShow)"
          @changeLanguage="changeLanguage"
        ></Header>
      </div>
      <div class="home-main">
        <div class="home-TUIKit">
          <div v-if="isPC || !currentConversationID" class="home-TUIKit-navbar">
            <NavBar
              :currentNavBar="currentNavBar"
              :isSettingShow="isSettingShow"
              @toggleSettingShow="toggleSettingShow"
              @switchNavBar="switchNavBar"
            >
              <template v-slot:profile>
                <Profile display-type="profile"></Profile>
              </template>
              <template v-slot:setting>
                <Profile
                ></Profile>
              </template>
            </NavBar>
          </div>
          <div v-if="isPC" class="home-TUIKit-main">
            <div v-show="currentNavBar === 'message'" class="home-TUIKit-main">
              <div class="home-conversation">
                <!-- <TUISearch searchType="global"></TUISearch> -->
                <TUIConversation @handleSwitchConversation="handleSwitchConversation"></TUIConversation>
              </div>
              <div class="home-chat">
                <TUIChat>
                  <ChatDefaultContent></ChatDefaultContent>
                </TUIChat>
                <TUIGroup class="chat-aside" />
                <TUISearch class="chat-aside" searchType="conversation" />
              </div>
              <div class="home-user-info" v-if="currentConversationID">
                <PatientInfo v-if="chatType=='patient'" :userID = "targetImUserID"/>
                <DoctorInfo v-if="chatType=='doctor'" :userID = "targetImUserID"/>
              </div>
              <TUIContact display-type="selectFriend" />
            </div>
            <div v-show="currentNavBar === 'relation'" class="home-TUIKit-main">
              <TUIContact
                display-type="contactList"
                @switchConversation="currentNavBar = 'message'"
              />
            </div>
          </div>
          <div v-else-if="isH5" class="home-TUIKit-main">
            <div class="home-TUIKit-main" v-if="!currentConversationID">
              <div v-show="currentNavBar === 'message'" class="home-TUIKit-main">
                <TUISearch searchType="global"></TUISearch>
                <TUIConversation></TUIConversation>
                <TUIContact display-type="selectFriend"></TUIContact>
              </div>
              <div v-show="currentNavBar === 'relation'" class="home-TUIKit-main">
                <TUIContact
                  display-type="contactList"
                  @switchConversation="currentNavBar = 'message'"
                />
              </div>
              <div v-show="currentNavBar === 'profile'" class="home-TUIKit-main">
                <Profile display-type="all" />
              </div>
            </div>
            <TUIChat v-else></TUIChat>
            <TUIGroup class="chat-popup" />
            <TUISearch class="chat-popup" searchType="conversation" />
          </div>
          <Drag :show="isCalling" domClassName="callkit-drag-container" ref="dragRef">
            <TUICallKit
              :class="[
                'callkit-drag-container',
                `callkit-drag-container-${isMinimized ? 'mini' : isH5 ? 'h5' : 'pc'}`,
              ]"
              :allowedMinimized="true"
              :allowedFullScreen="false"
              :beforeCalling="beforeCalling"
              :afterCalling="afterCalling"
              :onMinimized="onMinimized"
            />
          </Drag>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { ref, withDefaults, defineProps, defineEmits } from "../../TUIKit/adapter-vue";
import { TUILogin } from "@tencentcloud/tui-core";
import { TUIStore, StoreName } from "@tencentcloud/chat-uikit-engine";
import { TUICallKit } from "@tencentcloud/call-uikit-vue2";
import { imLogin } from "@/utils/im";
import { getIMUserSig, getIMUserId } from "@/utils/common";
import { TUIChat, TUIConversation, TUIContact, TUIGroup, TUISearch } from "../../TUIKit";
import Header from "../../components/customerService/Header.vue";
import Menu from "../../components/customerService/Menu.vue";
import NavBar from "../../components/customerService/NavBar.vue";
import Profile from "../../components/customerService/Profile.vue";
import ChatDefaultContent from "../../components/customerService/ChatDefaultContent.vue";
import PatientInfo from "../../components/customerService/PatientInfo.vue";
import DoctorInfo from "../../components/customerService/DoctorInfo.vue";
import Drag from "../../TUIKit/components/common/Drag";
import { isPC, isH5 } from "../../TUIKit/utils/env";
import { enableSampleTaskStatus } from "../../TUIKit/utils/enableSampleTaskStatus";
const __sfc_main = {};
__sfc_main.props = {
  language: {
    key: "language",
    required: false,
    type: String,
    default: "zh"
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emits = __ctx.emit;
  const locale = ref<string>(props.language);
  const isMenuShow = ref<boolean>(false);
  const currentNavBar = ref<string>("message");
  const currentConversationID = ref<string>("");
  const isCalling = ref<boolean>(false);
  const isMinimized = ref<boolean>(false);
  const dragRef = ref<typeof Drag>();
  const targetImUserID = ref<string>("");
  const isSettingShow = ref<boolean>(false);
  const chatType = ref<string>("");
  // changeLanguage: 语言切换
  function changeLanguage(language: string) {
    emits("changeLanguage", language);
  }
  TUIStore.watch(StoreName.CONV, {
    currentConversationID: (id: string) => {
      currentConversationID.value = id;
    }
  });
  let res = TUILogin.getContext();
  console.log("res===", res);
  if (!res.chat) {
    // IM登录无效
    let imUserId = getIMUserId();
    let imUserSig = getIMUserSig();
    if (imUserId && imUserSig) {
      console.log("imUserId===", imUserId);
      console.log("imUserSig===", imUserSig);
      imLogin(imUserId, imUserSig);
    }
  }
  function toggleMenu(value: boolean) {
    isMenuShow.value = value;
  }
  function handleSwitchConversation(conversation: any) {
    console.log("handleSwitchConversation===", conversation);
    if (conversation) {
      // conversation.value.userProfile.userID = conversation.conversationID;
      console.log('conversation.userProfile.userID=', conversation.userProfile.userID);
      targetImUserID.value = conversation.userProfile.userID;
      chatType.value = conversation.chatType;
    }
  }

  // beforeCalling: 通话前执行
  function beforeCalling() {
    isCalling.value = true;
    enableSampleTaskStatus("call");
  }
  // afterCalling: 通话后执行
  function afterCalling() {
    isCalling.value = false;
    isMinimized.value = false;
  }
  // onMinimized：音视频通话组件切换最小化状态时执行
  function onMinimized(oldMinimizedStatus: boolean, newMinimizedStatus: boolean) {
    isMinimized.value = newMinimizedStatus;
    dragRef?.value?.positionReset();
  }
  function toggleSettingShow(value: boolean) {
    isSettingShow.value = value;
  }
  function switchNavBar(value: string) {
    currentNavBar.value = value;
  }
  return {
    isPC,
    isH5,
    locale,
    isMenuShow,
    currentNavBar,
    currentConversationID,
    isCalling,
    isMinimized,
    dragRef,
    targetImUserID,
    isSettingShow,
    chatType,
    changeLanguage,
    toggleMenu,
    handleSwitchConversation,
    beforeCalling,
    afterCalling,
    onMinimized,
    toggleSettingShow,
    switchNavBar
  };
};
__sfc_main.components = Object.assign({
  Menu,
  Header,
  NavBar,
  Profile,
  TUIConversation,
  TUIChat,
  ChatDefaultContent,
  TUIGroup,
  TUISearch,
  PatientInfo,
  DoctorInfo,
  TUIContact,
  Drag,
  TUICallKit
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss">
@import "@/styles/customerService.scss";
</style>
