
import Layout from "@/layout";
export const constantRoutes = [
  {
    path: "/projectMain",
    pageName: "projectMain",
    component: Layout,
    name: "projectMain",
    redirect: "/project",
    children: [
      {
        path: "/project",
        name: "project",
        hidden: false,
        component: () => import("@/views/project/index"),
        meta: {
          title: "康复方案管理",
          icon: "home",
          breadcrumb: true,
          keepAlive: true,
        },
      },
      {
        path: "/project/add",
        name: "project-add",
        hidden: true,
        component: () => import("@/views/project/add"),
        meta: {
          title: "康复方案新增",
          icon: "home",
          breadcrumb: true,
          keepAlive: true,
        },
      },
      {
        path: "/project/patient",
        name: "project-patient",
        hidden: true,
        component: () => import("@/views/project/patient"),
        meta: {
          title: "康复方案患者",
          icon: "home",
          breadcrumb: true,
          keepAlive: true,
        },
      },
      
    ]
  },

];

export default constantRoutes;
