<!--
* @Description: 设置分成比例
* @Reference:
-->
<template>
  <el-dialog
    width="350px"
    title="分成比例"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    append-to-body
  >
    <el-form
      ref="insertForm"
      :model="form"
      size="small"
      label-position="right"
      label-width="80px"
      class="formBox"
      :rules="rules"
    >
      <el-form-item label="医生" prop="doctorId" v-if="!form.id">
        <el-select
          v-model="form.doctorId"
          clearable
          filterable
          size="medium"
          remote
          :remote-method="searchDoctor"
          placeholder="请输入医生姓名或手机号"
        >
          <el-option
            v-for="option in doctors"
            :key="option.id"
            :label="option.name"
            :value="option.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="分成比例" prop="shareInProportion">
        <el-input-number
          v-model="form.shareInProportion"
          @change="handleChange"
          size="medium"
          :min="1"
          :step="10"
          :max="100"
          label=""
        ></el-input-number
        >%
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit()">提 交</el-button>
      <el-button @click="updateVisible(false)">取 消</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { saveRatio } from "@/api/courseProportion";
import { getList } from "@/api/doctorManagement";
import _ from "lodash";

export default {
  props: {
    id: {
      type: String
    },
    timeStamp: {
      type: String
    }
  },
  data() {
    return {
      rules: {
        shareInProportion: [
          {
            required: true,
            min: 0,
            max: 100,
            type: "number",
            message: "分成比例",
            trigger: "blur"
          }
        ],
        doctorId: [
          {
            required: true,
            message: "请选择医生",
            trigger: "change"
          }
        ]
      },
      doctors: [],
      form: {
        id: "",
        doctorId: "",
        resourceId: "",
        shareInProportion: 10
      },
      visible: false
    };
  },
  created() {},
  mounted() {},
  methods: {
    handleChange(value) {
      this.form.shareInProportion = value;
    },
    searchDoctor: _.debounce(function(val) {
      if (!val) {
        this.doctors = [];
        return;
      }
      getList({ keyword: val }).then((res, timeStamp) => {
        this.doctors = res.res.records;
        this.doctors.forEach(item => {
          item.name = item.name + "(" + item.phoneNumber + ")";
        });
      });
    }, 300),
    submit() {
      this.$refs.insertForm.validate(valid => {
        if (valid) {
          const parm = this.form;
          if (!parm["id"]) {
            delete parm["id"];
          }
          saveRatio(parm).then(({ res }) => {
            this.submitSuccess(res);
          });
        }
      });
    },
    submitSuccess(res) {
      this.$message({ ...this.commonConfig.$handleSuccess });
      this.updateVisible(false);
      this.$emit("submit");
    },

    updateVisible(val, item) {
      this.visible = val;
      if (item) {
        this.form.resourceId = item.resource_id;
        this.form.doctorId = item.doctorId;
        this.form.id = item.goodsDoctorRelationId;
        this.form.shareInProportion = item.shareInProportion || 20;
      }
      if (val === false) {
        this.doctors = [];
        this.$refs.insertForm.resetFields();
      }
    }
  }
};
</script>
<style scoped lang="scss">
.el-input-number.is-without-controls .el-input__inner {
  width: 400px;
}
</style>
