
<template>
    <el-dialog
        width="70%"
        :title="id === '' ? '新增治疗方案' : '修改治疗方案'"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        append-to-body
    >
        <el-form
            ref="insertForm"
            :model="form"
            label-position="right"
            label-width="120px"
            class="formBox"
            :rules="rules"
        >
            <div class="mdrcc-info-title">基本信息</div>
            <el-row :gutter="10" type="flex" justify="start">
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="方案名称" prop="name">
                        <el-input
                            v-model="form.name"
                            placeholder="请输入..."
                            clearable
                            maxlength="20"
                        />
                    </el-form-item>
                </el-col>
                <!-- <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="分类" prop="kfGuidanceTypeId">
                        <el-cascader
                            v-model="form.kfGuidanceTypeId"
                            :options="classifyArray"
                            :props="{
                                checkStrictly: true,
                                emitPath: false,
                                label: 'officesName',
                                value: 'id',
                            }"
                            clearable
                            filterable
                        />
                    </el-form-item>
                </el-col> -->
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="来源" prop="source">
                        <el-select
                            v-model="form.source"
                            clearable
                            placeholder="请选择"
                            :filterable="true"
                            style="width: 100%"
                        >
                            <el-option
                                v-for="option in source"
                                :key="option.value"
                                :label="option.label"
                                :value="option.value"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="使用类型" prop="useType">
                        <el-select
                            v-model="form.useType"
                            clearable
                            placeholder="请选择"
                            :filterable="true"
                            style="width: 100%"
                        >
                            <el-option
                                v-for="option in useType"
                                :key="option.value"
                                :label="option.label"
                                :value="option.value"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :sm="24" :md="24" :lg="12" :xl="12">
                    <el-form-item label="图片" prop="photo">
                        <div class="img">
                            <img
                                :src="commonConfig.$filePublicUrl + form.photo"
                                v-show="form.photo"
                                @click="setLogoClick"
                                class="imgBox"
                            />
                            <div
                                :src="form.photo"
                                v-show="!form.photo"
                                @click="setLogoClick"
                                class="defalutImg"
                            >
                                <i class="el-icon-plus"></i>
                            </div>
                        </div>
                        <input
                            type="file"
                            name="form.photo"
                            accept="image/gif,image/jpeg,image/jpg,image/png"
                            style="display: none"
                            @change="changeLogoImage($event)"
                            ref="logoInput"
                        />
                    </el-form-item>
                    <el-form-item label="图片描述" prop="photoDescribe">
                        <el-input
                            v-model="form.photoDescribe"
                            type="textarea"
                            clearable
                            :autosize="{ minRows: 2, maxRows: 3 }"
                            maxlength="200"
                            placeholder="请输入图片描述"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="24" :md="24" :lg="24" :xl="24">
                    <el-form-item label="方案描述" prop="describes">
                        <el-input
                            v-model="form.describes"
                            type="textarea"
                            clearable
                            :autosize="{ minRows: 2, maxRows: 4 }"
                            maxlength="200"
                            placeholder="请输入方案描述"
                        />
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div style="margin: 10px 0px;">
            <div class="mdrcc-info-title">视频信息</div>
            <el-row :gutter="10">
                <div class="table-btn-group">
                    <el-button type="primary" @click="addVideo()"
                        >新增视频</el-button
                    >
                </div>
                <generalTable
                    ref="mainTable"
                    :table-data="tableData"
                    :config="tableConfig"
                    :total="total"
                    :newHeight ="tableData.length*69+36"
                >
                    <template slot="photo" slot-scope="scope">
                        <el-image
                            style="width: 50px; height: 50px; margin-top: 10px"
                            :src="
                                commonConfig.$filePublicUrl +
                                scope.data.row.photo
                            "
                            :preview-src-list="[
                                commonConfig.$filePublicUrl +
                                    scope.data.row.photo,
                            ]"
                        >
                        </el-image>
                    </template>
                    <template slot="handle" slot-scope="scope">
                        <el-button type="text" @click="toDetailVideo(scope)"
                            >详情</el-button
                        >
                        <!-- <el-button type="text" @click="toEdit(scope.row.id)"
                            >编辑</el-button
                        > -->
                        <el-button
                            type="text"
                            class="delBtn"
                            @click="toDelVideo(scope)"
                            >删除</el-button
                        >
                    </template>
                </generalTable>
            </el-row>
        </div>
        <addVideoDialog
            :id="checkId"
            ref="insert"
            :checkIds ="checkIds"
            orgList="tableData" 
            @submit="addVideoClick"
            :classify-array="classifyArray"
            :identifier-array="identifierArray"
        />
        <detailDialog
            :id="checkId"
            :identifier-array="identifierArray"
            ref="detail"
        />
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submit()">提 交</el-button>
            <el-button @click="updateVisible(false)">取 消</el-button>
        </span>
    </el-dialog>
</template>
<script>
import {
    insertData,
    editData,
    getDetail,
    getVideoList,
    insertVideoData,
    deleteVideoData,
    editVideoData,
} from "@/api/project";
import { uploadPrivateFile, uploadPublicFile } from "@/utils/uploadQiniu.js";
import generalTable from "@/components/Table";
import addVideoDialog from "@/views/rehabilitation/project/addVideoDialog";
import { getDict } from "@/api/common";
import detailDialog from "@/views/rehabilitation/videoEquipment/detailDialog";

export default {
    components: {
        generalTable,
        addVideoDialog,
        detailDialog,
    },
    props: {
        id: {
            type: String,
        },
        timeStamp: {
            type: String,
        },
        classifyArray: {
            required: true,
            type: Array,
        },
        identifierArray: {
            required: true,
            type: Array,
        },
        source: {
            required: true,
            type: Array,
        },
        useType: {
            required: true,
            type: Array,
        },
    },
    data() {
        return {
            checkId: "",

            rules: {
                name: [
                    {
                        required: true,
                        message: "请输入方案名称",
                        trigger: "blur",
                    },
                    {
                        min: 1,
                        max: 10,
                        message: "长度在 1 到 10 个字符",
                        trigger: "blur",
                    },
                ],
                // kfGuidanceTypeId: [
                //     {
                //         required: true,
                //         message: "请选择分类",
                //         trigger: "change",
                //     },
                // ],
                source: [
                    {
                        required: true,
                        message: "请选择来源",
                        trigger: "change",
                    },
                ],
                useType: [
                    {
                        required: true,
                        message: "请选择使用类型",
                        trigger: "change",
                    },
                ],
            },
            options: [],
            form: {
                id:'',
                checkbox: [],
                name: "", //名称
                source: "", //来源
                photo: "", //相片
                // kfGuidanceTypeId: "", //分类
                photoDescribe: "", //相片描述
                useType: "", //使用类型
                describes: "", //描述
                state:"1",//启用、禁用
                kfGuidanceVideoList:[],//关联视频
            },
            total: 0,
            visible: false,
            tableData: [],
            tableConfig: {
                align: "center", // 不必填 默认为center
                selection: "normal", // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: false, // 不必填 分页 默认为false
                column: [
                    {
                        label: "视频名称", // 必填
                        prop: "name", // 必填
                        // formatter: (row, column, cellValue, index) => {
                        //     return row.first + index;
                        // },
                    },
                    {
                        label: "类型", // 必填
                        prop: "identifier", // 必填
                        // formatter: (row) => {
                        //     // 根据字典表展示对应项目的label值
                        //     const currentType = this.identifier.filter(
                        //         (type) => type.value === row.identifier
                        //     );
                        //     if (currentType.length) {
                        //         return currentType[0].label;
                        //     }
                        // },
                        width: "100", // 不必填
                    },
                    {
                        label: "肢体类型", // 必填
                        prop: "limbsTypeLabel", // 必填
                    },
                    {
                        label: "治疗类型", // 必填
                        prop: "treatmentTypeLabel", // 必填
                    },
                    {
                        label: "方案等级", // 必填
                        prop: "guidanceLevelLabel", // 必填
                    },
                    // {
                    //     label: "分类", // 必填
                    //     prop: "guidanceTypeName", // 必填
                    // },
                    {
                        label: "描述", // 必填
                        prop: "describe", //
                        width: "220", // 不必填
                    },
                    {
                        label: "封面图片", // 必填
                        slot: "photo", // 必填
                        width: "100",
                    },
                    {
                        label: "锻炼时间（一次多长分钟）", // 必填
                        prop: "length", // 必填
                        width: "180", // 不必填
                        formatter: (row) => {
                            return row.length + "分钟";
                        },
                    },
                    {
                        label: "锻炼时长（锻炼几天）", // 必填
                        prop: "lengthExercise", // 必填
                        width: "180", // 不必填
                        formatter: (row) => {
                            return row.lengthExercise + "天";
                        },
                    },
                    {
                        label: "锻炼次数（每天几次)", // 必填
                        prop: "number", // 必填
                        width: "180", // 不必填
                        formatter: (row) => {
                            return row.number + "次";
                        },
                    },
                    {
                        slot: "handle", // 不必填 单列插槽
                        label: "操作", // 必填
                        fixed: "right",
                        width: "220", // 不必填
                    },
                ],
            },
            kfGuidanceId: "", //康复方案表id
            identifier: [],
            // timeStamp: "", //时间戳 用于修改数据或删除数据
            checkIds:[],
        };
    },
    watch: {
        visible(newVal, oldVal) {
            if (newVal === true) {
                if (this.id) {
                    // this.identifier = this.identifierArray;
                    this.getDetail();
                }
                //...todo
            }
        },
    },
    created() {},
    mounted() {},
    methods: {
        submit() {
            this.$refs.insertForm.validate((valid) => {
                if (valid) {
                    const parm = {...this.form, kfGuidanceVideoList: this.tableData};
                    if (this.id === "") {
                        insertData(parm).then(({ res }) => {
                            this.submitSuccess(res);
                        });
                    } else {
                        editData(parm, this.timeStamp).then(({ res }) => {
                            this.submitSuccess(res);
                        });
                    }
                }
            });
        },

        getDetail() {
            getDetail({ id: this.id }).then(({ res, timeStamp }) => {
                this.form = res;
                this.kfGuidanceId = res.id;
                // this.timeStamp = timeStamp
                this.getVideoList();
            });
        },
        /**
         * @description: 视频详情
         * @param {*} scope
         * @return {*}
         */
        toDetailVideo(scope) {
            this.checkId = scope.data.row.id;
            this.$refs.detail.updateVisible(true);
        },

        setLogoClick() {
            this.$refs.logoInput.click();
        },
        /**
         * @description: 上传头像
         * @param {*} event
         * @return {*}
         */
        changeLogoImage(event) {
            let file = event.target.files[0];
            const thiz = this;
            uploadPublicFile(file)
                .then((success) => {
                    console.log("success====", success);
                    thiz.form.photo = success;
                })
                .catch((handleError) => {
                    this.$message({
                        message: "上传失败，请重试",
                        type: "warning",
                    });
                    // console.log("handleError====", handleError);
                });
        },
        /**
         * @description: 获取绑定视频
         * @param {*}
         * @return {*}
         */
        getVideoList() {
            this.$nextTick(() => {
                // const param = this.getParam();
                getVideoList({ kfGuidanceId: this.kfGuidanceId }).then(
                    ({ res, timeStamp }) => {
                        this.tableData = res;
                        // this.timeStamp = timeStamp;
                        this.checkIds = res.map((item=>{
                            return item.id
                        }))
                        console.log('this.checkIds==',this.checkIds)
                    }
                );
            });
        },
        /**
         * @description: 删除视频
         * @param {*} scope
         * @return {*}
         */
        toDelVideo(scope) {
            const { $delMsg, $delTitle, $messageBoxConfig } = this.commonConfig;
            this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
                this.tableData = this.tableData.filter(item => item.id !== scope.data.row.id)
                this.checkIds = this.tableData.map((item=>{
                    return item.id
                }))
            });
        },
        /**
         * @description: 新增绑定视频
         * @param {*}
         * @return {*}
         */
        addVideo() {
            this.checkId = "";
            this.$refs.insert.updateVisible(true);
        },
        /**
         * @description: 添加视频回调
         * @param {*} data
         * @return {*}
         */
        addVideoClick(data) {
            this.tableData = [];
            const newData = [...this.tableData, ...data];
            this.tableData = newData;
            this.checkIds = newData.map(item=>{return item.id})
        },

        submitSuccess(res) {
            this.$message({ ...this.commonConfig.$handleSuccess });
            this.updateVisible(false);
            this.$emit("submit");
        },

        updateVisible(val) {
            this.visible = val;
            if (val === false) {
                this.$refs.insertForm.resetFields();
                this.tableData = [];
                this.form.id = '';
            }
        },
    },
};
</script>
<style scoped lang='scss'>
@import "~@/styles/variables.scss";

.imgBox {
    width: 150px;
    height: 150px;
    object-fit: contain;
    border: 1px solid $tableColor;
}
.defalutImg {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 150px;
    border: 1px solid $tableColor;
    border-radius: 4px;
    cursor: pointer;
}
</style>
      
        