<template>
    <el-dialog width="1000px" title="选择设备" :visible.sync="visible" :close-on-click-modal="false"
        :close-on-press-escape="false" append-to-body>

        <searchForm ref="mainSearch" :searchoptions="searchOptions" @search="search" :backStatus="false" />
        <generalTable ref="mainTable" :checkIds="checkIds" :table-data="tableData" :config="tableConfig" :total="total"
            @updatePage="getList" @selection-change="rows => checkLength = rows.length" @updateSelectedIds="updateSelectedIds"  @onClickRow="onClickRow"></generalTable>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submit()">选 择</el-button>
            <el-button @click="visible = false">取 消</el-button>
        </span>
        <selectDoctor
            ref="selectDoctorRef"
            :goodId="checkIds[0]"
            @selectDoctor="selectDoctor"
        />
    </el-dialog>
</template>
<script>
    import request from '@/utils/request'
    import searchForm from '@/components/SearchForm'
    import generalTable from '@/components/Table'
    import { getAllHospitalList } from '@/api/embodyAndRefund';
    import selectDoctor from '@/components/customerService/selectDoctor'
    export default {
        components: {
            searchForm, generalTable, selectDoctor
        },
        props: {
            defaultCheckIds: {
                type: Array,
                default: () => { return [] }
            },
            selectMode: {
                type: String,
                default: () => { return 'multiple' }
            },
        },
        data() {
            return {
                visible: false,
                total: 0,
                paramObj: {},
                searchOptions: [
                    {
                        type: 'input',
                        placeholder: '请输入商品名称',
                        key: 'goodsName',
                        label: '商品名称',
                        defaultVal: ''
                    },
                    {
                        type: 'input',
                        placeholder: '请输入医生姓名',
                        key: 'doctorName',
                        label: '医生姓名',
                        defaultVal: ''
                    },
                    // {
                    //     type: 'select',
                    //     placeholder: '请输入医生姓名',
                    //     key: 'doctorName',
                    //     filterable: true,
                    //     multiple: false,
                    //     label: '医生姓名',
                    //     options: [],
                    //     defaultVal: [],
                    //     remoteMethod: (params) => this.getRemoteHospital(params),
                    // },
                ],
                checkIds: [],
                checkRow: null,
                bindDoctorId: '',
                checkLength: 0,
                tableData: [],
                tableConfig: {
                    id: 'doctorListDialog',
                    index: false,
                    selection: this.selectMode,
                    pagination: true,
                    saveSelected: false,
                    column: [
                        {
                            label: '商品名称',
                            prop: 'name',
                        },
                        {
                            label: '商品编码',
                            prop: 'goodsSn',
                        },
                        {
                            label: '商品类型',
                            prop: 'categoryName',
                        },
                        // {
                        //     label: '商品属性',
                        //     prop: 'goodsType',
                        // },
                        // {
                        //     label: '所属方案ID',
                        //     prop: 'schemeInfoId',
                        // },
                        // {
                        //     label: '所属方案',
                        //     prop: 'schemeInfoName',
                        // },
                        {
                            label: '医生姓名',
                            prop: 'doctorName',
                        },
                    ]
                }
            };
        },
        watch: {
          visible(newVal, oldVal) {
            if (!newVal) {
              this.$refs.mainTable.setCurrent();
              this.searchOptions[1].options = [];
            };
          },
        },
        created() {
          console.log('this.selectMode===',this.selectMode);
        },
        mounted() { },
        methods: {

            open() {
                this.visible = true;
                this.checkIds = this.defaultCheckIds;
                console.log(' this.checkIds====', this.checkIds);
                this.checkLength = this.defaultCheckIds.length;
                if(this.$refs.mainSearch) {
                    this.$refs.mainSearch.resetForm();
                }else{
                    this.getList();
                }
            },
            submit() {
                const rows = this.checkRow;
                if(!rows){
                    this.$message.warning('请选择设备');
                    return
                }
                console.log('submit -----------> ', rows);
                this.$emit("submitDevice", {rows: rows, doctorId: this.bindDoctorId});
                this.checkRow = null;
                this.visible = false;
            },
            onClickRow(item) {
                // 单选模式时，设置选中ID
                if (this.selectMode == 'single') {
                  this.checkIds = [item.id];
                  this.checkRow = item;
                  this.$refs.selectDoctorRef.open()
                }
            },
            search(data) {
                Object.assign(this.paramObj, data);
                this.$refs.mainTable.handleCurrentChange(1);
            },
            updateSelectedIds(checkIds){
                this.checkIds = checkIds;
                console.log('this.checkIds', this.checkIds)
            },
            /**
             * @description:
             * @param {*}
             * @return {*}
             */
            getList() {
                this.$nextTick(() => {
                    const param = this.getParam();
                    request({
                        url: 'admin/kfSchemeInfo/queryGoodsList',
                        method: 'GET',
                        params: {
                            ...this.paramObj,
                            ...this.page,
                            auditStatus: 3,
                            goodsType: 5,
                            isOnSale: true,
                        },
                        showLoading: true
                    }).then(({ res, timeStamp }) => {
                        this.tableData = res.records;
                        this.total = parseInt(res.total)
                    });
                });
            },
            getParam() {
                const paramObj = this.$refs.mainSearch.packageData();
                const page = this.$refs.mainTable.getPage();
                const param = Object.assign({}, this.paramObj, page, paramObj);
                this.paramObj = param;
                return param;
            },
            getRemoteHospital(query) {
                // 如果用户输入内容了，就发请求拿数据，远程搜索模糊查询
                if (query !== "") {
                    this.loading = true; // 开始拿数据喽
                    let allHospitalList = [];
                    getAllHospitalList({name: query}).then(({ res }) => {
                    allHospitalList = res.records.map((item) => {
                            return {
                                value: item.id,
                                label: item.name,
                            };
                        });
                        this.loading = false // 拿到数据喽
                        this.searchOptions[1].options = allHospitalList;
                    });
                } else {
                    this.searchOptions[1].options = [];
                }
            },
            selectDoctor(doctorId) {
                this.bindDoctorId = doctorId
                this.submit()
            }

        },
    };
</script>
<style scoped lang='scss'>
</style>
