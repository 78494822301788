<!--
 * @Author: magy
 * @Date: 2022-03-22 15:28:04
 * @LastEditTime: 2022-04-20 09:08:53
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \recovery_home_management\src\views\basicSetting\officesManagement.vue
-->

<!--
* @Description: 科室管理页面
* @Reference:
-->

<template>
  <el-container>
    <el-header height="auto">
      <searchForm ref="mainSearch" :searchoptions="searchOptions" @search="search" :backStatus="backStatus" />
    </el-header>
    <el-main class="basic-main">
      <generalTable ref="mainTable" :table-data="tableData" :config="tableConfig" :total="total" @updatePage="getList" @objectSpanMethod="objectSpanMethod" @onClickRow="onClickRow">
        
      </generalTable>
    </el-main>
  </el-container>
</template>
<script>
import { getList} from '@/api/areaManagement'
import searchForm from '@/components/SearchForm'
import generalTable from '@/components/Table'

export default {
    name: 'Test',
    components: {
        searchForm,
        generalTable,
    },
    data() {
        return {
            tableData: [
            ],
            searchOptions: [
                {
                    type: 'input',
                    placeholder: '请输入...',
                    key: 'name',
                    label: '城市名称',
                    defaultVal: ''
                },
                // {
                //     type: "select",
                //     placeholder: "请选择",
                //     key: "grade",
                //     filterable: true,
                //     multiple: false,
                //     label: "城市等级",
                //     options: [
                //         {
                //             value: "PRPI_0201",
                //             label: "一级城市",
                //         },
                //         {
                //             value: "PRPI_0202",
                //             label: "二级城市",
                //         },
                //         {
                //             value: "PRPI_0203",
                //             label: "三级城市",
                //         },
                //         {
                //             value: "PRPI_0204",
                //             label: "四级城市",
                //         },
                //         {
                //             value: "PRPI_0205",
                //             label: "五级城市",
                //         }
                //     ],
                //     defaultVal: [],
                // },
            ],
            tableConfig: {
                id: 'test',
                align: 'center', // 不必填 默认为center
                selection: 'single', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: true, // 不必填 分页 默认为false
                column: [
                    {
                    label: '城市编号', // 必填
                    prop: 'code' // 必填
                },
                {
                    label: '城市名称', // 必填
                    prop: 'name' // 必填
                },
                {
                        label: "城市等级", // 必填
                        prop: "grade", // 必填
                        formatter: (row, column) => {
                            const stampContainer = {
                                PRPI_0201: "一级城市",
                                PRPI_0202: "二级城市",
                                PRPI_0203: "三级城市",
                                PRPI_0204: "四级城市",
                                PRPI_0205: "五级城市",
                            };
                            return stampContainer[row.grade];
                        },
                        width: "200",
                    },
                // {
                //     label: '城市等级编号', // 必填
                //     prop: 'grade' // 必填
                // },
                {
                    label: '创建时间', // 必填
                    prop: 'createDate', // 必填
                    formatter: (row, column) => {
                            return this.moment(row.createDate).format(
                                'YYYY-MM-DD'
                            )
                        }
                },
                {
                    label: '修改时间', // 必填
                    prop: 'updateDate', // 必填
                    formatter: (row, column) => {
                            return this.moment(row.updateDate).format(
                                'YYYY-MM-DD'
                            )
                        }
                },
                
                ]
            },
            paramObj: null, // 筛选项
            total: 0, // 总条数
            checkId: '', // 单条数据id 用于详情或编辑请求数据用
            backStatus: true, //返回按钮的隐藏显示
            timeStamp: '',
        }
    },
    created() {
        this.getList();
        // this.getOffice();
    },
    mounted: function() {},
    methods: {
        search(data) {
            this.paramObj = data
            this.$refs.mainTable.handleCurrentChange(1)
        },
        getList() {
            // 解决未挂载完组件时调用子组件方法报错问题
            this.$nextTick(() => {
                const param = this.getParam()
                getList(param).then((res, timeStamp) => {
                    this.tableData = res.res.records;
                    this.timeStamp = timeStamp;
                    this.total = res.res.total;
                })
            })
        },
        
        objectSpanMethod(param, cb) {
            cb([])
        },
        
        onClickRow(checkArray) {
            console.log('testststst')
            console.log('checkArray', checkArray)
        },
        getParam() {
            // 获取参数
            const paramObj = this.$refs.mainSearch.packageData()
            this.paramObj = paramObj
            const page = this.$refs.mainTable.getPage()
            const param = Object.assign(page, paramObj)
            return param
        },
        
    }
}
</script>

<style lang='scss'>
@import "~@/styles/variables.scss";

</style>
