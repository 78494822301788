<template>
  <div>
    <el-card>
      <el-button type="primary" @click="addTask()">添加康复任务</el-button>
      <el-button type="primary" @click="$refs.previewTaskDialog.open(form)">预览</el-button>
      <br><br>
      <el-timeline>
        <el-timeline-item
          :timestamp="task.sourceSubKey == 'online_consultation' || task.sourceSubKey == 'select_goods' ? task.sourceSubType : `服务开启后第 ${task.delayNum} 天  发送`"
          placement="top" v-for="(task,index) in form.kfTaskTemplateList" :key="index">
          <el-card>
            <!-- <h4>更新 Github 模板</h4> -->
            <p>任务名称：{{task.taskName}}
              <i class="el-icon-edit-outline" @click="addTask(task)"></i>
              <i class="el-icon-delete delBtn" @click="handleDelete(task)"></i>
            </p>
            <p>任务类型：{{task.sourceSubType}}</p>
            <p v-if="!(task.sourceSubKey == 'online_consultation' || task.sourceSubKey == 'select_goods')">执行时间：
              <template v-if="task.exeFrequency == '1' || task.exeFrequency == '2'">
                <el-tag>{{moment(parseInt(task.exeTime)).format('HH:mm')}}</el-tag> &nbsp;
              </template>
              <template v-else-if="task.exeFrequency == '3'">
                <template v-for="w in (task.exeWeek || '').split(',')">
                  <el-tag>星期{{w}} {{moment(parseInt(task.exeTime)).format('HH:mm')}}</el-tag> &nbsp;
                </template>
              </template>
              <template v-else-if="task.exeFrequency == '4'">
                <template v-for="t in task.exeTime.split(',')">
                  <el-tag>{{moment(parseInt(t)).format('HH:mm')}}</el-tag> &nbsp;
                </template>
              </template>
            </p>
            <!-- <div class="timeline-btnWrap">
              <el-button type="primary" @click="addTask(task)">修改</el-button>
              <el-button type="danger" @click="handleDelete(task)">删除</el-button>
            </div> -->
          </el-card>
        </el-timeline-item>
      </el-timeline>
    </el-card>
    <addTaskDialog ref="addTaskDialog" :servicePeriod.sync="servicePeriod" @submit="setTaskList" />
    <previewTaskDialog ref="previewTaskDialog" />

  </div>
</template>

<script>
  import request from '@/utils/request'
  import addTaskDialog from './addTaskDialog'
  import previewTaskDialog from './previewTaskDialog'


  export default {
    name: 'project-addTask',
    components: { addTaskDialog, previewTaskDialog },
    props: {
      form: {
        type: Object,
        default: () => { return { kfTaskTemplateList: [] } }
      },
      servicePeriod: {
        type: Number,
        default: null
      }
    },
    data() {
      return {
        rules: {
          name: [
            {
              required: true,
              message: "请输入方案名称",
              trigger: "blur",
            },
            {
              min: 1,
              max: 10,
              message: "长度在 1 到 10 个字符",
              trigger: "blur",
            },
          ],
        },
      }
    },
    computed: {
    },
    created() {
    },
    methods: {
      // 打开 编辑弹窗
      addTask(row) {
        this.$refs.addTaskDialog.open(row);
      },
      // 删除任务
      handleDelete(row) {
        this.$confirm("确定删除？", "删除确认").then(() => {
          request({
            url: 'admin/kfTaskTemplate/delete',
            method: 'POST',
            data: { id: row.id },
            showLoading: true
          }).then(({ res, timeStamp }) => {
            this.getList();
          });
        });
      },
      // 任务新增 和 更新
      setTaskList(task) {
        const { planTemplateId } = this.form;
        // console.log('setTaskList -----------> ', task);
        request({
          url: 'admin/kfTaskTemplate/' + (task.id ? 'update' : 'add'),
          method: 'POST',
          data: { ...task, planTemplateId },
          showLoading: true
        }).then(({ res, timeStamp }) => {
          console.log('setTaskList ------------> ', res);
          this.$refs.addTaskDialog.visible = false;
          this.getList();
        });
      },
      getList() {
        // console.log('getList kfTaskTemplateList ------------> ', this.form.kfTaskTemplateList);
        const { planTemplateId } = this.form;
        request({
          url: 'admin/kfTaskTemplate/list',
          method: 'GET',
          params: { planTemplateId },
          showLoading: true
        }).then(({ res, timeStamp }) => {
          // console.log('getList ------------> ', res);
          // this.$nextTick(() => {
          this.form.kfTaskTemplateList = res.records;
          this.$forceUpdate(); // 不强刷不更新... 不知道为啥
          // })
        });

      }
    }
  }
</script>

<style lang='scss' scoped>
  /* .el-timeline li:hover .timeline-btnWrap {
    display: flex;
  }

  .timeline-btnWrap {
    display: none;
    justify-content: space-around;
    width: 150px;
  } */
  .el-icon-edit-outline, .el-icon-delete{
    margin-left: 1em;
    font-size: 16px;
    cursor: pointer;
  }
  .el-icon-edit-outline{
    color: #469cf8;
  }
</style>
