/*
 * @Author: magy
 * @Date: 2022-03-30 15:37:59
 * @LastEditTime: 2022-04-20 14:50:23
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \recovery_home_management\src\api\hospitalManagement,js
 */
/**
 * 页面: 医院管理
 * pageName: hospitalManagement
 **/
import request from '@/utils/request'

/**
 * @description: 医院管理列表
 */
export function getHospitalList(data) {
  return request({
    url: 'admin/kfHospital/list',
    method: 'GET',
    params: data,
    showLoading: true
  });
}

/**
 * @description: 修改医院收费状态
 */
 export function changeIsCharge(data,timeStamp) {
  return request({
    url: 'admin/kfHospital/changeIsCharge',
    method: 'POST',
    data,
    showLoading: true,
    timeStamp
  });
}

/**
 * @description: 修改医院收费状态
 */
 export function changeSignCharge(data,timeStamp) {
  return request({
    url: 'admin/kfHospital/changeSignCharge',
    method: 'POST',
    data,
    showLoading: true,
    timeStamp
  });
}

/**
 * @description: 删除医生
 */
 export function deleteData(data, timeStamp) {
    return request({
        url: 'admin/kfHospital/delete',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}


/**
 * @description: 获取用户基本信息
 * @param {Object} data
 */
 export function getHospitalInfo(data) {
  return request({
    url: 'admin/kfHospital/info',
    method: 'GET',
    params: data,
    showLoading: true
  })
}

/**
 * @description: 创建用户
 * @param {Object} data
 */
 export function createHospital(data,timeStamp) {
  return request({
    url: 'admin/kfHospital/add',
    method: 'POST',
    data,
    showLoading: true,
    timeStamp
  })
}

/**
 * @description: 更新用户信息
 * @param {Object} data
 */
export function updateHospitalInfo(data, timeStamp) {
  return request({
    url: 'admin/kfHospital/update',
    method: 'POST',
    data,
    showLoading: true,
    timeStamp
  })
}