/*
 * @Author: magy
 * @Date: 2022-03-18 10:58:11
 * @LastEditTime: 2023-10-08 15:35:38
 * @LastEditors: Please set LastEditors
 * @Description: ��koroFileHeader�鿴���� ��������: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /recovery_home_management_dev/src/api/doctorManagement.js
 */
/**
 * ҳ��: ҽ������
 * pageName: doctorTreatment
 **/
import request from '@/utils/request'

/**
 * @description: ҽ�������б�
 */
export function getList(data) {
  return request({
    url: 'admin/userDoctor/list',
    method: 'GET',
    params: data,
    showLoading: true
  });
}

/**
 * @description: ɾ��ҽ��
 */
 export function deleteData(data, timeStamp) {
    return request({
        url: 'admin/userDoctor/deleteDoctor',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}

/**
 * @description: ����/����
 */
 export function doctorStatus(data, timeStamp) {
    return request({
        url: 'admin/userDoctor/updateStatus',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}

/**
 * @description: ��ȡ�û�������Ϣ
 * @param {Object} data
 */
 export function getUserInfo(data) {
  return request({
    url: 'admin/userDoctor/info',
    method: 'GET',
    params: data,
    showLoading: true
  })
}

/**
 * @description: �����û�
 * @param {Object} data
 */
 export function createUser(data,timeStamp) {
  return request({
    url: 'admin/userDoctor/saveDoctor',
    method: 'POST',
    data,
    showLoading: true,
    timeStamp
  })
}

/**
 * @description: �����û���Ϣ
 * @param {Object} data
 */
export function updateUserInfo(data, timeStamp) {
  return request({
    url: 'admin/userDoctor/update',
    method: 'POST',
    data,
    showLoading: true,
    timeStamp
  })
}

/**
 * @description: ��ȡ�û���ϸ��Ϣ
 * @param {Object} data
 */
 export function getDoctorInfo(data) {
  return request({
    url: 'admin/userDoctor/detailDialog',
    method: 'GET',
    params: data,
    showLoading: true
  })
}
/**
 * @description: ����ҽ����ϸ��Ϣ
 * @param {Object} data
 */
 export function createDoctorInfo(data,timeStamp) {
  return request({
    url: 'admin/kfDoctorAudit/add',
    method: 'POST',
    data,
    showLoading: true,
    timeStamp
  })
}

/**
 * @description: ����ҽ����ϸ��Ϣ
 * @param {Object} data
 */
export function updateDoctorInfo(data, timeStamp) {
  return request({
    url: 'admin/kfDoctorAudit/update',
    method: 'POST',
    data,
    showLoading: true,
    timeStamp
  })
}

/**
 * @description: ��ȡ��������
 */
 export function getServiceConfig(data) {
  return request({
    url: 'admin/kfDoctorProduct/serviceConfig',
    method: 'GET',
    params: data,
    showLoading: true
  });
}

/**
 * @description: ��ȡ������������
 */
 export function getServiceConfigInfo(data) {
  return request({
    url: 'admin/kfDoctorProduct/info',
    method: 'GET',
    params: data,
    showLoading: true
  });
}

/**
 * @description: ���·�������
 * @param {Object} data
 */
 export function updateServiceConfig(data, timeStamp) {
  return request({
    url: 'admin/kfDoctorProduct/update',
    method: 'POST',
    data,
    showLoading: true,
    timeStamp
  })
}

/**
 * @description: ���·�������
 * @param {Object} data
 */
 export function createServiceConfig(data, timeStamp) {
  return request({
    url: 'admin/kfDoctorProduct/add',
    method: 'POST',
    data,
    showLoading: true,
    timeStamp
  })
}

/**
 * @description: ɾ��ҽ��
 */
 export function deleteServiceConfigInfo(data, timeStamp) {
  return request({
      url: 'admin/kfDoctorProduct/delete',
      method: 'POST',
      data,
      showLoading: true,
      timeStamp
  })
}




/**
 * @description: 是否推荐
 */
 export function recommendedStatus(data, timeStamp) {
  return request({
      url: 'admin/kfDoctorProduct/recommendedStatus',
      method: 'POST',
      data,
      showLoading: true,
      timeStamp
  })
}


/**
 * @description: 是否推荐
 */
export function getDoctorNames(data) {
  return request({
      url: 'admin/userDoctor/doctorNames',
      method: 'POST',
      data,
      showLoading: true,
  })
}
