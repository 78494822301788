/*
 * @Author: your name
 * @Date: 2022-03-21 11:51:49
 * @LastEditTime: 2022-03-23 16:56:52
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /recovery_home_management/src/api/videoEquipment.js
 */

/**
 * 页面: 康复指导视频
 * pageName: videoEquipment
 **/
import request from '@/utils/request'

/**
 * @description: 获取康复指导视频列表数据
 */
export function getList(data) {
    return request({
        url: '/admin/kfGuidanceVideoInfo/list',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 新增康复指导视频数据
 */
export function insertData(data) {
    return request({
        url: '/admin/kfGuidanceVideoInfo/add',
        method: 'POST',
        data,
        showLoading: true
    })
}

/**
 * @description: 修改康复指导视频数据
 */
export function editData(data, timeStamp) {
    return request({
        url: '/admin/kfGuidanceVideoInfo/update',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}
            

/**
 * @description: 获取康复指导视频详情数据
 */
export function getDetail(data) {
    return request({
        url: '/admin/kfGuidanceVideoInfo/info',
        method: 'GET',
        params: data,
        showLoading: true
    })
}
            
/**
 * @description: 删除康复指导视频数据
 */
    export function deleteData(data, timeStamp) {
    return request({
        url: '/admin/kfGuidanceVideoInfo/delete',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}
        