/*
 * @Description:
 * @Author: sunn
 * @Date: 2023-07-03 14:24:59
 * @LastEditTime: 2021-10-13 08:59:52
 * @LastEditors: sunn
 * @Reference:
 */

/**
 * 页面: 课程分润模块
 **/
import request from "@/utils/request";

/**
 * @description: 获取小鹅通售卖商品列表
 */
export function getXETGoodsList(data) {
  return request({
    url: "xiaoePayment/goods/list",
    method: "POST",
    data,
    showLoading: true
  });
}

/**
 * @description: 保存分成比例
 */
export function saveRatio(data) {
  return request({
    url: "admin/goodsDoctorRelation/save",
    method: "POST",
    data,
    showLoading: true
  });
}

/**
 * @description: 删除分成比例
 */
export function deleteRatio(data) {
  return request({
    url: "/admin/goodsDoctorRelation/delete",
    method: "POST",
    data,
    showLoading: true
  });
}
