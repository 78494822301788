<!--
 * @Author: sixteen
 * @Date: 2022-04-02 15:03:56
 * @LastEditTime: 2023-11-10 11:56:51
 * @LastEditors: Please set LastEditors
 * @Description: 患者档案弹窗
 * @FilePath: /miniprogram_patient_dev/Users/shangxue/newgit_workspace/recovery_home_management_dev/src/views/patientManagement/patientRecordDialog.vue
-->

<template>
    <el-dialog
        width="60%"
        title="患者档案"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        custom-class="basicDialog"
        append-to-body
    >
        <el-tabs v-if="tabData.length > 0" type="border-card">
            <el-tab-pane
                v-for="item in tabData"
                :key="item.id"
                :label="item.relationshipLabel"
            >
                <el-descriptions
                    class="margin-top"
                    title="档案信息"
                    :column="4"
                >
                    <el-descriptions-item label="档案名称">{{
                        item.name
                    }}</el-descriptions-item>
                    <el-descriptions-item label="性别">
                        <el-tag size="small">{{ item.genderLabel }}</el-tag>
                    </el-descriptions-item>
                    <el-descriptions-item label="生日">{{
                        moment(item.birthday).format("yyyy-MM-DD")
                    }}</el-descriptions-item>
                    <el-descriptions-item label="电话">{{
                        item.mobileNumber
                    }}</el-descriptions-item>
                    <el-descriptions-item label="身高">{{
                        item.height
                    }}</el-descriptions-item>
                    <el-descriptions-item label="体重">{{
                        item.weight
                    }}</el-descriptions-item>
                    <el-descriptions-item label="婚姻">{{
                        item.maritalLabel
                    }}</el-descriptions-item>
                    <el-descriptions-item label="是否吸烟">{{
                        item.smokingLabel
                    }}</el-descriptions-item>
                    <el-descriptions-item label="是否饮酒">{{
                        item.drinkLabel
                    }}</el-descriptions-item>
                    <el-descriptions-item label="疾病史">
                        <div v-if="item.diseasesHistoryName">
                            {{ item.diseasesHistoryName.map(name=>name.name).join()}}
                            </div>
                       
                       
                    </el-descriptions-item>
                    <el-descriptions-item label="备注">{{
                        item.remark
                    }}</el-descriptions-item>
                </el-descriptions>
                <el-descriptions
                    class="margin-top"
                    title="患病信息"
                    :column="4"
                >
                    <el-descriptions-item label="患病">
                    <div v-for="(v, index) in item.disease" :key="index">
                        {{ v }}
                    </div>
                    </el-descriptions-item>
                    <el-descriptions-item label="患病部位">
                    <div v-for="(v, index) in item.diseasedSite" :key="index">
                        {{ v }}
                    </div>
                    </el-descriptions-item>
                    <el-descriptions-item label="影响功能">
                        <div v-for="(v, index) in item.influenceFunction" :key="index">
                        {{ v }}
                        </div>
                    </el-descriptions-item>
                    <el-descriptions-item label="影响能力">
                        <div v-for="(v, index) in item.influenceCapacity" :key="index">
                        {{ v }}
                        </div>
                    </el-descriptions-item>
                    <el-descriptions-item label="其他患病">                  
                        <div v-for="(v, index) in item.obstacle" :key="index">
                        {{ v }}
                        </div>
                        <div v-for="(v, index) in item.otherDisease" style="white-space:initial;" :key="index">
                        {{ v }}
                        </div>
                    </el-descriptions-item>
                </el-descriptions>
                <el-button type="text" style="margin-right: 10px" @click="gotoIndex(item.id)">服务记录</el-button>
            </el-tab-pane>
        </el-tabs>
        <el-empty v-else description="暂无档案"></el-empty>

        <template slot="footer" class="dialog-footer">
            <el-button @click="updateVisible(false)">取 消</el-button>
        </template>
    </el-dialog>
</template>


<script>
import { getPatientRecordList } from "@/api/patientManagement";

export default {
    props: {
        id: {
            type: String,
        },
    },

    data() {
        return {
            visible: false,
            tabData: [],
        };
    },

    watch: {
        visible(newVal, oldVal) {
            if (newVal === true) {
                if (this.id) {
                    this.getPatientRecordList();
                }
                //...todo
            }
        },
    },

    methods: {
        getPatientRecordList() {
            const param = { userId: this.id }
            getPatientRecordList(param).then(({ res }) => {
                this.tabData = res;
            });
        },

        updateVisible(val) {
            this.visible = val;
            if (val === false) {
                // this.$refs.insertForm.resetFields();
            }
        },
            /**
        　* 服务记录
        　* @Description:
          * @Param  {*} scope
          * @Param 
          * @Return
        　* @Author wangz
        　* @Date 2022/3/21 13:56
        　*/
        gotoIndex (scope) {
            const query = Object.assign({},{
                consumerId: scope,
              
            })
            console.log("ssssssssssss",query)
            this.$router.push({ path: '/order/index', query: query})
            
        },
    },
};
</script>

<style lang='scss' scoped>
@import "~@/styles/variables.scss";

.margin-top {
    padding: 20px;
    background-color: white;
}
</style>
