/*
 * @Author: 358617935@qq.com 358617935@qq.com
 * @Date: 2023-05-30 08:59:11
 * @LastEditors: 358617935@qq.com 358617935@qq.com
 * @LastEditTime: 2023-06-02 11:41:08
 * @FilePath: /recovery_home_management/src/api/trainingAuthority.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

/**
 * 页面: 康复训练权限配置
 * pageName: trainingAuthority
 **/
import request from '@/utils/request'

/**
 * @description: 获取康复训练权限配置列表数据
 */
export function getList(data) {
    return request({
        url: '/trainingMenuUserRelation/list',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 获取游戏配置列表数据
 */
export function getMenuList(data) {
    return request({
        url: 'trainingMenuInfo/list',
        method: 'GET',
        params: data,
        showLoading: true
    })
}



/**
 * @description: 获取康复训练权限详情数据
 */
export function getDetail(data) {
    return request({
        url: '/trainingMenuUserRelation/info',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 新增康复训练权限配置数据
 */
export function insertData(data) {
    return request({
        url: '/trainingMenuUserRelation/add',
        method: 'POST',
        data,
        showLoading: true
    })
}

/**
 * @description: 修改康复训练权限配置数据
 */
export function editData(data, timeStamp) {
    return request({
        url: '/trainingMenuUserRelation/update',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}
            

/**
 * @description: 删除康复训练权限配置数据
 */
    export function deleteData(data, timeStamp) {
    return request({
        url: '/trainingMenuUserRelation/delete',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}
        