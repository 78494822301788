
<!--
* @Description: 医生管理页面
* @Reference:
-->

<template>
  <el-container>
    <el-header height="auto">
      <searchForm ref="mainSearch" :searchoptions="searchOptions" @search="search" />
    </el-header>
    <el-main class="basic-main">
      <el-row class="table-btn-group"><el-button type="primary" @click="toInsert()">新增</el-button><el-button type="primary" plain @click="toExport">导出</el-button></el-row>
      <generalTable ref="mainTable" :table-data="tableData" :selectable="selectable" :config="tableConfig" :total="total" @updatePage="getList" @objectSpanMethod="objectSpanMethod" @onClickRow="onClickRow">
         <template slot="status" slot-scope="scope">
                        <el-switch
                            v-model="scope.data.row.status"
                            :inactive-value="1"
                            :active-value="0"
                            @change="changeStatus(scope,$event)"
                        />
                    </template>
        <template slot="handle" slot-scope="scope" >
          <el-button type="text" @click.stop="toDetail(scope)">认证</el-button>
          <el-button type="text" style="margin-right: 10px" @click.stop="toEdit(scope)">编辑</el-button>
          <el-button type="text" style="margin-right: 10px" @click.stop="toServiceConfig(scope)">服务配置</el-button>
          <el-button type="text" class="delBtn" @click.stop="toDel(scope)">删除</el-button>
        </template>
      </generalTable>
      <addDialog :id="checkId" ref="insert" :submit="search" :time-stamp="timeStamp" @submit='getList()' />
      <serviceConfig :id="checkId" ref="conf" :submit="search" :time-stamp="timeStamp" />
    </el-main>
  </el-container>
</template>
<script>
import { getList, deleteData, doctorStatus, exportIdCardImage } from '@/api/doctorManagement'
import searchForm from '@/components/SearchForm'
import generalTable from '@/components/Table'
import addDialog from '@/views/doctorManagement/addDialog'
import serviceConfig from '@/views/doctorManagement/serviceConfig'
import { getHospitalList} from '@/api/hospitalManagement'
import { exportExcel } from "@/utils/common";
import { getAllHospitalList } from '@/api/embodyAndRefund';
export default {
    name: 'Test',
    components: {
        searchForm,
        generalTable,
        addDialog,
        serviceConfig
    },
    data() {
        return {
            tableData: [
            ],
            searchOptions: [
                {
                    type: 'input',
                    placeholder: '请输入...',
                    key: 'name',
                    label: '姓名',
                    defaultVal: ''
                },
                {
                    type: 'input',
                    placeholder: '请输入...',
                    key: 'phoneNumber',
                    label: '手机号',
                    defaultVal: ''
                },
                {
                    type: 'select',
                    placeholder: '请选择',
                    key: 'kfHospitalId',
                    filterable: true,
                    multiple: false,
                    label: '医院',
                    options: [],
                    defaultVal: [],
                    remoteMethod: (params) => this.getRemoteHospital(params),
                },
                {
                    type: 'select',
                    placeholder: '请选择',
                    key: 'auditStatus',
                    filterable: true,
                    multiple: false,
                    label: '认证审核状态',
                    options: [
                        {
                            value: '1',
                            label: '待审核'
                        },
                        {
                            value: '2',
                            label: '审核不通过'
                        },
                        {
                            value: '3',
                            label: '审核通过'
                        },
                        {
                            value: '4',
                            label: '未认证'
                        },
                    ],
                    defaultVal: []
                }
            ],
            
            tableConfig: {
                id: 'test',
                align: 'center', // 不必填 默认为center
                selection: 'multiple', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: true, // 不必填 分页 默认为false
                column: [
                {
                    label: '排序', // 必填
                    prop: 'sort' // 必填
                },
                {
                    label: '姓名', // 必填
                    prop: 'name' // 必填
                },
                {
                    label: '手机号', // 必填
                    prop: 'phoneNumber' // 必填
                },
                {
                    label: '注册时间', // 必填
                    prop: 'createDate', // 必填
                    formatter: (row, column) => {
                            return this.moment(row.createDate).format(
                                'YYYY-MM-DD'
                            )
                        }
                },
                {
                    label: '医院', // 必填
                    prop: 'hospitalName' // 必填
                },
                {
                    label: '认证审核状态', // 必填
                    prop: 'auditStatus', // 必填
                    formatter: (row, column) => {
                            const stampContainer = {
                                1: "待审核",
                                2: "审核不通过",
                                3: "审核通过",
                                4: "未认证",
                            };
                            return stampContainer[row.auditStatus];
                        },
                },
                {
                    label: '购买人次', // 必填
                    prop: 'buyerCount' // 必填
                },
                {
                    label: '服务人次', // 必填
                    prop: 'serviceCount' // 必填
                },
                {
                    label: '禁用/启用', // 必填
                    slot: 'status' // 必填
                },
                {
                    slot: 'handle', // 不必填 单列插槽
                    label: '操作', // 必填
                    fixed: 'right', // 不必填 固定列  left right
                    width: '220' // 不必填
                }
                ]
            },
            paramObj: null, // 筛选项
            total: 0, // 总条数
            checkId: '', // 单条数据id 用于详情或编辑请求数据用
            timeStamp: '',
            pages:'',//页数
            hospitalList:[],//医院列表
        }
    },
    created() {
        this.getList();
        this.getHospital();
    },
    mounted: function() {},
    methods: {
        search(data) {
            this.paramObj = data
            this.$refs.mainTable.handleCurrentChange(1)
        },
        getList() {
            // 解决未挂载完组件时调用子组件方法报错问题
            this.$nextTick(() => {
                const param = this.getParam()
                getList(param).then((res, timeStamp) => {
                    this.tableData = res.res.records;
                    this.timeStamp = timeStamp;
                    this.total = res.res.total;
                })
            })

        },
        toExport() {
          const param = this.getParam()
          let checkObj = this.$refs.mainTable.getChecked();
          let ids = [];
          checkObj.map((element) => {
            ids.push(element.id)
          });
          if (ids.length) {
            param.ids = ids;
          }
          console.log("param",param);
          let _this = this;
          let url = process.env.VUE_APP_BASE_API + "/admin/userDoctor/downloadDoctorIdCardPic";
          exportExcel(url,{},param,'医生身份证列表','zip').then(res=>{
          }).catch(function (error) {
             _this.$message({
                    message: error.message || '导出失败',
                    duration: 5000,
                    type: "warning",
              });
          });
        },
        selectable(row, index){
          if (row.idNo && row.idBackPic && row.idFrontPic) {
            return true;
          }
          return false;
        },
        objectSpanMethod(param, cb) {
            cb([])
        },
        toInsert() {
            this.checkId = ''
            this.$refs.insert.updateVisible(true)
        },
        toDetail(scope) {
            this.$router.push({
                path: '/doctorManagement/detailDialog',
                query:{
                    id:scope.data.row.id,
                }
            })
        },
        toEdit(scope) {
            this.checkId = scope.data.row.id
            this.$refs.insert.updateVisible(true)
        },
        toServiceConfig(scope){
            this.checkId = scope.data.row.id
            this.$refs.conf.updateVisible(true)
        },
        toDel(scope) {
            const { $delMsg, $delTitle, $messageBoxConfig, $handleSuccess } =
                this.commonConfig;
            this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
            const param = { id: scope.data.row.id };
            deleteData(param, this.timeStamp).then(({ res }) => {
                this.$message({ ...$handleSuccess });
                this.getList();
                });
            });
        },
        onClickRow(checkArray) {
            console.log('testststst')
            console.log('checkArray', checkArray)
        },
        getParam() {
            // 获取参数
            const paramObj = this.$refs.mainSearch.packageData()
            this.paramObj = paramObj
            const page = this.$refs.mainTable.getPage()
            const param = Object.assign(page, paramObj)
            return param
        },
        changeStatus(checkArray){
            const parm = {
                id:checkArray.data.row.id,
                status:checkArray.data.row.status
            }
            doctorStatus(parm,this.timeStamp).then(({res}) =>{
                this.getList()
            })
            // .catch((err) => {
            //         if (err) {
            //             this.tableData[scope.data.$index].status =
            //                 event === 0 ? '1' : '0'
            //         }
            //     })

        },
        // 获取医院
        getHospital() {
            const param = []
            getAllHospitalList(param).then(({ res }) => {
                this.hospitalList = res.records.map((item) => {
                    return {
                        value: item.id,
                        label: item.name,
                    };
                });
                this.searchOptions[2].options = this.hospitalList
            });
        },
        getRemoteHospital(query) {
            // 如果用户输入内容了，就发请求拿数据，远程搜索模糊查询
            if (query !== "") {
                this.loading = true; // 开始拿数据喽
                let allHospitalList = [];
                getAllHospitalList({name: query}).then(({ res }) => {
                allHospitalList = res.records.map((item) => {
                        return {
                            value: item.id,
                            label: item.name,
                        };
                    });
                    this.loading = false // 拿到数据喽
                    this.searchOptions[2].options = allHospitalList;
                });
            } else {
                this.searchOptions[2].options = [];
            }
        },
    }
}
</script>

<style lang='scss'>
@import "~@/styles/variables.scss";

</style>
