
<!--
* @Description: 开票信息查看页面
* @Reference:
-->

<template>
    <el-container>
        <el-header height="auto">
            <searchForm ref="mainSearch" :searchoptions="searchOptions" @search="search" :backStatus="backStatus" />
        </el-header>
        <el-main class="basic-main">
            <el-row class="table-btn-group">
                <el-button type="primary" plain @click="toExport">导出</el-button>
            </el-row>
            <generalTable ref="mainTable" :table-data="tableData" :config="tableConfig" :total="total" @updatePage="getList"
                @onClickRow="onClickRow">
            </generalTable>
            <detailDialog :id="checkId" ref="detail" />

        </el-main>
    </el-container>
</template>

<script>
import { getList, deleteData } from '@/api/invoice'
import searchForm from '@/components/SearchForm'
import generalTable from '@/components/Table'
import { exportExcel } from "@/utils/common";
import detailDialog from '@/views/financial/invoice/detailDialog'
import moment from 'moment'

export default {
    name: 'Invoice',
    components: {
        searchForm,
        generalTable,
        detailDialog,

    },
    data() {
        return {
            tableData: [],
            serviceTypeArr: ["", "康复服务", "出院指导", "团队服务"],
            searchOptions: [
                {
                    type: 'input',
                    placeholder: '请输入...',
                    key: 'consumerName',
                    label: '申请人',
                    defaultVal: ''
                },
                {
                    type: 'input',
                    placeholder: '请输入...',
                    key: 'mobileNumber',
                    label: '手机号',
                    defaultVal: ''
                },

            ],
            tableConfig: {
                id: 'invoice',
                align: 'center', // 不必填 默认为center
                selection: 'single', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: true, // 不必填 分页 默认为false
                column: [
                    {
                        label: '申请人', // 必填
                        prop: 'consumerName',// 必填
                        width: 120,
                        // formatter: (row, column) => {
                        //     return row.archivesInfoId.name
                        // }
                    },
                    {
                        label: '手机号', // 必填
                        prop: 'mobileNumber',// 必填
                        width: 120,
                    },
                    {
                        label: '申请时间', // 必填
                        prop: 'createDate', // 必填
                        width: 150,
                        formatter: (row, column) => {
                            return moment(row.createDate).format('YYYY-MM-DD HH:mm')
                        }
                    },
                    {
                        label: '订单类型', // 必填
                        prop: 'serviceType',// 必填
                        width: 120, //服务/团队（1：康复服务；2：出院指导；3：团队服务）
                        formatter: (row, column) => {
                            return ["", "康复服务", "出院指导", "团队服务", "线下服务"][row.serviceType]
                        }
                    },
                    {
                        label: '订单号', // 必填
                        prop: 'orderNo' // 必填
                    },
                    {
                        label: '开票金额(元)', // 必填
                        prop: 'invoicedAmount', // 必填
                        width: 120,
                    },
                    {
                        label: '发票类型', // 必填
                        prop: 'invoiceType', // 必填
                    },
                    {
                        label: '抬头类型', // 必填
                        prop: 'headingType', // 必填
                        width: 100,
                        //个人：1；企业：2；
                         formatter: (row, column) => {
                            return ["", "个人", "企业"][row.headingType]
                        }
                    },
                    {
                        label: '抬头名称', // 必填
                        prop: 'headingName' // 必填
                    },
                    {
                        label: '发票内容', // 必填
                        prop: 'invoiceContent' // 必填
                    },
                    {
                        label: '邮寄地址', // 必填
                        prop: 'consumerAddress' // 必填
                    },
                ]
            },
            paramObj: null, // 筛选项
            total: 0, // 总条数
            checkId: '', // 单条数据id 用于详情或编辑请求数据用
            backStatus: true, //返回按钮的隐藏显示
            timeStamp: '', //时间戳 用于修改数据或删除数据
        }
    },
    created() {
        this.getList()
    },
    mounted: function () { },
    methods: {
        search(data) {
            Object.assign(this.paramObj, data)
            this.$refs.mainTable.handleCurrentChange(1)
        },
        getList() {
            this.$nextTick(() => {
                const param = this.getParam()
                getList(param).then(({ res, timeStamp }) => {
                    this.tableData = res.records
                    this.timeStamp = timeStamp
                    this.total = res.total
                })
            })
        },

        toExport() {
            const param = this.getParam()

            let _this = this;
            let url = process.env.VUE_APP_BASE_API + "/admin/companyInvoice/export";
            exportExcel(url, {}, param, '开票信息列表').then(res => {
            }).catch(function (error) {
                _this.$message({
                    message: error.message || '导出失败',
                    duration: 5000,
                    type: "warning",
                });
            });
        },
        onClickRow(row) {
            console.log('点击行:', row)
        },
        getParam() {
            const paramObj = this.$refs.mainSearch.packageData()
            const page = this.$refs.mainTable.getPage()
            const param = Object.assign({}, this.paramObj, page, paramObj)
            this.paramObj = param
            return param
        }
    }
}
</script>

<style lang='scss' scoped>
@import "~@/styles/variables.scss";
</style>

        