<template>
  <el-dialog width="60%" title="查看商品" :visible.sync="visible" :close-on-click-modal="false"
    :close-on-press-escape="false" :show-close="false" custom-class="basicDialog" append-to-body>
    <el-descriptions class="margin-top" title="商品信息" :column="3">
      <el-descriptions-item label="商品ID">{{detail.goodsId||commonConfig.$nullData}}</el-descriptions-item>
      <el-descriptions-item label="商品名称">{{detail.name||commonConfig.$nullData}}</el-descriptions-item>
      <el-descriptions-item label="商品分类">{{detail.goodsTypeLabel||commonConfig.$nullData}}</el-descriptions-item>
      <el-descriptions-item label="商品状态">{{detail.goodsStateLabel||commonConfig.$nullData}}</el-descriptions-item>
      <el-descriptions-item label="商品图片">{{detail.percent||commonConfig.$nullData}}</el-descriptions-item>
      <el-descriptions-item label="商品活动价格"> {{detail.activityPrice||commonConfig.$nullData}}</el-descriptions-item>
      <el-descriptions-item label="商品实际价格"> {{detail.actualPrice||commonConfig.$nullData}}</el-descriptions-item>
      <el-descriptions-item label="商品库存"> {{detail.inventory||commonConfig.$nullData}}</el-descriptions-item>
      <el-descriptions-item label="备注"> {{detail.remark||commonConfig.$nullData}}</el-descriptions-item>

      <el-descriptions class="margin-top" title="文件附件" :column="3" size="mini">
        <el-descriptions-item v-for="(item) in detail.details" :key="item.id" :label="item.fileType">
          <span class="file-name">{{`${item.name}.${item.suffix}`}}</span>
          <el-button @click.stop="downLoad(item)" type="primary" plain circle icon="el-icon-download"></el-button>
        </el-descriptions-item>
      </el-descriptions>
      <el-divider />
    </el-descriptions>
    <el-divider />
    <el-form ref="insertForm" v-model="detail" label-position="right" label-width="120px" class="formBox"
      :rules="rules">
      <el-row :gutter="10" type="flex" justify="start">
        <el-col :sm="24" :md="24" :lg="24" :xl="24">
          <el-form-item label="商品详情" prop="details" class="content-wrapper">
            <tinymce :height="500" v-if="visible" disabled="true" name="details" class="details" v-model="detail.details" id="tinymce">
            </tinymce>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="24" :lg="24" :xl="24">
          <el-form-item label="商品图片" prop="photoList">
            <div class="imgList">
              <div v-for="(url, index) in detail.photoList" class="defalutImg " :key="url+index">
                <el-image style="width: 150px; height: 150px" :src="commonConfig.$filePublicUrl + url" fit="contain">
                </el-image>
              </div>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>


    <template slot="footer" class="dialog-footer">
      <el-button @click="updateVisible(false)">取 消</el-button>
    </template>
  </el-dialog>
</template>
<script>
  import { getDetail } from '@/api/products'
  import generalTable from '@/components/Table'
  import Tinymce from '@/components/Tinymce' // 富文本插件
  export default {
    props: {
      id: {
        type: String,
        required: true
      }
    },
    components: {
      generalTable,
      Tinymce
    },
    data() {
      return {
        rules: {},
        visible: false,
        detail: {
          photoList: [],
          photoUrl: ""
        }, // 详情数据
        tableData: [],
        tableConfig: {
          align: 'center', // 不必填 默认为center
          selection: 'normal', // 不必填 多选: multiple  单选: single  默认为 normal
          index: true, // 不必填 序号
          pagination: false, // 不必填 分页 默认为false
          column: [
            {
              label: '名称', // 名称
              prop: 'name' // 
            },
            {
              label: '所属治疗', // 所属治疗
              prop: 'operTypeLabel' // 
            }
          ]
        },
        stand: [],
        total: 0 // 总条数
      }
    },
    watch: {
      visible(newVal, oldVal) {
        newVal && this.id && this.getDetail()
      }
    },
    created() {
    },
    mounted() {
    },
    methods: {
      /**
       * 获取商品详情
       * @Description: 
        * @Param null
        * @Return null
       * @Author wangz
       * @Date 
       */
      getDetail() {
        const param = {}
        if (this.id) {
          param.id = this.id

        }

        getDetail(param).then(({ res }) => {
          this.detail = res;
          this.detail.photoList = this.detail.photoUrl.split(",")
        })
      },
      uploadPublicFile(file) {
        return new Promise((resolve) => {
          uploadPublicFile(file)
            .then((success) => {
              console.log("upload success------", success)
              this.photoList.push(success)
              resolve(success)
            })
            .catch((handleError) => {
              console.log("handleError====", handleError);
            });
        })
      },
      setLogoClick() {
        this.$refs.logoInput.click();
      },
      updateVisible(flag) {
        this.visible = flag
      }
    }
  }
</script>
<style scoped lang='scss'>
  @import "~@/styles/variables.scss";

  .imgList {
    display: flex;
    column-gap: 10px;
    flex-wrap: wrap;
    row-gap: 10px;
    margin: 10px 0;
  }

  .defalutImg {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 150px;
    border: 1px solid #333;
    border-radius: 4px;
    cursor: pointer;
    position: relative;
  }
</style>