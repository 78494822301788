
<template>
  <el-dialog
    width="60%"
    title="商品订单管理详情页"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    custom-class="basicDialog"
    append-to-body
  >
    <el-descriptions class="margin-top" title="基础信息" :column="4" >
      <el-descriptions-item label="订单编号">{{detail.orderNo||commonConfig.$nullData}}</el-descriptions-item>
      <el-descriptions-item label="物流单号">{{detail.deliveryNumber||commonConfig.$nullData}}</el-descriptions-item>
      <el-descriptions-item label="用户账号"> {{detail.mobileNumber||commonConfig.$nullData}}</el-descriptions-item>
      <el-descriptions-item label="用户昵称">{{detail.patName||commonConfig.$nullData}}</el-descriptions-item>
      <el-descriptions-item label="支付方式">{{detail.payWayLabel||commonConfig.$nullData}}</el-descriptions-item>
      <el-descriptions-item label="绑定医生"> {{detail.docName||commonConfig.$nullData}}</el-descriptions-item>
      <el-descriptions-item label="医生推荐码"> {{detail.recommendationCode||commonConfig.$nullData}}</el-descriptions-item>
      <el-descriptions-item label="商品合计">{{detail.presentTotal||commonConfig.$nullData}}</el-descriptions-item>
    </el-descriptions>
    <el-divider />
    <el-descriptions class="margin-top" title="收货信息" :column="4" >
      <el-descriptions-item label="收件人">{{detail.addName||commonConfig.$nullData}}</el-descriptions-item>
      <el-descriptions-item label="手机号">{{detail.addPhoneNumber||commonConfig.$nullData}}</el-descriptions-item>
      <el-descriptions-item label="收货地址"> {{detail.address||commonConfig.$nullData}}</el-descriptions-item>
    </el-descriptions>
    <el-divider />
    <el-descriptions class="margin-top" title="商品信息" :column="4" >
      <el-descriptions-item label="商品名称">{{detail.goodsName||commonConfig.$nullData}}</el-descriptions-item>
      <el-descriptions-item label="商品ID">{{detail.goodsId||commonConfig.$nullData}}</el-descriptions-item>
      <!-- <el-descriptions-item label="图片"> {{detail.photoUrl||commonConfig.$nullData}}</el-descriptions-item> -->
      <el-descriptions-item label="数量">{{detail.goodsQuantity||commonConfig.$nullData}}</el-descriptions-item>
      <el-descriptions-item label="价格">{{detail.prodrctTotal||commonConfig.$nullData}}</el-descriptions-item>
    </el-descriptions>
    <el-form ref="insertForm" v-model="detail" label-position="right" label-width="120px" class="formBox"
   >
    <el-row :gutter="10" type="flex" justify="start">
    
      <el-col :sm="24" :md="24" :lg="24" :xl="24">
        <el-form-item label="商品图片" prop="photoList">
          <div class="imgList">
            <div v-for="(url, index) in detail.photoList" class="defalutImg " :key="url+index">
              <el-image style="width: 150px; height: 150px" :src="commonConfig.$filePublicUrl + url" fit="contain">
              </el-image>
            </div>
          </div>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
    <el-divider />
    
    <template slot="footer" class="dialog-footer">
      <el-button  @click="updateVisible(false)">取 消</el-button>
    </template>
  </el-dialog>
</template>
<script>
import { getDetail } from '@/api/order'
import generalTable from '@/components/Table'
import { getDict } from '@/api/common'

export default {
    props: {
        id: {
            type: String,
            required: true
        }
    },
    components: {
      generalTable
    },
    data() {
        return {
            visible: false,
            detail: [], // 详情数据
            tableData: [],
            detail: {
              photoList: [],
              photoUrl: ""
            }, // 详情数据
            operTypes: [],
            total: 0 // 总条数
        }
    },
    watch: {
      visible(newVal, oldVal) {
        if (newVal && this.id) this.getDetail()
      }
    },
    created() {
      
    },
    mounted() {
    },
    methods: {
      getDetail() {
        const param = {}
        if (this.id) {
          param.id = this.id
        }
        getDetail(param).then(({res}) => {
          this.detail = res
          this.detail.photoList = this.detail.photoUrl.split(",")
          this.operTypes.forEach(element => {
            if(element.value == this.detail.operType){
              this.detail.operType = element.label;
            }
          });
        })
      },
     
      updateVisible(flag) {
        this.visible = flag
      },
      getDict(){
            getDict({ type: 'pay_way' }).then(({res}) => {
               // this.searchOptions[4].options = res.pay_way;
            });
         },
    
    }
}
</script>
<style scoped lang='scss'>
   @import "~@/styles/variables.scss";

.imgList {
  display: flex;
  column-gap: 10px;
  flex-wrap: wrap;
  row-gap: 10px;
  margin: 10px 0;
}

.defalutImg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  border: 1px solid #333;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
}
</style>
