<template>
  <el-dialog width="450px" title="选择绑定医生" :visible.sync="visible" :close-on-click-modal="false"
      :close-on-press-escape="false" append-to-body>
      <p style="margin-bottom: 12px;"><span class="">请为该商品选择推荐医生:</span><span style="color: #999999">（您选择的医生将与商品绑定推荐）</span></p> 
      <el-select v-model="selectDocotrId" filterable placeholder="请选择绑定医生">
        <el-option
          v-for="item in options"
          :key="item.id"
          :label="item.doctorName"
          :value="item.id">
        </el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="submit()">选 择</el-button>
          <el-button @click="visible = false">取 消</el-button>
      </span>
  </el-dialog>
</template>
<script>
  import request from '@/utils/request'
  import { getAllHospitalList } from '@/api/embodyAndRefund';

  export default {
      components: {
        
      },
      props: {
          goodId: {
              type: String,
              default: () => { return '' }
          },
      },
      data() {
          return {
              visible: false,
              selectDocotrId: '',
              options: [],
          };
      },
      watch: {
        visible(newVal, oldVal) {
          if (!newVal) {
            
          };
        },
      },
      created() {
        console.log('this.selectMode===',this.selectMode);
      },
      mounted() { },
      methods: {

          open() {
              this.visible = true;
              this.getList();
          },
          submit() {
              const selectDocotrId = this.selectDocotrId;
              if(selectDocotrId == ''){
                  this.$message.warning('请选择绑定医生');
                  return
              }
              console.log('selectDoctor selectDocotrId -----------> ', selectDocotrId);
              this.$emit("selectDoctor", selectDocotrId);
              this.selectDocotrId = [];
              this.visible = false;
          },
          /**
           * @description:
           * @param {*}
           * @return {*}
           */
          getList() {
            this.$nextTick(() => {
            // 获取该商品可绑定医生列表
            request({
                url: 'admin/kfSchemeInfo/queryGoodsDoctorList',
                method: 'GET',
                params: {'goodsId':this.goodId},
                showLoading: true
            }).then(res => {
              this.options = res.res;
              this.options.unshift({id: 'platform', doctorName: '无'})
            });
        });
      },
    },
  };
</script>
<style scoped lang='scss'>
</style>
