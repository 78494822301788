<!--
 * @Author: 马广源
 * @Date: 2022-05-12 14:56:55
 * @LastEditTime: 2022-08-04 10:31:08
 * @LastEditors: magy “……”
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \recovery_home_management\src\views\patientManagement\index.vue
-->

<!--
* @Description: 退款列表
* @Reference:
-->

<template>
  <el-container>
    <el-header height="auto">
      <searchForm ref="mainSearch" :searchoptions="searchOptions" @search="search" :backStatus="backStatus"/>
    </el-header>
    <el-main class="basic-main">
      <generalTable ref="mainTable" :table-data="tableData" :config="tableConfig" :total="total" @updatePage="getList" @objectSpanMethod="objectSpanMethod" @onClickRow="onClickRow">
        <!-- <template slot="handle" slot-scope="scope">
          <el-button type="text" style="margin-right: 10px" @click="toEdit(scope)">查看</el-button>
          
        </template> -->
      </generalTable>
            <!-- <refundDialog
            :id="checkId"
            ref="refund"
        /> -->
    </el-main>
  </el-container>
</template>
<script>
import { getRefundList, patientStatus } from '@/api/embodyAndRefund'
import searchForm from '@/components/SearchForm'
import generalTable from '@/components/Table'
// import refundDialog from "@/views/financial/refund/refundDialog";

export default {
    name: 'Test',
    components: {
        searchForm,
        generalTable,
        // refundDialog,
    },
    data() {
        return {
            tableData: [
            ],
            searchOptions: [
                {
                    type: 'input',
                    placeholder: '请输入...',
                    key: 'orderNo',
                    label: '订单号',
                    defaultVal: ''
                },
                {
                    type: 'input',
                    placeholder: '请输入...',
                    key: 'patientName',
                    label: '患者姓名',
                    defaultVal: ''
                },
                {
                    type: 'input',
                    placeholder: '请输入...',
                    key: 'patientPhone',
                    label: '患者手机',
                    defaultVal: ''
                },
                {
                    type: 'input',
                    placeholder: '请输入...',
                    key: 'doctorName',
                    label: '医生姓名',
                    defaultVal: ''
                },
                {
                    type: 'input',
                    placeholder: '请输入...',
                    key: 'doctorPhone',
                    label: '医生手机',
                    defaultVal: ''
                },
                {
                    type: 'daterange',
                    startPlaceholder: '开始日期',
                    endPlaceholder: '结束日期',
                    key: 'refundTime',
                    label: '申请时间',
                    defaultVal: []
                },
                {
                    type: 'select',
                    key: 'orderType',
                    filterable: true,
                    label: '订单类型',
                    options: [
                        {
                            label: '设备购买',
                            value: '1'
                        },
                        {
                            label: '康复服务',
                            value: '2'
                        },
                        {
                            label: '租赁',
                            value: '3'
                        }
                    ],
                    defaultVal: ''
                },
                {
                    type: 'select',
                    key: 'refundStatus',
                    filterable: true,
                    label: '退款状态',
                    options: [
                        {
                            label: '退款成功',
                            value: '0'
                        },
                        {
                            label: '处理中',
                            value: '1'
                        },
                        {
                            label: '其他',
                            value: '2'
                        },
                        {
                            label: '退款失败',
                            value: '9'
                        }
                    ],
                    defaultVal: ''
                },
            ],
            tableConfig: {
                id: 'test',
                align: 'center', // 不必填 默认为center
                selection: 'single', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: true, // 不必填 分页 默认为false
                column: [
                // {
                //     label: '排序', // 必填
                //     prop: 'sort' // 必填
                // },
                {
                    label: '订单号', // 必填
                    prop: 'orderNo' // 必填
                },
                {
                    label: '患者姓名', // 必填
                    prop: 'patientName', // 必填
                },
                {
                    label: '患者手机号', // 必填
                    prop: 'patientPhone' // 必填
                },
                {
                    label: '服务医生', // 必填
                    prop: 'doctorName' // 必填
                },
                {
                    label: '医生手机号', // 必填
                    prop: 'doctorPhone' // 必填
                },
                {
                    label: '退款时间', // 必填
                    prop: 'refundTime', // 必填
                    formatter: (row, column) => {
                            return this.moment(row.refundTime).format(
                                'YYYY-MM-DD'
                            )
                        }
                },
                {
                    label: '退款价格(元)', // 必填
                    prop: 'refundAmount', // 必填
                    formatter: (row, column) => {
                            const amount = row.refundAmount / 100
                            return amount
                        }
                },
                {
                    label: '订单类型', // 必填
                    prop: 'orderType', // 必填
                    formatter: (row, column) => {
                            const stampContainer = {
                                1: "设备购买",
                                2: "康复服务",
                                3: "租赁"
                            };
                            return stampContainer[row.orderType];
                        },
                },
                {
                    label: '退款原因', // 必填
                    prop: 'refundReason' // 必填
                },
                 {
                    label: '退款说明', // 必填
                    prop: 'refundDescription' // 必填
                },
                {
                    label: '退款状态', // 必填
                    prop: 'refundStatus', // 必填
                    formatter: (row, column) => {
                            const stampContainer = {
                                0: "退款成功",
                                1: "处理中",
                                2: "其他",
                                9: "退款失败"
                            };
                            return stampContainer[row.refundStatus];
                        },
                },
                // {
                //     slot: 'handle', // 不必填 单列插槽
                //     label: '操作', // 必填
                //     fixed: 'right', // 不必填 固定列  left right
                //     width: '220' // 不必填
                // }
                ]
            },
            paramObj: null, // 筛选项
            total: 0, // 总条数
            checkId: '', // 单条数据id 用于详情或编辑请求数据用
            timeStamp: '',
            backStatus: true, //返回按钮的隐藏显示
        }
    },
    created() {
        this.getList();
    },
    mounted: function() {},
    methods: {
        search(data) {
            this.paramObj = data
            this.$refs.mainTable.handleCurrentChange(1)
        },
        getList() {
            // 解决未挂载完组件时调用子组件方法报错问题
            this.$nextTick(() => {
                const param = this.getParam()
                getRefundList(param).then((res, timeStamp) => {
                    this.tableData = res.res.records;
                    this.timeStamp = timeStamp;
                    this.total = res.res.total;
                })
            })
            
        },
        objectSpanMethod(param, cb) {
            cb([])
        },
     
        // toEdit(scope) {
        //     this.checkId = scope.data.row.id
        //     this.$refs.refund.updateVisible(true)
        // },
        onClickRow(checkArray) {
            console.log('testststst')
            console.log('checkArray', checkArray)
        },
        getParam() {
            // 获取参数
            const paramObj = this.$refs.mainSearch.packageData()
            this.paramObj = paramObj
            const page = this.$refs.mainTable.getPage()
            const param = Object.assign(page, paramObj)
            return param
        },
       
    }
}
</script>

<style lang='scss'>
@import "~@/styles/variables.scss";

</style>
