/*
 * @Author: 358617935@qq.com 358617935@qq.com
 * @Date: 2023-05-25 09:49:55
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-06-06 09:00:49
 * @FilePath: /recovery_home_management_dev/src/api/withdraw.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

/**
 * 页面: 医生提现审核
 * pageName: withdraw
 **/
import request from '@/utils/request'

/**
 * @description: 获取医生提现审核列表数据 

 */
export function getList(data) {
    return request({
        url: '/admin/doctorWithdrawInfo/list',
        method: 'GET',
        params: data,
        showLoading: true
    })
}


/**
 * @description: 获取详情数据
 */
export function getDetail(data) {
    return request({
        url: '',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 操作 医生提现审核数据 操作类型 0-拒绝 1-审核
 */
export function auditData(data, timeStamp) {
    return request({
        url: '/admin/doctorWithdrawInfo/audit',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}

/**
 * @description: 删除医生提现审核数据
 */
    export function deleteData(data, timeStamp) {
    return request({
        url: '',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}

/**
 * @description: 获取医生财务明细 
 */
export function getSplitDetailList(data, timeStamp) {
    return request({
        url: '',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}

        