
<template>
    <el-dialog width="40%"  title="导出" :visible.sync="visible" :close-on-click-modal="false"  custom-class="basicDialog"
        :close-on-press-escape="false" :show-close="false" append-to-body>
        <el-form ref="exportForm" :model="exportForm" label-position="right" label-width="120px" class="formBox" :rules="rules" size="small">
            <el-row :gutter="10" type="flex" justify="start">
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="选择日期" prop="exportDate">
                        <el-date-picker
                            v-model="exportForm.exportDate"
                            type="daterange"
                            range-separator="至"
                            :picker-options="pickerOptions"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            value-format="yyyy-MM-dd"
                            >
                        </el-date-picker>
                    </el-form-item>
                </el-col>
                
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="toExport()">导出</el-button>
            <el-button @click="updateVisible(false)">取 消</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { insertData, editData, getDetail } from '@/api/channel';
import { exportExcel } from "@/utils/common";

export default {
    props: {
        id: {
            type: String
        },
        timeStamp: {
            type: String
        }
    },
    data() {
        return {
            pickerOptions: {
                disabledDate: time => {
                    return time.getTime() > Date.now();
                }
            },
            rules: {
                exportDate: [{
                    required: true,
                    message: "日期是必填项",
                    trigger: 'change',
                    
                }]
            },

            exportForm: {
                exportDate: null,
            },
            total: 0,
            visible: false,
            // timeStamp: '', //时间戳 用于修改数据或删除数据
        }
    },
    watch: {
        visible(newVal, oldVal) {
            if (newVal === true) {
                //...todo
            }
        }
    },
    created() { },
    mounted() { },
    methods: {
         updateVisible(val) {
            this.visible = val
            if (val === false) {
                this.$refs.exportForm.resetFields();
            }
        },
         toExport() {
            this.$refs.exportForm.validate((valid) => {
                if (valid) {
                    const param = {
                        startTime: this.exportForm.exportDate[0] + ' 00:00:00',
                        endTime: this.exportForm.exportDate[1] + ' 23:59:59',
                    }
                    const idParam = {distributorId: this.id};
                    if (this.id !== null ) {
                        Object.assign(param, idParam);
                    }
                    //if (this.id === '') {
                        let _this = this;
                        // let url = process.env.VUE_APP_BASE_API + "/admin/userDoctor/downloadDoctorIdCardPic";
                        let url = process.env.VUE_APP_BASE_API + "/distributorInfo/exportDistributor";
                        exportExcel(url, {}, param, '渠道商管理列表').then(res => {
                            _this.$message({
                                message: '导出成功',
                                duration: 2000,
                                type: "success",
                            });
                            _this.updateVisible(false);
                        }).catch(function (error) {
                            _this.$message({
                                message: error.message || '导出失败',
                                duration: 5000,
                                type: "warning",
                            });
                        });
                    //} 
                }
            })
            
        },
    }
}
</script>
<style scoped lang='scss'></style>
      
        