/*
 * @Author: your name
 * @Date: 2022-04-17 09:42:52
 * @LastEditTime: 2022-04-18 09:22:48
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \recovery_home_management\src\api\bannerManage.js
 */

/**
 * 页面: 分账系数管理页面
 * pageName: bannerManage
 **/
import request from '@/utils/request'

/**
 * @description: 获取分账系数列表数据
 */
export function getList(data) {
    return request({
        url: 'admin/financeRate/list',
        method: 'GET',
        params: data,
        showLoading: true
    })
}



/**
 * @description: 修改分账系数数据
 */
export function editData(data, timeStamp) {
    return request({
        url: 'admin/financeRate/update',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}
            
/**
 * @description: 分账系数数据详情
 */
 export function getDetail(data) {
    return request({
        url: 'admin/financeRate/info',
        method: 'GET',
        params: data,
        showLoading: true
    })
}
            

/**
 * @description: 修改分账系数数据
 */
 export function bannerStatus(data, timeStamp) {
    return request({
        url: 'admin/financeRate/changeStatus',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}
        