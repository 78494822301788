
<!--
* @Description: 服务单管理， 服务记录
* @Reference:
-->

<template>
  <el-container>
      <el-header height="auto">
          <searchForm ref="mainSearch" :searchoptions="searchOptions" @search="search" :backStatus="backStatus" />
      </el-header>
      <el-main class="basic-main">
          <generalTable ref="mainTable" :table-data="tableData" :config="tableConfig" :total="total" @updatePage="getList">
            
          </generalTable>
      </el-main>
  </el-container>
  </template>
  
  <script>
  
  import { getList } from '@/api/order'
  import searchForm from '@/components/SearchForm'
  import generalTable from '@/components/Table'
  import { getDict } from '@/api/common'
 
  
  export default {
      name: 'DoctorWorkload',
      components: {
          searchForm,
          generalTable,
      },
      data() {
          return {
              tableData: [],
              searchOptions: [
              {
                      type: 'input',
                      placeholder: '请输入...',
                      key: 'detailOrderNo',
                      label: '服务单号',
                      defaultVal: ''
                  },
                  {
                      type: 'input',
                      placeholder: '请输入...',
                      key: 'patientsName',
                      label: '患者姓名',
                      defaultVal: ''
                  },
                  {
                      type: 'input',
                      placeholder: '请输入...',
                      key: 'dictorName',
                      label: '服务医生',
                      defaultVal: ''
                  },
                 
                  {
                      type: 'daterange',
                      startPlaceholder: '开始日期',
                      endPlaceholder: '结束日期',
                      key: 'startTime',
                      label: '服务开始时间',
                      defaultVal: []
                  },
                  {
                      type: 'daterange',
                      startPlaceholder: '开始日期',
                      endPlaceholder: '结束日期',
                      key: 'endTime',
                      label: '服务结束时间',
                      defaultVal: []
                  },
                  {
                    type: "select",
                    placeholder: "请选择",
                    key: "status",
                    filterable: true,
                    label: "服务单状态",
                    options: [],
                    defaultVal: [],
                  },
              ],
              tableConfig: {
                  id: 'doctorWorkload',
                  align: 'center', // 不必填 默认为center
                  selection: 'single', // 不必填 多选: multiple  单选: single  默认为 normal
                  index: true, // 不必填 序号
                  pagination: true, // 不必填 分页 默认为false
                  column: [
                  {
                    label: '服务单号', // 必填
                    prop: 'detailOrderNo' // 必填
                  },
                  {
                    label: '患者姓名', // 必填
                    prop: 'patientsName' // 必填
                  },
                  {
                    label: '医生姓名', // 必填
                    prop: 'dictorName' // 必填
                  },
                  {
                    label: '所属订单号', // 必填
                    prop: 'orderNo' // 必填
                  },
                  {
                    label: '服务开始时间', // 必填
                    prop: 'startTime', // 必填
                  
                    formatter: (row, column) => {
                      if(row.startTime==null){
                      return "--"
                    }else{
                        return this.moment(row.startTime).format("YYYY-MM-DD HH:mm:ss")
                      }
                    }
                  },
                  {
                    label: '服务结束时间', // 必填
                    prop: 'endTime', // 必填
                    formatter: (row, column) => {
                      if(row.endTime==null){
                      return "--"
                    }else{
                        return this.moment(row.endTime).format("YYYY-MM-DD HH:mm:ss")
                      }
                    }
                  },
                  {
                    label: '服务状态', // 必填
                    prop: 'statusLabel' // 必填
                  },
             
                 
                  ]
              },
              paramObj: null, // 筛选项
              total: 0, // 总条数
              orderParam: [], //其他页面跳转传过来参数
              checkId: '', // 单条数据id 用于详情或编辑请求数据用
              backStatus: true, //返回按钮的隐藏显示
              timeStamp: '', //时间戳 用于修改数据或删除数据
             
  
          }
      },
      created() {
        this.orderParam = {
          orderId: this.$route.query.orderId, //其他页面传过来的订单主表id
          consumerId: this.$route.query.consumerId, //其他页面传过来的患者档案id
        }
        this.getList();
        this.getDict();  
      },
      mounted: function() {},
      methods: {
          search(data) {
              Object.assign(this.paramObj, data)
              this.$refs.mainTable.handleCurrentChange(1)
          },
          getList() {
              this.$nextTick(() => {
                 
                  let param = Object.assign({},this.getParam(), this.orderParam)
                  getList(param,this.orderParam).then(({ res, timeStamp }) => {
                      this.tableData = res.records
                      this.timeStamp = timeStamp
                      this.total = parseInt(res.total)
                  })
              })
          },
         
         
         
          getParam() {
              const paramObj = this.$refs.mainSearch.packageData()
            
              const page = this.$refs.mainTable.getPage()
              const param = Object.assign({}, this.paramObj, page, paramObj)
              this.paramObj = param
              console.log("param",param)
              return param
          },
          getDict(){
            getDict({ type: 'detail_status' }).then(({res}) => {
                this.searchOptions[5].options = res.detail_status;
            });
         },
          
      }
  }
  </script>
  
  <style lang='scss' scoped>
  @import "~@/styles/variables.scss";
  </style>
  
          