<template>
  <!--本地 icon 资源, uniapp 打包到 app 仅支持标签 image, 打包小程序和 H5 均可支持标签 img -->
  <div
    :class="['common-icon-container', !isPC && 'common-icon-container-mobile']"
    :style="{
      padding: iconHotAreaSize,
    }"
    @click="handleImgClick"
  >
    <image
      v-if="isApp"
      class="common-icon"
      :src="props.file"
      :style="{ width: iconWidth, height: iconHeight }"
    />
    <img
      v-else
      class="common-icon"
      :src="props.file"
      :style="{ width: iconWidth, height: iconHeight }"
    />
  </div>
</template>
<script lang="ts">
import { withDefaults, computed } from "../../adapter-vue";
import { isApp, isPC } from "../../utils/env";
interface IProps {
  file: string;
  size?: string;
  width?: string;
  height?: string;
  hotAreaSize?: number | string;
}
interface IEmits {
  (key: "onClick", e: Event): void;
}
const __sfc_main = {};
__sfc_main.props = {
  file: {
    key: "file",
    required: false,
    type: String,
    default: ""
  },
  size: {
    key: "size",
    required: false,
    type: String
  },
  width: {
    key: "width",
    required: false,
    type: String,
    default: "20px"
  },
  height: {
    key: "height",
    required: false,
    type: String,
    default: "20px"
  },
  hotAreaSize: {
    key: "hotAreaSize",
    required: false,
    type: [Number, String]
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const emits = __ctx.emit;
  const props = __props;
  const iconHotAreaSize = computed<undefined | string>(() => {
    if (!props.hotAreaSize) {
      return undefined;
    }
    if (isNaN(Number(props.hotAreaSize))) {
      return String(props.hotAreaSize);
    }
    return `${props.hotAreaSize}px`;
  });
  const iconWidth = computed(() => {
    return props.size ? props.size : props.width;
  });
  const iconHeight = computed(() => {
    return props.size ? props.size : props.height;
  });
  const handleImgClick = (e: Event) => {
    emits("onClick", e);
  };
  return {
    isApp,
    isPC,
    props,
    iconHotAreaSize,
    iconWidth,
    iconHeight,
    handleImgClick
  };
};
export default __sfc_main;
</script>

<style lang="scss" scoped>
.common-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}
.common-icon-container-mobile{
  cursor: none;
}
</style>
