<!--
 * @Author: SixTeen
-->
<template>
	<el-dialog width="70%" title="新增推荐医生" :visible.sync="visible" :close-on-click-modal="false"
		:close-on-press-escape="false" :show-close="false" append-to-body>
		<searchForm ref="mainSearch" :searchoptions="searchOptions" :back-status="false" @search="search"
			style="padding: 0px 10px" />
		<generalTable ref="mainTable" :table-data="tableData" :config="tableConfig" :total="total" @updatePage="getList"
			@onClickRow="clickRow" @onDoubleClickRow="onDoubleClickRow" :newHeight="'447px'">
			<template slot="handle" slot-scope="scope">
				<div v-if="scope.data.row.isExits">已添加</div>
				<el-button v-else type="text" @click="toAdd(scope)">添加</el-button>
			</template>
		</generalTable>

		<span slot="footer" class="dialog-footer">
			<!-- <el-button type="primary" @click="submit()">添 加</el-button> -->
			<el-button @click="updateVisible(false)">取 消</el-button>
		</span>
	</el-dialog>
</template>
<script>
import generalTable from "@/components/Table";
import searchForm from "@/components/SearchForm";
import { getChooseDoctorList, insertDoctorData } from '@/api/channel'
import { getHospitalList } from '@/api/hospitalManagement'

export default {
	components: {
		generalTable,
		searchForm,
	},
	props: {
		id: {
			type: String,
		},
		distributorId: {
			type: String,
		}
	},
	data() {
		return {
			searchOptions: [
				//全部患者搜索筛选
				{
					type: "input",
					placeholder: "请输入姓名",
					key: "doctorName",
					label: "姓名",
					defaultVal: "",
				},
				{
					type: "input",
					placeholder: "请输入",
					key: "doctorPhone",
					label: "手机号",
					defaultVal: "",
				},
				{
					type: "select",
					placeholder: "请选择医院",
					key: "hospitalId",
					filterable: true,
					label: "医院",
					options: [],
					defaultVal: [],
				},
			],
			tableConfig: {
				align: "center", // 不必填 默认为center
				selection: "single", // 不必填 多选: multiple  单选: single  默认为 normal
				index: true, // 不必填 序号
				pagination: true, // 不必填 分页 默认为false
				column: [
					{
						label: "医生姓名", // 必填
						prop: "doctorName", // 必填
					},
					{
						label: "手机号", // 必填
						prop: "doctorPhone", // 必填
					},
					{
						label: "医院", // 必填
						prop: "hospitalName", // 必填
					},
					{
						slot: "handle", // 不必填 单列插槽
						label: "操作", // 必填
						fixed: "right", // 不必填 固定列  left right
						width: "220", // 不必填
					},
				],
			},
			tableData: [],
			paramObj: null, // 筛选项
			total: 0, // 总条数
			checkId: '', // 单条数据id 用于详情或编辑请求数据用
			visible: false,
			checkInfo: {}, //选择患者数据
			// timeStamp: "" //时间戳 用于修改数据或删除数据
		};
	},
	watch: {
		visible(newVal, oldVal) {
			if (newVal === true) {
				this.getList();
			}
		},
	},
	created() {
		this.getHospital()
	},
	mounted() { },
	methods: {
		/**
		 * @description: 获取医院
		 * @param {*}
		 * @return {*}
		 */
		getHospital() {
			const param = []
			getHospitalList(param).then(({ res }) => {
				this.hospitalList = res.records.map((item) => {
					return {
						value: item.id,
						label: item.name,
					};
				});
				this.searchOptions[2].options = this.hospitalList
			});
		},

		getList() {
			this.$nextTick(() => {
				const param = this.getParam()
				const idParam = {distributorId: this.distributorId};
				if (this.distributorId !== null ) {
						Object.assign(param, idParam);
				}

				getChooseDoctorList(param).then(({ res, timeStamp }) => {
					this.tableData = res.records
					this.timeStamp = timeStamp
					this.total = res.total
				})
			})
		},

		/**
		 * @description: 点击我的患者行
		 * @param {*}
		 * @return {*}
		 */
		clickRow(scope) {
			// this.checkInfo = scope;
		},
		/**
		 * @description:双击我的患者行
		 * @param {*}
		 * @return {*}
		 */
		onDoubleClickRow(scope) {
			// this.checkInfo = scope;
			// if (scope.basicInfoId) {
			// 	this.updateVisible(false);
			// 	this.$emit("submit", scope);
			// } else {
			// 	this.$message("请选择患者");
			// }
		},
		/**
		 * @description:  模糊查询
		 * @param {*} data
		 * @return {*}
		 */
		search(data) {
			Object.assign(this.paramObj, data)
			this.$refs.mainTable.handleCurrentChange(1);
		},

		getParam() {
			const paramObj = this.$refs.mainSearch.packageData();
			const page = this.$refs.mainTable.getPage();
			const param = Object.assign({}, this.paramObj, page, paramObj);
			this.paramObj = param;
			return param;
		},


		/**
		 * @description: 添加 回传
		 * @param {*}
		 * @return {*}
		 */
		toAdd(scope) {
			const parm = {
				doctorId: scope.data.row.doctorId,
				distributorId: this.distributorId
			}
			insertDoctorData(parm).then(({ res }) => {
				this.$message({ ...this.commonConfig.$handleSuccess })
				this.updateVisible(false)
				this.$emit('submit')
			})
		},
		/**
		 * @description: 关闭
		 * @param {*} val
		 * @return {*}
		 */
		updateVisible(val) {
			this.visible = val;
		},
	},
};
</script>
<style scoped lang="scss"></style>
