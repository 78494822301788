
<!--
* @Description: 推广人管理
* @Reference:
-->

<template>
  <el-container>
      <el-header height="auto">
          <searchForm ref="mainSearch" :searchoptions="searchOptions" @search="search" :backStatus="backStatus" />
      </el-header>
      <el-main class="basic-main">
          <generalTable ref="mainTable" :table-data="tableData" :config="tableConfig" :total="total" @updatePage="getList">
            
          </generalTable>
      </el-main>
  </el-container>
  </template>
  
  <script>
  
  import { getList } from '@/api/promoter'
  import searchForm from '@/components/SearchForm'
  import generalTable from '@/components/Table'
  import { getDict } from '@/api/common'
 
  
  export default {
      name: 'DoctorWorkload',
      components: {
          searchForm,
          generalTable,
      },
      data() {
          return {
              tableData: [],
              searchOptions: [
             
                  {
                      type: 'input',
                      placeholder: '请输入...',
                      key: 'prName',
                      label: '推荐人登录名',
                      defaultVal: ''
                  },
                  
              ],
              tableConfig: {
                  id: 'doctorWorkload',
                  align: 'center', // 不必填 默认为center
                  selection: 'single', // 不必填 多选: multiple  单选: single  默认为 normal
                  index: true, // 不必填 序号
                  pagination: true, // 不必填 分页 默认为false
                  column: [
                  {
                    label: '推荐人登录名', // 必填
                    prop: 'prName' // 必填
                  },
                  {
                    label: '推荐人电话', // 必填
                    prop: 'prPhone' // 必填
                  },
                  {
                    label: '被推荐人登录名', // 必填
                    prop: 'peName' // 必填
                  },
                  {
                    label: '被推荐人电话', // 必填
                    prop: 'pePhone' // 必填
                  },
                  {
                    label: 'UUID', // 必填
                    prop: 'uuid' // 必填
                  },
                  {
                    label: '推广类型（医生还是患者）', // 必填
                    prop: 'userType' // 必填
                  },
                  {
                    label: '推广来源（具体功能）', // 必填
                    prop: 'shareSource' // 必填
                  },
                  {
                    label: '推荐时间', // 必填
                    prop: 'createDate', // 必填
                  
                    formatter: (row, column) => {
                      if(row.createDate==null){
                      return "--"
                    }else{
                        return this.moment(row.createDate).format("YYYY-MM-DD HH:mm:ss")
                      }
                    }
                  },
                 
             
                 
                  ]
              },
              paramObj: null, // 筛选项
              total: 0, // 总条数
            
              checkId: '', // 单条数据id 用于详情或编辑请求数据用
              backStatus: true, //返回按钮的隐藏显示
              timeStamp: '', //时间戳 用于修改数据或删除数据
             
  
          }
      },
      created() {
       
        this.getList();
       
      },
      mounted: function() {},
      methods: {
          search(data) {
              Object.assign(this.paramObj, data)
              this.$refs.mainTable.handleCurrentChange(1)
          },
          getList() {
              this.$nextTick(() => {
                 
                  let param = Object.assign({},this.getParam())
                  getList(param).then(({ res, timeStamp }) => {
                      this.tableData = res.records
                      this.timeStamp = timeStamp
                      this.total = parseInt(res.total)
                  })
              })
          },
         
         
         
          getParam() {
              const paramObj = this.$refs.mainSearch.packageData()
            
              const page = this.$refs.mainTable.getPage()
              const param = Object.assign({}, this.paramObj, page, paramObj)
              this.paramObj = param
              console.log("param",param)
              return param
          },
         
          
      }
  }
  </script>
  
  <style lang='scss' scoped>
  @import "~@/styles/variables.scss";
  </style>
  
          