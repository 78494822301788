/**
 * 页面: 推荐人
 * pageName: test
 **/
import request from '@/utils/request'

/**
 * @description: 推荐人列表
 */
export function getList(data) {
    return request({
        url: 'admin/kfAssessment/KfServiceSummary',
        method: 'GET',
        params: data,
        showLoading: true
    })
}
