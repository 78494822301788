
<template>
    <el-dialog width="70%" :title="id === '' ? '新增渠道商管理' : '修改渠道商管理'" :visible.sync="visible" :close-on-click-modal="false"
        :close-on-press-escape="false" :show-close="false" append-to-body>
        <el-form ref="insertForm" :model="form" label-position="right" label-width="120px" class="formBox" :rules="rules" size="small">
            <div class="mdrcc-info-title">
                基本信息
            </div>
            <el-row :gutter="10" type="flex" justify="start">
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="渠道商名称" prop="distributorName">
                        <el-input v-model="form.distributorName" placeholder="请输入..." clearable maxlength="30" />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="联系人" prop="contactName">
                        <el-input v-model="form.contactName" placeholder="请输入..." clearable maxlength="30" />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="手机号" prop="contactPhone">
                        <el-input v-model="form.contactPhone" placeholder="请输入..." clearable maxlength="11" />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="地址" prop="distributorAddress">
                        <el-input v-model="form.distributorAddress" placeholder="请输入..." clearable maxlength="50" />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="邮箱" prop="email">
                        <el-input v-model="form.email" placeholder="请输入..." clearable maxlength="50" />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="运营负责人" prop="operateManagement">
                        <el-input v-model="form.operateManagement" placeholder="请输入..." clearable maxlength="30" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-divider />

        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submit()">提 交</el-button>
            <el-button @click="updateVisible(false)">取 消</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { insertData, editData, getDetail } from '@/api/channel';
import { validatorPhone, validatorMail } from '@/utils/validate';

export default {
    props: {
        id: {
            type: String
        },
        timeStamp: {
            type: String
        }
    },
    data() {
        return {
            checkId: '',
            rules: {
                distributorName: [
                    { required: true, message: '请输入渠道商名称', trigger: 'blur' },
                    { min: 1, max: 30, message: '长度在 1 到 30 个字符', trigger: 'blur' }
                ],
                contactName: [
                    { required: true, message: '请输入联系人名称', trigger: 'blur' }
                ],
                contactPhone: [
                    { 
                        required: true, 
                        message: '请填写手机号', 
                        trigger: 'blur',
                        validator: validatorPhone,
                    }   
                ],
                operateManagement: [
                    { required: true, message: '请输入运营负责人名称', trigger: 'blur' }
                ],
                  email: [
                    { 
                        required: false, 
                        trigger: 'blur',
                        validator: validatorMail,
                    }   
                ],
            },

            form: {
                distributorName: "",
                distributorAddress: "",
                contactName: "",
                contactPhone: "",
                email: "",
                operateManagement: "",
                id: "",
            },
            total: 0,
            visible: false,
            // timeStamp: '', //时间戳 用于修改数据或删除数据
        }
    },
    watch: {
        visible(newVal, oldVal) {
            if (newVal === true) {
                if (this.id) {
                    this.getDetail()
                }
                //...todo
            }
        }
    },
    created() { },
    mounted() { },
    methods: {
        submit() {
            this.$refs.insertForm.validate((valid) => {
                if (valid) {
                    const parm = this.form
                    if (this.id === '') {
                        insertData(parm).then(({ res }) => {
                            this.submitSuccess(res)
                        })
                    } else {
                        editData(parm, this.timeStamp).then(({ res }) => {
                            this.submitSuccess(res)
                        })
                    }
                }
            })
        },
        submitSuccess(res) {
            this.$message({ ...this.commonConfig.$handleSuccess })
            this.updateVisible(false)
            this.$emit('submit')
        },
        getDetail() {
            getDetail({ id: this.id }).then(({ res, timeStamp }) => {
                this.form = res
                // this.timeStamp = timeStamp
            })
        },
   
        updateVisible(val) {
            this.visible = val
            if (val === false) {
                this.$refs.insertForm.resetFields()
                this.resetForm();
            }
        },
        resetForm(){
            for( let val in this.form){
                this.form[val] = '';
            }
        }
    }
}
</script>
<style scoped lang='scss'></style>
      
        