
<!--
* @Description: 康复方案订单管理
* @Reference:
-->

<template>
  <el-container>
      <el-header height="auto">
          <searchForm ref="mainSearch" :searchoptions="searchOptions" exportStatus @search="search" @reset="reset" :backStatus="backStatus"  @exportFun="toExport"/>
      </el-header>
      <el-main class="basic-main">
        <el-table ref="mainTable" border stripe row-contextmenu size="mini" header-row-class-name="table-header"  v-tableHeight="{ bottomOffset: 80 }"
        :data="tableData" style="width: 100%" @row-click="handleRowClick">
          <el-table-column type="expand" width="50" fixed="left">
            <template slot-scope="parentScope">
              <div class="table-expand">     
                <el-descriptions title="订单信息" border :column="3">
                  <el-descriptions-item label="收货人" :labelStyle="{
                  width: '100px'
                }" :contentStyle="{width: '100px'}"> {{ parentScope.row.consignee ? parentScope.row.consignee : '-'}}</el-descriptions-item>
                  <el-descriptions-item label="手机号" :labelStyle="{
                  width: '100px'
                }" :contentStyle="{width: '100px'}">{{ parentScope.row.mobile ? parentScope.row.mobile : '-'}}</el-descriptions-item>
                  <el-descriptions-item label="详细地址" :labelStyle="{
                  width: '100px'
                }" :contentStyle="{width: '300px'}">{{parentScope.row.province}}{{parentScope.row.city}}{{parentScope.row.district}}{{parentScope.row.address ? parentScope.row.address : '-'}}</el-descriptions-item>

                <el-descriptions-item label="备注" :labelStyle="{
                  width: '100px'
                }" :contentStyle="{width: '300px'}">{{parentScope.row.postscript ? parentScope.row.postscript : '-'}}</el-descriptions-item>
                  <el-descriptions-item label="上门信息" v-if="parentScope.row.visitAddress" >
                    {{parentScope.row.visitName ? parentScope.row.visitName : '-' }}
                  </el-descriptions-item>
                  <el-descriptions-item label="手机号" v-if="parentScope.row.visitAddress" >
                    {{parentScope.row.visitPhone ? parentScope.row.visitPhone : '-' }}
                  </el-descriptions-item>
                      <el-descriptions-item label="详细地址" v-if="parentScope.row.visitAddress" >{{parentScope.row.visitAddress ? parentScope.row.visitAddress : '-'}}</el-descriptions-item>
                  </el-descriptions>
                  <el-descriptions title="设备信息" :column="2" border style="margin-top: 10px;">
                      <el-descriptions-item label="设备名称"> {{ parentScope.row.sourceGoodsName ? parentScope.row.sourceGoodsName : '-'}}</el-descriptions-item>
                      <el-descriptions-item label="设备规格">{{parentScope.row.sourceGoodsNameValue ? parentScope.row.sourceGoodsNameValue : '-'}}</el-descriptions-item>
                  </el-descriptions>
              </div>
            </template>
          </el-table-column>

          <el-table-column v-for="(column, index) in tableConfig.column" :key="index" 
          :label="column.label" :prop="column.prop" 
          :align="column.align || 'center'" 
          :formatter="column.formatter" :width="column.width"    
          :fixed="column.fixed"
          show-overflow-tooltip >
        
          </el-table-column>
          <el-table-column label="操作" width="60" align="center" fixed="right">
            <template slot-scope="parentScope">
              <el-button
                type="text"
                @click="toAddRemark(parentScope.data.row)"
                >备注</el-button
              >
            </template>
          </el-table-column>
         
          <!-- <generalTable ref="mainTable" :table-data="tableData" :config="tableConfig" :total="total" @updatePage="schemeList">
            <template slot="handle" slot-scope="scope">
              <el-button
                type="text"
                @click="toAddRemark(scope.data.row)"
                >备注</el-button
              >
            </template>
          </generalTable> -->
        </el-table>
        <br>
        <el-pagination background layout="total, sizes, prev, pager, next" style="text-align: right;"
          :current-page.sync="page.current" :page-size.sync="page.size" :total="total"
          :page-sizes="[10, 20, 30, 40, 50, 100]" @current-change="schemeList()" @size-change="handlePageSizeChange">
        </el-pagination>
      </el-main>
      <schemeOrderRemark :id="itemID" ref="schemeOrderRemark" @remarkClick="remarkClick"></schemeOrderRemark>
  </el-container>
  </template>
  
  <script>
  
  import { schemeList } from '@/api/order'
  import schemeOrderRemark from "@/views/order/schemeOrderRemark/remark"
  import searchForm from '@/components/SearchForm'
  import generalTable from '@/components/Table'
  import {                                                                                                                                       } from '@/api/common'
  import { exportExcel } from "@/utils/common";
  import moment from "moment";
  export default {
      name: 'DoctorWorkload',
      components: {
          searchForm,
          generalTable,
          schemeOrderRemark
      },
      data() {
          return {
              page: { current: 1, size: 20, },
              paramObj: {},
              tableData: [],
              searchOptions: [
              {
                    type: 'input',
                    placeholder: '请输入...',
                    key: 'orderId',
                    label: '订单编号',
                    maxlength: '100',
                    defaultVal: ''
                },
                {
                    type: 'input',
                    placeholder: '请输入...',
                    key: 'patientName',
                    label: '患者姓名',
                    defaultVal: ''
                },
                {
                    type: 'input',
                    placeholder: '请输入...',
                    key: 'patientPhoneNumber',
                    label: '患者手机号',
                    defaultVal: ''
                },
                {
                    type: 'input',
                    placeholder: '请输入...',
                    key: 'doctorPhoneNumber',
                    label: '医生手机号',
                    defaultVal: ''
                },
                {
                    type: 'input',
                    placeholder: '请输入...',
                    key: 'goodsName',
                    label: '商品名称',
                    defaultVal: ''
                },
                {
                    type: 'input',
                    placeholder: '请输入...',
                    key: 'doctorName',
                    label: '服务医生',
                    defaultVal: ''
                },
                
                {
                    type: 'daterange',
                    startPlaceholder: '开始日期',
                    endPlaceholder: '结束日期',
                    key: 'addTime',
                    label: '下单时间',
                    defaultVal: []
                },
                {
                  type: "select",
                  placeholder: "请选择",
                  key: "orderOwner",
                  filterable: true,
                  label: "订单归属",
                  options: [
                    {
                      value : '1',
                      label: '平台'
                    },
                    {
                      value : '0',
                      label: '医生'
                    },
                  ],
                  defaultVal: [],
                },
                {
                  type: "select",
                  placeholder: "请选择",
                  key: "orderState",
                  filterable: true,
                  label: "订单状态",
                  options: [
                //     {
                //   value : '-1',
                //   label: '未知'
                // }
                {
                  value : '0',
                  label: '待付款'
                },
                {
                  value : '100',
                  label: '已失效',
                },
                //{
                //   value : '101',
                //   label: '订单已取消'
                // },{
                //   value : '102',
                //   label: '订单已删除'
                // },{
                //   value : '201',
                //   label: '订单已付款,等待发货'
                // },{
                //   value : '300',
                //   label: '订单已发货'
                // },{
                //   value : '301',
                //   label: '用户确认收货'
                // },{
                //   value : '400',
                //   label: '申请退款'
                // },{
                //   value : '401',
                //   label: '没有发货，退款　'
                // },{
                //   value : '402',
                //   label: '已收货，退款退货'
                // },
                {
                  value : '1',
                  label: '服务中'
                },
                {
                  value : '2',
                  label: '待结案'
                },
                {
                  value : '3',
                  label: '已结案'
                }, 
                {
                  value : '4',
                  label: '设备已付款'
                },
              ],
                  defaultVal: [],
                },

                {
                  type: "select",
                  placeholder: "请选择",
                  key: "hasSourceGoods",
                  filterable: true,
                  label: "设备套餐",
                  options: [
                    {
                      value : true,
                      label: '是'
                    },
                    {
                      value : false,
                      label:'否'
                    },
                  ],
                  defaultVal: [],
                },
                
              ],
              tableConfig: {
                  id: 'doctorWorkload',
                  align: 'center', // 不必填 默认为center
                  selection: 'single', // 不必填 多选: multiple  单选: single  默认为 normal
                  index: true, // 不必填 序号
                  pagination: true, // 不必填 分页 默认为false
                  column: [
                  {
                    label: '订单编号', // 必填
                    prop: 'orderId', // 必填
                    width: "160",
                    fixed: true,
                  },
                  {
                    label: '订单类型', // 必填
                    prop: 'orderType', // 必填
                    width: "140",
                  },
                  
                  {
                    label: '患者姓名', // 必填
                    prop: 'patientName', // 必填
                    fixed: true,
                  },
                  {
                    label: '患者手机号', // 必填
                    prop: 'patientPhoneNumber', // 必填
                    width: "180",
                  },
                  // {
                  //   label: '患者登录人姓名', // 必填
                  //   prop: 'loginName' // 必填
                  // },
                  {
                    label: '商品名称', // 必填
                    prop: 'goodsName', // 必填
                    width: "180",
                  },
                  {
                    label: '服务医生', // 必填
                    prop: 'doctorName' // 必填
                },
                {
                    label: '医生手机号', // 必填
                    prop: 'doctorPhoneNumber', // 必填
                    width: "180",
                },{
                    label: '所属医院', // 必填
                    prop: 'hospitalName' // 必填
                },          
                {
                    label: '医生推荐码', // 必填
                    prop: 'recommendationCode', // 必填
                    width: "140",
                },
                {
                    label: '订单归属', // 必填
                    prop: 'orderOwner', // 必填
                    width: "140",
                    formatter: (row, column) => {
                        return row.orderOwner == 1 ? '平台': '医生'
                    }
                },
                  {
                    label: '下单时间', // 必填
                    prop: 'addTime', // 必填
                    width: "140",
                    formatter: (row, column) => {
                        return this.moment(row.addTime).format("YYYY-MM-DD HH:mm:ss")
                    }
                  },
                  {
                    label: '订单支付时间', // 必填
                    prop: 'payTime', // 必填
                    width: "140",
                    formatter: (row, column) => {
                        
                    if(row.payTime==null){
                      return "--"
                    }else{
                        return this.moment(row.payTime).format("YYYY-MM-DD HH:mm:ss")
                      }
                    }
                  },
                 
                {
                    label: '订单状态', // 必填
                    prop: 'orderState', // 必填
                    width: "140",
                    formatter: (row) => {
                      let order = null

                      for (let i = 0; i < this.orderStateOptions.length; i++) {
                        if (this.orderStateOptions[i].value == row.orderState) { 
                          order = (row.goodsType == 5 && row.orderState == 3)? '设备已付款' : this.orderStateOptions[i].name 
                          break;
                        }
                      }
                      return order
                    },
                },
                {
                    label: '支付渠道', // 必填
                    prop: 'payWay', // 必填
                },
                
                {
                    label: '订单实付金额(元)', // 必填
                    prop: 'actualPrice', // 必填
                    formatter: (records) => {
                        return records.actualPrice
                    },
                    width: "105", // 不必填
                },
                {
                    label: '备注', // 必填
                    prop: 'remark', // 必填
                },
                // {
                //   slot: "handle",
                //   label: "操作",
                //   fixed: "right",
                //   width: "60"
                // },
                // {
                //     label: '订单总金额(元)', // 必填
                //     prop: 'presentTotal', // 必填
                //     formatter: (records) => {
                //         return records.presentTotal/100
                //     },
                //     width: "105", // 不必填
                // },
                // {
                //     label: '订单总次数', // 必填
                //     prop: 'totalNumber', // 必填
                //     width: "90", // 不必填 // 必填
                // },
                // {
                //     label: '已服务次数', // 必填
                //     prop: 'recoveryNum', // 必填
                //     width: "90", // 不必填 // 必填
                //   },
                //   {
                //     label: '剩余服务次数', // 必填
                //     prop: 'remainingNum', // 必填
                //     width: "100", // 不必填
                //   }, 
                //   {
                //   slot: "handle", // 不必填 单列插槽
                //   label: "操作", // 必填
                //   fixed: "right", // 不必填 固定列  left right
                //   width: "180", // 不必填
                // },
                 
                  ]
              },

              total: 0, // 总条数
              mobileNumber:'',
              checkId: '', // 单条数据id 用于详情或编辑请求数据用
              backStatus: true, //返回按钮的隐藏显示
              timeStamp: '', //时间戳 用于修改数据或删除数据
              orderStateOptions: [{
                  value : '-1',
                  name: '未知'
                },{
                  value : '0',
                  name: '待付款'
                },{
                  value : '100',
                  name: '已失效'
                },{
                  value : '101',
                  name: '订单已取消'
                },{
                  value : '102',
                  name: '订单已删除'
                },{
                  value : '201',
                  name: '订单已付款,等待发货'
                },{
                  value : '300',
                  name: '订单已发货'
                },{
                  value : '301',
                  name: '用户确认收货'
                },{
                  value : '400',
                  name: '申请退款'
                },{
                  value : '401',
                  name: '没有发货，退款　'
                },{
                  value : '402',
                  name: '已收货，退款退货'
                },
                {
                  value : '1',
                  name: '服务中'
                },
                {
                  value : '2',
                  name: '待结案'
                },
                {
                  value : '3',
                  name: '已结案'
                },
              ],
              itemID: ''
          }
      },
      created() {
          this.mobileNumber = this.$route.query.mobileNumber;
          console.log('mobileNumber===', this.mobileNumber);
          let name = this.$route.query.name
          console.log('name===',name);
          // 设置患者名为默认查询参数
          this.searchOptions[1].defaultVal = name;
          this.searchOptions[2].defaultVal = this.mobileNumber;
          this.schemeList();
          this.getDict();
          
      },
      mounted: function() {},
      methods: {
          search(data) {
              Object.assign(this.paramObj, data)
              this.page.current = 1;
              this.schemeList();
          },
          reset(){
            this.searchOptions[1].defaultVal = '';
            this.searchOptions[2].defaultVal = '';
            this.mobileNumber = '';
          },
          schemeList() {
              this.$nextTick(() => {
                  const param = this.getParam()
                  // 如果手机号清空，删除手机号传参
                  if (this.mobileNumber) {
                     param.phoneNum = this.mobileNumber;
                  } else {
                    delete param.phoneNum;
                  }
                  console.log("param",param);
                  schemeList(param).then(({ res, timeStamp }) => {
                      this.tableData = res.records
                      this.timeStamp = timeStamp
                      this.total = parseInt(res.total)
                  })
              })
          },      
          getParam() {
             // const paramObj = this.$refs.mainSearch.packageData()
            
              //const page = this.$refs.mainTable.getPage()
              const param = {
                ...this.paramObj,
                ...this.page,
              }
              // this.paramObj = param
              // console.log("param",param)
              return param
          },
          remarkClick() {
            this.schemeList()
          },
          handlePageSizeChange() {
            this.page.current = 1;
            this.schemeList()
          },    
          toAddRemark(val){
            this.itemID = val.id
            this.$refs.schemeOrderRemark.updateVisible(true)
          },  
          getDict(){
            // getDict({ type: 'main_recovery_status' }).then(({res}) => {
            //     this.searchOptions[5].options = res.main_recovery_status;
            //     // console.error('res.main_recovery_status -------->', res.main_recovery_status);
            // });
         },
             /**
        　* 服务记录
        　* @Description:
          * @Param  {*} scope
          * @Param 
          * @Return
        　* @Author wangz
        　* @Date 2022/3/21 13:56
        　*/
        gotoIndex (scope) {
            const query = Object.assign({},{
              orderId: scope.data.row.id,
              
            })
            this.$router.push({ path: '/order/index', query: query})
            
        },
        toExport() {
          const param = this.getParam()
          // 订单状态后台接口是数组，此处特殊处理下
          if (typeof param.orderState === "string") {
            param.orderState = param.orderState.split(',');
          }
          let exportName = '商品订单列表' + moment(new Date()).format("YYYYMMDD");
          let url = process.env.VUE_APP_BASE_API + '/admin/kfSchemeInfo/adminSchemeOrder/export'
          let _this = this;
          exportExcel(url, {}, param, exportName).then(res=>{
          }).catch(function (error) {
             _this.$message({
                    message: error.message || '导出失败',
                    duration: 5000,
                    type: "warning",
              });
          });;
        },
        handlePageSizeChange() {
          this.page.current = 1;
          this.getList();
        },
        handleRowClick(row = {}, column, event) {
          this.$refs.mainTable.toggleRowExpansion(row);
        },
      }
  }
  </script>
  
  <style lang='scss' scoped>
  @import "~@/styles/variables.scss";
  ::v-deep {
    .table-expand .el-descriptions{
      max-width: 800px;
    }
    .table-expand .el-table--border td, .el-table--border th {
      border-color: none!important;
    }
  }
  </style>


