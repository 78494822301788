<template>
    <el-dialog width="1000px" title="选择方案" :visible.sync="visible" :close-on-click-modal="false"
        :close-on-press-escape="false" append-to-body>
        <searchForm ref="mainSearch" :searchoptions="searchOptions" @search="search" :backStatus="false" />
        <el-table ref="mainTable" border stripe row-contextmenu size="mini" header-row-class-name="table-header"
        :data="tableData" style="width: 100%" @row-click="handleRowClick">
            <el-table-column type="expand" width="50">
            <template slot-scope="parentScope">
                <generalTable newHeight="auto" :table-data="parentScope.row.schemeDoctorList || []" :config="tableConfig" @onClickRow="onClickRow">
                <template slot="ongoingCount" slot-scope="scope">
                    <span v-if="scope.data.row.isOnSale === null">
                    --
                    </span>
                    <el-button size="text" type="primary" v-else>{{scope.data.row.ongoingCount}}</el-button>
                </template>

                <template slot="waitFinishCount" slot-scope="scope">
                    <span v-if="scope.data.row.isOnSale === null">
                    --
                    </span>
                    <el-button size="text" type="primary" v-else>{{scope.data.row.waitFinishCount}}</el-button>
                </template>

                <template slot="finishedCount" slot-scope="scope">
                    <span v-if="scope.data.row.isOnSale === null">
                    --
                    </span>
                    <el-button size="text" type="primary" v-else>{{scope.data.row.finishedCount}}</el-button>
                </template>
                </generalTable>
            </template>
            </el-table-column>
            <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>
            <el-table-column label="ID" prop="id" align="center"></el-table-column>
            <el-table-column label="方案名称" prop="schemeName" align="center"></el-table-column>
            <el-table-column label="是否定制化" prop="customize" align="center" :formatter="cusFormatter">
            </el-table-column>
            <el-table-column label="服务周期（天）" prop="servicePeriod" width="120" align="center"></el-table-column>
            <el-table-column label="适用病种" prop="diseaseType" align="center" show-overflow-tooltip></el-table-column>
            <!--版权归属 0 平台 1 机构 2 医生-->
            <el-table-column label="版权归属" prop="copyright" align="center" :formatter="formatter" show-overflow-tooltip ></el-table-column>

            <el-table-column label="版权授权" prop="schemeAuth" align="center" show-overflow-tooltip></el-table-column>
            <!-- <el-table-column label="方案状态" prop="schemeState" width="150" align="center">
            </el-table-column> -->
        </el-table>
        <br>
       
        <span slot="footer" class="dialog-footer">
                <el-pagination background layout="total, sizes, prev, pager, next" style="text-align: right;"
                :current-page.sync="page.current" :page-size.sync="page.size" :total="total"
                :page-sizes="[10, 20, 30, 40, 50, 100]" @current-change="getList()" @size-change="handlePageSizeChange">
            </el-pagination>
            <el-button type="primary" @click="submit()" :disabled="checkLength > 10"> {{checkLength > 10 ? '只能选择10个' : '选 择'}}</el-button>
            <el-button @click="visible = false">取 消</el-button>
        </span>
    </el-dialog>
</template>
<script>
    import request from '@/utils/request'
    import searchForm from '@/components/SearchForm'
    import generalTable from '@/components/Table'
    import { getAllHospitalList} from '@/api/embodyAndRefund';
    import { getSchemeList } from '@/api/project';
    export default {
        components: {
            searchForm, generalTable
        },
        props: {
            defaultCheckIds: {
                type: Array,
                default: () => { return [] }
            },
            selectMode: {
                type: String,
                default: () => { return 'multiple' }
            },
        },
        data() {
            return {
                visible: false,
                page: { current: 1, size: 20, },
                total: 0,
                paramObj: {},
                searchOptions: [
                    {
                        type: 'input',
                        placeholder: '请输入方案名称',
                        key: 'schemeName',
                        label: '方案名称',
                        defaultVal: ''
                    },
                    {
                        type: 'input',
                        placeholder: '请输入服务医生',
                        key: 'doctorName',
                        label: '服务医生',
                        defaultVal: ''
                    },
                ],
                checkIds: [],
                checkRow: {},
                checkLength: 0,
                tableData: [],
                tableConfig: {
                    id: 'project-child',
                    index: false,
                    selection: 'single',
                    saveSelected: false,
                    column: [
                    {
                    label: '服务医生',
                    prop: 'doctorName',
                    },
                    {
                    label: '服务中患者',
                    prop: 'ongoingCount',
                    slot: 'ongoingCount',
                    width: 150,
                    },
                    {
                    label: '待结案患者',
                    prop: 'waitFinishCount',
                    slot: 'waitFinishCount',
                    width: 150,
                    },
                    {
                    label: '已结案患者',
                    prop: 'finishedCount',
                    slot: 'finishedCount',
                    width: 150,
                    },
                ]}
            };
        },
        watch: {
          visible(newVal, oldVal) {
            if (!newVal) {
              //this.$refs.mainTable.setCurrent();
              this.searchOptions[1].options = [];
            };
          },
        },
        created() {
          console.log('this.selectMode===',this.selectMode);
          this.getList();
        },
        mounted() { },
        methods: {

            open() {
                this.visible = true;
                this.checkIds = this.defaultCheckIds;
                console.log(' this.checkIds====', this.checkIds);
                this.checkLength = this.defaultCheckIds.length;
                if(this.$refs.mainSearch) {
                    this.$refs.mainSearch.resetForm();
                }else{
                    this.getList();
                }
            },
            submit() {
                const rows = this.checkIds;
                const checkRow = this.checkRow;
                if(rows.length == 0){
                    this.$message.warning('请选择方案');
                    return
                }
                console.log('submit -----------> ', rows);
                this.$emit("submitScheme", checkRow);
                this.checkIds = [];
                this.visible = false; 
            },
            onClickRow(item) {
                // 单选模式时，设置选中ID
                if (this.selectMode == 'single') {
                  this.checkIds = [item.goodsId];
                  this.checkRow = item;
                }
            },
            search(data) {
                Object.assign(this.paramObj, data);
                this.page.current = 1;
                this.getList();
            },
            updateSelectedIds(checkIds){
                this.checkIds = checkIds;
                console.log('this.checkIds', this.checkIds)
            },
            /**
             * @description:
             * @param {*}
             * @return {*}
             */
            getList() {
                this.$nextTick(() => {
                    getSchemeList({
                        ...this.paramObj,
                        ...this.page,
                        auditStatus: 3,
                        isOnSale: true,
                    }).then(({ res, timeStamp }) => {
                        this.tableData = res.records;
                        this.total = parseInt(res.total)
                    });
                });
            },
            getParam() {
                const paramObj = this.$refs.mainSearch.packageData();
                const page = this.$refs.mainTable.getPage();
                const param = Object.assign({}, this.paramObj, page, paramObj);
                this.paramObj = param;
                return param;
            },
            handlePageSizeChange() {
                this.page.current = 1;
                this.getList();
            },
            handleRowClick(row = {}, column, event) {
                this.$refs.mainTable.toggleRowExpansion(row);
            },
            getRemoteHospital(query) {
                // 如果用户输入内容了，就发请求拿数据，远程搜索模糊查询
                if (query !== "") {
                    this.loading = true; // 开始拿数据喽
                    let allHospitalList = [];
                    getAllHospitalList({name: query}).then(({ res }) => {
                    allHospitalList = res.records.map((item) => {
                            return {
                                value: item.id,
                                label: item.name,
                            };
                        });
                        this.loading = false // 拿到数据喽
                        this.searchOptions[1].options = allHospitalList;
                    });
                } else {
                    this.searchOptions[1].options = [];
                }
            },
            formatter(row, column){
                //版权归属 0 平台 1 机构 2 医生
                switch (row.copyright) {
                case '0':
                return "平台"
                case '1':
                return "机构"
                case '2':
                return "医生"
                default:
                    return row.copyright;
                }
            },
            cusFormatter(row, column){
                return row.customize == true ? '是' : '否'
            },

        },
    };
</script>
<style scoped lang='scss'>
</style>
