import { render, staticRenderFns } from "./goodsIndex.vue?vue&type=template&id=e20a6ffe&scoped=true"
import script from "./goodsIndex.vue?vue&type=script&lang=js"
export * from "./goodsIndex.vue?vue&type=script&lang=js"
import style0 from "./goodsIndex.vue?vue&type=style&index=0&id=e20a6ffe&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e20a6ffe",
  null
  
)

export default component.exports