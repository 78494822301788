<template>
  <Dialog
    :show="true"
    :isH5="!isPC"
    :isHeaderShow="false"
    :isFooterShow="false"
    :background="false"
    @update:show="reset"
  >
    <Transfer
      :isSearch="props.isNeedSearch"
      :title="props.title"
      :list="props.userList"
      :isH5="!isPC"
      :isRadio="props.isRadio"
      :total="props.total"
      @getMore="handleGetMore"
      @search="handleSearchUser"
      @submit="submit"
      @cancel="reset"
    />
  </Dialog>
</template>
<script lang="ts">
import { ref } from "../../../adapter-vue";
import { isPC } from "../../../utils/env";
import Dialog from "../Dialog/index.vue";
import Transfer from "../Transfer/index.vue";
const __sfc_main = {};
__sfc_main.props = {
  isRadio: {
    type: Boolean,
    default: false
  },
  isNeedSearch: {
    type: Boolean,
    default: false
  },
  title: {
    type: String,
    default: ''
  },
  userList: {
    type: Array,
    default: []
  },
  total: {
    type: Number,
    default: 0
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const emits = __ctx.emit;
  const props = __props;
  const reset = () => {
    emits('complete', []);
  };
  const submit = (dataList: any) => {
    emits('complete', dataList);
  };
  const handleSearchUser = (userID: string) => {
    emits('search', userID);
  };
  const handleGetMore = () => {
    emits('getMore');
  };
  return {
    isPC,
    props,
    reset,
    submit,
    handleSearchUser,
    handleGetMore
  };
};
__sfc_main.components = Object.assign({
  Dialog,
  Transfer
}, __sfc_main.components);
export default __sfc_main;
</script>