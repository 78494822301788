
<template>
  <el-dialog
    width="78%"
    :title="id === '' ? '物流单号' : '物流单号'"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    append-to-body
  >
    <el-form
      ref="insertForm"
      :model="form"
      label-position="right"
      label-width="120px"
      class="formBox"
     
    >
      <div class="mdrcc-info-title">
        物流单号
      </div>
      <el-row :gutter="10" type="flex" justify="start">
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="物流单号" prop="deliveryNumber">
            <el-input
              v-model="form.deliveryNumber"
              placeholder="请输入..."
              clearable
              maxlength="20"
            />
          </el-form-item>
        </el-col>
        
      </el-row>
      <el-divider />
     
     
     
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit()">提 交</el-button>
      <el-button @click="updateVisible(false)">取 消</el-button>
    </span>
    
  </el-dialog>

</template>
<script>
import {  editData ,getDetail} from '@/api/order'
import generalTable from '@/components/Table'
export default {
    components: {
        generalTable
        
    },
    props: {
        id: {
            type: String
        },
        timeStamp: {
            type: String
        }
    },
    data() {
        return {
        checkId: '',
        tableData: [  
        ],
        tableConfig: {
          
        },
        total: 0, // 总条数
      
        options: [
           
        ],
        form: {
            checkbox: [],
            deliveryNumber:"",
        },
        visible: false
        }
    },
    created() {},
    watch: {
            visible(newVal, oldVal) {
                if (newVal === true) {
                    if (this.id) {
                        this.getDetail()
                    }
                }
            }
        },
    mounted() {},
    methods: {
        getTableData() {
            this.$emit('getTableData')
        },
        submit() {
            this.$refs.insertForm.validate((valid) => {
               
              
                const param = { id: this.id ,deliveryNumber:this.form.deliveryNumber}
                    editData(param, this.timeStamp).then(({ res }) => {
                    this.submitSuccess(res)
                    })
            
            })
        },
        submitSuccess(res) {
            this.$message({...this.commonConfig.$handleSuccess})
            this.updateVisible(false)
            this.$emit('submit')
        },
        
        toDetail(scope) {
            this.checkId = this.id
            this.$refs.detail.updateVisible(true)
        },
        getDetail() {
                const param = {}
                if (this.id) {
                    param.id = this.id
                }
                getDetail(param).then(({ res, timeStamp }) => {
                    const { id, ...temp } = res
                    this.form = temp
                    
                })
            },
        toDel(scope) {
            const { $delMsg, $delTitle, $messageBoxConfig } = this.commonConfig
            this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
                const param = { id: scope.data.row.id }
                deleteData(param).then(({ res }) => {
                    this.$message({
                    message: '删除成功',
                    type: 'success'
                    })
                })
            })
        },
        add() {
          console.log(1)
        },
        updateVisible(val) {
            this.visible = val
            //this.$refs.insertForm.resetFields();
        }
    }
}
</script>
<style scoped>
</style>

