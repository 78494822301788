/*
 * @Description:
 * @Author: yzw
 * @Date: 2020-06-23 06:15:04
 * @LastEditTime: 2024-08-28 17:13:20
 * @LastEditors: Please set LastEditors
 * @Reference:
 */
import Vue from "vue";
import Router from "vue-router";
import projectRouter from "./projectRouter";
import sourceRouter from "./sourceRouter";

Vue.use(Router);

/* Layout */
import Layout from "@/layout";
// // 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
// const originalPush = Router.prototype.push
// Router.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err)
// }

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRoutes = [
  {
    path: "/login",
    component: () => import("@/views/login/index"),
    hidden: true,
  },
  {
    path: "customerService",
    component: Layout,
    redirect: "/customerService",
    name: "在线客服",
    children: [
      {
        path: "/customerService",
        name: "customerService",
        component: () => import("@/views/customerService/index"),
        // meta: { title: '在线客服', icon: 'home', breadcrumb: false, keepAlive: true },
      },
      // {
      //   path: '/doctorManagement/detailDialog',
      //   name: 'doctorManagement/detailDialog',
      //   hidden: true,
      //   component: () => import('@/views/doctorManagement/detailDialog'),
      //   meta: { title: '医生管理详情', icon: 'home' }
      // }
    ],
  },
  // {
  //   path: '/',
  //   name: 'home',
  //   component: () => import('@/views/home/index'),
  //   hidden: true
  // },

  // {
  //   path: "/",
  //   component: Layout,
  //   redirect: "/test",
  //   children: [
  //     {
  //       path: "/test",
  //       name: "test",
  //       component: () => import("@/views/test/index"),
  //       meta: { title: "测试", icon: "home" },
  //     },
  //   ],
  // },
  ...projectRouter,
  ...sourceRouter,
  {
    path: "/",
    pageName: "rehabilitationMain",
    component: Layout,
    name: "rehabilitationMain",
    redirect: "/rehabilitation",
    children: [
      {
        path: "/rehabilitation",
        name: "rehabilitation",
        hidden: false,
        component: () => import("@/views/rehabilitation/index"),
        meta: {
          title: "方案管理",
          icon: "home",
          breadcrumb: true,
          keepAlive: true,
        },
      },
      {
        path: "/rehabilitation/project",
        name: "rehabilitation/project",
        hidden: false,
        component: () => import("@/views/rehabilitation/project/index"),
        meta: {
          title: "方案管理",
          icon: "home",
          breadcrumb: true,
          keepAlive: true,
        },
      },
      {
        path: "/rehabilitation/review",
        name: "rehabilitation/review",
        hidden: false,
        component: () => import("@/views/rehabilitation/review/index"),
        meta: {
          title: "方案审核",
          icon: "home",
          breadcrumb: true,
          keepAlive: true,
        },
      },
      // {
      //   path: "/rehabilitation/classify",
      //   name: "rehabilitation/classify",
      //   hidden: false,
      //   component: () => import("@/views/rehabilitation/classify/index"),
      //   meta: {
      //     title: "治疗指导方案分类",
      //     icon: "home",
      //     breadcrumb: true,
      //     keepAlive: true,
      //   },
      // },
      {
        path: "/rehabilitation/videoEquipment",
        name: "rehabilitation/videoEquipment",
        hidden: false,
        component: () => import("@/views/rehabilitation/videoEquipment/index"),
        meta: {
          title: "治疗指导视频设备列表",
          icon: "home",
          breadcrumb: true,
          keepAlive: true,
        },
      },
      {
        path: "/rehabilitation/segmentedVideo",
        name: "rehabilitation/segmentedVideo",
        hidden: false,
        component: () => import("@/views/rehabilitation/segmentedVideo/index"),
        meta: {
          title: "治疗指导视频库",
          icon: "home",
          breadcrumb: true,
          keepAlive: true,
        },
      },
      {
        path: "/rehabilitation/generalVideo",
        name: "rehabilitation/generalVideo",
        hidden: false,
        component: () => import("@/views/rehabilitation/generalVideo/index"),
        meta: {
          title: "视频库通用管理",
          icon: "home",
          breadcrumb: true,
          keepAlive: true,
        },
      },
    ],
  },

  {
    path: "/assessmentMain",
    pageName: "assessmentMain",
    component: Layout,
    name: "assessmentMain",
    redirect: "/assessment",
    children: [
      {
        path: "/assessment",
        name: "assessment",
        hidden: false,
        component: () => import("@/views/assessment/index"),
        meta: {
          title: "评估管理",
          icon: "home",
          breadcrumb: true,
          keepAlive: true,
        },
      },
      // {
      //   path: '/assessment/assessmentMethods',
      //   name: 'assessment/assessmentMethods',
      //   hidden: false,
      //   component: () => import('@/views/assessment/assessmentMethods/index'),
      //   meta: { title: '评估方法', icon: 'home' , breadcrumb: true, keepAlive: true },

      // },
      {
        path: "/assessment/assessmentClass",
        name: "assessment/assessmentClass",
        hidden: false,
        component: () => import("@/views/assessment/assessmentClass/index"),
        meta: {
          title: "评估管理分类",
          icon: "home",
          breadcrumb: true,
          keepAlive: true,
        },
      },
      {
        path: "/assessment/assessmentVideo",
        name: "assessment/assessmentVideo",
        hidden: false,
        component: () => import("@/views/assessment/assessmentVideo/index"),
        meta: {
          title: "评估管理视频设备列表",
          icon: "home",
          breadcrumb: true,
          keepAlive: true,
        },
      },
    ],
  },

  {
    path: "doctorMain",
    pageName: "doctorMain",
    name: "doctorMain",
    component: Layout,
    redirect: "/doctor",
    children: [
      {
        path: "/doctor",
        name: "doctor",
        hidden: false,
        component: () => import("@/views/doctor/index"),
        meta: {
          title: "医生管理",
          icon: "home",
          breadcrumb: true,
          keepAlive: true,
        },
      },
      {
        path: "/doctorManagement",
        name: "doctorManagement",
        component: () => import("@/views/doctorManagement/index"),
        hidden: false,
        meta: { title: "医生管理", icon: "home" },
      },
      {
        path: "/doctorManagement/detailDialog",
        name: "doctorManagement/detailDialog",
        hidden: true,
        component: () => import("@/views/doctorManagement/detailDialog"),
        meta: { title: "医生管理详情", icon: "home" },
      },
      {
        path: "/doctor/recommend",
        name: "doctor/recommend",
        hidden: false,
        component: () => import("@/views/doctor/recommend/index"),
        meta: {
          title: "推荐医生",
          icon: "home",
          breadcrumb: true,
          keepAlive: true,
        },
      },
    ],
  },

  {
    path: "patientManagement",
    component: Layout,
    redirect: "/patientManagement",
    children: [
      {
        path: "/patientManagement",
        name: "patientManagement",
        component: () => import("@/views/patientManagement/index"),
        meta: { title: "患者管理", icon: "home" },
      },
      // {
      //   path: '/doctorManagement/detailDialog',
      //   name: 'doctorManagement/detailDialog',
      //   hidden: true,
      //   component: () => import('@/views/doctorManagement/detailDialog'),
      //   meta: { title: '医生管理详情', icon: 'home' }
      // }
    ],
  },

  {
    path: "channel",
    component: Layout,
    redirect: "/channel",
    children: [
      {
        path: "/channel",
        name: "channel",
        component: () => import("@/views/channel/index"),
        meta: { title: "渠道商管理", icon: "home" },
      },
      {
        path: "/channel/referDoctor",
        name: "channel/referDoctor",
        hidden: true,
        component: () => import("@/views/channel/referDoctor"),
        meta: { title: "引荐医生", icon: "home" },
      },
    ],
  },

  // 运营管理
  {
    path: "/operationMain",
    pageName: "operationMain",
    component: Layout,
    name: "operationMain",
    redirect: "/operation",
    children: [
      {
        path: "/operation",
        name: "operation",
        hidden: false,
        component: () => import("@/views/operation/index"),
        meta: {
          title: "运营管理",
          icon: "home",
          breadcrumb: true,
          keepAlive: true,
        },
      },
      {
        path: "/operation/columnManage",
        name: "operation/columnManage",
        hidden: false,
        component: () => import("@/views/operation/columnManage/index"),
        meta: {
          title: "栏目管理",
          icon: "home",
          breadcrumb: true,
          keepAlive: true,
        },
      },
      {
        path: "/operation/classifyManage",
        name: "operation/classifyManage",
        hidden: false,
        component: () => import("@/views/operation/classifyManage/index"),
        meta: {
          title: "分类管理",
          icon: "home",
          breadcrumb: true,
          keepAlive: true,
        },
      },
      {
        path: "/operation/contentManage",
        name: "operation/contentManage",
        hidden: false,
        component: () => import("@/views/operation/contentManage/index"),
        meta: {
          title: "内容管理",
          icon: "home",
          breadcrumb: true,
          keepAlive: true,
        },
      },
      {
        path: "/operation/bannerManage",
        name: "operation/bannerManage",
        hidden: false,
        component: () => import("@/views/operation/bannerManage/index"),
        meta: {
          title: "Banner管理",
          icon: "home",
          breadcrumb: true,
          keepAlive: true,
        },
      },
      {
        path: "/operation/newsManage",
        name: "operation/newsManage",
        hidden: false,
        component: () => import("@/views/operation/newsManage/index"),
        meta: {
          title: "消息管理",
          icon: "home",
          breadcrumb: true,
          keepAlive: true,
        },
      },
      // {
      //   path: "/operation/pushManage",
      //   name: "operation/pushManage",
      //   hidden: false,
      //   component: () => import("@/views/operation/pushManage/index"),
      //   meta: {
      //     title: "推送管理",
      //     icon: "home",
      //     breadcrumb: true,
      //     keepAlive: true,
      //   },
      // },
      {
        path: "/operation/feedbackManage",
        name: "operation/feedbackManage",
        hidden: false,
        component: () => import("@/views/operation/feedbackManage/index"),
        meta: {
          title: "帮助与反馈",
          icon: "home",
          breadcrumb: true,
          keepAlive: true,
        },
      },
      {
        path: "/operation/evaluation",
        name: "operation/evaluation",
        hidden: false,
        component: () => import("@/views/operation/evaluation/index"),
        meta: {
          title: "评价管理",
          icon: "home",
          breadcrumb: true,
          keepAlive: true,
        },
      },
      {
        path: "/operation/healthData",
        name: "operation/healthData",
        hidden: false,
        component: () => import("@/views/operation/healthData/index"),
        meta: {
          title: "患者健康数据导入",
          icon: "home",
          breadcrumb: true,
          keepAlive: true,
        },
      },
      {
        path: "/operation/trainingAuthority",
        name: "operation/trainingAuthority",
        hidden: false,
        component: () => import("@/views/operation/trainingAuthority/index"),
        meta: {
          title: "患者康复训练权限配置",
          icon: "home",
          breadcrumb: true,
          keepAlive: true,
        },
      },
      {
        path: "/operation/trainingRecord",
        name: "operation/trainingRecord",
        hidden: false,
        component: () => import("@/views/operation/trainingRecord/index"),
        meta: {
          title: "患者康复训练记录查看",
          icon: "home",
          breadcrumb: true,
          keepAlive: true,
        },
      },
      {
        path: "/rehabilitationService",
        name: "rehabilitationService",
        hidden: false,
        component: () => import("@/views/rehabilitationService/index"),
        meta: {
          title: "用户购买服务训练信息导表",
          icon: "home",
          breadcrumb: true,
          keepAlive: true,
        },
      },
      {
        path: "/trainingRecordOfCognition",
        name: "trainingRecordOfCognition",
        hidden: false,
        component: () =>
          import("@/views/operation/trainingRecordOfCognition/index"),
        meta: {
          title: "认知系统患者训练记录",
          icon: "home",
          breadcrumb: true,
          keepAlive: true,
        },
      },
      {
        path: "/trainingRecordOfSpeak",
        name: "trainingRecordOfSpeak",
        hidden: false,
        component: () =>
          import("@/views/operation/trainingRecordOfSpeak/index"),
        meta: {
          title: "言语系统患者训练记录",
          icon: "home",
          breadcrumb: true,
          keepAlive: true,
        },
      },
    ],
  },

  {
    path: "/orderMain",
    pageName: "orderMain",
    component: Layout,
    name: "orderMain",
    redirect: "/order",
    children: [
      {
        path: "/order",
        name: "order",
        hidden: false,
        component: () => import("@/views/order/orderIndex"),
        meta: {
          title: "订单管理",
          icon: "home",
          breadcrumb: true,
          keepAlive: true,
        },
      },
      {
        path: "/order/classify",
        name: "order/classify",
        hidden: false,
        component: () => import("@/views/order/goodsIndex"),
        meta: {
          title: "设备订单管理",
          icon: "home",
          breadcrumb: true,
          keepAlive: true,
        },
      },
      {
        path: "/order/videoEquipment",
        name: "order/videoEquipment",
        hidden: false,
        component: () => import("@/views/order/recoveryIndex"),
        meta: {
          title: "康复服务订单管理",
          icon: "home",
          breadcrumb: true,
          keepAlive: true,
        },
      },
      {
        path: "/order/schemeOrder",
        name: "order/schemeOrder",
        hidden: false,
        component: () => import("@/views/order/schemeOrder"),
        meta: {
          title: "商品订单管理3.0",
          icon: "home",
          breadcrumb: true,
          keepAlive: true,
        },
      },
      {
        path: "/order/index",
        name: "order/index",
        hidden: false,
        component: () => import("@/views/order/index"),
        meta: {
          title: "服务记录",
          icon: "home",
          breadcrumb: true,
          keepAlive: true,
        },
      },
      {
        path: "/order/productsIndex",
        name: "order/productsIndex",
        hidden: false,
        component: () => import("@/views/order/productsIndex"),
        meta: {
          title: "商品管理",
          icon: "home",
          breadcrumb: true,
          keepAlive: true,
        },
      },
      {
        path: "/order/courseOrder",
        name: "order/courseOrder",
        hidden: false,
        component: () => import("@/views/order/courseOrder"),
        meta: {
          title: "课程订单",
          icon: "home",
          breadcrumb: true,
          keepAlive: true,
        },
      },
    ],
  },

  // 财务管理
  {
    path: "/financialMain",
    pageName: "financialMain",
    component: Layout,
    name: "financialMain",
    redirect: "/financial",
    children: [
      {
        path: "/financial",
        name: "financial",
        hidden: false,
        component: () => import("@/views/financial/index"),
        meta: {
          title: "财务管理",
          icon: "home",
          breadcrumb: true,
          keepAlive: true,
        },
      },
      {
        path: "/financial/embody",
        name: "financial/embody",
        hidden: false,
        component: () => import("@/views/financial/embody/index"),
        meta: {
          title: "提现管理",
          icon: "home",
          breadcrumb: true,
          keepAlive: true,
        },
      },
      {
        path: "/financial/refund",
        name: "financial/refund",
        hidden: false,
        component: () => import("@/views/financial/refund/index"),
        meta: {
          title: "退款列表",
          icon: "home",
          breadcrumb: true,
          keepAlive: true,
        },
      },
      {
        path: "/financial/financeRate",
        name: "financial/financeRate",
        hidden: false,
        component: () => import("@/views/financial/financeRate/index"),
        meta: {
          title: "分账系数管理",
          icon: "home",
          breadcrumb: true,
          keepAlive: true,
        },
      },
      {
        path: "/financial/amount",
        name: "financial/amount",
        hidden: false,
        component: () => import("@/views/financial/amount/index"),
        meta: {
          title: "账户信息",
          icon: "home",
          breadcrumb: true,
          keepAlive: true,
        },
      },
      {
        path: "/financial/withdraw",
        name: "financial/withdraw",
        hidden: false,
        component: () => import("@/views/financial/withdraw/index"),
        meta: {
          title: "医生提现信息审核",
          icon: "home",
          breadcrumb: true,
          keepAlive: true,
        },
      },
      {
        path: "/financial/invoice",
        name: "financial/invoice",
        hidden: false,
        component: () => import("@/views/financial/invoice/index"),
        meta: {
          title: "开票信息查看",
          icon: "home",
          breadcrumb: true,
          keepAlive: true,
        },
      },
      {
        path: "/financial/reconciliation",
        name: "financial/reconciliation",
        hidden: false,
        component: () => import("@/views/financial/reconciliation/index"),
        meta: {
          title: "提现对账管理",
          icon: "home",
          breadcrumb: true,
          keepAlive: true,
        },
      },
      {
        path: "/financial/courseProportion",
        name: "financial/courseProportion",
        hidden: false,
        component: () => import("@/views/financial/courseProportion/index"),
        meta: {
          title: "课程分润",
          icon: "home",
          breadcrumb: true,
          keepAlive: true,
        },
      },
    ],
  },
  //推广人管理
  {
    path: "promoter",
    component: Layout,
    redirect: "/promoter",
    children: [
      {
        path: "/promoter",
        name: "promoter",
        component: () => import("@/views/promoter/index"),
        meta: { title: "推荐人管理", icon: "home" },
      },
    ],
  },

  {
    path: "/basicSettingMain",
    pageName: "basicSettingMain",
    component: Layout,
    name: "basicSettingMain",
    redirect: "/basicSetting",
    children: [
      {
        path: "/basicSetting",
        name: "basicSetting",
        hidden: false,
        component: () => import("@/views/basicSetting/index"),
        meta: {
          title: "基础设置",
          icon: "home",
          breadcrumb: true,
          keepAlive: true,
        },
      },
      {
        path: "/basicSetting/officesManagement",
        name: "basicSetting/officesManagement",
        hidden: false,
        component: () => import("@/views/basicSetting/officesManagement/index"),
        meta: {
          title: "科室管理",
          icon: "home",
          breadcrumb: true,
          keepAlive: true,
        },
      },
      {
        path: "/basicSetting/typeManagement",
        name: "basicSetting/typeManagement",
        hidden: false,
        component: () => import("@/views/basicSetting/typeManagement/index"),
        meta: {
          title: "类型管理",
          icon: "home",
          breadcrumb: true,
          keepAlive: true,
        },
      },
      {
        path: "/basicSetting/diseaseManagement",
        name: "basicSetting/diseaseManagement",
        hidden: false,
        component: () => import("@/views/basicSetting/diseaseManagement/index"),
        meta: {
          title: "病种管理",
          icon: "home",
          breadcrumb: true,
          keepAlive: true,
        },
      },
      {
        path: "/basicSetting/goodAtDomain",
        name: "basicSetting/goodAtDomain",
        hidden: false,
        component: () => import("@/views/basicSetting/goodAtDomain/index"),
        meta: {
          title: "擅长领域",
          icon: "home",
          breadcrumb: true,
          keepAlive: true,
        },
      },
      {
        path: "/basicSetting/doctorTitle",
        name: "basicSetting/doctorTitle",
        hidden: false,
        component: () => import("@/views/basicSetting/doctorTitle/index"),
        meta: {
          title: "医师职称",
          icon: "home",
          breadcrumb: true,
          keepAlive: true,
        },
      },
      {
        path: "/basicSetting/hospitalLevel",
        name: "basicSetting/hospitalLevel",
        hidden: false,
        component: () => import("@/views/basicSetting/hospitalLevel/index"),
        meta: {
          title: "医院级别",
          icon: "home",
          breadcrumb: true,
          keepAlive: true,
        },
      },
      {
        path: "/basicSetting/cityLevel",
        name: "basicSetting/cityLevel",
        hidden: false,
        component: () => import("@/views/basicSetting/cityLevel/index"),
        meta: {
          title: "城市级别",
          icon: "home",
          breadcrumb: true,
          keepAlive: true,
        },
      },
      {
        path: "/basicSetting/hospitalManagement",
        name: "basicSetting/hospitalManagement",
        hidden: false,
        component: () =>
          import("@/views/basicSetting/hospitalManagement/index"),
        meta: {
          title: "医院管理",
          icon: "home",
          breadcrumb: true,
          keepAlive: true,
        },
      },
      {
        path: "/basicSetting/areaManagement",
        name: "basicSetting/areaManagement",
        hidden: false,
        component: () => import("@/views/basicSetting/areaManagement/index"),
        meta: {
          title: "地域管理",
          icon: "home",
          breadcrumb: true,
          keepAlive: true,
        },
      },
    ],
  },

  {
    path: "dictionaryManagement",
    component: Layout,
    redirect: "/dictionaryManagement",
    children: [
      {
        path: "/dictionaryManagement",
        name: "dictionaryManagement",
        component: () => import("@/views/dictionaryManagement/index"),
        meta: { title: "字典管理", icon: "home" },
      },
    ],
  },

  // {
  //   path: '/basicSetting',
  //   component: Layout,
  //   redirect: '/basicSetting',
  //   children: [{
  //     path: '/basicSetting',
  //     name: 'basicSetting',
  //     component: () => import('@/views/basicSetting/officesManagement'),
  //     meta: { title: '基础设置', icon: 'home', breadcrumb: true, keepAlive: true }
  //   }
  // ]
  // },
];
export const doctorRouter = [
  {
    path: "/demo",
    component: Layout,
    redirect: "/demo",
    children: [
      {
        path: "demo",
        name: "demo",
        component: () => import("@/views/test/index"),
        meta: { title: "测试2", icon: "home" },
      },
    ],
  },
];

export const notFoundRouter = [
  {
    path: "*",
    redirect: "/404",
    component: () => import("@/views/404"),
    hidden: true,
  },
];

const createRouter = () =>
  new Router({
    // mode: 'history', // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes,
    // routes: [...constantRoutes, ...doctorRouter]
  });

const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

// fix: “Avoided redundant navigation to current location...” 重复点击路由报错问题
let originPush = Router.prototype.push; //备份原push方法

Router.prototype.push = function (location, resolve, reject) {
  if (resolve && reject) {
    //如果传了回调函数，直接使用
    originPush.call(this, location, resolve, reject);
  } else {
    //如果没有传回调函数，手动添加
    originPush.call(
      this,
      location,
      () => {},
      () => {}
    );
  }
};

export default router;
