<!--
 * @Author: 邹博文
 * @Date: 2023-08-14 19:24:05
 * @LastEditTime: 2023-08-14 19:24:05
 * @LastEditors: 邹博文
 * @Description: 康复方案
 * @FilePath: src/views/source/index.vue
-->
<template>
  <el-container>
    <el-header height="auto">
      <searchForm ref="mainSearch" :searchoptions="searchOptions" @search="search" :backStatus="false" />
    </el-header>
    <el-main class="basic-main">
      <el-button type="primary" @click="handleEdit()">新增</el-button>
      <br><br>
      <generalTable ref="mainTable" :table-data="tableData" :config="tableConfig" :total="total" @updatePage="getList">
        <template slot="state" slot-scope="scope">
          <el-switch inactive-value="0" active-value="1" inactive-text="禁用" active-text="启用"
            v-model="scope.data.row.state" @change="handleOff(scope.data.row)" />
        </template>
        <template slot="handle" slot-scope="scope">
          <el-button size="text" type="primary" @click="handleEdit(scope.data.row)">编辑</el-button>
          <el-button size="text" class="delBtn" @click="handleDelete(scope.data.row)">删除</el-button>
        </template>
      </generalTable>
    </el-main>
  </el-container>
</template>

<script>
  import request from '@/utils/request'
  import searchForm from '@/components/SearchForm'
  import generalTable from '@/components/Table'
  import { mapGetters } from 'vuex'
  export default {
    name: 'source',
    components: {
      searchForm, generalTable
    },
    data() {
      return {
        total: 0,
        paramObj: {},
        searchOptions: [
          {
            type: 'input',
            placeholder: '请输入资源名称',
            key: 'sourceName',
            label: '资源名称',
            defaultVal: ''
          },
          {
            type: "cascader",
            placeholder: '请选择资源类型',
            key: 'sourceKey',
            filterable: true,
            label: '资源类型',
            options: [],
            defaultVal: '',
            props: {
              checkStrictly: true,
              emitPath: false,
              checkStrictly: true,
              label: "sourceName",
              value: "sourceKey",
            }
          },
        ],
        tableData: [],
        tableConfig: {
          id: 'source',
          index: true,
          pagination: true, // 不必填 分页 默认为false
          column: [
            {
              label: '资源名称',
              prop: 'sourceName',
            },
            {
              label: '资源类型',
              prop: 'sourceType',
              width: 150,
            },
            {
              label: '资源子类型',
              prop: 'sourceSubType',
              width: 150,
            },
            {
              label: '资源属性',
              prop: 'sourceAttribute',
              width: 150,
            },
            {
              label: '资源描述',
              prop: 'sourceDesc',
            },
            {
              label: '资源状态',
              prop: 'state',
              slot: 'state',
              width: 150,
            },
            {
              slot: "handle",
              label: "操作",
              fixed: "right",
              width: 150,
            },
          ]
        },
      }
    },
    computed: {
      ...mapGetters([
      ])
    },
    created() {

      // 资源类型树
      request({
        url: 'admin/kfSourceType/treeList',
        method: 'GET',
        showLoading: true
      }).then(({ res }) => {
        this.searchOptions[1].options = res;
        // .map(r => {
        //   return {
        //     value: r.sourceKey,
        //     label: r.sourceName
        //   }
        // });
      })
    },
    mounted() {
      this.getList();
    },
    methods: {
      search(data) {
        Object.assign(this.paramObj, data);
        this.$refs.mainTable.handleCurrentChange(1);
        // this.getList();
      },
      getList() {
        request({
          url: 'admin/kfSource/list',
          method: 'GET',
          params: this.getParam(),
          showLoading: true
        }).then(({ res }) => {
          // console.log('getList -----------> ', res);
          this.tableData = res.records;
          this.total = parseInt(res.total);
        })
      },

      handleEdit(row = {}) {
        if (!row.id) {
          this.$router.push({ path: '/source/add', query: { id: row.id } });
        } else {
          this.$router.push({ path: '/source/edit', query: { id: row.id } });
        }
      },
      handleDelete(row) {
        this.$confirm("确定删除？", "删除确认").then(() => {
          // console.log('handleDelete --------> ', row);
          const { id } = row;
          request({
            url: 'admin/kfSource/delete',
            method: 'POST',
            data: { id },
            showLoading: true
          }).then(({ res }) => {
            this.getList()
          })
        });
      },
      // 子 table 上/下架
      handleOff(row = {}) {
        // console.log('handleOff --------> ', row.isOnSale, row.goodsId);
        const { state, id } = row
        request({
          url: 'admin/kfSource/updateSourceState',
          method: 'POST',
          data: {
            id,
            state,
          },
          showLoading: true
        }).then(({ res }) => {
          this.getList()
        })
      },

      getParam() {
        const paramObj = this.$refs.mainSearch.packageData()
        console.log('paramObj ------------> ', paramObj);
        // paramObj.sourceKey = paramObj.sourceKey ? paramObj.sourceKey.join(',') : '';
        const page = this.$refs.mainTable.getPage();
        const param = Object.assign({}, this.paramObj, page, paramObj)
        this.paramObj = param
        console.log("param",param)
        return param
      },
    }
  }
</script>

<style scoped>
  >>>.el-table__expanded-cell {
    position: relative;
    padding-left: 100px;
    padding-right: 150px;
  }

  >>>.el-table__expanded-cell::before {
    content: "";
    position: absolute;
    top: 8%;
    bottom: 8%;
    left: 75px;
    width: 1px;
    background-color: #ddd;
  }
</style>
