/*
 * @Description:
 * @Author: yzw
 * @Date: 2021-09-22 14:24:59
 * @LastEditTime: 2024-03-20 14:42:17
 * @LastEditors: Please set LastEditors
 * @Reference:
 */

/**
 * 页面: 订单模块
 * pageName: test
 **/
import request from '@/utils/request'

/**
 * @description: 服务单列表
 */
export function getList(data) {
    return request({
        url: 'admin/order/list',
        method: 'GET',
        params: data,
        showLoading: true
    })
}
/**
 * @description: 商品订单列表
 */
 export function getGoodsList(data) {
    return request({
        url: 'admin/order/goodsList',
        method: 'GET',
        params: data,
        showLoading: true
    })
}
/**
 * @description: 康复订单列表
 */
 export function recoveryList(data) {
    return request({
        url: 'admin/order/recoveryList',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 商品订单详情页
 */
 export function getDetail(data) {
    return request({
        url: 'admin/order/getDetail',
        method: 'GET',
        params: data,
        showLoading: true
    })
}
/**
 * @description: 商品订单修改快递单号
 */
 export function editData(data) {
    return request({
        url: 'admin/order/editData',
        method: 'POST',
        data,
        showLoading: true
    })
}
/**
 * @description: 获取小鹅通订单列表
 */
export function getXETOrderList(data) {
  return request({
      url: 'xiaoePayment/order/list',
      method: 'GET',
      params: data,
      showLoading: true
  })
}


/**
 * @description: 康复方案订单列表
 */
export function schemeList(data) {
    return request({
        url: 'admin/kfSchemeInfo/adminSchemeOrder',
        method: 'GET',
        params: data,
        showLoading: true
    })
}


export function kfSchemeOrderUpdate(data) {
    return request({
        url: 'admin/kfSchemeInfo/adminSchemeOrderUpdate',
        method: 'POST',
        data,
        showLoading: true
    })
}

export function getkfSchemeOrderInfoById(data) {
    return request({
        url: 'admin/kfSchemeInfo/remarkInfo',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

