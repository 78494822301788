const time = {
  "周": "周",
  "天": "天",
  "小时": "小时",
  "分钟": "分钟",
  "刚刚": "刚刚",
  "前": "前",
  "昨天": "昨天",
  "星期一": "星期一",
  "星期二": "星期二",
  "星期三": "星期三",
  "星期四": "星期四",
  "星期五": "星期五",
  "星期六": "星期六",
  "星期日": "星期日",
  "一月": "一月",
  "二月": "二月",
  "三月": "三月",
  "四月": "四月",
  "五月": "五月",
  "六月": "六月",
  "七月": "七月",
  "八月": "八月",
  "九月": "九月",
  "十月": "十月",
  "十一月": "十一月",
  "十二月": "十二月",
  "一": "一",
  "二": "二",
  "三": "三",
  "四": "四",
  "五": "五",
  "六": "六",
  "日": "日",
}

export default time;
