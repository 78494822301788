<!--
 * @Author: magy
 * @Date: 2022-03-22 16:13:04
 * @LastEditTime: 2024-04-15 10:10:50
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /recovery_home_management_dev/src/views/doctorManagement/detailDialog.vue
-->
<template>
  <el-container class="container">
    <el-form ref="insertForm" :model="form" label-position="right" label-width="120px" class="formBox" :rules="rules">
      <div style="display: flex; justify-content: space-between">
        <div class="mdrcc-info-title">详细信息</div>
        <div style="display: flex; align-items: center; margin-bottom: 5px">
          <el-button type="primary" @click="submit()">提 交</el-button>
          <!-- <el-button @click="updateVisible(false)">取 消</el-button> -->
          <el-button @click="back()">返 回</el-button>
        </div>
      </div>

      <el-row :gutter="10" type="flex" justify="start">
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="医生姓名" prop="name">
            <el-input v-model="form.name" placeholder="请输入..." clearable maxlength="20" />
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="医生类型" prop="doctorType">
            <el-select v-model="form.doctorType" clearable placeholder="请选择" :filterable="true">
              <el-option v-for="option in doctorTypeList" :key="option.value" :label="option.label"
                :value="option.value" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="注册时间" prop="createDate">
            <el-date-picker v-model="form.createDate" align="right" type="date" clear-icon="el-input__icon"
              placeholder="选择日期" value-format="timestamp" :editable="false" style="width: 100%" />
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="医院" prop="practisingHospital">
            <el-select v-model="form.practisingHospital" clearable placeholder="请选择" :filterable="true" remote :remote-method="(params) => {getRemoteHospital(params)}">
              <el-option v-for="option in hospitalList" :key="option.value" :label="option.label"
                :value="option.value" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="审核状态" prop="auditStatus">
            <el-select v-model="form.auditStatus" clearable placeholder="请选择">
              <el-option v-for="option in auditStatusList" :key="option.value" :label="option.label"
                :value="option.value" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="审核不通过原因" prop="auditStatusDescription">
            <el-input v-model="form.auditStatusDescription" placeholder="请输入..." clearable maxlength="20" />
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="科室" prop="section">
            <el-select v-model="form.section" clearable placeholder="请选择" :filterable="true">
              <el-option v-for="option in officeList" :key="option.value" :label="option.label" :value="option.value" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="简介" prop="introduce">
            <el-input v-model="form.introduce" placeholder="请输入..." clearable maxlength="100" />
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="学术获奖" prop="academicAward">
            <el-input v-model="form.academicAward" placeholder="请输入..." clearable maxlength="20" />
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="性别" prop="sex">
            <el-select v-model="form.sex" clearable placeholder="请选择" :filterable="true">
              <el-option v-for="option in sex" :key="option.value" :label="option.label" :value="option.value" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="职称" prop="functional">
            <el-select v-model="form.functional" clearable placeholder="请选择" :filterable="true">
              <el-option v-for="option in functionalList" :key="option.value" :label="option.label"
                :value="option.value" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="病种" prop="diseaseId">
            <el-select v-model="form.diseaseId" clearable placeholder="请选择" :filterable="true">
              <el-option v-for="option in diseaseIdList" :key="option.value" :label="option.label"
                :value="option.value" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="专业领域" prop="goodsListS">
            <el-select v-model="form.goodsListS" clearable multiple placeholder="请选择" :filterable="true">
              <el-option v-for="option in goodsList" :key="option.value" :label="option.label" :value="option.value" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="擅长" prop="goodAt">
            <el-input v-model="form.goodAt" placeholder="请输入..." clearable maxlength="100" />
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="执业经历" prop="educationalBackground">
            <el-input v-model="form.educationalBackground" placeholder="请输入..." clearable maxlength="100" />
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="推荐日期" prop="recommendDate">
            <el-date-picker v-model="form.recommendDate" align="right" type="date" clear-icon="el-input__icon"
              placeholder="选择日期" value-format="timestamp" :editable="false" style="width: 100%" />
          </el-form-item>
        </el-col>

         <!-- <el-col :sm="12" :md="12" :lg="8" :xl="6">
         <el-form-item label="身份证号" prop="idNo">
              <el-input
                  v-model="form.idNo"
                  placeholder="请输入..."
                  clearable
                  maxlength="18"
              />
          </el-form-item> 
        </el-col>-->
        <!-- <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="银行卡号" prop="bankNo">
              <el-input
                  v-model="form.bankNo"
                  placeholder="请输入..."
                  clearable
                  maxlength="20"
              />
          </el-form-item>
        </el-col> -->
        <el-col :sm="24" :md="24" :lg="24" :xl="24">
          <el-form-item label="其他" prop="doctorNote">
            <el-input v-model="form.doctorNote" type="textarea" clearable :autosize="{ minRows: 2, maxRows: 4 }"
              maxlength="200" placeholder="请输入内容" />
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="24" :lg="24" :xl="24">
          <el-form-item label="医生头像">
            <div style="display: flex">
              <!-- <img  class="imagesStyle" :src="commonConfig.$filePublicUrl + form.coverUrl" alt="" /> -->
              <el-image v-if="form.coverUrl" class="imagesStyle" :src="commonConfig.$filePublicUrl + form.coverUrl" :preview-src-list="[commonConfig.$filePublicUrl + form.coverUrl]">
              </el-image>
              <i v-if="form.coverUrl" style="margin: 5px 10px 5px 0px; font-size: 18px;color: red; cursor: pointer;" class="el-icon-error"
                @click="removeCover()"></i>
              <div class="img">
                <div :src="form.coverUrl" @click="setLogoClick" class="defaultImg">
                  <i class="el-icon-plus"></i>
                </div>
              </div>
              <input type="file" name="form.certificatePracticeListS" accept="image/gif,image/jpeg,image/jpg,image/png"
                style="display: none" multiple="multiple" @change="changeLogoImage($event)" ref="logoInput" />
            </div>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="24" :lg="24" :xl="24" style="margin-bottom: 20px">
          <el-form-item label="职业证书">
            <div style="display: flex">
              <div style="display: flex;cursor: pointer;" v-for="(item, index) in form.certificatePracticeListS" :key="index" @click="previewImage(index)">
                <!-- <img class="imagesStyle" :src="commonConfig.$filePublicUrl + item" alt="" /> -->
                <el-image class="imagesStyle" :src="commonConfig.$filePublicUrl + item" ref="previewRef"
                  :preview-src-list="[commonConfig.$filePublicUrl + item]">
                </el-image>
                <i style="margin: 5px 10px 5px 0px; font-size: 18px;color: red;" class="el-icon-error"
                  @click="removePhoto(index)"></i>
              </div>
              <div class="img">
                <div :src="form.certificatePracticeListS" @click="setCertificateClick" class="defaultImg">
                  <i class="el-icon-plus"></i>
                </div>
              </div>
              <input type="file" name="form.certificatePracticeListS" accept="image/gif,image/jpeg,image/jpg,image/png"
                style="display: none" multiple="multiple" @change="changeCertifyImage($event)" ref="certificateInput" />
            </div>
          </el-form-item>
        </el-col>
        <!-- <el-col :sm="24" :md="24" :lg="24" :xl="24">
          <el-col :sm="12" :md="12" :lg="8" :xl="12">
                    <el-form-item label="身份证人像面" prop="idFrontPic">
                        <div class="img">
                            <el-image class="imgBox" v-show="form.idFrontPic" :src="form.idFrontPicUrl"
                            :preview-src-list="[form.idFrontPicUrl]">
                          </el-image>
                            <i style="font-size: 18px;color: red;" class="el-icon-error delete-btn"
                              @click="removeIdFrontPic()" v-show="form.idFrontPic"></i>
                            <div
                                :src="form.idFrontPic"
                                v-show="!form.idFrontPic"
                                @click="setIdCardFrontClick"
                                class="defaultImg"
                            >

                            <img class="id-card-img" :src="obverse" mode=""/>
                            </div>
                        </div>
                        <input
                            type="file"
                            name="form.idFrontPic"
                            accept="image/gif,image/jpeg,image/jpg,image/png"
                            style="display: none"
                            @change="uploadIdCardFrontImage($event)"
                            ref="idFrontInput"
                        />
                    </el-form-item>
                </el-col>
                <el-col :sm="12" :md="12" :lg="8" :xl="12">
                    <el-form-item label="身份证国徽面" prop="idBackPic">
                        <div class="img">
                            <el-image class="imgBox" v-show="form.idBackPic" :src="form.idBackPicUrl"
                            :preview-src-list="[form.idBackPicUrl]"></el-image>
                            <i v-show="form.idBackPic" class="el-icon-error delete-btn"
                              @click="removeIdBackPic()"></i>
                            <div
                                :src="form.idBackPic"
                                v-show="!form.idBackPic"
                                @click="setIdCardBackClick"
                                class="defaultImg"
                            >
                            <img class="id-card-img" :src="reverse" mode=""/>
                            </div>
                        </div>
                        <input
                            type="file"
                            name="form.idBackPic"
                            accept="image/gif,image/jpeg,image/jpg,image/png"
                            style="display: none"
                            @change="uploadIdCardBackImage($event)"
                            ref="idBackInput"
                        />
                    </el-form-item>
                </el-col>
        </el-col> -->
      </el-row>
    </el-form>
  </el-container>
</template>

<script>
import {
  getDoctorInfo,
  createDoctorInfo,
  updateDoctorInfo,
} from "@/api/doctorManagement";
import { getKfType } from "@/api/basicSetting";
import { getHospitalList } from "@/api/hospitalManagement";
import { uploadPrivateFile, uploadPublicFile } from "@/utils/uploadQiniu.js";
import 	idCardFile from "@/utils/idCardImage";
import { validID, validBankId } from '@/utils/validate'
import { getAllHospitalList } from '@/api/embodyAndRefund';
export default {
  props: {
    id: {
      type: String,
    },
  },
  data() {
    return {
      obverse: idCardFile.obverse,
			reverse: idCardFile.reverse,
      rules: {
            idNo: [{ required: false, message: '身份证号不能为空', trigger: 'change' },
                  { validator: validID, trigger: 'change' }
            ],
            bankNo: [{ required: false, message: '银行卡号不能为空', trigger: 'change' },
              { validator: validBankId, trigger: 'change' }
            ],
        // sort: [
        //   {
        //     required: true,
        //     message: "请输入医生序号",
        //     trigger: "blur",
        //   },
        // ],
        // name: [
        //   { required: true, message: "请输入医生姓名", trigger: "blur" },
        //   {
        //     min: 2,
        //     max: 20,
        //     message: "长度在 2 到 20 个字符",
        //     trigger: "blur",
        //   },
        // ],
      },
      auditStatusList: [
        {
          value: "1",
          label: "待审核",
        },
        {
          value: "2",
          label: "审核不通过",
        },
        {
          value: "3",
          label: "审核通过",
        },
      ],
      sex: [
        {
          value: "1",
          label: "男",
        },
        {
          value: "2",
          label: "女",
        },
        {
          value: "3",
          label: "未知",
        },
      ],
      form: {
        // sort: '', // 医生序号
        // name: '', // 医生姓名
        // phoneNumber: '', // 电话
        // createDate:'',//注册时间
        // hospitalName: '', // 所属医院
        // id: '', // 医生id
        // kfHospitalId: '', // 医院所属id
        // status: 1, // 状态
        // taxRate:'',//税费比例
        // platformCharge:'',//平台技术服务费比例
        // remark: '', // 备注
        coverUrl:'', //头像
        auditStatus: "", //审核状态
        section: "", //科室
        introduce: "", //简介
        academicAward: "", //学术获奖
        sex: "", //性别
        functional: "", //职称
        diseaseId: "", //病种
        goodsListS: "", //擅长
        goodAt: "", //擅长及诊所介绍
        educationalBackground: "", //执业经历
        recommendDate: "", //推荐日期
        bankNo: '',// 银行卡号
        idNo: '',// 身份证号
        doctorNote: "", //医生寄语
        certificatePractice: "", //职业证书
        titleCertificate: "", //职称证书
        certificatePracticeListS:[],
        idBackPic: '',// 身份证国徽面
        idFrontPic: '',// 身份证人像面
      },
      visible: false,
      functionalList: [], //职称列表
      diseaseIdList: [], //病种列表
      goodsList: [], //擅长列表
      hospitalList: [], //医院
      doctorTypeList: [], //医生类型
      // fileList: [],
      // uploadDisabled: '',
      officeList: [], //科室列表
      timeStamp: "", // 时间戳
      submitFlag: true, // 防抖
    };
  },
  watch: {
    visible(newVal, oldVal) {
      if (newVal === true && this.id) {
        // this.getUserInfo()
      }
    },
  },
  async created() {
    await this.getGoods();
    this.getDoctorInfo();
    this.getOffice();
    this.getFunctional();
    this.getDiseaseId();
    this.getDoctorTypes();
    this.getHospital();
  },
  methods: {
    submit() {
      if (!this.submitFlag) {
        return;
      }
      let _this = this;
      const { $messageBoxConfig } = this.commonConfig;
      this.$confirm("是否确认提交？", "提交确认", $messageBoxConfig).then(
        () => {
          this.submitFlag = false;
          this.$refs.insertForm.validate((valid) => {
            if (valid) {
              const parm = Object.assign({}, _this.form)
              if (!parm.id) {
                parm.userDoctorId = this.$route.query.id;
                delete parm["idBackPicUrl"];
                delete parm["idFrontPicUrl"];
                console.table('parmadd=',parm);
                createDoctorInfo(parm)
                  .then(({ res }) => {
                    this.submitSuccess(res);
                  })
                  .catch((error) => {
                    this.submitFailure(error);
                  });
              } else {
                console.table('parmupdate=',parm);
                delete parm["idBackPicUrl"];
                delete parm["idFrontPicUrl"];
                updateDoctorInfo(parm, this.timeStamp)
                  .then(({ res }) => {
                    this.submitSuccess(res);
                  })
                  .catch((error) => {
                    this.submitFailure(error);
                  });
              }
            } else {
              this.submitFlag = true;
            }
          });
        }
      );
    },
    /**
     * @description: 上传身份证正面
     * @param {*} event
     * @return {*}
     */
      uploadIdCardFrontImage(event) {
        console.table(event.target)
        let file = event.target.files[0];
        if (!file) return;
        const _this = this;
        uploadPrivateFile(file)
            .then((success) => {
                console.log("success====", success);
                let oFReader = new FileReader()
                oFReader.readAsDataURL(file)
                oFReader.onloadend = function(oFRevent) {
                  _this.form.idFrontPicUrl = oFRevent.target.result;
                  _this.form.idFrontPic = success;
                }
                event.target.value = "";
                console.log("success====11111111", _this.form.idFrontPic);
        })
        .catch((handleError) => {
            this.$message({
                message: "上传失败，请重试",
                type: "warning",
            });
            console.log("handleError====", handleError);
        });
    },
    /**
     * @description: 上传身份证反面
     * @param {*} event
     * @return {*}
     */
      uploadIdCardBackImage(event) {
        let file = event.target.files[0];
        console.table(event.target)
        if (!file) return;
        const _this = this;
        uploadPrivateFile(file)
        .then((success) => {
            console.log("success====", success);

            let oFReader = new FileReader()
            event.target.value = "";
            oFReader.readAsDataURL(file)
            oFReader.onloadend = function(oFRevent) {
              _this.form.idBackPicUrl = oFRevent.target.result;
              _this.form.idBackPic = success;
            }
            console.log("success====11111111", _this.form.idBackPic);
        })
        .catch((handleError) => {
          _this.$message({
                message: "上传失败，请重试",
                type: "warning",
            });
            console.log("handleError====", handleError);
        });
    },
    submitFailure(error) {
      this.submitFlag = true;
    },
    submitSuccess(res) {
      this.submitFlag = true;
      this.$message({ ...this.commonConfig.$handleSuccess });
      this.$emit("submit");
      this.updateVisible(false);
      this.$router.go(-1);
    },
    // handleRemove(file, fileList) {
    //     this.fileList = []
    //     this.form.photo = ''
    //     this.uploadDisabled = ''
    // },
    //获取科室
    getOffice() {
      getKfType({ types: "1" }).then(({ res }) => {
        this.officeList = res.map((item) => {
          return {
            value: "" + item.code,
            label: item.name,
          };
        });
      });
    },
    //获取医师类型
    getDoctorTypes() {
      getKfType({ types: "2" }).then(({ res }) => {
        this.doctorTypeList = res.map((item) => {
          return {
            value: "" + item.code,
            label: item.name,
          };
        });
      });
    },
    //获取职称
    getFunctional() {
      getKfType({ types: "5" }).then(({ res }) => {
        this.functionalList = res.map((item) => {
          return {
            value: "" + item.code,
            label: item.name,
          };
        });
      });
    },
    previewImage (index) {
      if (index <= this.$refs.previewRef.length) {
        this.$refs.previewRef[index].clickHandler();
      }
    },
    //获取病种
    getDiseaseId() {
      getKfType({ types: "3" }).then(({ res }) => {
        this.diseaseIdList = res.map((item) => {
          return {
            value: "" + item.code,
            label: item.name,
          };
        });
      });
    },
    //获取擅长
    getGoods() {
      return new Promise((reslove) => {
        getKfType({ types: "4" }).then(({ res }) => {
          this.goodsList = res.map((item) => {
            return {
              value: "" + item.code,
              label: item.name,
            };
          });
          reslove(this.goodsList);
        });
      });
    },
    // 获取医院
    getHospital() {
      getAllHospitalList().then(({ res }) => {
        this.hospitalList = res.records.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        });
      });
    },
    getRemoteHospital(query) {
            // 如果用户输入内容了，就发请求拿数据，远程搜索模糊查询
            if (query !== "") {
                this.loading = true; // 开始拿数据喽
                let allHospitalList = [];
                getAllHospitalList({name: query}).then(({ res }) => {
                allHospitalList = res.records.map((item) => {
                        return {
                            value: item.id,
                            label: item.name,
                        };
                    });
                    this.loading = false // 拿到数据喽
                    this.hospitalList = allHospitalList;
                    console.log('---------this.hospitalList-------------', this.hospitalList)
                });
            } else {
              this.hospitalList = [];
            }
        },
    listToMap(list, idKey, labelKey) {
      let dataMap = {};
      list.forEach((item) => {
        dataMap[item[idKey]] = item[labelKey];
      });
      return dataMap;
    },

    removePhoto(index) {
      const { $delMsg, $delTitle, $messageBoxConfig, $handleSuccess } = this.commonConfig;
      this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
        this.form.certificatePracticeListS.splice(index, 1);
        // this.form.photoUrl = this.form.photoList.join();
      });
    },
    removeIdFrontPic(){
      this.form.idFrontPic = null;
      this.form.idFrontPicUrl = null;
    },
    removeIdBackPic(){
      this.form.idBackPic = null;
      this.form.idBackPicUrl = null;
    },
    removeCover() {
      const { $delMsg, $delTitle, $messageBoxConfig, $handleSuccess } = this.commonConfig;
      this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
        // this.form.certificatePracticeListS.splice(index, 1);
        // this.form.photoUrl = this.form.photoList.join();
        this.form.coverUrl = "";
      });
    },
    setLogoClick() {
      this.$refs.logoInput.click();
    },
    setCertificateClick() {
      this.$refs.certificateInput.click();
    },
    setIdCardFrontClick() {
      this.$refs.idFrontInput.click();
    },
    setIdCardBackClick() {
      this.$refs.idBackInput.click();
    },
    /**
     * @description: 上传图片
     * @param {*} event
     * @return {*}
     */
    async changeLogoImage(event) {
      let file = event.target.files[0];
      const thiz = this;
      for (let file of event.target.files) {
        await this.updatePublicFile(file);
      }
      // this.form.photoUrl = this.form.photoList.join();
      // thiz.form.photoUrl = success
      // uploadPublicFile(file)
      //     .then((success) => {
      //         console.log("success====", success);
      //         thiz.form.photoUrl = success
      //     })
      //     .catch((handleError) => {
      //         console.log("handleError====", handleError);
      //     });
    },
    /**
     * @description: 上传图片
     * @param {*} event
     * @return {*}
     */
    async changeCertifyImage(event) {
      let file = event.target.files[0];
      const thiz = this;
      for (let file of event.target.files) {
        await this.addPublicFile(file);
      }
      // this.form.photoUrl = this.form.photoList.join();
      // thiz.form.photoUrl = success
      // uploadPublicFile(file)
      //     .then((success) => {
      //         console.log("success====", success);
      //         thiz.form.photoUrl = success
      //     })
      //     .catch((handleError) => {
      //         console.log("handleError====", handleError);
      //     });
    },

    getDoctorInfo() {
      const param = { id: this.$route.query.id };
      const goodsMap = this.listToMap(this.goodsList, "value", "value");
      getDoctorInfo(param).then(({ res, timeStamp }) => {
        this.timeStamp = timeStamp;
        if (res) {
          this.form = res;
          this.form.idFrontPicUrl = this.form.idFrontPic ? this.form.idFrontPic : '';
          this.form.idBackPicUrl = this.form.idBackPic ? this.form.idBackPic : '';
        }
      });
    },

    addPublicFile(file) {
      let _this = this;
      return new Promise((resolve) => {
        uploadPublicFile(file)
          .then((success) => {
            if (!_this.form.certificatePracticeListS) {
              _this.form.certificatePracticeListS = [];
            }
            _this.form.certificatePracticeListS = [
              ..._this.form.certificatePracticeListS,
              success,
            ];
            resolve(success);
          })
          .catch((handleError) => {
            console.log("handleError====", handleError);
          });
      });
    },
    updatePublicFile(file) {
      let _this = this;
      return new Promise((resolve) => {
        uploadPublicFile(file)
          .then((success) => {
            console.log("upload success------", success);
            _this.form.coverUrl = success;
            resolve(success);
          })
          .catch((handleError) => {
            console.log("handleError====", handleError);
          });
      });
    },
    updateVisible(val) {
      this.visible = val;
      if (val === false) {
        // this.fileList = []
        //  this.uploadDisabled = ''
        this.$refs.insertForm.resetFields();
      }
    },
    back() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped lang="scss">
@import "../../styles/variables.scss";

.imagesStyle {
  width: 150px;
  height: 150px;
  margin: 5px 0px;
}

.container {
  overflow-x: hidden;
  position: relative !important;
  background: transparent !important;
  width: auto;
  margin-top: 50px;
  //height: auto;
  margin-right: 10px;
}

.imgBox {
  margin: 5px 0px;
  width: 150px;
  height: 150px;
  cursor: pointer;
  object-fit: contain;
  border-radius: 4px;
  border: 1px solid #333;
}

.imgList {
  display: flex;
  column-gap: 10px;
  flex-wrap: wrap;
  row-gap: 10px;
  margin: 10px 0;
}

.remove {
  position: absolute;
  right: -0.5em;
  top: -0.5em;
  line-height: 1;
  font-size: 16px;
  color: #aaaaaa;
  background-color: #ffffff;
  cursor: pointer;
}

.defaultImg {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  border: 1px solid #333;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  margin: 5px 0px;
}

.id-card-img {
    width: 145px;
    height: 120px;
}
.el-col {
  margin-bottom: 5px;
 }

 .delete-btn {
  // position: relative;
  width: 18px;
  height: 18px;
  font-size: 18px;
  color: red;
  position: absolute;
  margin-top: 4px;
  cursor: pointer;
 }
</style>
