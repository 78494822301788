
<!--
* @Description: 评估方法页面
* @Reference:
-->
<template>
    <el-container>
        <el-header height="auto">
            <searchForm
                ref="mainSearch"
                :searchoptions="searchOptions"
                @search="search"
                :backStatus="backStatus"
            />
        </el-header>
        <el-main class="basic-main">
            <el-row class="table-btn-group"
                ><el-button type="primary" @click="toInsert()"
                    >新增</el-button
                ></el-row
            >
            <generalTable
                ref="mainTable"
                :table-data="tableData"
                :config="tableConfig"
                :total="total"
                @updatePage="getList"
                @onClickRow="onClickRow"
            >
                     <template slot="state" slot-scope="scope">
                        <el-switch
                            v-model="scope.data.row.state"
                            inactive-value="1"
                            active-value="0"
                            @change="changeState(scope,$event)"
                        />
                    </template>
                <template slot="handle" slot-scope="scope">
                    <el-button type="text" @click="toDetail(scope)"
                        >查看</el-button
                    >
                    <el-button type="text" @click="toEdit(scope)"
                        >编辑</el-button
                    >
                    <el-button type="text" class="delBtn" @click="toDel(scope)"
                        >删除</el-button
                    >
                </template>
            </generalTable>
            <detailDialog
                :id="checkId"
                ref="detail"
                :source="source"
                :use-type="use_type"
                :identifier-array="identifierArray"
            />
            <addDialog
                :id="checkId"
                ref="insert"
                :timeStamp="timeStamp"
                :source="source"
                :use-type="use_type"
                @submit="search"
                :classify-array="classifyArray"
                :identifier-array="identifierArray"
            />
        </el-main>
    </el-container>
</template>

<script>
import { getList, deleteData, changeState } from "@/api/assessmentMethods";
import searchForm from "@/components/SearchForm";
import generalTable from "@/components/Table";
import addDialog from "@/views/assessment/assessmentMethods/addDialog";
import detailDialog from "@/views/assessment/assessmentMethods/detailDialog";
import { getDict } from "@/api/common";
import { getAssessmentClassList } from "@/api/assessmentClass";

export default {
    name: "assessmentMethods",
    components: {
        searchForm,
        generalTable,
        detailDialog,
        addDialog,
    },
    data() {
        return {
            tableData: [],
            searchOptions: [
                {
                    type: "input",
                    placeholder: "请输入...",
                    key: "name",
                    label: "名称",
                    defaultVal: "",
                },
                {
                    type: "cascader",
                    placeholder: "请选择",
                    key: "kfGuidanceTypeId",
                    filterable: true,
                    multiple: true,
                    label: "分类",
                    options: [],
                    defaultVal: [],
                    props: {
                        checkStrictly: true,
                        emitPath: false,
                        label: "officesName",
                        value: "id",
                    },
                },
                {
                    type: "select",
                    placeholder: "请选择",
                    key: "useType",
                    filterable: true,
                   
                    label: "使用类型",
                    options: [],
                    defaultVal: [],
                },
                {
                    type: "select",
                    placeholder: "请选择",
                    key: "state",
                    filterable: true,
                   
                    label: "状态",
                    options: [
                        {
                            value: "0",
                            label: "启用",
                        },
                        {
                            value: "1",
                            label: "禁用",
                        },
                    ],
                    defaultVal: [],
                },
            ],
            tableConfig: {
                id: "classify",
                align: "center", // 不必填 默认为center
                selection: "single", // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: true, // 不必填 分页 默认为false
                column: [
                    {
                        label: "名称", // 必填
                        prop: "name", // 必填
                    },
                    {
                        label: "分类", // 必填
                        prop: "assessmentTypeName", // 必填
                    },
                    {
                        label: "来源", // 必填
                        prop: "source", // 必填
                        formatter: (row) => {
                            // 根据字典表展示对应项目的label值
                            const currentType = this.source.filter(
                                (type) => type.value === row.source
                            );
                            if (currentType.length) {
                                return currentType[0].label;
                            }
                        },
                        width: "100",
                    },
                    {
                        label: '禁用/启用', // 必填
                        slot: 'state' // 必填
                    },
                    {
                        label: "方法描述", // 必填
                        prop: "describes", // 必填
                    },
                    {
                        label: "使用类型", // 必填
                        prop: "useType", // 必填
                        formatter: (row) => {
                            // 根据字典表展示对应项目的label值
                            const currentType = this.use_type.filter(
                                (type) => type.value === row.useType
                            );
                            if (currentType.length) {
                                return currentType[0].label;
                            }
                        },
                        width: "100",
                    },
                    {
                        slot: "handle", // 不必填 单列插槽
                        label: "操作", // 必填
                        fixed: "right", // 不必填 固定列  left right
                        width: "220", // 不必填
                    },
                ],
            },
            paramObj: null, // 筛选项
            total: 0, // 总条数
            checkId: "", // 单条数据id 用于详情或编辑请求数据用
            backStatus: true, //返回按钮的隐藏显示
            timeStamp: "", //时间戳 用于修改数据或删除数据
            use_type: [],
            source: [],
            classifyArray: [],
            identifierArray: [],
        };
    },
    created() {
        this.getUseTypeList();
        this.getSourceList();
        this.getAssessmentClassList();
        this.getIdentifierList();
        this.getList();
    },
    mounted: function () {},
    methods: {
        /**
         * @description: 获取使用类型
         * @param {*}
         * @return {*}
         */
        getUseTypeList() {
            getDict({ type: "use_type" }).then(({ res }) => {
                this.use_type = res.use_type;
                this.searchOptions[2].options = res.use_type;
            });
        },
        /**
         * @description: 获取所属平台
         * @param {*}
         * @return {*}
         */
        getIdentifierList() {
            getDict({ type: "identifier" }).then(({ res }) => {
                this.identifierArray = res.identifier;
                this.searchOptions[1].options = res.identifier;
            });
        },
        /**
         * @description: 获取来源
         * @param {*}
         * @return {*}
         */
        getSourceList() {
            getDict({ type: "source" }).then(({ res }) => {
                this.source = res.source;
                this.searchOptions[2].options = res.source;
            });
        },
        /**
         * @description: 获取分类列表
         * @param {*}
         * @return {*}
         */
        getAssessmentClassList() {
            this.$nextTick(() => {
                const param = this.getParam();
                getAssessmentClassList(param).then(({ res, timeStamp }) => {
                    this.classifyArray = res;
                    this.searchOptions[1].options = res;
                });
            });
        },
        search(data) {
            Object.assign(this.paramObj, data);
            this.$refs.mainTable.handleCurrentChange(1);
        },
        getList() {
            this.$nextTick(() => {
                const param = this.getParam();
                getList(param).then(({ res, timeStamp }) => {
                    this.tableData = res.records;
                    this.timeStamp = timeStamp;
                    this.total = parseInt(res.total)
                });
            });
        },
        toInsert() {
            this.checkId = "";
            this.$refs.insert.updateVisible(true);
        },
        toDetail(scope) {
            this.checkId = scope.data.row.id;
            this.$refs.detail.updateVisible(true);
        },
        toEdit(scope) {
            this.checkId = scope.data.row.id;
            this.$refs.insert.updateVisible(true);
        },
        toDel(scope) {
            if(scope.data.row.state=='0'){
                this.$message({
                    showClose: true,
                    message: '只有禁用可删除，启用不可删除',
                    type: 'warning'
                })
            }else{
                const { $delMsg, $delTitle, $messageBoxConfig, $handleSuccess } =
                    this.commonConfig;
                this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
                    const param = { id: scope.data.row.id };
                    deleteData(param, this.timeStamp).then(({ res }) => {
                        this.$message({ ...$handleSuccess });
                        this.getList();
                    });
                });
            }
        },
        onClickRow(row) {
            console.log("点击行:", row);
        },
        getParam() {
            const paramObj = this.$refs.mainSearch.packageData();
            const page = this.$refs.mainTable.getPage();
            const param = Object.assign({}, this.paramObj, page, paramObj);
            this.paramObj = param;
            return param;
        },
        changeState(checkArray){
            const parm = {
                id: checkArray.data.row.id,
                state: checkArray.data.row.state
            }
            changeState(parm,this.timeStamp).then(({res}) =>{
                this.getList()
            })
        },
    },
};
</script>

<style lang='scss' scoped>
@import "~@/styles/variables.scss";
</style>

        