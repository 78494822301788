
<!--
* @Description: 帮助与反馈页面
* @Reference:
-->

<template>
    <el-container>
        <el-header height="auto">
            <searchForm ref="mainSearch" :searchoptions="searchOptions" @search="search" :backStatus="backStatus" />
        </el-header>
        <el-main class="basic-main">
            <generalTable ref="mainTable" :table-data="tableData" :config="tableConfig" :total="total"
                @updatePage="getList" @onClickRow="onClickRow">
                <template slot="handle" slot-scope="scope">
                    <el-button type="text" @click="toDetail(scope)">查看</el-button>
                    <el-button type="text" class="delBtn" @click="toDel(scope)">删除</el-button>
                </template>
            </generalTable>
            <detailDialog :id="checkId" ref="detail" @sonFunc="sonFunc" />
            <addDialog :id="checkId" ref="insert" :timeStamp="timeStamp" @submit="search" />
        </el-main>
    </el-container>
</template>

<script>
import { getList, deleteData } from '@/api/feedbackManage'
import searchForm from '@/components/SearchForm'
import generalTable from '@/components/Table'
import addDialog from '@/views/operation/feedbackManage/addDialog'
import detailDialog from '@/views/operation/feedbackManage/detailDialog'

export default {
    name: 'FeedbackManage',
    components: {
        searchForm,
        generalTable,
        detailDialog,
        addDialog
    },
    data() {
        return {
            tableData: [],
            searchOptions: [
                {
                    type: 'select',
                    placeholder: '请选择',
                    key: 'type',
                    filterable: true,
                    multiple: false,
                    label: '反馈类型',
                    options: [
                        {
                            value: '0',
                            label: '产品建议'
                        },
                        {
                            value: '1',
                            label: '程序错误'
                        },
                        {
                            value: '9',
                            label: '其他'
                        }
                    ],
                    defaultVal: []
                },
                {
                    type: 'select',
                    placeholder: '请选择',
                    key: 'source',
                    filterable: true,
                    multiple: false,
                    label: '反馈来源',
                    options: [
                        {
                            value: '0',
                            label: '患者端'
                        },
                        {
                            value: '1',
                            label: '医生端'
                        },
                        {
                            value: '2',
                            label: '小程序'
                        }
                    ],
                    defaultVal: []
                },
                {
                    type: 'input',
                    placeholder: '请选择',
                    key: 'systemInfo',
                    filterable: true,
                    multiple: false,
                    label: '系统',
                    defaultVal: []
                },
                {
                    type: 'input',
                    placeholder: '请输入...',
                    key: 'name',
                    label: '版本号',
                    defaultVal: ''
                },
                {
                    type: 'daterange',
                    startPlaceholder: '开始日期',
                    endPlaceholder: '结束日期',
                    key: 'serviceTime',
                    label: '反馈时间',
                    defaultVal: []
                },
                {
                    type: 'input',
                    placeholder: '请输入...',
                    key: 'username',
                    label: '用户名',
                    defaultVal: ''
                },
                {
                    type: 'select',
                    placeholder: '请选择',
                    key: 'status',
                    filterable: true,
                    multiple: false,
                    label: '状态',
                    options: [
                        {
                            value: '1',
                            label: '待处理'
                        },
                        {
                            value: '2',
                            label: '已回复'
                        }
                    ],
                    defaultVal: []
                },
            ],
            tableConfig: {
                id: 'feedbackManage',
                align: 'center', // 不必填 默认为center
                selection: 'single', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: true, // 不必填 分页 默认为false
                column: [
                    {
                        label: '问题编号',// 必填
                        prop: 'id' // 必填
                    },
                    {
                        label: '反馈类型', // 必填
                        prop: 'type', // 必填
                        formatter: (row, column) => {
                            const stampContainer = {
                                0: "产品建议",
                                1: "程序错误",
                                9: "其他",
                            };
                            return stampContainer[row.type];
                        },
                    },
                    {
                        label: '反馈内容', // 必填
                        prop: 'content' // 必填
                    },
                    {
                        label: '来源', // 必填
                        prop: 'source', // 必填
                        formatter: (row, column) => {
                            const stampContainer = {
                                0: "患者端",
                                1: "医生端",
                                2: "小程序"
                            };
                            return stampContainer[row.source];
                        }
                    },
                    {
                        label: '用户名', // 必填
                        prop: 'username' // 必填
                    },
                    {
                        label: '反馈时间', // 必填
                        prop: 'serviceTime', // 必填
                        formatter: (row, column) => {
                            if (!row.serviceTime) return '';
                            return this.moment(row.serviceTime).format(
                                'YYYY-MM-DD HH:mm'
                            )
                        }
                    },
                    {
                        label: '状态', // 必填
                        prop: 'status', // 必填
                        formatter: (row, column) => {
                            const stampContainer = {
                                1: "待处理",
                                2: "已回复",
                            };
                            return stampContainer[row.status];
                        },
                    },
                    // {
                    //     label: '用户id', // 必填
                    //     prop: 'userId' // 必填
                    // },
                    {
                        label: '联系方式', // 必填
                        prop: 'contactInformation' // 必填
                    },
                    {
                        label: '版本号', // 必填
                        prop: 'address' // 必填
                    },
                    {
                        label: '系统', // 必填
                        prop: 'systemInfo', // 必填
                        // formatter: (row, column) => {
                        //         const stampContainer = {
                        //             0: "Android ",
                        //             1: "IOS"
                        //         };
                        //         return stampContainer[row.systemInfo];
                        // }
                    },
                    {
                        slot: 'handle', // 不必填 单列插槽
                        label: '操作', // 必填
                        fixed: 'right', // 不必填 固定列  left right
                        width: '220' // 不必填
                    }
                ]
            },
            paramObj: null, // 筛选项
            total: 0, // 总条数
            checkId: '', // 单条数据id 用于详情或编辑请求数据用
            backStatus: true, //返回按钮的隐藏显示
            timeStamp: '', //时间戳 用于修改数据或删除数据
        }
    },
    created() {
        this.getList()
    },
    mounted: function () { },
    methods: {
        sonFunc() {
            this.getList()
        },
        search(data) {
            Object.assign(this.paramObj, data)
            this.$refs.mainTable.handleCurrentChange(1)
        },
        getList() {
            this.$nextTick(() => {
                const param = this.getParam()
                getList(param).then(({ res, timeStamp }) => {
                    this.tableData = res.records
                    this.timeStamp = timeStamp
                    this.total = parseInt(res.total)
                })
            })
        },
        toInsert() {
            this.checkId = ''
            this.$refs.insert.updateVisible(true)
        },
        toDetail(scope) {
            this.checkId = scope.data.row.id
            this.$refs.detail.updateVisible(true)
        },
        toEdit(scope) {
            this.checkId = scope.data.row.id
            this.$refs.insert.updateVisible(true)
        },
        toDel(scope) {
            const { $delMsg, $delTitle, $messageBoxConfig, $handleSuccess } = this.commonConfig
            this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
                const param = { id: scope.data.row.id }
                deleteData(param, this.timeStamp).then(({ res }) => {
                    this.$message({ ...$handleSuccess })
                    this.getList()
                })
            })
        },
        onClickRow(row) {
            console.log('点击行:', row)
        },
        getParam() {
            const paramObj = this.$refs.mainSearch.packageData()
            const page = this.$refs.mainTable.getPage()
            const param = Object.assign({}, this.paramObj, page, paramObj)
            this.paramObj = param
            return param
        }
    }
}
</script>

<style lang='scss' scoped>
@import "~@/styles/variables.scss";
</style>

