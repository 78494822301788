/*
 * @LastEditors: yzw
 * @Author: Richardlv
 */
import request from '@/utils/request'

/**
 * @description: 获取字典项
 */
export function getDict(data) {
  return request({
    url: '/dict/list',
    method: 'GET',
    params: data,
    showLoading: false,
  })
}

/**
 * @description: 获取当前用户绑定的治疗设备列表
 */
export function getUserDevices(data) {
  return request({
    url: "/ruleArmarium/getUserChooseArmarium",
    method: "GET",
    params: data,
    showLoading: false
  });
}

/**
 * @description: 上传单个文件
 * @param {Object} data
 */
export function uploadFile(data) {
  return request({
    url: '/file/uploadFile',
    method: 'POST',
    showLoading: true,
    data
  })
}

/**
 * @description: 上传多个文件
 * @param {Object} data
 */
export function uploadFiles(data) {
  return request({
    url: '/file/uploadFiles',
    method: 'POST',
    upload: true,
    data
  })
}

/**
 * @description: 下载文件
 * @param {Object} data: data中必须有id字段
 */
export function downloadFile(data) {
  return request({
    url: '/file/download/' + data.id,
    method: 'GET',
    responseType: 'blob',
  })
}

/**
 * @description: 获取封装信息字典
 * @param {String} type: 字典类型
 */
export function getPatientPackage(type) {
  return request({
    url: "/patientPackage/getByType",
    method: "GET",
    params: {type}
  });
}

/**
 * @description: 保存封装信息字典
 * @param {Object} data
 */
export function savePatientPackage(data) {
  return request({
    url: "patientPackage/save",
    method: "POST",
    data
  });
}

;