import { render, staticRenderFns } from "./addBasic copy.vue?vue&type=template&id=4b9996f5"
import script from "./addBasic copy.vue?vue&type=script&lang=js"
export * from "./addBasic copy.vue?vue&type=script&lang=js"
import style0 from "./addBasic copy.vue?vue&type=style&index=0&id=4b9996f5&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports