<!--
* @Description: 商品管理
* @Reference:
-->

<template>
    <el-container>
        <el-header height="auto">
            <searchForm ref="mainSearch" :searchoptions="searchOptions" @search="search" :backStatus="backStatus" />
        </el-header>
        <el-main class="basic-main">
            <el-row class="table-btn-group">
                <el-button type="primary" @click="toInsert()">新增</el-button>
                <!-- <el-button type="primary" @click="toDelete()">批量删除</el-button>
              <el-button type="primary" @click="toState()">批量上架</el-button>
              <el-button type="primary" @click="toShelf()">批量下架</el-button> -->
            </el-row>
            <generalTable ref="mainTable" :table-data="tableData" :config="tableConfig" :total="total"
                @updatePage="getList">
                <template slot="handle" slot-scope="scope">
                    <el-button type="text" @click="toDetail(scope)">详情</el-button>
                    <el-button type="text" @click="toEdit(scope)">编辑</el-button>
                    <el-button type="text" class="delBtn" @click="toDel(scope)">删除</el-button>
                </template>
                <template slot="contentState" slot-scope="scope">
                    <el-switch v-if="contentState" v-model="scope.data.row.contentState" active-value="1"
                        inactive-value="2" @change="toContentState(scope.data.row)" />
                </template>
                <template slot="photoUrl" slot-scope="scope">
                    <el-image style="width: 50px; height: 50px; margin-top: 10px" :src="
                          commonConfig.$filePublicUrl + scope.data.row.photoUrl
                      " :preview-src-list="[commonConfig.$filePublicUrl + scope.data.row.photoUrl]">
                    </el-image>
                </template>
            </generalTable>
            <detailDialog :id="checkId" ref="detail" />
            <addDialog :id="checkId" :timeStamp="timeStamp" ref="insert" @submit="search"
                :classify-array="classifyList" />
        </el-main>
    </el-container>
</template>

<script>
    import { getList, deleteData, toState, toShelf, toContentState, getColumnList, getClassifyList } from '@/api/contentManage'
    import searchForm from '@/components/SearchForm'
    import generalTable from '@/components/Table'
    import addDialog from '@/views/operation/contentManage/addDialog'
    import detailDialog from '@/views/operation/contentManage/detailDialog'
    import { getDict } from '@/api/common'

    export default {
        name: 'contentManage',
        components: {
            searchForm,
            generalTable,
            detailDialog,
            addDialog
        },
        data() {
            return {
                tableData: [],
                searchOptions: [
                    {
                        type: 'input',
                        placeholder: '请输入',
                        key: 'name',
                        label: '文章标题',
                        defaultVal: ''
                    },


                    {
                        type: "cascader",
                        placeholder: "请选择",
                        key: "kfTypeId",
                        filterable: true,
                        multiple: true,
                        label: "分类",
                        options: [],
                        defaultVal: [],
                        props: {
                            checkStrictly: true,
                            emitPath: false,
                            label: "name",
                            value: "id",
                        },
                    },
                    {
                        type: "daterange",
                        startPlaceholder: "开始日期",
                        endPlaceholder: "结束日期",
                        key: "pushTime",
                        label: "发布时间",
                        defaultVal: [],
                    },

                    {
                        type: "select",
                        placeholder: "请选择",
                        key: "releaseState",
                        filterable: true,
                        label: "发布状态",
                        options: [
                            {
                                value: "1",
                                label: "已发布",
                            },
                            {
                                value: "2",
                                label: "待发布",
                            },
                        ],
                        defaultVal: [],
                    },
                    {
                        type: "select",
                        placeholder: "请选择",
                        key: "contentState",
                        filterable: true,
                        label: "状态(上/下架)",
                        options: [
                            {
                                value: "1",
                                label: "上架",
                            },
                            {
                                value: "2",
                                label: "下架",
                            },
                        ],
                        defaultVal: [],
                    },
                ],
                tableConfig: {
                    id: 'products',
                    align: 'center', // 不必填 默认为center
                    selection: 'multiple', // 不必填 多选: multiple  单选: single  默认为 normal
                    index: true, // 不必填 序号
                    pagination: true, // 不必填 分页 默认为false
                    column: [
                        {
                            label: '文章标题', // 必填
                            prop: 'name' // 必填
                        },

                        {
                            label: '封面图片', // 必填
                            slot: "photoUrl", // 必填
                            width: "100",
                        },
                        {
                            label: '所属栏目', // 必填
                            prop: 'column' // 必填
                        },

                        {
                            label: '分类', // 必填
                            prop: 'kfTypeName', // 必填

                        },
                        {
                            label: '发布时间', // 必填
                            prop: 'pushTime', // 必填
                            formatter: (row, column) => {
                                return this.moment(row.pushTime).format("YYYY-MM-DD HH:mm:ss")
                            },
                        },
                        {
                            label: '结束时间', // 必填
                            prop: 'endPushTime', // 必填
                            formatter: (row, column) => {
                                return row.endPushTime ? this.moment(row.endPushTime).format("YYYY-MM-DD HH:mm:ss") : ''
                            }
                        },
                        {
                            label: '描述', // 必填
                            prop: 'synopsis', // 必填

                        },
                        {
                            label: '发布状态', // 必填
                            prop: 'releaseState', // 必填

                        },
                        {
                            label: '状态(上/下架)', // 必填
                            slot: 'contentState', // 必填

                        },

                        {

                            slot: 'handle', // 不必填 单列插槽
                            label: '操作', // 必填
                            fixed: 'right', // 不必填 固定列  left right
                            width: '220' // 不必填
                        }
                    ]
                },
                paramObj: null, // 筛选项

                classifyList: [],//分类列表
                total: 0, // 总条数 
                contentState: '1',
                checkId: '', // 单条数据id 用于详情或编辑请求数据用
                backStatus: true, //返回按钮的隐藏显示
                timeStamp: '', //时间戳 用于修改数据或删除数据
                kfTypeId: '',
                column: '',
                ids: []
            }
        },
        created() {

            this.getClassifyList();
            this.getList()

        },
        mounted: function () { },
        methods: {
            search(data = {}) {
                Object.assign(this.paramObj, data)
                this.$refs.mainTable.handleCurrentChange(1)
            },
            getList() {
                this.$nextTick(() => {
                    const param = this.getParam()
                    getList(param).then(({ res, timeStamp }) => {

                        this.tableData = res.records
                        this.timeStamp = timeStamp
                        this.total = parseInt(res.total)
                    })
                })
            },
            toInsert() {
                this.checkId = ''
                this.$refs.insert.updateVisible(true)
            },
            // toInsert (scope) {
            //     const query = Object.assign({},{
            //       orderId: '',

            //     })
            //     console.log("ssssssssssss",query)
            //     this.$router.push({ path: '/products/addDialog',})

            // },
            toDetail(scope) {
                this.checkId = scope.data.row.id
                this.$refs.detail.updateVisible(true)
            },
            toEdit(scope) {
                this.checkId = scope.data.row.id

                this.$refs.insert.updateVisible(true)
            },
            toDel(scope) {
                if (scope.data.row.contentState == '1') {
                    this.$message({
                        showClose: true,
                        message: '只有下架可删除，上架不可删除',
                        type: 'warning'
                    })
                } else if (scope.data.row.releaseState == '已发布') {
                    this.$message({
                        showClose: true,
                        message: '只有待发布可删除，已发布不可删除',
                        type: 'warning'
                    })
                }
                else {
                    const { $delMsg, $delTitle, $messageBoxConfig, $handleSuccess } = this.commonConfig
                    this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
                        deleteData({id:scope.data.row.id}, this.timeStamp).then(res => {
                            this.$message({ ...this.commonConfig.$handleSuccess })
                            this.getList()
                        })
                    })
                }
            },


            getParam() {
                const paramObj = this.$refs.mainSearch.packageData()
                const page = this.$refs.mainTable.getPage()
                const param = Object.assign({}, this.paramObj, page, paramObj)
                this.paramObj = param
                return param
            },
            //   getDictType(){
            //       getDict({ type: 'goods_type' }).then(({res}) => {
            //           this.searchOptions[2].options = res.goods_type;
            //       });
            //    },
            //    getDict(){
            //       getDict({ type: 'goods_state' }).then(({res}) => {
            //           this.searchOptions[3].options = res.goods_state;
            //       });
            //    },
            toContentState(item) {       //状态 id:角色ID status:上架/下架状态
                const parm = {
                    id: item.id,
                    contentState: item.contentState
                }

                toContentState(parm, this.timeStamp).then(({ res }) => {
                    // 成功消息提示，需要使用扩展运算符的形式，否则容易造成某些成功提示语不消失一直显示的问题
                    this.$message({ ...this.commonConfig.$handleSuccess })
                    this.getList()
                });
            },
            //获取分类
            getClassifyList() {
                const param = []
                getClassifyList(param).then(({ res }) => {


                    this.classifyList = res;
                    this.searchOptions[1].options = res;
                });
            },

        }
    }
</script>

<style lang='scss' scoped>
    @import "~@/styles/variables.scss";
</style>
