<!--
 * @Author: 358617935@qq.com 358617935@qq.com
 * @Date: 2023-05-24 09:53:49
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-06-09 14:00:47
 * @FilePath: /recovery_home_management_dev/src/views/doctor/recommend/detailDialog.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->

<template>
	<el-dialog width="30%" title="编辑排名" :visible.sync="visible":close-on-click-modal="false" :close-on-press-escape="false"
		:show-close="false" custom-class="basicDialog" append-to-body>
		<el-form ref="insertForm" :model="form" label-position="right" label-width="120px" class="formBox" :rules="rules" size="small">
			<el-row :gutter="10" type="flex" justify="start">
				<el-col :sm="24" :md="24" :lg="18" :xl="18">
					<el-form-item label="排名" prop="sort">
						<el-input v-model="form.sort" placeholder="请输入..." clearable maxlength="2" />
					</el-form-item>
				</el-col>
				<el-col :sm="24" :md="24" :lg="18" :xl="18">
					<el-form-item label="医生名称：" prop="doctorName">
						<el-input v-model="form.doctorName" disabled />
					</el-form-item>
				</el-col>
				<el-col :sm="24" :md="24" :lg="18" :xl="18">
					<el-form-item label="手机号：" prop="doctorPhone">
						<el-input v-model="form.doctorPhone" disabled />
					</el-form-item>
				</el-col>
				<el-col :sm="24" :md="24" :lg="18" :xl="18">
					<el-form-item label="医院：" prop="hospitalId">
						<el-input v-model="form.hospitalName" disabled />
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
		<template slot="footer" class="dialog-footer">
			<el-button type="primary" @click="submit()">提 交</el-button>
			<el-button @click="updateVisible(false)">取 消</el-button>
		</template>
	</el-dialog>
</template>
<script>
import { editData, getDetail } from '@/api/recommend'
export default {
	props: {
		id: {
			type: String,
			required: true
		},
		timeStamp: String
	},
	data() {
		/**
		 * 排序验证
		 * @description: 排序字段必须为数字
		 * @param {*} rule 验证规则
		 * @param {*} value 要验证的数据
		 * @param {*} callback 回调
		 * @return {*}
		 */
		const validatorSort = (rule, value, callback) => {
			if (/^[0-9]+$/.test(value) === false) {
				callback(new Error("请输入数字"));
			} else if(value == 0){
				callback(new Error("输入1-99正整数"));
			}else {
				callback();
			}
		};
		return {
			visible: false,
			detail: [], // 详情数据
			form: {
				sort: "",
				doctorName: "",
				doctorPhone: "",
				hospitalName:"",
			},
			rules: {
				sort: [
					{ required: true, message: '请输入排名', trigger: 'blur' },
					{ required: true, validator: validatorSort, trigger: "blur", message: "请输入1-99正整数", },
					{ min: 1, max: 99, required: true, message: "请输入1-99正整数", trigger: "blur", }
				],

			},
		}
	},
	watch: {
		visible(newVal, oldVal) {
			if (newVal && this.id) this.getDetail()
		}
	},
	created() { },
	mounted() {
	},
	methods: {
		getDetail() {
			getDetail({ id: this.id }).then(({ res }) => {
				this.form = res
			})
		},
		submit() {
			this.$refs.insertForm.validate((valid) => {
				if (valid) {
					const parm = {
						id: this.form.id,
						doctorId: this.form.doctorId,
						sort: this.form.sort
					}
					editData(parm, this.timeStamp).then(({ res }) => {
						this.submitSuccess(res)
					})

				}
			})
		},
		submitSuccess(res) {
			this.$message({ ...this.commonConfig.$handleSuccess })
			this.updateVisible(false)
			this.$emit('submit')
		},
		updateVisible(flag) {
			this.visible = flag
		}
	}
}
</script>
<style scoped lang='scss'></style>
