<template>
  <el-dialog
    width="30%"
    :title="id === '' ? '修改提现状态' : '修改提现状态'"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    append-to-body
  >
    <el-form
      ref="stateForm"
      :model="form"
      label-position="right"
      label-width="120px"
      class="formBox"
    >
      <div class="mdrcc-info-title">基本信息</div>
      <el-row :gutter="24" type="flex" justify="start">
        <el-col>
          <el-form-item label="医生姓名" prop="name">
            <el-input v-model="form.withdrawAccountName" :disabled="true" />
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item label="所属医院" prop="userHospital">
            <el-input v-model="form.userHospital" :disabled="true" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24" type="flex" justify="start">
        <el-col>
          <el-form-item label="提现时间" prop="withdrawTime">
            <el-input v-model="form.withdrawTime" :disabled="true" />
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item label="提现金额(元)" prop="withdrawAmount">
            <el-input v-model="form.withdrawAmount" :disabled="true" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24" type="flex" justify="start">
        <el-col>
          <el-form-item label="提现状态" prop="name">
            <el-select v-model="form.withdrawStatus" placeholder="请选择">
              <el-option
                v-for="item in states"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-divider />
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit()">提 交</el-button>
      <el-button @click="updateVisible(false)">取 消</el-button>
    </span>
  </el-dialog>
</template>
<script>
import moment from "moment";
import generalTable from "@/components/Table";
import { changeEmbodyState } from '@/api/embodyAndRefund'
export default {
  name: "editState",
  props: {
    id: {
      type: String,
    },
  },
  data() {
    return {
      checkId: "",

      form: {
        id:'',
        withdrawAccountName: "",
        userHospital: "",
        withdrawTime: "",
        withdrawStatus: '',
        withdrawAmount: "",
        status: "",
        checkbox: [],
      },
      states: [
        {
          value: "1",
          label: "已到账",
        },
        {
          value: "0",
          label: "未到账",
        },
        {
          value: "2",
          label: "提现中",
        },
      ],
      visible: false,
      // timeStamp: '', //时间戳 用于修改数据或删除数据
    };
  },
  watch: {
    visible(newVal, oldVal) {
      if (newVal === true) {
        if (this.id) {
        }
      }
    },
  },
  created() {},
  mounted() {},
  methods: {
    submit() {
        let _this = this;
        let params = {
          id:_this.form.id,
          withdrawStatus:_this.form.withdrawStatus
        }
        console.table(params);
        changeEmbodyState(params).then(({ res }) => {
          _this.submitSuccess(res);
          console.table(res);
        }).catch((error)=> {
        });
    },
    submitSuccess(res) {
      this.$message({ ...this.commonConfig.$handleSuccess });
      this.updateVisible(false);
      this.$emit("submit");
    },
    updateVisible(val, item) {
      this.visible = val;
      if (val === false) {
        this.$refs.stateForm.resetFields();
      }
      if (item) {
        let record = {
          id: item.id,
          userId: item.userId,
          withdrawStatus:item.withdrawStatus,
          userHospital: item.userHospital,
          withdrawAccountName: item.withdrawAccountName,
          withdrawTime: moment(item.withdrawTime).format("YYYY-MM-DD"),
          withdrawAmount: item.withdrawAmount,
        };
        console.table(record);
        this.form = Object.assign({}, record);
      }
    },
  },
};
</script>
<style scoped lang="scss"></style>
