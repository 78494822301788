<!--
 * @Author: 358617935@qq.com 358617935@qq.com
 * @Date: 2023-05-26 11:27:20
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-06-08 08:34:36
 * @FilePath: /recovery_home_management_dev/src/views/financial/withdraw/detailDialog.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->

<template>
    <el-dialog width="40%" title="信息审核" :visible.sync="visible" :close-on-click-modal="false" :close-on-press-escape="false"
        :show-close="false" custom-class="basicDialog" append-to-body>
        <el-descriptions class="margin-top" title="" :column="2" size="medium">
            <el-descriptions-item label="医生姓名">{{detailData.doctorName === null ? '' :  detailData.doctorName}}</el-descriptions-item>
            <el-descriptions-item label="申请时间">{{moment(detailData.applyDate).format("yyyy-MM-DD HH:mm:ss")}}</el-descriptions-item>
            <el-descriptions-item label="持卡人姓名">{{detailData.cardHolderName}}</el-descriptions-item>
            <el-descriptions-item label="卡号">{{detailData.bankNo}}</el-descriptions-item>
            <el-descriptions-item label="预留手机号">{{detailData.phone}}</el-descriptions-item>
        </el-descriptions>

        <template slot="footer" class="dialog-footer">
            <el-button type="primary" @click="auditData(1)" v-loading.fullscreen.lock="fullscreenLoading" element-loading-text="审核中, 请稍后...">审核</el-button>
            <el-button type="danger" @click="refuse(0)">拒绝</el-button>
            <el-button @click="updateVisible(false)">取 消</el-button>
        </template>
    </el-dialog>
</template>
<script>
import { getDetail, auditData } from '@/api/withdraw'
export default {
    props: {
        id: {
            type: String,
            required: true
        },
        detailData: {
            type: Object
        },
    },
    data() {
        return {
            visible: false,
            detail: [], // 详情数据
            fullscreenLoading: false

        }
    },
    watch: {
        visible(newVal, oldVal) {
            if (newVal && this.id) this.getDetail()
        }
    },
    created() { },
    mounted() {
    },
    methods: {
        getDetail() {
            // getDetail(this.id).then(({ res }) => {
            //     this.detail = res
            // })
        },
        toCheck() {
            setTimeout(() => {
                this.$alert(
                    `<i class="el-icon-success" style="color: #6B9BF1; font-size: 100px;"></i>
                    <div style="color: #6B9BF1; font-weight: bold; font-size: 16px;">审核通过</div>`, 
                    '审核结果', 
                    {
                        dangerouslyUseHTMLString: true,
                        confirmButtonText: '返回',
                        center: true,
                        callback: action => {
                            // this.$message({
                            //     type: 'success',
                            //     message: "审核通过"
                            // });
                            this.fullscreenLoading = false;
                            this.$emit('audit');
                            this.updateVisible(false);
                        }
                    });
            }, 2000);
        },
        toUnCheck(reason) {
             setTimeout(() => {
                const error_reason = ""
                this.$alert(
                    ` <i class="el-icon-error" style="color: #D9001B;font-size: 100px;"></i>
                    <div style="color: #D9001B; font-weight: bold; font-size: 16px;margin: 10px 0;">审核不通过</div>
                    <div><span style="color: #D9001B;">*</span><span>操作原因：`+ reason + `</span></div>`, 
                    '审核结果', 
                    {
                        dangerouslyUseHTMLString: true,
                        customClass: 'check-tip',
                        confirmButtonText: '返回',
                        center: true,
                        callback: action => {
                            this.fullscreenLoading = false;
                            this.$emit('audit');
                            this.updateVisible(false);
                        }
                    });
            }, 2000);
        },
        refuse(){
            const { $delMsg, $delTitle, $messageBoxConfig } =
                this.commonConfig;
            this.$confirm("是否拒绝该申请？", $delTitle, $messageBoxConfig).then(() => {
                this.auditData(0);
            });
        },
        toRefuse(){
            this.$message({
                showClose: true,
                message: '拒绝成功',
                type: 'success'
            })
            this.$emit('audit');
            this.fullscreenLoading = false;
            this.updateVisible(false);
        },

        /**
         * @description: 操作  操作类型 0-拒绝 1-审核
         * @return {*}
         */
        auditData(type) {
            this.fullscreenLoading = true;
            const param = { id: this.id, operationType: type };
            const paramSubmit =  {...this.detailData, ...param};

            auditData(paramSubmit, this.timeStamp).then(({ res }) => {
                if(type == 1){
                    if(res.status){
                        this.toCheck();
                    }else{
                        this.toUnCheck(res.failReason);
                    }
                    
                }else{
                    this.toRefuse();
                }
            })
            .catch((error)=> {
                this.fullscreenLoading = false;
            })
        },
        updateVisible(flag) {
            this.visible = flag
        }
    }
}
</script>
<style scoped lang='scss'>
.dialog-footer {
    display: flex;
    justify-content: center;
}
</style>
