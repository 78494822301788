/*
 * @Author: your name
 * @Date: 2022-03-21 11:50:58
 * @LastEditTime: 2022-03-24 15:05:03
 * @LastEditors: Please set LastEditors
 * @Description: 方案分类
 * @FilePath: /recovery_home_management/src/api/classify.js
 */

/**
 * 页面: 康复指导分类
 * pageName: classify
 **/
import request from '@/utils/request'

/**
 * @description: 获取康复指导分类列表数据
 */
export function getClassifyList(data) {
    return request({
        url: '/admin/kfGuidanceType/list',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 新增康复指导分类数据
 */
export function insertData(data) {
    return request({
        url: '/admin/kfGuidanceType/add',
        method: 'POST',
        data,
        showLoading: true
    })
}

/**
 * @description: 修改康复指导分类数据
 */
export function editData(data, timeStamp) {
    return request({
        url: '/admin/kfGuidanceType/update',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}
            

/**
 * @description: 获取康复指导分类详情数据
 */
export function getDetail(data) {
    return request({
        url: '/admin/kfGuidanceType/info',
        method: 'GET',
        params: data,
        showLoading: true
    })
}
            
/**
 * @description: 删除康复指导分类数据
 */
    export function deleteData(data, timeStamp) {
    return request({
        url: '/admin/kfGuidanceType/delete',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}
        