<template>
  <el-dialog
    width="1000px"
    :title="title"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    append-to-body
  >
    <!-- <searchForm
      ref="mainSearch"
      :searchoptions="searchOptions"
      @search="search"
      :backStatus="false"
    /> -->
    <generalTable
      ref="mainTable"
      :checkIds="[]"
      :table-data="tableData"
      :config="tableConfig"
      :total="total"
      @updatePage="getList"
    >
      <template slot="sort" slot-scope="scope">
        <el-input-number
          v-model="scope.data.row.sort"
          :precision="0"
          :min="0"
          :max="10000"
        ></el-input-number>
      </template>
      <template slot="isOnSale" slot-scope="scope">
        {{ scope.data.row.isOnSale === 0 ? "否" : "是" }}
      </template>
    </generalTable>
    <span slot="footer" class="dialog-footer">
      <el-button
        type="primary"
        @click="submit()"
        :disabled="tableData.length == 0"
        >保 存</el-button
      >
      <el-button @click="visible = false">关 闭</el-button>
    </span>
  </el-dialog>
</template>
<script>
import request from "@/utils/request";
import searchForm from "@/components/SearchForm";
import generalTable from "@/components/Table";

export default {
  components: {
    searchForm,
    generalTable,
  },
  data() {
    return {
      visible: false,
      total: 0,
      title: '',
      paramObj: {
        doctorId: "",
        schemeId: "",
      },
      searchOptions: [
        {
          type: "input",
          placeholder: "请输入商品名称",
          key: "name",
          label: "商品名称",
          defaultVal: "",
        },
        {
          type: "input",
          placeholder: "请输入商品编码",
          key: "phoneNumber",
          label: "商品编码",
          defaultVal: "",
        },
      ],
      tableData: [],
      tableConfig: {
        id: "deviceListDialog",
        index: false,
        selection: this.selectMode,
        pagination: false,
        saveSelected: true,
        column: [
          {
            label: "商品名称",
            prop: "goodsName",
          },
          {
            label: "商品编码",
            prop: "goodsSn",
          },
          {
            label: "商品类型",
            prop: "goodsTypeName",
          },
          {
            label: "是否上架",
            slot: "isOnSale",
            prop: "isOnSale",
          },
          {
            label: "商品排序",
            slot: "sort",
            prop: "sort",
          },
        ],
      },
    };
  },
  methods: {
    open(doctorId, schemeId, title) {
      if (doctorId && schemeId) {
        this.visible = true;
      }
      this.paramObj.doctorId = doctorId;
      this.paramObj.schemeId = schemeId;
      if (title) {
        this.title = `${title}---绑定康复实体商品列表`;
      }

      if (this.$refs.mainSearch) {
        this.$refs.mainSearch.resetForm();
      } else {
        this.getList();
      }
    },
    submit() {
      let _this = this;
      const params = {
        schemeId: _this.paramObj.schemeId,
        doctorId: _this.paramObj.doctorId,
        goodsList: [],
      };
      _this.tableData.forEach((item) => {
        params.goodsList.push({
          goodsId: item.goodsId,
          sort: item.sort,
        });
      });
      console.table("params====", params);
      request({
          url: "kfGoodsExtra/modifySort",
          method: "POST",
          data: {
            ...params,
          },
          showLoading: true,
        }).then(({ res, timeStamp }) => {
          _this.visible = false;
          _this.$message({
              showClose: true,
              message: "修改成功",
              type: "success"
          });
      });
    },
    search(data) {
      Object.assign(this.paramObj, data);
      this.$refs.mainTable.handleCurrentChange(1);
    },
    /**
     * @description:
     * @param {*}
     * @return {*}
     */
    getList() {
      this.$nextTick(() => {
        const param = this.getParam();
        request({
          url: "kfGoodsExtra/list",
          method: "GET",
          params: {
            ...param
          },
          showLoading: true,
        }).then(({ res }) => {
          this.tableData = res || [];
          this.total = parseInt(this.tableData.length);
        });
      });
    },
    getParam() {
      const page = this.$refs.mainTable.getPage();
      const param = Object.assign({}, this.paramObj, page);
      this.paramObj = param;
      return param;
    },
  },
};
</script>
<style scoped lang="scss"></style>
