<!--
 * @Description: 菜单框
 * @Author: yzw
 * @Date: 2021-03-10 11:16:11
 * @LastEditTime: 2022-03-30 10:37:03
 * @LastEditors: Please set LastEditors
 * @Reference:
-->
<template>
    <el-container>
        <el-scrollbar class="menu-scroll">
            <el-main class="menu-main basic-main">
                <div class="menu-box">
                    <div v-for="item in roles" :key="item.id" class="menu-item">
                        <router-link :to="item.path">
                            <span class="menu-item-span">{{ item.meta.title }}</span>
                            <!-- <el-image
                class="menu-item-img"
                :src="require(`@/assets/btnImg/${item.meta.icon}.png`)"
                fit="contain"
              /> -->
                        </router-link>
                    </div>
                </div>
            </el-main>
        </el-scrollbar>
    </el-container>
</template>

<script>
export default {
    name: "MenuBox",
    props: {
        roles: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {};
    },
};
</script>

<style lang='scss' scoped>
@import "~@/styles/variables.scss";
.menu-main {
    display: flex;
    align-items: center;
    justify-content: center;
}
.menu-scroll {
    width: 100%;
    height: 100%;
    background: $white;
}
.menu-box {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    width: 89%;
    margin: 0 auto;
    &::after {
        content: "";
        flex: 1;
    }
}
.menu-item {
    width: calc(calc(100% - 168px) / 6);
    background-color: #67c23a;
    margin: 15px;
    border-radius: 5px;
    ::after {
        content: "";
        display: block;
        padding-bottom: 40%;
    }
    .menu-item-span {
        transition: box-shadow 0.2s;
        color: white;
        align-items: center;
        justify-content: center;
        display:flex;
        font-size: 20px;
        font-weight: bold;
        margin: 20px;
        text-align: center;
    }
    // &-img {
    //     transition: box-shadow 0.2s;
    //     border-radius: 5px;
    //     &:hover {
    //         box-shadow: 8px 6px 26px 1px rgba(108, 108, 108, 0.41);
    //     }
    // }
}

</style>
