
<!--
* @Description: 患者健康数据导入页面
* @Reference:
-->

<template>
    <el-container>
        <el-header height="auto">
            <searchForm ref="mainSearch" :searchoptions="searchOptions" @search="search" :backStatus="backStatus" />
        </el-header>
        <el-main class="basic-main">
            <el-row class="table-btn-group">
                <!-- <el-upload class="upload-demo" action="#" :http-request="toImport" :before-upload="beforeUpload"
                    :on-remove="handleRemove" :before-remove="beforeRemove" multiple :file-list="form.files">
                    <el-button type="primary" plain>导入</el-button>
                </el-upload> -->
                <input style="display:none" type="file" accept=".xls, .xlsx" @change="toImport($event)" ref="ModelInput" />
                <el-button type="primary" plain @click="setModelInput">导入</el-button>
                <el-button type="primary" plain @click="toDownload">模板下载</el-button>
            </el-row>
            <generalTable ref="mainTable" :table-data="tableData" :config="tableConfig" :total="total" @updatePage="getList"
                @onClickRow="onClickRow">
                <template slot="handle" slot-scope="scope">
                    <el-button type="text" class="delBtn" @click="toDel(scope)">删除</el-button>
                </template>
            </generalTable>
        </el-main>
    </el-container>
</template>

<script>
import { getList, deleteData, templateDownload, importData } from '@/api/healthData'
import searchForm from '@/components/SearchForm'
import generalTable from '@/components/Table'



export default {
    name: 'HealthData',
    components: {
        searchForm,
        generalTable,
    },
    data() {
        return {
            tableData: [],
            searchOptions: [
                {
                    type: 'input',
                    placeholder: '请输入...',
                    key: 'userName',
                    label: '患者姓名',
                    defaultVal: ''
                },
                {
                    type: 'input',
                    placeholder: '请输入...',
                    key: 'phoneNum',
                    label: '手机号',
                    defaultVal: ''
                },
            ],
            tableConfig: {
                id: 'healthData',
                align: 'center', // 不必填 默认为center
                selection: 'single', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: true, // 不必填 分页 默认为false
                column: [
                    {
                        label: '患者姓名', // 必填
                        prop: 'userName',// 必填
                        width: '120' // 不必填
                        // formatter: (row, column) => {
                        //     return row.archivesInfoId.name
                        // }
                    },
                    {
                        label: '手机号', // 必填
                        prop: 'phoneNum' // 必填
                    },
                    {
                        label: '记录时间', // 必填
                        prop: 'recordTime' // 必填
                    },
                    {
                        label: '心率（bpm）', // 必填
                        prop: 'bmp' // 必填
                    },
                    {
                        label: '卡路里（kcal）', // 必填
                        prop: 'kcal' // 必填
                    },
                    {
                        label: '步数（步）', // 必填
                        prop: 'step' // 必填
                    },
                    {
                        label: '精神压力', // 必填
                        prop: 'stress' // 必填
                    },
                    {
                        label: '站立时长', // 必填
                        prop: 'standingTime' // 必填
                    },
                    {
                        label: '坐立时长', // 必填
                        prop: 'sitTime' // 必填
                    },
                    {
                        label: '血氧（%）', // 必填
                        prop: 'bloodOxygen' // 必填
                    },
                    {
                        label: '收缩压（mmHg）', // 必填
                        prop: 'sbp' // 必填
                    },
                    {
                        label: '舒张压（mmHg）', // 必填
                        prop: 'dbp' // 必填
                    },
                    {

                        slot: 'handle', // 不必填 单列插槽
                        label: '操作', // 必填
                        fixed: 'right', // 不必填 固定列  left right
                        width: '120' // 不必填
                    }
                ]
            },
            paramObj: null, // 筛选项
            total: 0, // 总条数
            checkId: '', // 单条数据id 用于详情或编辑请求数据用
            backStatus: true, //返回按钮的隐藏显示
            timeStamp: '', //时间戳 用于修改数据或删除数据
        }
    },
    created() {
        this.getList()
    },
    mounted: function () { },
    methods: {
        search(data) {
            Object.assign(this.paramObj, data)
            this.$refs.mainTable.handleCurrentChange(1)
        },
        getList() {
            this.$nextTick(() => {
                const param = this.getParam()
                getList(param).then(({ res, timeStamp }) => {
                    this.tableData = res.records
                    this.total = res.total
                    this.timeStamp = timeStamp
                })
            })
        },
        toInsert() {
            this.checkId = ''
            this.$refs.insert.updateVisible(true)
        },
        toDetail(scope) {
            this.checkId = scope.data.row.id
            this.$refs.detail.updateVisible(true)
        },
        toEdit(scope) {
            this.checkId = scope.data.row.id
            this.$refs.insert.updateVisible(true)
        },
        toDel(scope) {
            const { $delMsg, $delTitle, $messageBoxConfig, $handleSuccess } = this.commonConfig
            this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
                const param = { id: scope.data.row.id }
                deleteData(param, this.timeStamp).then(({ res }) => {
                    this.$message({ ...$handleSuccess });
                    this.getList();
                })
            })
        },
        /**
         * @description: 读取模板文件
         */
        toImport(event) {
            let _this = this;
            let file = event.target.files[0];
            console.log('file=', file)
            // let reader = new FileReader();
            // reader.readAsDataURL(file);

            let formData = new FormData();
            formData.append("file", file);
            console.log("formData", formData.get('file'));
            importData(formData).then(({ res }) => {
              this.$message({
                message: '导入成功',
                type: 'success'
              })
              _this.getList();
            }).finally(()=>{
              // 清空file文件
              _this.$refs.ModelInput.value = '';
            });

        },
        toDownload() {
            templateDownload().then(({ res, timeStamp }) => {
                const dom = document.createElement('a')
                dom.style.display = 'none'
                const blob = new Blob([res], {
                    type: ""
                })
                const url = window.URL.createObjectURL(blob)
                dom.href = url
                // download后面必须设置为文件全名，即文件名+.+文件类型，否则当文件名中有.时，会导致文件类型出错
                dom.setAttribute('download', `${"患者健康数据导入模板"}.${"xlsx"}`)
                document.body.appendChild(dom)
                dom.click()
            })
        },
        onClickRow(row) {
            console.log('点击行:', row)
        },
        getParam() {
            const paramObj = this.$refs.mainSearch.packageData()
            const page = this.$refs.mainTable.getPage()
            const param = Object.assign({}, this.paramObj, page, paramObj)
            this.paramObj = param
            return param
        },
        setModelInput() {
            this.$refs.ModelInput.click();
        },
        /**上传之前触发的函数
         * @description: 上传之前先校验文件类型，如果全局配置的commonConfig中没有包含该类型的文件，则阻止上传，并提示文件类型错误
         * @param {*} file 要上传的文件名
         * @return {*}
         */
        // beforeUpload(file) {
        //     const {
        //         $docTypes,
        //         $imgTypes,
        //         $audioTypes,
        //         $videoTypes
        //     } = this.commonConfig;
        //     const allTypes = [
        //         ...$docTypes,
        //         ...$imgTypes,
        //         ...$audioTypes,
        //         ...$videoTypes
        //     ];
        //     // 用.切分文件名，最后一个.后面就是文件类型
        //     const names = file.name.split(".");
        //     const fileType = names[names.length - 1]; // 文件类型
        //     // 如果文件、图片、音频、视频文件类型都不包含该文件类型，则进行消息提示，并阻止上传
        //     if (!allTypes.includes(fileType)) {
        //         this.$message({
        //             type: "warning",
        //             message: "文件格式不正确！"
        //         });
        //         return false;
        //     } else {
        //         return true;
        //     }
        // },
    }
}
</script>

<style lang='scss' scoped>
@import "~@/styles/variables.scss";
</style>

