<template>
  <el-dialog
    width="70%"
    :title="id === '' ? '新增Banner管理' : '修改Banner管理'"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    append-to-body
  >
    <el-form
      ref="insertForm"
      :model="form"
      label-position="right"
      label-width="120px"
      class="formBox"
      :rules="rules"
    >
      <div class="mdrcc-info-title">基本信息</div>
      <el-row :gutter="10" type="flex" justify="start">
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="名称" prop="name">
            <el-input
              v-model="form.name"
              placeholder="请输入..."
              clearable
              maxlength="20"
            />
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="描述" prop="content">
            <el-input
              v-model="form.content"
              placeholder="请输入..."
              clearable
              maxlength="20"
            />
          </el-form-item>
        </el-col>
        
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="平台" prop="platform">
            <el-select
              v-model="form.platform"
              clearable
              placeholder="请选择"
              :filterable="true"
            >
              <el-option
                v-for="option in options"
                :key="option.value"
                :label="option.label"
                :value="option.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <!-- <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="素材" prop="imageUrl">
            <el-input
              v-model="form.imageUrl"
              placeholder="请输入..."
              clearable
              maxlength="20"
            />
          </el-form-item>
        </el-col> -->
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="跳转路径" prop="jumpUrl">
            <el-input
              v-model="form.jumpUrl"
              placeholder="请输入..."
              clearable
              maxlength="200"
            />
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="跳转类型" prop="jumpType">
            <el-input
              v-model="form.jumpType"
              placeholder="请输入..."
              clearable
              maxlength="20"
            />
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="排序" prop="sort">
            <el-input
              v-model="form.sort"
              placeholder="请输入..."
              clearable
              maxlength="20"
            />
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="状态" prop="status">
            <el-switch
              v-model="form.status"
              name="status"
              active-text="启用"
              inactive-text="禁用"
              inactive-value="1"
              active-value="0"
            />
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="8" :xl="12">
          <el-form-item label="素材图片" prop="imageUrl">
              <div class="imgList">
                  <div v-for="(url, index) in form.photoList" class="defalutImg " :key="url+index">
                      <div class="remove" @click="removePhoto(index)"><i class="el-icon-error"></i></div>
                      <el-image style="width: 150px; height: 150px" :src="commonConfig.$filePublicUrl + url"
                          fit="contain"></el-image>
                  </div>
              </div>
              <div class="img">
                  <div :src="form.imageUrl" @click="setLogoClick" class="defalutImg">
                      <i class="el-icon-plus"></i>
                  </div>
              </div>
              <input type="file" name="form.imageUrl" accept="image/gif,image/jpeg,image/jpg,image/png"
                  style="display: none" multiple="multiple" @change="changeLogoImage($event)"
                  ref="logoInput" />
          </el-form-item>
      </el-col>
      </el-row>
      <el-divider />
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit()">提 交</el-button>
      <el-button @click="updateVisible(false)">取 消</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { insertData, editData, getDetail } from "@/api/bannerManage";
import generalTable from "@/components/Table";
import { uploadPrivateFile, uploadPublicFile } from "@/utils/uploadQiniu.js";

export default {
  props: {
    id: {
      type: String,
    },
    timeStamp: {
      type: String,
    },
  },
  data() {
    return {
      checkId: "",
      tableConfig: {
        align: "center", // 不必填 默认为center
        selection: "normal", // 不必填 多选: multiple  单选: single  默认为 normal
        index: true, // 不必填 序号
        pagination: false, // 不必填 分页 默认为false
      },
      rules: {
        name: [
          { required: true, message: "请输入名称", trigger: "blur" },
          { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
        ],
        platform: [
          { required: true, message: "请选择平台", trigger: "blur" },
          
        ],
       
       

      },
      options: [
        {
          value: "1",
          label: "医生端",
        },
        {
          value: "2",
          label: "患者端",
        },
        {
          value: "0",
          label: "通用",
        },
        {
          value: "3",
          label: "小程序患者端",
        },
        
      ],
     
      form: {
        checkbox: [],
        photoList: [], // 图片列表
        imageUrl: "", //图片
      },
      total: 0,
      visible: false,
      // timeStamp: '', //时间戳 用于修改数据或删除数据
    };
  },
  watch: {
    visible(newVal, oldVal) {
      if (newVal === true) {
        if (this.id) {
          this.getDetail();
        }
        //...todo
      }
    },
  },
  created() {},
  mounted() {},
  methods: {
    submit() {
      this.$refs.insertForm.validate((valid) => {
        if (valid) {
          const parm = this.form;
          if (this.id === "") {
            insertData(parm).then(({ res }) => {
              this.submitSuccess(res);
            });
          } else {
            editData(parm, this.timeStamp).then(({ res }) => {
              this.submitSuccess(res);
            });
          }
        }
      });
    },
    submitSuccess(res) {
      this.$message({ ...this.commonConfig.$handleSuccess });
      this.updateVisible(false);
      this.$emit("submit");
    },
    getDetail() {
      getDetail({ id: this.id }).then(({ res, timeStamp }) => {
        this.form = res;
        this.form.photoList = this.form.imageUrl.split(",")
        // this.timeStamp = timeStamp
      });
    },
    toDel(scope) {
      const { $delMsg, $delTitle, $messageBoxConfig } = this.commonConfig;
      this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
        const param = { id: scope.data.row.id };
        deleteData(param, this.timeStamp).then(({ res }) => {
          this.$message({ ...this.commonConfig.$handleSuccess });
        });
      });
    },
    removePhoto(index) {
                const { $delMsg, $delTitle, $messageBoxConfig, $handleSuccess } = this.commonConfig
                this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
                    this.form.photoList.splice(index, 1)
                    this.form.imageUrl = this.form.photoList.join()
                })
            },

            setLogoClick() {
                this.$refs.logoInput.click();
            },

            uploadPublicFile(file) {
                return new Promise((resolve) => {
                    uploadPublicFile(file)
                        .then((success) => {
                            console.log("upload success------", success)
                            this.form.photoList.push(success)
                            resolve(success)
                        })
                        .catch((handleError) => {
                            console.log("handleError====", handleError);
                        });
                })
            },

            /**
            * @description: 上传图片
            * @param {*} event
            * @return {*}
            */
            async changeLogoImage(event) {
                console.log("files------", event.target.files)
                let file = event.target.files[0];
                const thiz = this
                for (let file of event.target.files) {
                    await this.uploadPublicFile(file)
                }
                this.form.imageUrl = this.form.photoList.join()
                // thiz.form.imageUrl = success
                // uploadPublicFile(file)
                //     .then((success) => {
                //         console.log("success====", success);
                //         thiz.form.imageUrl = success
                //     })
                //     .catch((handleError) => {
                //         console.log("handleError====", handleError);
                //     });

            },
    updateVisible(val) {
      this.visible = val;
      if (val === false) {
        this.$refs.insertForm.resetFields();
        this.form = {
          // imageUrl: "",
          checkbox: [],
        photoList: [], // 图片列表
        imageUrl: "", //图片
                    }
      }
    },
  },
};
</script>
<style scoped lang='scss'>
  @import "~@/styles/variables.scss";

  .imgBox {
      width: 150px;
      height: 150px;
      object-fit: contain;
      border: 1px solid #333;
  }

  .imgList {
      display: flex;
      column-gap: 10px;
      flex-wrap: wrap;
      row-gap: 10px;
      margin: 10px 0;
  }

  .remove {
      position: absolute;
      right: -0.5em;
      top: -0.5em;
      line-height: 1;
      font-size: 16px;
      color: #aaaaaa;
      background-color: #ffffff;
      cursor: pointer;
  }

  .defalutImg {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 150px;
      height: 150px;
      border: 1px solid #333;
      border-radius: 4px;
      cursor: pointer;
      position: relative;
  }
</style>
