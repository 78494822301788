<template>
  <MenuBox :roles="secondMenu" />
</template>

<script>
import { mapGetters } from "vuex";
import MenuBox from "@/components/MenuBox";
export default {
  name: "System",
  components: { MenuBox },
  data() {
    return {
      secondMenu: [],
    };
  },
  computed: {
    ...mapGetters(["roles"]),
  },
  created() {
    const { roles } = this.$store.getters;
    for (let i = 0; i < roles.length; i++) {
      const element = roles[i];
      if (element.name === "operationMain") {
        this.secondMenu = element.children.filter(
          (obj) => obj.hidden === false && element.redirect !== obj.path
        );
      }
    }
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
