/*
 * @LastEditors: Please set LastEditors
 * @Author: yzw
 */
import router from './router'
import store from './store'
import NProgress from 'nprogress' // progress bar
// import 'nprogress/nprogress.css' // progress bar style
import { getToken } from '@/utils/auth' // get token from cookie

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/login'] // no redirect whitelist

function logout() {
	store.dispatch('logout')
}

router.beforeEach(async (to, from, next) => {
	NProgress.start()
	// clearPending()
	if (getToken()) {
		if (to.path === '/login') {
			next({ path: '/' })
			NProgress.done()
		} else {
			const roles = store.getters.roles
			const toDeepth = to.path.split('/').length
			const fromDeepth = from.path.split('/').length
			if (toDeepth < fromDeepth) {
			  from.meta.keepAlive = false
			  to.meta.keepAlive = true
			}

			const hasGetUserInfo = store.getters.name || "admin"
			// console.log('-----------------------', hasGetUserInfo)

			// if (roles.length > 2) {
			if (hasGetUserInfo) {
				next()
			} else {
			// 	store.dispatch('getInfo').then((res) => {
			// 		console.log('ROUTER-----------------------')
			// 		//   store.dispatch("getUnReadMsgList");
			// 		next(to.fullPath)
			// 	}).catch(e => {
			// 		store.dispatch('resetToken')
			// 		console.error('当前用户无权限')
					logout()
			// 		next("/login");
			// 	})
			}
		}
	} else {
		if (whiteList.indexOf(to.path) !== -1) {
			next()
		} else {
			next('/login')
			NProgress.done()
		}
	}
})

router.afterEach(() => {
	// finish progress bar
	NProgress.done()
})
