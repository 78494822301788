<template>
  <el-dialog width="1000px" title="选择视频" :visible.sync="visible" :close-on-click-modal="false"
      :close-on-press-escape="false" append-to-body>
      <searchForm ref="mainSearch" :searchoptions="searchOptions" @search="search" :backStatus="false" />
      <el-table ref="mainTable" border stripe row-contextmenu size="mini" header-row-class-name="table-header" :row-key="getRowKey"
      :data="tableData" style="width: 100%" @row-click="handleRowClick">
          <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>
          <el-table-column label="视频名称" prop="videoName" align="center"></el-table-column>      
      </el-table>
      <br>
     
      <span slot="footer" class="dialog-footer">
              <el-pagination background layout="total, sizes, prev, pager, next" style="text-align: right;"
              :current-page.sync="page.current" :page-size.sync="page.size" :total="total"
              :page-sizes="[10, 20, 30, 40, 50, 100]" @current-change="getList()" @size-change="handlePageSizeChange">
          </el-pagination>
          <!-- <el-button type="primary" @click="submit()" :disabled="checkLength > 10"> {{checkLength > 10 ? '只能选择10个' : '选 择'}}</el-button> -->
          <el-button @click="visible = false">取 消</el-button>
      </span>
  </el-dialog>
</template>
<script>
  import searchForm from '@/components/SearchForm'
  import generalTable from '@/components/Table'
  import { getAllHospitalList} from '@/api/embodyAndRefund';
  import {getGeneralVideoList } from "@/api/generalVideoManagement"
  export default {
      components: {
          searchForm, generalTable
      },
      props: {
          defaultCheckIds: {
              type: Array,
              default: () => { return [] }
          },
          selectMode: {
              type: String,
              default: () => { return 'multiple' }
          },
          id: {
              type: String,
              default: () => { return '' }
          },
          index: {
              type: Number,
              default: () => { return null}
          },
      },
      data() {
          return {
              visible: false,
              page: { current: 1, size: 20, },
              total: 0,
              paramObj: {},
              searchOptions:  [{
                  type: "input",
                  placeholder: "请输入视频名称",
                  key: "videoName",
                  label: "视频名称",
                  defaultVal: "",
                },
              ],
              checkIds: [],
              checkRow: {},
              checkLength: 0,
              tableData: [],
              tableConfig: {
                  id: 'project-child',
                  index: false,
                  selection: 'single',
                  saveSelected: false,
                  column: [
                  {
                  label: '视频名称',
                  prop: 'videoName',
                  }
              ]}
          };
      },
      watch: {
        visible(newVal, oldVal) {
          if (!newVal) {
          };
        },
      },
      created() {
        console.log('this.selectMode===',this.selectMode);
        this.getList();
      },
      mounted() { },
      methods: {
          getRowKey(row) {
            return row.id;
          },
          open() {
              this.visible = true;
              this.checkIds = this.defaultCheckIds;
              console.log(' this.checkIds====', this.checkIds);
              this.checkLength = this.defaultCheckIds.length;
              if(this.$refs.mainSearch) {
                  this.$refs.mainSearch.resetForm();
              }else{
                  this.getList();
              }
          },
          submit(row) {
              console.log('submit -----------> ', row);
              this.checkIds = [];
              this.$emit("submitVideo", {row: row, id: this.id, index: this.index});
              this.visible = false; 
          },
          search(data) {
              Object.assign(this.paramObj, data);
              this.page.current = 1;
              this.getList();
          },
          updateSelectedIds(checkIds){
            this.checkIds = checkIds;
            console.log('this.checkIds', this.checkIds)
          },
          /**
           * @description:
           * @param {*}
           * @return {*}
           */
          getList() {
              this.$nextTick(() => {
                getGeneralVideoList({
                      ...this.paramObj,
                      ...this.page,
                  }).then(({ res, timeStamp }) => {
                      this.tableData = res.records;
                      this.total = parseInt(res.total)
                  });
              });
          },
          getParam() {
              const paramObj = this.$refs.mainSearch.packageData();
              const page = this.$refs.mainTable.getPage();
              const param = Object.assign({}, this.paramObj, page, paramObj);
              this.paramObj = param;
              return param;
          },
          handlePageSizeChange() {
              this.page.current = 1;
              this.getList();
          },
          handleRowClick(row = {}, column, event) {
            this.submit(row)
          }
      },
  };
</script>
<style scoped lang='scss'>
</style>
