
<!--
* @Description: 用户购买服务训练信息导表功能
* @Reference:
-->

<template>
  <el-container>
    <el-header height="auto">
      <searchForm
        @keyup.enter.native="search"
        ref="mainSearch"
        :searchoptions="searchOptions"
        @search="search"
        :backStatus="backStatus"
      />
    </el-header>
    <el-main class="basic-main">
      <el-row class="table-btn-group">
        <el-button type="primary" plain @click="toExport">导出</el-button>
      </el-row>
      <generalTable
        ref="mainTable"
        :table-data="tableData"
        :config="tableConfig"
        :total="total"
        @updatePage="getList"
      >
      </generalTable>
    </el-main>
  </el-container>
</template>

  <script>
import { getList } from '@/api/rehabilitationService';
import searchForm from '@/components/SearchForm';
import generalTable from '@/components/Table';
import { getDict } from '@/api/common';
import moment from 'moment';
import { exportExcel } from "@/utils/common";

export default {
  name: 'DoctorWorkload',
  components: {
    searchForm,
    generalTable,
  },
  data() {
    return {
      tableData: [],
      searchOptions: [
        {
          type: 'input',
          placeholder: '请输入订单号',
          key: 'orderNo',
          label: '订单号',
        },
        {
          type: 'input',
          placeholder: '请输入医生姓名',
          key: 'doctorName',
          label: '医生姓名',
        },
        {
          type: 'input',
          placeholder: '请输入患者姓名',
          key: 'patientName',
          label: '患者姓名',
        },
        {
          type: 'datetimerange',
          placeholder: '请输入...',
          key: 'service',
          label: '服务时间',
          defaultVal: [moment().subtract(1, 'months').format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
        },
      ],
      tableConfig: {
        id: 'doctorWorkload',
        align: 'center', // 不必填 默认为center
        selection: 'single', // 不必填 多选: multiple  单选: single  默认为 normal
        index: true, // 不必填 序号
        pagination: true, // 不必填 分页 默认为false
        column: [
          {
            label: '订单号', // 必填
            prop: 'orderId', // 必填
          },
          {
            label: '服务开始时间', // 必填
            prop: 'serviceStartTime', // 必填
            formatter: (row, column) => {
              if (row.serviceStartTime == null) {
                return '--';
              } else {
                return this.moment(row.serviceStartTime).format(
                  'YYYY-MM-DD HH:mm:ss',
                );
              }
            },
          },
          {
            label: '服务结束时间', // 必填
            prop: 'serviceEndTime', // 必填
            formatter: (row, column) => {
              if (row.serviceEndTime == null) {
                return '--';
              } else {
                return this.moment(row.serviceEndTime).format(
                  'YYYY-MM-DD HH:mm:ss',
                );
              }
            },
          },
          {
            label: '患者姓名', // 必填
            prop: 'patientName', // 必填
          },
          {
            label: '医生姓名', // 必填
            prop: 'doctorName', // 必填
          },
          {
            label: '评估等级', // 必填
            children: [
              {
                label: '上肢',
                prop: 'arm',
              },
              {
                label: '手',
                prop: 'hand',
              },
              {
                label: '下肢',
                prop: 'leg',
              },
              {
                label: '平衡-坐',
                prop: 'sit',
              },
              {
                label: '平衡-站',
                prop: 'stand',
              },
              {
                label: '吞咽',
                prop: 'swallow',
              },
            ],
          },
          {
            label: '方案信息',
            children: [
              {
                label: '方案名称',
                prop: 'name',
              },
              {
                label: '上肢/手/下肢/平衡/吞咽',
                prop: 'type',
              },
              {
                label: '自定义/标准',
                prop: 'standard',
              },
              // {
              //   label: '方案分类',
              //   children: [
              //     {
              //       label: '上肢/手/下肢/平衡/吞咽',
              //       prop: 'type',
              //     },
              //     {
              //       label: '自定义/标准',
              //       prop: 'standard',
              //     }
              //   ]
              // },
              {
                label: '训练时长',
                prop: 'trainingDuration',
              },
              {
                label: '训练次数',
                prop: 'trainingTimes',
              },
              {
                label: '训练周期',
                prop: 'trainingCycle',
              }
            ]
          },
          {
            label: '方案使用情况',
            children: [
              {
                label: '方案打开次数',
                prop: 'openTimes',
              },
              {
                label: '方案完成观看次数',
                prop: 'watchTimes',
              },
              {
                label: '上传训练视频次数',
                prop: 'uploadTimes',
              }
            ]
          },
          {
            label: '用户数据',
            children: [
              {
                label: '打开软件总次数',
                prop: 'totalOpenTimes',
              },
              {
                label: '使用软件总时长',
                prop: 'totalUseDuration',
              }
            ]
          }

          // {
          //   label: '推广来源（具体功能）', // 必填
          //   prop: 'shareSource', // 必填
          // },
          // {
          //   label: '推荐时间', // 必填
          //   prop: 'createDate', // 必填

          //   formatter: (row, column) => {
          //     if (row.createDate == null) {
          //       return '--';
          //     } else {
          //       return this.moment(row.createDate).format(
          //         'YYYY-MM-DD HH:mm:ss',
          //       );
          //     }
          //   },
          // },
        ],
      },
      paramObj: null, // 筛选项
      total: 0, // 总条数

      checkId: '', // 单条数据id 用于详情或编辑请求数据用
      backStatus: true, //返回按钮的隐藏显示
      timeStamp: '', //时间戳 用于修改数据或删除数据
    };
  },
  created() {
    this.getList();
  },
  mounted: function () {},
  methods: {
    toExport() {
      const param = this.getParam();
      let _this = this;
      let url = process.env.VUE_APP_BASE_API + "/admin/kfAssessment/KfServiceSummaryExport";
      exportExcel(url,{},param,'用户购买服务训练信息导表','xlsx').then(res=>{
      }).catch(function (error) {
          _this.$message({
                message: error.message || '导出失败',
                duration: 5000,
                type: "warning",
          });
      });
    },
    search(data) {
      Object.assign(this.paramObj, data);
      this.$refs.mainTable.handleCurrentChange(1);
    },
    getList(params) {
      this.$nextTick(() => {
        let param = Object.assign({}, this.getParam(), params);
        getList(param).then(({ res, timeStamp }) => {
          this.tableData = res.records;
          this.timeStamp = timeStamp;
          this.total = parseInt(res.total);
        });
      });
    },

    getParam() {
      const paramObj = this.$refs.mainSearch.packageData();

      const page = this.$refs.mainTable.getPage();
      const param = Object.assign({}, this.paramObj, page, paramObj);
      this.paramObj = param;
      console.log('param', param);
      return param;
    },
  },
};
</script>

  <style lang='scss' scoped>
@import '~@/styles/variables.scss';
</style>

