
<!--
* @Description: 康复服务订单管理
* @Reference:
-->

<template>
  <el-container>
      <el-header height="auto">
          <searchForm ref="mainSearch" :searchoptions="searchOptions" exportStatus @search="search" :backStatus="backStatus"  @exportFun="toExport"/>
      </el-header>
      <el-main class="basic-main">
          <generalTable ref="mainTable" :table-data="tableData" :config="tableConfig" :total="total" @updatePage="recoveryList">
            <template slot="handle" slot-scope="scope">
                <el-button type="text" @click="gotoIndex(scope)">服务记录</el-button>
            </template>
          </generalTable>
      </el-main>
  </el-container>
  </template>
  
  <script>
  
  import { recoveryList } from '@/api/order'
  import searchForm from '@/components/SearchForm'
  import generalTable from '@/components/Table'
  import { getDict } from '@/api/common'
  import { exportExcel } from "@/utils/common";
  import moment from "moment";
  export default {
      name: 'DoctorWorkload',
      components: {
          searchForm,
          generalTable,
      },
      data() {
          return {
              tableData: [],
              searchOptions: [
              {
                      type: 'input',
                      placeholder: '请输入...',
                      key: 'orderNo',
                      label: '订单编号',
                      defaultVal: ''
                  },
                  {
                      type: 'input',
                      placeholder: '请输入...',
                      key: 'patName',
                      label: '患者姓名',
                      defaultVal: ''
                  },
                  {
                      type: 'input',
                      placeholder: '请输入...',
                      key: 'docName',
                      label: '服务医生',
                      defaultVal: ''
                  },
                 
                  {
                      type: 'daterange',
                      startPlaceholder: '开始日期',
                      endPlaceholder: '结束日期',
                      key: 'createTime',
                      label: '下单时间',
                      defaultVal: []
                  },
                  {
                    type: "select",
                    placeholder: "请选择",
                    key: "productType",
                    filterable: true,
                    label: "订单类型",
                    options: [
                      {value: '1', label: '视频问诊+标准服务'},
                      {value: '2', label: '视频问诊+定制服务'},
                      {value: '3', label: '图文问诊+标准服务'},
                      {value: '4', label: '图文问诊+定制服务'},
                      {value: '5', label: '线下服务'},
                    ],
                    defaultVal: [],
                  },
                 
                  {
                    type: "select",
                    placeholder: "请选择",
                    key: "status",
                    filterable: true,
                    label: "订单状态",
                    options: [],
                    defaultVal: [],
                  },
              ],
              tableConfig: {
                  id: 'doctorWorkload',
                  align: 'center', // 不必填 默认为center
                  selection: 'single', // 不必填 多选: multiple  单选: single  默认为 normal
                  index: true, // 不必填 序号
                  pagination: true, // 不必填 分页 默认为false
                  column: [
                  {
                    label: '订单编号', // 必填
                    prop: 'orderNo', // 必填
                    width: "160",
                  },
                  {
                    label: '订单类型', // 必填
                    prop: 'ktName', // 必填
                    width: "160",
                  },
                  
                  {
                    label: '患者姓名', // 必填
                    prop: 'patName' // 必填
                  },
                  {
                    label: '患者登录人姓名', // 必填
                    prop: 'loginName' // 必填
                  },
                  // {
                  //   label: '用户电话', // 必填
                  //   prop: 'mobileNumber' // 必填
                  // },
                  {
                    label: '服务医生', // 必填
                    prop: 'docName' // 必填
                },
                {
                    label: '医生推荐码', // 必填
                    prop: 'recommendationCode', // 必填
                    width: "140",
                },
                  {
                    label: '下单时间', // 必填
                    prop: 'createTime', // 必填
                    width: "140",
                    formatter: (row, column) => {
                        return this.moment(row.createTime).format("YYYY-MM-DD HH:mm:ss")
                    }
                  },
                  {
                    label: '订单支付时间', // 必填
                    prop: 'payTime', // 必填
                    width: "140",
                    formatter: (row, column) => {
                        
                    if(row.payTime==null){
                      return "--"
                    }else{
                        return this.moment(row.payTime).format("YYYY-MM-DD HH:mm:ss")
                      }
                    }
                  },
                 
                {
                    label: '订单状态', // 必填
                    prop: 'recoveryLabel' // 必填
                },
                {
                    label: '支付渠道', // 必填
                    prop: 'payWayLabel', // 必填
                },
                {
                    label: '订单总金额(元)', // 必填
                    prop: 'presentTotal', // 必填
                    formatter: (records) => {
                        return records.presentTotal/100
                    },
                    width: "105", // 不必填
                },
                {
                    label: '订单总次数', // 必填
                    prop: 'totalNumber', // 必填
                    width: "90", // 不必填 // 必填
                },
                {
                    label: '已服务次数', // 必填
                    prop: 'recoveryNum', // 必填
                    width: "90", // 不必填 // 必填
                  },
                  {
                    label: '剩余服务次数', // 必填
                    prop: 'remainingNum', // 必填
                    width: "100", // 不必填
                  }, {
                  slot: "handle", // 不必填 单列插槽
                  label: "操作", // 必填
                  fixed: "right", // 不必填 固定列  left right
                  width: "180", // 不必填
                },
                 
                  ]
              },
              paramObj: null, // 筛选项
              total: 0, // 总条数
              checkId: '', // 单条数据id 用于详情或编辑请求数据用
              backStatus: true, //返回按钮的隐藏显示
              timeStamp: '', //时间戳 用于修改数据或删除数据
             
  
          }
      },
      created() {
          this.recoveryList();
          this.getDict();
          
      },
      mounted: function() {},
      methods: {
          search(data) {
              Object.assign(this.paramObj, data)
              this.$refs.mainTable.handleCurrentChange(1)
          },
          recoveryList() {
              this.$nextTick(() => {
                  const param = this.getParam()
                  console.log("param",param);
                  recoveryList(param).then(({ res, timeStamp }) => {
                      this.tableData = res.records
                      this.timeStamp = timeStamp
                      this.total = parseInt(res.total)
                  })
              })
          },
         
         
         
          getParam() {
              const paramObj = this.$refs.mainSearch.packageData()
            
              const page = this.$refs.mainTable.getPage()
              const param = Object.assign({}, this.paramObj, page, paramObj)
              this.paramObj = param
              console.log("param",param)
              return param
          },
          getDict(){
            getDict({ type: 'main_recovery_status' }).then(({res}) => {
                this.searchOptions[5].options = res.main_recovery_status;
                // console.error('res.main_recovery_status -------->', res.main_recovery_status);
            });
         },
             /**
        　* 服务记录
        　* @Description:
          * @Param  {*} scope
          * @Param 
          * @Return
        　* @Author wangz
        　* @Date 2022/3/21 13:56
        　*/
        gotoIndex (scope) {
            const query = Object.assign({},{
              orderId: scope.data.row.id,
              
            })
            console.log("ssssssssssss",query)
            this.$router.push({ path: '/order/index', query: query})
            
        },
        toExport() {
          const param = this.getParam()
          let exportName = '订单列表' + moment(new Date()).format("YYYYMMDD");
          let url = process.env.VUE_APP_BASE_API + '/admin/order/export'
          let _this = this;
          exportExcel(url, {}, param, exportName).then(res=>{
          }).catch(function (error) {
             _this.$message({
                    message: error.message || '导出失败',
                    duration: 5000,
                    type: "warning",
              });
          });;
        }
      }
  }
  </script>
  
  <style lang='scss' scoped>
  @import "~@/styles/variables.scss";
  </style>


