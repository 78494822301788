/*
 * @Author: 358617935@qq.com 358617935@qq.com
 * @Date: 2023-05-23 14:24:06
 * @LastEditors: 358617935@qq.com 358617935@qq.com
 * @LastEditTime: 2023-06-01 15:09:28
 * @FilePath: /recovery_home_management/src/api/channel.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

/**
 * 页面: 渠道商管理
 * pageName: channel
 **/
import request from '@/utils/request'

/**
 * @description: 获取渠道商管理列表数据
 */
export function getList(data) {
    return request({
        url: '/distributorInfo/list',
        method: 'GET',
        params: data,
        showLoading: true
    })
}


/**
 * @description: 新增渠道商管理数据
 */
export function insertData(data) {
    return request({
        url: '/distributorInfo/add',
        method: 'POST',
        data,
        showLoading: true
    })
}

/**
 * @description: 修改渠道商管理数据
 */
export function editData(data, timeStamp) {
    return request({
        url: '/distributorInfo/update',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}


/**
 * @description: 获取渠道商管理详情数据
 */
export function getDetail(data) {
    return request({
        url: '/distributorInfo/info',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 删除渠道商管理数据
 */
export function deleteData(data, timeStamp) {
    return request({
        url: '/distributorInfo/delete',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}



/**
 * @description: 获取引荐医生列表数据
 */
export function getDoctorList(data) {
    return request({
        url: '/distributorDoctorRelation/list',
        method: 'GET',
        params: data,
        showLoading: true
    })
}


/**
 * @description: 获取待引荐医生列表数据
 */
export function getChooseDoctorList(data) {
    return request({
        url: '/distributorInfo/doctorIntroduceChooseList',
        method: 'GET',
        params: data,
        showLoading: true
    })
}

/**
 * @description: 新增引荐医生数据
 */
export function insertDoctorData(data) {
    return request({
        url: '/distributorDoctorRelation/add',
        method: 'POST',
        data,
        showLoading: true
    })
}

/**
 * @description: 删除引荐医生数据
 */
export function deleteDoctorData(data, timeStamp) {
    return request({
        url: '/distributorDoctorRelation/delete',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}