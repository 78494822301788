<!--
 * @Author: shangxue
 * @Date: 2023-06-29 09:44:46
 * @LastEditTime: 2023-07-04 09:30:56
 * @LastEditors: Please set LastEditors
 * @Description: 
 * @FilePath: /recovery_home_management_dev/src/components/load-select/index.vue
-->
<template>
    <el-select :value="value" v-loadmore="loadMore" @focus="focus" v-bind="$attrs" v-on="$listeners">
        <el-option
            v-for="option in data"
            :label="option.label"
            :value="option.value"
            :key="option.value"
        ></el-option>
    </el-select>
</template>

<script>
export default {
    props: {
        value: {
            type: String,
            default: ""
        },
        // 列表数据
        data: {
            type: Array,
            default: () => []
        },
        label: {
            type: String,
            default: "label"
        },
        value: {
            type: String,
            default: "value"
        },
        // 调用页数的接口
        request: {
            type: Function,
            default: () => {}
        },
        current: {
            type: [Number, String],
            default: 1
        },
        pages: {
            type: [Number, String],
            default: 0
        },
    },
    data() {
        return {};
    },
    methods: {
        // 请求下一页的数据
        loadMore(event) {
            this.request({ current: this.current + 1 });    
        },
        // 选中下拉框没有数据时，自动请求第一页的数据
        focus(event) {
           if (!this.data.length) {
                this.request({current: 1});
            }
        }
    }
};
</script>
