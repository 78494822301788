<!--
 * @Author: 马广源
 * @Date: 2022-05-12 14:56:55
 * @LastEditTime: 2022-05-26 19:30:35
 * @LastEditors: magy “……”
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \recovery_home_management\src\views\patientManagement\index.vue
-->

<!--
* @Description: 提现管理
* @Reference:
-->

<template>
  <el-container>
    <el-header height="auto">
      <searchForm ref="mainSearch" :searchoptions="searchOptions" exportStatus @exportFun="toExport" @search="search" :backStatus="backStatus"/>
    </el-header>
    <el-main class="basic-main">
      <generalTable ref="mainTable" :table-data="tableData" :config="tableConfig" :total="total" @updatePage="getList" @objectSpanMethod="objectSpanMethod" @onClickRow="onClickRow">
         <!-- <template slot="frozenAccount" slot-scope="scope">
                        <el-switch
                            v-model="scope.data.row.frozenAccount"
                            inactive-value="1"
                            active-value="0"
                            @change="changeStatus(scope,$event)"
                        />
                    </template> -->
        <template slot="handle" slot-scope="scope">
          <el-button type="text" style="margin-right: 10px" :disabled="scope.data.row.withdrawStatus == 1" @click="toEdit(scope)">编辑</el-button>
        </template>
      </generalTable>
            <!-- <patientRecordDialog
            :id="checkId"
            ref="patientRecord"
        /> -->
        <editState
            :id="checkId"
            ref="editState"
            :timeStamp="timeStamp"
            @submit="search"
        />
    </el-main>
  </el-container>
</template>
<script>
import { getEmbodyList, patientStatus } from '@/api/embodyAndRefund'
import searchForm from '@/components/SearchForm'
import generalTable from '@/components/Table'
import { getHospitalList} from '@/api/hospitalManagement'
import editState from "./editState";
import { exportExcel } from "@/utils/common";
export default {
    name: 'Test',
    components: {
        searchForm,
        generalTable,
        editState,
        // patientRecordDialog,
        // addDialog,
        // serviceConfig
    },
    data() {
        return {
            tableData: [
            ],
            searchOptions: [
                {
                    type: 'input',
                    placeholder: '请输入...',
                    key: 'withdrawSerial',
                    label: '提现单号',
                    defaultVal: ''
                },
                {
                    type: 'input',
                    placeholder: '请输入...',
                    key: 'userName',
                    label: '医生姓名',
                    defaultVal: ''
                },
                {
                    type: 'select',
                    placeholder: '请选择',
                    key: 'kfHospitalId',
                    filterable: true,
                    multiple: false,
                    label: '所属医院',
                    options: [],
                    defaultVal: []
                },
                {
                    type: 'daterange',
                    startPlaceholder: '开始日期',
                    endPlaceholder: '结束日期',
                    key: 'withdrawTime',
                    label: '提现时间',
                    defaultVal: []
                },
                {
                    type: 'select',
                    key: 'withdrawType',
                    filterable: true,
                    label: '提现方式',
                    options: [
                        {
                            label: '微信',
                            value: '1'
                        },
                        {
                            label: '支付宝',
                            value: '0'
                        },
                        {
                            label: '银行卡',
                            value: '2'
                        }
                    ],
                    defaultVal: ''
                },
                {
                    type: 'select',
                    key: 'withdrawStatus',
                    filterable: true,
                    label: '提现状态',
                    options: [
                        {
                            label: '提现中',
                            value: '2'
                        },
                        {
                            label: '已到账',
                            value: '1'
                        },
                        {
                            label: '未到账',
                            value: '0'
                        }
                    ],
                    defaultVal: ''
                }
            ],
            tableConfig: {
                id: 'test',
                align: 'center', // 不必填 默认为center
                selection: 'single', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: true, // 不必填 分页 默认为false
                column: [
                // {
                //     label: '排序', // 必填
                //     prop: 'sort' // 必填
                // },
                {
                    label: '流水单号', // 必填
                    prop: 'withdrawSerial' // 必填
                },
                {
                    label: '医生姓名', // 必填
                    prop: 'userName' // 必填
                },
                {
                    label: '所属医院', // 必填
                    prop: 'userHospital' // 必填
                },
                {
                    label: '提现时间', // 必填
                    prop: 'withdrawTime', // 必填
                    formatter: (row, column) => {
                            return this.moment(row.withdrawTime).format(
                                'YYYY-MM-DD'
                            )
                        }
                },
                {
                    label: '提现金额(元)', // 必填
                    prop: 'withdrawAmount' // 必填
                },
                {
                    label: '提现方式', // 必填
                    prop: 'withdrawType', // 必填
                    formatter: (row, column) => {
                            const stampContainer = {
                                1: "微信",
                                0: "支付宝",
                                2: "银行卡",
                            };
                            return stampContainer[row.withdrawType];
                        },
                },
                {
                    label: '提现状态', // 必填
                    prop: 'withdrawStatus', // 必填
                    formatter: (row, column) => {
                            const stampContainer = {
                                1: "已到账",
                                0: "未到账",
                                2: "提现中"
                            };
                            return stampContainer[row.withdrawStatus];
                        },
                },
                // {
                //     label: '禁用/启用', // 必填
                //     slot: 'frozenAccount' // 必填
                // },
                {
                    slot: 'handle', // 不必填 单列插槽
                    label: '操作', // 必填
                    fixed: 'right', // 不必填 固定列  left right
                    width: '100' // 不必填
                }
                ]
            },
            paramObj: null, // 筛选项
            total: 0, // 总条数
            checkId: '', // 单条数据id 用于详情或编辑请求数据用
            timeStamp: '',
            backStatus: true, //返回按钮的隐藏显示
            hospitalList:[],//医院列表
        }
    },
    created() {
        this.getList();
        this.getHospital();
    },
    mounted: function() {},
    methods: {
        search(data) {
            this.paramObj = data
            this.$refs.mainTable.handleCurrentChange(1)
        },
        toExport() {
          let params = this.getParam();
          console.table(params)
          let url = process.env.VUE_APP_BASE_API + "/admin/finance/export";
          let _this = this;
          exportExcel(url,{},params,'导入慧用工系统结算报表','xls').then(res=>{
          }).catch(function (error) {
             _this.$message({
                    message: error.message || '导出失败',
                    duration: 5000,
                    type: "warning",
              });
          });
        },
        getList() {
            // 解决未挂载完组件时调用子组件方法报错问题
            this.$nextTick(() => {
                const param = this.getParam()
                getEmbodyList(param).then((res, timeStamp) => {
                    this.tableData = res.res.records;
                    this.timeStamp = timeStamp;
                    this.total = res.res.total;
                })
            })
            
        },
        objectSpanMethod(param, cb) {
            cb([])
        },

        toEdit(scope) {
            console.table(scope.data.row)
            this.checkId = scope.data.row.id
            this.$refs.editState.updateVisible(true,scope.data.row)
        },
        onClickRow(checkArray) {
            console.log('testststst')
            console.log('checkArray', checkArray)
        },
        getParam() {
            // 获取参数
            const paramObj = this.$refs.mainSearch.packageData()
            this.paramObj = paramObj
            const page = this.$refs.mainTable.getPage()
            const param = Object.assign(page, paramObj)
            return param
        },
         // 获取医院
        getHospital() {
            const param = []
            getHospitalList(param).then(({ res }) => {
                this.hospitalList = res.records.map((item) => {
                    return {
                        value: item.id,
                        label: item.name,
                    };
                });
                this.searchOptions[2].options = this.hospitalList
            });
        },
       
    }
}
</script>

<style lang='scss'>
@import "~@/styles/variables.scss";

</style>
