<!--
* @Description: 分账系数管理页面
* @Reference:
-->

<template>
  <el-container>
    <el-header height="auto">
    
      <div>
        <el-button @click="returnPage()" type="primary" plain>返回</el-button>
      </div>
    
  </el-header>
    <el-main class="basic-main">
  
      
      <generalTable
        ref="mainTable"
        :table-data="tableData"
        :config="tableConfig"
        :total="total"
        @updatePage="getList"
        @onClickRow="onClickRow"
      >
        <template slot="status" slot-scope="scope">
            <el-switch
                v-model="scope.data.row.status"
                inactive-value="1"
                active-value="0"
                @change="changeStatus(scope,$event)"
            />
        </template>
     
        <template slot="handle" slot-scope="scope">
       
          <el-button type="text" @click="toEdit(scope)">编辑</el-button>
         
        </template>
      </generalTable>
      <detailDialog :id="checkId" ref="detail" />
      <addDialog
        :id="checkId"
        ref="insert"
        :timeStamp="timeStamp"
        @submit="getList"
      />
    </el-main>
  </el-container>
</template>

<script>
import { getList, deleteData, bannerStatus } from "@/api/financeRate";
import searchForm from "@/components/SearchForm";
import generalTable from "@/components/Table";
import detailDialog from '@/views/operation/bannerManage/detailDialog'
import addDialog from "@/views/financial/financeRate/addDialog";

export default {
  name: "BannerManage",
  components: {
    searchForm,
    generalTable,
    detailDialog,
    addDialog,
  },
  data() {
    return {
      tableData: [],
      searchOptions: [
  
      ],
      tableConfig: {
        id: "bannerManage",
        align: "center", // 不必填 默认为center
        selection: "single", // 不必填 多选: multiple  单选: single  默认为 normal
        index: true, // 不必填 序号
        pagination: true, // 不必填 分页 默认为false
        column: [
        {
            label: "ID", // 必填
            prop: "id", // 必填
          },
          {
            label: "名称", // 必填
            prop: "name", // 必填
          },
          {
            label: "系数", // 必填
            prop: "rate",// 必填
            formatter: (row) => {  
                  return row.rate+"%";
            },
          },
          {
            label: "类型", // 必填
            prop: "orderType", // 必填
            formatter: (row, column) => {
                            const stampContainer = {
                                1: "设备",
                                2: "服务",
                            };
                            return stampContainer[row.orderType];
                    },
          },
        
          {
            label: "禁用/启用", // 必填
            slot: "status", // 必填
          },
          {
            slot: "handle", // 不必填 单列插槽
            label: "操作", // 必填
            fixed: "right", // 不必填 固定列  left right
            width: "220", // 不必填
          },
        ],
      },
      paramObj: null, // 筛选项
      total: 0, // 总条数
      checkId: "", // 单条数据id 用于详情或编辑请求数据用
      backStatus: true, //返回按钮的隐藏显示
      timeStamp: "", //时间戳 用于修改数据或删除数据
    };
  },
  created() {
    this.getList();
  },
  mounted: function () {},
  methods: {
    search(data) {
      Object.assign(this.paramObj, data);
      this.$refs.mainTable.handleCurrentChange(1);
    },
    getList() {
      this.$nextTick(() => {
        // const param = this.getParam();
        getList().then(({ res, timeStamp }) => {
          this.tableData = res;
          this.timeStamp = timeStamp;
          this.total = parseInt(res.total)
        });
      });
    },
    toInsert() {
      this.checkId = "";
      this.$refs.insert.updateVisible(true);
    },
    toEdit(scope) {
      this.checkId = scope.data.row.id;
      this.$refs.insert.updateVisible(true);
    },
    toDetail(scope) {
            this.checkId = scope.data.row.id
            this.$refs.detail.updateVisible(true)
        },
  
    changeStatus(checkArray){
            const parm = {
                id:checkArray.data.row.id,
                status:checkArray.data.row.status
            }
            bannerStatus(parm,this.timeStamp).then(({res}) =>{
                this.getList()
            })
        },
        /**
       * [returnPage 返回按钮]
       * @return {[type]} [description]
       */
      returnPage() {
      // 跳转上一级
      // 这个判断用来解决这种情况，当用户没有上一条路由的历史记录，出现点击返回按钮没有反应时，下面的代码用来判断有没有上一条路由的历史记录   如果没有则返回首页
        if (window.history.length <= 1) {
          this.$router.push({ path: "/zh-CN/home" });
          return false;
        } else {
          this.$router.go(-1);
        }
      },
    onClickRow(row) {
      console.log("点击行:", row);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
</style>
