
<template>
    <el-dialog width="50%" :title="id === '' ? '新增康复训练权限配置' : '修改康复训练权限配置'" :visible.sync="visible"
        :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false" append-to-body>
        <el-form ref="insertForm" :model="form" label-position="right" label-width="120px" class="formBox" :rules="rules">
            <el-row :gutter="10" type="flex" justify="start">

                <el-col :sm="12" :md="12" :lg="8" :xl="8">
                    <el-form-item label="选择患者：" prop="userId">
                        <el-select v-model="form.userId" :disabled="id !== ''" clearable placeholder="请选择" :filterable="true"
                            :remote-method="getPatList" remote :loading="remoteLoading">
                            <el-option v-for="option in options" :key="option.id" :label="option.name" :value="option.id" >
                                <span style="float: left">{{option.name}} ｜ </span>
                                <span>{{option.phoneNumber}}</span>
                            </el-option>
                        </el-select>
                        <!-- <el-autocomplete v-model="form.userId" label="id" :fetch-suggestions="getPatList"
                            placeholder="请输入内容" @select="handleSelect"></el-autocomplete> -->


                    </el-form-item>

                </el-col>
            </el-row>
            <el-row :gutter="10" type="flex" justify="start">
                <el-col :sm="24" :md="24" :lg="24" :xl="24">
                    <el-form-item label="游戏配置：" prop="trainingMenu">
                        <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
                        <el-checkbox-group v-model="form.trainingMenu" @change="handleCheckedTrainingChange">
                            <el-checkbox v-for="key in menuList" :label="key.typeId" :key="key.id">
                                {{ key.typeName }}
                            </el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                </el-col>
            </el-row>


        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submit()">提 交</el-button>
            <el-button @click="updateVisible(false)">取 消</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { insertData, editData, getDetail, getMenuList } from '@/api/trainingAuthority'
import { getList, findPatientsList } from '@/api/patientManagement'

export default {
    props: {
        id: {
            type: String
        },
        timeStamp: {
            type: String
        }
    },
    data() {
        return {
            rules: {
                userId: [
                    { required: true, message: '请选择患者', trigger: 'change' }
                ],
                trainingMenu: [
                    { type: 'array', required: true, message: '请至少选择一个游戏配置', trigger: 'change' }
                ],
            },
            remoteLoading:false,
            checkAll: false,
            isIndeterminate: false,
            options: [],
            menuList: [],
            form: {
                trainingMenu: [],
                userId: "",
            },
            total: 0,
            visible: false,
            // timeStamp: '', //时间戳 用于修改数据或删除数据
        }
    },
    watch: {
        visible(newVal, oldVal) {
            if (newVal === true) {
                if (this.id) {
                    this.getDetail()
                }
            }
        },
    },
    created() {
        this.getMenuList()
    },
    mounted() { },
    methods: {
        getPatList(text) {
            const parma = Object.assign({
                searchContext: text
            })
            this.remoteLoading = true
            findPatientsList(parma).then((res, timeStamp) => {
                if (res.res) {
                    this.options = res.res
                }
            }).finally(()=>{
              this.remoteLoading = false
            })
        },

        /**
         * @description: 游戏配置
         * @param {*}
         * @return {*}
         */
        getMenuList() {
            // const param = []
            getMenuList().then(({ res }) => {
                this.menuList = res.records
            });
        },
        handleSelect() {

        },
        handleCheckAllChange(val) {
          if (val){
            this.form.trainingMenu = [];
            this.menuList.forEach(item=>{
              this.form.trainingMenu.push(item.typeId);
            })
          } else {
            this.form.trainingMenu = [];
          }
          this.isIndeterminate = false;
        },
        handleCheckedTrainingChange(value){
          let checkedCount = value.length;
          this.checkAll = checkedCount === this.menuList.length;
          this.isIndeterminate = checkedCount > 0 && checkedCount < this.menuList.length;
        },
        submit() {
            this.$refs.insertForm.validate((valid) => {
                if (valid) {

                    let parm = this.form.trainingMenu.map((item) => {
                        return { userId: this.form.userId, trainingMenuId: item }
                    })
                    // const parm = Object.assign(data)
                    if (this.id === '') {
                        insertData(parm).then(({ res }) => {
                            this.submitSuccess(res)
                        })
                    } else {
                        editData(parm, this.timeStamp).then(({ res }) => {
                            this.submitSuccess(res)
                        })
                    }
                }
            })
        },
        submitSuccess(res) {
            this.$message({ ...this.commonConfig.$handleSuccess })
            this.updateVisible(false)
            this.$emit('submit')
        },
        getDetail() {
            getDetail({ userId: this.id }).then(({ res, timeStamp }) => {
                this.form.trainingMenu = res.map((item) => {
                    return parseInt(item.gameId)
                })
                this.handleCheckedTrainingChange(this.form.trainingMenu);
                let user = res.filter((item) => item.userId === this.id);
                this.options = [{
                    name: user[0].userName,
                    id: user[0].userId,
                    phoneNumber: user[0].phoneNum
                }];
                this.form.userId = user[0].userId;

            })
        },
        toDel(scope) {
            const { $delMsg, $delTitle, $messageBoxConfig } = this.commonConfig
            this.$confirm($delMsg, $delTitle, $messageBoxConfig).then(() => {
                const param = { id: scope.data.row.id }
                deleteData(param, this.timeStamp).then(({ res }) => {
                    this.$message({ ...this.commonConfig.$handleSuccess })
                })
            })
        },
        updateVisible(val) {
            this.visible = val
            if (val === false) {
                this.$refs.insertForm.resetFields()
                this.isIndeterminate = false;
                this.checkAll = false;
                this.options = [];
            }
        },
    }
}
</script>
<style scoped lang='scss'></style>

