<template>
  <el-dialog
    width="70%"
    :title="id === '' ? '新增分账系数' : '修改分账系数'"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    append-to-body
  >
    <el-form
      ref="insertForm"
      :model="form"
      label-position="right"
      label-width="120px"
      class="formBox"
      :rules="rules"
    >
      <div class="mdrcc-info-title">基本信息</div>
      <el-row :gutter="10" type="flex" justify="start">
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="名称" prop="name">
            <el-input
              v-model="form.name"
              placeholder="请输入..."
              clearable
              maxlength="20"
            />
          </el-form-item>
        </el-col>
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="系数(%)" prop="rate">
            <el-input
              v-model="form.rate"
              placeholder="请输入..."
              clearable
              maxlength="20"
            />
          </el-form-item>
        </el-col>
        
       
        <el-col :sm="12" :md="12" :lg="8" :xl="6">
          <el-form-item label="状态" prop="status">
            <el-switch
              v-model="form.status"
              name="status"
              active-text="启用"
              inactive-text="禁用"
              inactive-value="1"
              active-value="0"
            />
          </el-form-item>
        </el-col>
        
      </el-row>
      <el-divider />
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit()">提 交</el-button>
      <el-button @click="updateVisible(false)">取 消</el-button>
    </span>
  </el-dialog>
</template>
<script>
import {  editData, getDetail } from "@/api/financeRate";
import generalTable from "@/components/Table";


export default {
  props: {
    id: {
      type: String,
    },
    timeStamp: {
      type: String,
    },
  },
  data() {
    return {
      checkId: "",
      tableConfig: {
        align: "center", // 不必填 默认为center
        selection: "normal", // 不必填 多选: multiple  单选: single  默认为 normal
        index: true, // 不必填 序号
        pagination: false, // 不必填 分页 默认为false
      },
      rules: {
        name: [
          { required: true, message: "请输入名称", trigger: "blur" },
         
        ],

      },

      form: {
        checkbox: [],
     
      },
      total: 0,
      visible: false,
      // timeStamp: '', //时间戳 用于修改数据或删除数据
    };
  },
  watch: {
    visible(newVal, oldVal) {
      if (newVal === true) {
        if (this.id) {
          this.getDetail();
        }
        //...todo
      }
    },
  },
  created() {},
  mounted() {},
  methods: {
    submit() {
      this.$refs.insertForm.validate((valid) => {
        
          const parm = this.form;
         
            editData(parm, this.timeStamp).then(({ res }) => {
              this.submitSuccess(res);
            });
       
      });
    },
    submitSuccess(res) {
      this.$message({ ...this.commonConfig.$handleSuccess });
      this.updateVisible(false);
      this.$emit("submit");
    },
    getDetail() {
      getDetail({ id: this.id }).then(({ res, timeStamp }) => {
        this.form = res;
      });
    },
    updateVisible(val) {
      this.visible = val;
      if (val === false) {
        this.$refs.insertForm.resetFields();
      }
    },
 
 
  },
};
</script>
<style scoped lang='scss'>
 
</style>
