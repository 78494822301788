import translate from "./translate"

const TUIContact = {
  "群聊通知": "群聊通知",
  "系统通知": "系统通知",
  "我的群聊": "我的群聊",
  "输入ID": "请输入用户/群聊 ID，回车搜索",
  "群名称": "群名称",
  "群ID": "群ID",
  "加入群聊": "加入群聊",
  "解散群聊": "解散群聊",
  "退出群聊": "退出群聊",
  "进入群聊": "进入群聊",
  "群类型": "群类型",
  "请填写验证信息": "请填写验证信息",
  "申请加入": "申请加入",
  "已申请":"已申请",
  "接受": "接受",
  "拒绝": "拒绝",
  "已处理": "已处理",
  "添加群聊": "添加群聊",
  "已加入": "已加入",
  "该群组不存在": "该群组不存在",
  "加群": "加群",
  "我的好友": "我的好友",
  "发送消息": "发送消息",
  "个性签名": "个性签名",
  "等待管理员同意": "申请成功，等待管理员同意入群申请",
  "加群成功": "加群成功",
  "您已是群成员": "您已是群成员",
  "新的联系人": "新的联系人",
  "黑名单": "黑名单",
  "无搜索结果": "无搜索结果",
  "客服号": "客服号",
  "ID": "ID",
  "联系人": "联系人",
  "群聊": "群聊",
  "添加好友/群聊": "添加好友/群聊",
  "备注名": "备注名",
  "加入黑名单": "加入黑名单",
  "删除好友": "删除好友",
  "等待验证": "等待验证",
  "验证信息": "验证信息",
  "发送申请": "发送申请",
  "加入直播群": "加入直播群",
  "同意": "同意",
  "删除好友成功": "删除好友成功",
  "删除好友失败": "删除好友失败",
  "申请已发送": "申请已发送",
  "申请发送失败": "申请发送失败",
  "进入会话失败": "进入会话失败",
  "添加好友成功": "添加好友成功",
  "同意好友申请失败": "同意好友申请失败",
  "拒绝成功": "拒绝成功",
  "拒绝好友申请失败": "拒绝好友申请失败",
  "解散群聊成功": "解散群聊成功",
  "解散群聊失败": "解散群聊失败",
  "退出群组成功": "退出群组成功",
  "退出群组失败": "退出群组失败",
  "申请入群失败": "申请入群失败",
  "加入黑名单失败": "加入黑名单失败",
  "移除黑名单失败": "移除黑名单失败",
  "修改备注失败: 备注长度不得超过 96 字节": "修改备注失败: 备注长度不得超过 96 字节",
  "修改备注成功": "修改备注成功",
  "修改备注失败": "修改备注失败",
}

export default TUIContact;
