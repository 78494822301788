/*
 * @Author: your name
 * @Date: 2022-04-01 21:13:13
 * @LastEditTime: 2023-06-05 18:27:59
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /recovery_home_management_dev/src/api/patientManagement.js
 */
/**
 * 页面: 医生管理
 * pageName: doctorTreatment
 **/
 import request from '@/utils/request'

 /**
  * @description: 医生管理列表
  */
 export function getList(data) {
   return request({
    //  url: 'patient/userPatients/list',
    url: 'admin/patientManagement/list',
     method: 'GET',
     params: data,
     showLoading: true
   });
 }

 /**
 * @description: 启用/禁用
 */
  export function patientStatus(data, timeStamp) {
    return request({
        // url: 'patient/userPatients/updateStatus',
        url: 'admin/patientManagement/updateStatus',
        method: 'POST',
        data,
        showLoading: true,
        timeStamp
    })
}


export function getPatientRecordList(data) {
  return request({
    url: '/userPatientsArchives/list',
    method: 'GET',
    params: data,
    showLoading: true
  });
}

 /**
 * @description: 选择患者按手机号或姓名查询接口
 */
export function findPatientsList(data) {
  return request({
    url: '/trainingMenuUserRelation/findPatientsList',
    method: 'GET',
    params: data,
    showLoading: false
  });
}
