<template>
  <div>
    <generalTable ref="mainTable" :table-data="tableData" :config="tableConfig" :total="total" @updatePage="getList" @objectSpanMethod="objectSpanMethod" 
     @selection-change="handleSelectionChange" @onClickRow="onClickRow">
          <template slot="handle" slot-scope="scope">
            <!-- <el-button type="text" style="margin-right: 10px" :disabled="scope.data.row.withdrawStatus == 1" @click="toEdit(scope)">编辑</el-button> -->
            <el-button type="text" style="margin-right: 10px" @click="toWithdraw(scope)">提现</el-button>
          </template>
        </generalTable>
        <!-- <editState
              :id="checkId"
              ref="editState"
              :timeStamp="timeStamp"
              @submit="search"
          /> -->
          <detailDialog :id="checkId" :detailData="detailData" type="withdraw" ref="detail" @audit="search"/>
  </div>
</template>
<script>
import { getEmbodyList, patientStatus } from '@/api/embodyAndRefund';
import searchForm from '@/components/SearchForm';
import generalTable from '@/components/Table';
import editState from "./editState";
import { exportExcel } from "@/utils/common";
import { getDict } from "@/api/common";
import detailDialog from '@/views/financial/embody/detailDialog';
import { auditData } from '@/api/embodyAndRefund';
export default {
    name: 'Test',
    components: {
        searchForm,
        generalTable,
        editState,
        detailDialog
    },
      props: {
        searchObj: Object
    },
   
    data() {
        return {
            tableData: [
            ],
            tableConfig: {
                id: 'test',
                align: 'center', // 不必填 默认为center
                selection: 'single', // 不必填 多选: multiple  单选: single  默认为 normal
                index: true, // 不必填 序号
                pagination: true, // 不必填 分页 默认为false
                selection: 'multiple', //表格模式（单选、多选）
                //saveSelected: true, //翻页是否保存选中项
                column: [
                // {
                //     label: '排序', // 必填
                //     prop: 'sort' // 必填
                // },
                {
                    label: '流水单号', // 必填
                    prop: 'withdrawSerial' // 必填
                },
                {
                    label: '医生姓名', // 必填
                    prop: 'userName' // 必填
                },
                {
                    label: '所属医院', // 必填
                    prop: 'userHospital' // 必填
                },
                {
                    label: '提现时间', // 必填
                    prop: 'withdrawTime', // 必填
                    formatter: (row, column) => {
                            return this.moment(row.withdrawTime).format(
                                'YYYY-MM-DD'
                            )
                        }
                },
                {
                    label: '提现金额(元)', // 必填
                    prop: 'withdrawAmount' // 必填
                },
                {
                    label: '提现方式', // 必填
                    prop: 'withdrawType', // 必填
                    formatter: (row, column) => {
                            const stampContainer = {
                                1: "微信",
                                0: "支付宝",
                                2: "银行卡",
                            };
                            return stampContainer[row.withdrawType];
                        },
                },
                {
                    label: '状态', // 必填
                    prop: 'withdrawStatus', // 必填
                    formatter: (row, column) => {
                            // 根据字典表展示对应项目的label值
                            const currentType = this.withdrawStatusList.filter(
                                (type) => type.value === row.withdrawStatus
                            );
                            if (currentType.length) {
                                return currentType[0].label;
                            }
                        },
                },
                // {
                //     label: '禁用/启用', // 必填
                //     slot: 'frozenAccount' // 必填
                // },
                {
                    slot: 'handle', // 不必填 单列插槽
                    label: '操作', // 必填
                    fixed: 'right', // 不必填 固定列  left right
                    width: '100' // 不必填
                }
                ]
            },
            paramObj: null, // 筛选项
            total: 0, // 总条数
            checkId: '', // 单条数据id 用于详情或编辑请求数据用
            timeStamp: '',
            backStatus: true, //返回按钮的隐藏显示
            hospitalList:[],//医院列表
            withdrawStatusList: [], // 提现状态列表
            detailData: {}, // 审核详情信息
            checkIds: [],
        }
    },
    created() {
        this.getList();
        this.getWithdrawStatusList();
    },
    mounted: function() {},
    watch: {
        searchObj(newVal, oldVal) {
            console.log('searchObj=', newVal)
            if (newVal) {
                this.getList()
                //...todo
            }
        }
    },
    methods: {
        search(data) {
            this.paramObj = data
            this.$refs.mainTable.handleCurrentChange(1)
        },
        toExport() {
          let params = this.getParam();
          let url = process.env.VUE_APP_BASE_API + "/admin/finance/export";
          let _this = this;
          exportExcel(url,{},params,'导入慧用工系统结算报表','xls').then(res=>{
          }).catch(function (error) {
             _this.$message({
                    message: error.message || '导出失败',
                    duration: 5000,
                    type: "warning",
              });
          });
        },
           /**
         * @description: 提现操作
         * @param {*}
         * @return {*}
         */
        toWithdraw(scope){
            this.checkId = scope.data.row.id;
			this.detailData = scope.data.row;
			this.$refs.detail.updateVisible(true);
        },
        /**
         * @description: 提现操作
         * @param {*}
         * @return {*}
         */
        toBatchWithdraw(){
            const { $delMsg, $delTitle, $messageBoxConfig } =
                this.commonConfig;
            this.$confirm("是否申请批量提现？", $delTitle, $messageBoxConfig).then(() => {
                this.fullscreenLoading = true;             
                const checkArray = this.$refs.mainTable.getChecked();
                const checkIds = checkArray.map((item, index)=>{
                    return item.id;
                    
                })
                const param = { ids: checkIds, auditStatus: '2' };
                auditData(param, this.timeStamp).then(({ res }) => {
                    if(res.status === true){
                        this.successMessage();
                    }else{
                        const reason = {
                           totalNo: res.totalNo,
                            successNo: res.successNo,
                            failNo: res.failNo,
                            failReasonList: res.failReasonList,
                        }
                        this.failMessage(reason);
                    }
                })
                .catch((error)=> {
                    this.fullscreenLoading = false;
                })
            });
        },
         successMessage(){
            this.$message({
                showClose: true,
                message: "提现申请已提交，请耐心等待。",
                type: 'success'
            })
            this.search();
            this.fullscreenLoading = false;    
            this.$refs.detail.updateVisible(true);
        },
        failMessage(reason){            
            let error_reason = reason.failReasonList.length === 0 ?  '' : `<ul class="failreason-list">`;
            reason.failReasonList.forEach((item) => {
                error_reason += `<li><span>流水单号：` + item.withdrawSerial + `, </span><span class="fail-color">失败原因：` + item.reason +  `<span></li>`
            });
            error_reason += reason.failReasonList.length === 0 ?  '' : `</ul`;
            this.$alert(
                `
                <i class="el-icon-error error-icon"></i>
                <div ><p>您共提现&nbsp;` + reason.totalNo + `&nbsp;条。提现成功&nbsp;<span class="success-color">` + reason.successNo + `</span>&nbsp;条, 提现失败 <span class="fail-color">` + reason.failNo + `</span>&nbsp;条。<p>` + `<p>提现信息请到<span style="font-weight: bold;"> 已完结 </span>中查看。</p>
                <div>`+ error_reason + `</div>`, 
                '提现结果', 
                {
                    dangerouslyUseHTMLString: true,
                    customClass: 'check-tip',
                    confirmButtonText: '返回',
                    center: true,
                    callback: action => {
                        this.search();
                        this.fullscreenLoading = false;
                    }
                });
        },
        
          /**
         * @description: 获取所属平台
         * @param {*}
         * @return {*}
         */
        getWithdrawStatusList() {
            getDict({ type: "withdraw_status" }).then(({ res }) => {
                this.withdrawStatusList = res.withdraw_status;
            });
        },
        getList() {
            // 解决未挂载完组件时调用子组件方法报错问题
            this.$nextTick(() => {
                const param = this.getParam()
                // tab 分组查询 List 分类参数
                // statusType：1. 待审批 2. 待提现  3. 已完结（提现中、提现失败、审批拒绝）
                const paramObj = Object.assign({}, this.getParam(), { statusType: "2" });
                getEmbodyList(paramObj).then((res, timeStamp) => {
                    this.tableData = res.res.records;
                    this.timeStamp = timeStamp;
                    this.total = res.res.total;
                })
            })
            
        },
        objectSpanMethod(param, cb) {
            cb([])
        },
        toEdit(scope) {
            console.table(scope.data.row)
            this.checkId = scope.data.row.id
            this.$refs.editState.updateVisible(true,scope.data.row)
        },
        onClickRow(checkArray) {
        },
        handleSelectionChange(checkIds){
             const flag = checkIds.length == 0 ? this.batchBtnVisible = false : this.batchBtnVisible = true;
            this.$emit('changeBatchBtn', flag);
        },
        getParam() {
            // 获取参数
            const page = this.$refs.mainTable.getPage();
            const param = Object.assign({}, this.searchObj, page, { statusType: "2" });
            this.paramObj = param;
            return param;
        },
    }
}
</script>

<style lang='scss'>
@import "~@/styles/variables.scss";

.failreason-list{
    padding-inline-start: 25px;
    li{
        text-align: left;     
    }
}
.fail-color{
    color: #f56c6c;
}
.success-color{
    color: #67c23a;
}  
.error-icon{
    color: #D9001B;font-size: 100px;
}


</style>
