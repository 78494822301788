
<!--
* @Description: 
* @Reference:
-->
<template>
  <el-dialog
    width="40%"
    :title="'修改分组'"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    append-to-body
  >
    <el-form
      ref="insertForm"
      :model="form"
      label-position="right"
      label-width="140px"
      class="formBox"
      :rules="rules"
    >
    <el-row :gutter="15" type="flex" justify="start">
      <el-col>
        <el-form-item label="分组" prop="videoTypeId">
          <el-select
            v-model="form.videoTypeId"
            clearable
            placeholder="请选择"
            :filterable="true"
          >
            <el-option
              v-for="option in videoTypeList"
              :key="option.id"
              :label="option.videoTypeName"
              :value="option.id"
            />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="submit()">提 交</el-button>
          <el-button @click="updateVisible(false)">取 消</el-button>
      </span>
  </el-dialog>
</template>
<script>
import {getGeneralVideoInfo, updateGeneralVideoInfo, groupBatchGeneralVideoInfo, getGeneralVideoTypeListNoPage } from '@/api/generalVideoManagement'
export default {
  props: {
    id: {
      type: String
    },
    ids: {
      type: Array
    },
    isBatchGroup: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      rules: {
        videoTypeId: [
          {
            required: true,
            message: "请选择分组",
            trigger: "change",
          },
        ],
      },
      form: {
        videoTypeId: '', 
      },
      videoTypeList: [],
      total: 0,
      visible: false,
      timeStamp: '', // 时间戳
      submitFlag: true // 防抖
    }
  },
  watch: {
    visible(newVal, oldVal) {
      this.$nextTick(() => {
        if (newVal === true && this.id ) {
          this.getVideoTypeList()
          this.getGeneralVideoInfo()
        }else if(newVal === true && !this.id && this.isBatchGroup) {
          this.getVideoTypeList()
        }
      })
    }
  },
  created() {

  },
  mounted() {
    
  },
  methods: {
    submit() {
      if (!this.submitFlag) {
        return
      }

      this.$refs.insertForm.validate((valid) => {
        if (valid) {
          const parm = this.form
          this.submitFlag = false

          if(!this.id){
            // 转换为包含 ID 和 type 的对象数组
            const params = this.ids.map((id) => ({
              id: id,
              videoTypeId: this.form.videoTypeId 
            }));

            groupBatchGeneralVideoInfo(params, this.timeStamp).then(({ res }) => {
              this.submitSuccess(res)
              this.form.id = ''
            }).catch((error)=> {
                this.submitFailure(error)
            })
          }else{
            updateGeneralVideoInfo(parm, this.timeStamp).then(({ res }) => {
              this.submitSuccess(res)
              this.form.id = ''
            }).catch((error)=> {
                this.submitFailure(error)
            })
          }
          
      } else{
        this.submitFlag = true
      }
    })
  },
    submitFailure(error) {
      this.submitFlag = true
    },
    submitSuccess(res) {
      this.submitFlag = true
      this.$message({ ...this.commonConfig.$handleSuccess })
      this.$emit('submit')
      this.updateVisible(false)
    },
    getVideoTypeList() {
      getGeneralVideoTypeListNoPage().then(({ res }) => {
        console.log('getGeneralVideoTypeListNoPage--', res)
        this.videoTypeList = res.videoTypeResList
      })
    },
    getGeneralVideoInfo() {
      const param = {}
      if (this.id) {
        param.id = this.id
      }
      getGeneralVideoInfo(param).then(({ res, timeStamp }) => {
        this.timeStamp = timeStamp
        this.form = res
      })
    },
    updateVisible(val) {
      this.visible = val
      if (val === false) {
        this.$refs.insertForm.resetFields()
        this.form.id = ''
      }
    },
  }
}
</script>
<style scoped lang='scss'>
@import "~@/styles/variables.scss";

.imgBox {
  width: 150px;
  height: 150px;
  border-radius: 4px;
  object-fit: contain;
  border: 1px solid #333;
}
.defalutImg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  border: 1px solid #333;
  border-radius: 4px;
  cursor: pointer;
}

.id-card-img {
  width: 145px;
  height: 120px;
}
.el-col {
margin-bottom: 5px;
}

.delete-btn {
// position: relative;
width: 18px;
height: 18px;
font-size: 18px;
color: red;
position: absolute;
margin-top: 4px;
cursor: pointer;
}
</style>

