<!--
 * @Author: your name
 * @Date: 2022-03-21 11:50:58
 * @LastEditTime: 2022-04-25 14:49:21
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /recovery_home_management/src/views/rehabilitation/classify/detailDialog.vue
-->

<template>
    <el-dialog
        width="60%"
        title="康复指导视频设备详情"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        custom-class="basicDialog"
        append-to-body
    >
        <el-descriptions class="margin-top" title="基本信息" :column="4">
            <el-descriptions-item label="名称">{{
                detail.name
            }}</el-descriptions-item>
            <el-descriptions-item label="类型">
                <el-tag size="small">{{ detail.identifier }}</el-tag>
            </el-descriptions-item>
            <el-descriptions-item label="肢体类型">{{
                detail.limbsTypeLabel
            }}</el-descriptions-item>
            <el-descriptions-item label="治疗类型">{{
                detail.treatmentTypeLabel
            }}</el-descriptions-item>
            <el-descriptions-item label="方案等级">{{
                detail.guidanceLevelLabel
            }}</el-descriptions-item>
            <el-descriptions-item label="排序">{{
                detail.sort
            }}</el-descriptions-item>
            <el-descriptions-item label="视频" :span="4">
                <video
                    :src="commonConfig.$filePublicUrl + detail.url"
                    controls
                    width="100%"
                    height="350px"
                ></video>
            </el-descriptions-item>
            <el-descriptions-item label="封面图片">
                <el-image
                    class="imgBox"
                    :src="commonConfig.$filePublicUrl + detail.photo"
                    :preview-src-list="[
                        commonConfig.$filePublicUrl + detail.photo,
                    ]"
                    fit="contain"
                />
            </el-descriptions-item>

            <el-descriptions-item label="次数（每天几次)"
                >{{ detail.number }}次
            </el-descriptions-item>
            <el-descriptions-item label="时长（一次多长分钟）">
                {{ detail.length }}分钟
            </el-descriptions-item>
            <el-descriptions-item label="锻炼时长（锻炼几天）"
                >{{ detail.lengthExercise }}天
            </el-descriptions-item>
            <el-descriptions-item label="描述" :span="4">{{
                detail.describe
            }}</el-descriptions-item>
        </el-descriptions>
        <template slot="footer" class="dialog-footer">
            <el-button @click="updateVisible(false)">取 消</el-button>
        </template>
    </el-dialog>
</template>
<script>
import { getDetail } from "@/api/videoEquipment";
export default {
    props: {
        id: {
            type: String,
            required: true,
        },
        identifierArray: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            visible: false,
            detail: [], // 详情数据
        };
    },
    watch: {
        visible(newVal, oldVal) {
            if (newVal && this.id) this.getDetail();
        },
    },
    created() {},
    mounted() {},
    methods: {
        getDetail() {
            getDetail({ id: this.id }).then(({ res }) => {
                this.detail = res;
                const currentType = this.identifierArray.filter(
                    (type) => type.value === res.identifier
                );
                if (currentType.length) {
                    this.detail.identifier = currentType[0].label;
                }
            });
        },
        updateVisible(flag) {
            this.visible = flag;
        },
    },
};
</script>
<style scoped lang='scss'>
@import "~@/styles/variables.scss";

.imgBox {
    width: 150px;
    height: 150px;
    object-fit: contain;
    border: 1px solid $tableColor;
}
</style>
